import { request } from '@/api/_service.js';
export function listPurchaseOrder(params) {
  return request({
    url: '/purchaseOrderErp/getPurchaseOrderList',
    method: 'get',
    params: params
  });
}
export function listPurchaseOrderExport(params) {
  return request({
    url: '/purchaseOrderErp/getPurchaseOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function createPurchaseOrder(data) {
  return request({
    url: '/purchaseOrderErp/createPurchaseOrder',
    method: 'post',
    data: data
  });
}
export function updatePurchaseOrder(data) {
  return request({
    url: '/purchaseOrderErp/updatePurchaseOrder',
    method: 'put',
    data: data
  });
}
export function deletePurchaseOrder(data) {
  return request({
    url: '/purchaseOrderErp/deletePurchaseOrder',
    method: 'delete',
    data: data
  });
}
export function findPurchaseOrder(params) {
  return request({
    url: '/purchaseOrderErp/findPurchaseOrder',
    method: 'get',
    params: params
  });
}
export function checkPurchaseOrder(data) {
  return request({
    url: '/purchaseOrderErp/updatePurchaseOrderState',
    method: 'put',
    data: data
  });
}
export function checkPurchaseOrderOff(data) {
  return request({
    url: '/purchaseOrderErp/updatePurchaseOrderOff',
    method: 'put',
    data: data
  });
}
export function listPurchaseOrderDetail(params) {
  return request({
    url: '/purchaseOrderDetailErp/getPurchaseOrderDetailList',
    method: 'get',
    params: params
  });
}
export function createPurchaseOrderDetail(data) {
  return request({
    url: '/purchaseOrderDetailErp/createPurchaseOrderDetail',
    method: 'post',
    data: data
  });
}
export function createPurchaseOrderDetails(data) {
  return request({
    url: '/purchaseOrderDetailErp/createPurchaseOrderDetails',
    method: 'post',
    data: data
  });
}
export function deletePurchaseOrderDetail(data) {
  return request({
    url: '/purchaseOrderDetailErp/deletePurchaseOrderDetail',
    method: 'delete',
    data: data
  });
}
export function updatePurchaseOrderDetail(data) {
  return request({
    url: '/purchaseOrderDetailErp/updatePurchaseOrderDetail',
    method: 'put',
    data: data
  });
}
export function createInvoice(data) {
  return request({
    url: '/purchaseInvoiceErp/createPurchaseInvoice',
    method: 'post',
    data: data
  });
}
export function updateInvoice(data) {
  return request({
    url: '/purchaseInvoiceErp/updatePurchaseInvoice',
    method: 'put',
    data: data
  });
}
export function listInvoice(params) {
  return request({
    url: '/purchaseInvoiceErp/getPurchaseInvoiceList',
    method: 'get',
    params: params
  });
}
export function findInvoice(params) {
  return request({
    url: '/purchaseInvoiceErp/findPurchaseInvoice',
    method: 'get',
    params: params
  });
}
export function deleteInvoice(data) {
  return request({
    url: '/purchaseInvoiceErp/deletePurchaseInvoice',
    method: 'delete',
    data: data
  });
}
export function listReceiveOrder(params) {
  return request({
    url: '/purchaseReceivErp/getPurchaseReceivList',
    method: 'get',
    params: params
  });
}
export function findReceiveOrder(params) {
  return request({
    url: '/purchaseReceivErp/findPurchaseReceiv',
    method: 'get',
    params: params
  });
}
export function createReceiveOrder(data) {
  return request({
    url: '/purchaseReceivErp/createPurchaseReceiv',
    method: 'post',
    data: data
  });
}
export function deleteReceiveOrder(data) {
  return request({
    url: '/purchaseReceivErp/deletePurchaseReceiv',
    method: 'delete',
    data: data
  });
}
export function listReceiveOrderDetail(params) {
  return request({
    url: '/purchaseReceivDetailErp/getPurchaseReceivDetailList',
    method: 'get',
    params: params
  });
}
export function createPurchaseReceivDetail(data) {
  return request({
    url: '/purchaseReceivDetailErp/createPurchaseReceivDetail',
    method: 'post',
    data: data
  });
}
export function createPurchaseReceivDetails(data) {
  return request({
    url: '/purchaseReceivDetailErp/createPurchaseReceivDetails',
    method: 'post',
    data: data
  });
}
export function listAccept(params) {
  return request({
    url: '/purchaseAcceptErp/getPurchaseAcceptList',
    method: 'get',
    params: params
  });
}
export function listAcceptExport(params) {
  return request({
    url: '/purchaseAcceptDetailErp/getPurchaseAcceptDetailList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateAccept(data) {
  return request({
    url: '/purchaseAcceptErp/updatePurchaseAccept',
    method: 'put',
    data: data
  });
}
export function checkAccept(data) {
  return request({
    url: '/purchaseAcceptErp/updatePurchaseAcceptState',
    method: 'put',
    data: data
  });
}
export function listAcceptDetail(params) {
  return request({
    url: '/purchaseAcceptDetailErp/getPurchaseAcceptDetailList',
    method: 'get',
    params: params
  });
}
export function updateAcceptDetail(data) {
  return request({
    url: '/purchaseAcceptDetailErp/updatePurchaseAcceptDetail',
    method: 'put',
    data: data
  });
}
export function createInWarehouseInfoAccept(data) {
  return request({
    url: '/inWarehouseInfoAcceptErp/createInWarehouseInfoAccept',
    method: 'post',
    data: data
  });
}
export function createInWarehouseInfoAccepts(data) {
  return request({
    url: '/inWarehouseInfoAcceptErp/createInWarehouseInfoAccepts',
    method: 'post',
    data: data
  });
}
export function updateInWarehouseInfoAccept(data) {
  return request({
    url: '/inWarehouseInfoAcceptErp/updateInWarehouseInfoAccept',
    method: 'put',
    data: data
  });
}
export function downloadPurchaseOrderTemplate(params) {
  return request({
    url: '/purchaseOrderDetailErp/getPurchaseOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updatePurchaseOrderCompleted(data) {
  return request({
    url: '/purchaseOrderErp/updatePurchaseOrderCompleted',
    method: 'put',
    data: data
  });
}
export function listPurchaseOrderPayList(params) {
  return request({
    url: '/purchaseOrderErp/getPurchaseOrderPayList',
    method: 'get',
    params: params
  });
}