var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "100px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入仓库名称", clearable: "" },
                        model: {
                          value: _vm.itemForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "name", $$v)
                          },
                          expression: "itemForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库地址", prop: "addressDetail" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入仓库地址",
                          type: "textarea",
                          rows: 2,
                          clearable: ""
                        },
                        on: { input: _vm.mapSearch },
                        model: {
                          value: _vm.itemForm.addressDetail,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "addressDetail", $$v)
                          },
                          expression: "itemForm.addressDetail"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "负责人", prop: "warehouseKeepers" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择负责人"
                          },
                          model: {
                            value: _vm.itemForm.warehouseKeepers,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "warehouseKeepers", $$v)
                            },
                            expression: "itemForm.warehouseKeepers"
                          }
                        },
                        _vm._l(_vm.userList, function(item) {
                          return _c("el-option", {
                            key: item.uid,
                            attrs: { label: item.name, value: item.uid }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "contactNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系电话", clearable: "" },
                        model: {
                          value: _vm.itemForm.contactNum,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "contactNum", $$v)
                          },
                          expression: "itemForm.contactNum"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "省份", prop: "provinceCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择省份" },
                          on: { change: _vm.provinceChange },
                          model: {
                            value: _vm.itemForm.provinceCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "provinceCode", $$v)
                            },
                            expression: "itemForm.provinceCode"
                          }
                        },
                        _vm._l(_vm.provinceList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "城市", prop: "cityCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择城市",
                            disabled: !_vm.itemForm.provinceCode
                          },
                          on: { change: _vm.cityChange },
                          model: {
                            value: _vm.itemForm.cityCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "cityCode", $$v)
                            },
                            expression: "itemForm.cityCode"
                          }
                        },
                        _vm._l(_vm.cityList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域", prop: "districtCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择街道/区",
                            disabled: !_vm.itemForm.cityCode
                          },
                          on: { change: _vm.changeSearch },
                          model: {
                            value: _vm.itemForm.districtCode,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "districtCode", $$v)
                            },
                            expression: "itemForm.districtCode"
                          }
                        },
                        _vm._l(_vm.districtList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.code }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "经纬度坐标", required: true } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: {
                                    "label-width": "0",
                                    label: "",
                                    prop: "longitude"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入经度",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.itemForm.longitude,
                                      callback: function($$v) {
                                        _vm.$set(_vm.itemForm, "longitude", $$v)
                                      },
                                      expression: "itemForm.longitude"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 2, align: "center" } },
                            [_vm._v("-")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: {
                                    "label-width": "0",
                                    label: "",
                                    prop: "latitude"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入纬度",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.itemForm.latitude,
                                      callback: function($$v) {
                                        _vm.$set(_vm.itemForm, "latitude", $$v)
                                      },
                                      expression: "itemForm.latitude"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  !_vm.mapLoading
                    ? _c(
                        "baidu-map",
                        {
                          key: "map" + _vm.timestamp,
                          ref: "baiduMapRef",
                          staticClass: "map",
                          staticStyle: { margin: "0 20px" },
                          attrs: {
                            zoom: _vm.zoom,
                            "scroll-wheel-zoom": true,
                            ak: "33B192o1jPaqOHASGGAIkoMuwi8W76j3"
                          },
                          on: { ready: _vm.mapReady, click: _vm.mapClick }
                        },
                        [
                          _c("bm-geolocation", {
                            attrs: {
                              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
                              showAddressBar: true,
                              autoLocation: true
                            },
                            on: { locationSuccess: _vm.mapLoctionSuccess }
                          }),
                          _c("bm-view", {
                            style: { width: "100%", height: "380px" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }