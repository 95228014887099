var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CommonComponent", {
    ref: "CommonComponentRef",
    attrs: { id: _vm.id }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }