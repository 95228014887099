import { request } from '@/api/_service.js'; // 结转成本报表

export function listCost(params) {
  return request({
    url: '/costErp/getCostList',
    method: 'get',
    params: params
  });
} // 结转成本报表导出

export function listCostExport(params) {
  return request({
    url: '/costErp/getCostList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 销售报表

export function listSalesReport(params) {
  return request({
    url: '/sale/getSalesInfoListReport',
    method: 'get',
    params: params
  });
} // 销售报表导出

export function listSalesReportExport(params) {
  return request({
    url: '/sale/getSalesInfoListReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 出库消耗报表

export function listSalesConsumeReport(params) {
  return request({
    url: '/sale/getSalesInfoListConsumeReport',
    method: 'get',
    params: params
  });
} // 出库消耗报表导出

export function listSalesConsumeReportExport(params) {
  return request({
    url: '/sale/getSalesInfoListConsumeReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 入库成本报表

export function listInWarehouseCostReport(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoListReport',
    method: 'get',
    params: params
  });
} // 入库成本报表导出

export function listInWarehouseCostReportExport(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoListReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 盘点差异报表

export function listDiffReport(params) {
  return request({
    url: '/inventoryCountingInfoErp/getInventoryCountingInfoListReport',
    method: 'get',
    params: params
  });
} // 盘点差异报表导出

export function listDiffReportExport(params) {
  return request({
    url: '/inventoryCountingInfoErp/getInventoryCountingInfoListReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 入库报表

export function listInWarehouseReport(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoListReport',
    method: 'get',
    params: params
  });
} // 入库报表导出

export function listInWarehouseReportExport(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoListReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 出库报表

export function listOutWarehouseReport(params) {
  return request({
    url: '/stock/getStockoutInfoListReport',
    method: 'get',
    params: params
  });
} // 出库报表导出

export function listOutWarehouseReportExport(params) {
  return request({
    url: '/stock/getStockoutInfoListReport',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 历史库存

export function listStockHistory(params) {
  return request({
    url: '/mainStockHisErp/getMainStockHisList',
    method: 'get',
    params: params
  });
} // 历史库存导出

export function listStockHistoryExport(params) {
  return request({
    url: '/mainStockHisErp/getMainStockHisList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}