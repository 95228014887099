import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import d2Container from './d2-container';
import d2ContainerFrame from './d2-container-frame';
import d2LinkBtn from './d2-link-btn'; // 注意 有些组件使用异步加载会有影响

Vue.component('d2-container', d2Container);
Vue.component('d2-container-frame', d2ContainerFrame);
Vue.component('d2-link-btn', d2LinkBtn);
Vue.component('d2-count-up', function () {
  return import('./d2-count-up');
});
Vue.component('d2-highlight', function () {
  return import('./d2-highlight');
});
Vue.component('d2-icon', function () {
  return import('./d2-icon');
});
Vue.component('d2-icon-svg', function () {
  return import('./d2-icon-svg/index.vue');
});
Vue.component('d2-icon-select', function () {
  return import('./d2-icon-select/index.vue');
});
Vue.component('d2-icon-svg-select', function () {
  return import('./d2-icon-svg-select/index.vue');
});
Vue.component('d2-markdown', function () {
  return import('./d2-markdown');
});
Vue.component('d2-mde', function () {
  return import('./d2-mde');
});
Vue.component('d2-module-index-banner', function () {
  return import('./d2-module-index-banner');
});
Vue.component('d2-module-index-menu', function () {
  return import('./d2-module-index-menu');
});
Vue.component('d2-quill', function () {
  return import('./d2-quill');
});
Vue.component('d2-scrollbar', function () {
  return import('./d2-scrollbar');
}); // Vue.component('d2-ueditor', () => import('./d2-ueditor'))