var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "350px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          attrs: { model: _vm.rowInfo, "label-width": "auto", size: "small" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品编号" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.rowInfo.specs.productNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.rowInfo.specs, "productNumber", $$v)
                  },
                  expression: "rowInfo.specs.productNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品名称" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.rowInfo.main.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.rowInfo.main, "productName", $$v)
                  },
                  expression: "rowInfo.main.productName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规格型号" } },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.rowInfo.specs.specificationModel,
                  callback: function($$v) {
                    _vm.$set(_vm.rowInfo.specs, "specificationModel", $$v)
                  },
                  expression: "rowInfo.specs.specificationModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "容量" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.rowInfo.capacity,
                  callback: function($$v) {
                    _vm.$set(_vm.rowInfo, "capacity", $$v)
                  },
                  expression: "rowInfo.capacity"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.dialogClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }