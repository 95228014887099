var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "auto",
            size: "small",
            rules: _vm.formRules,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { type: "flex", gutter: 20 }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请分类名称", prop: "className" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "分类名称", clearable: "" },
                        model: {
                          value: _vm.form.className,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "className", $$v)
                          },
                          expression: "form.className"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "申请分类简称", prop: "classNameShort" }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "分类简称", clearable: "" },
                        model: {
                          value: _vm.form.classNameShort,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "classNameShort", $$v)
                          },
                          expression: "form.classNameShort"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, align: "center" } },
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("取消")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }