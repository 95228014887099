var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "450px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      !_vm.createdResult.ID
        ? _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-suffix": ":",
                "label-width": "170px",
                size: "small"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台代码", prop: "platformCode" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.form.platformCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "platformCode", $$v)
                      },
                      expression: "form.platformCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "平台映射ERP供应商", prop: "manufacturerId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", clearable: "" },
                      model: {
                        value: _vm.form.manufacturerId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "manufacturerId", $$v)
                        },
                        expression: "form.manufacturerId"
                      }
                    },
                    _vm._l(_vm.supplierList, function(item, index) {
                      return _c("el-option", {
                        key: "manufacturerId" + index,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "平台映射ERP归属公司", prop: "subsidiaryId" }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", clearable: "" },
                      model: {
                        value: _vm.form.subsidiaryId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "subsidiaryId", $$v)
                        },
                        expression: "form.subsidiaryId"
                      }
                    },
                    _vm._l(_vm.subsidiaryList, function(item, index) {
                      return _c("el-option", {
                        key: "subsidiaryId" + index,
                        attrs: { label: item.companyName, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "0", align: "center" } },
                [
                  _c("el-button", { on: { click: _vm.dialogClose } }, [
                    _vm._v("关闭")
                  ]),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", loading: _vm.formLoading },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "el-form",
            {
              attrs: {
                "label-suffix": ":",
                "label-width": "170px",
                size: "small"
              }
            },
            [
              _c(
                "h3",
                { staticClass: "text-success", attrs: { align: "center" } },
                [_vm._v("绑定成功")]
              ),
              _c("el-form-item", { attrs: { label: "绑定的平台" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.createdResult.platformOrganization.name) +
                    " "
                )
              ]),
              _c("el-form-item", { attrs: { label: "绑定的平台经销商" } }, [
                _vm._v(
                  " " + _vm._s(_vm.createdResult.platformDistributor.name) + " "
                )
              ]),
              _c("el-form-item", { attrs: { label: "平台映射ERP供应商" } }, [
                _vm._v(" " + _vm._s(_vm.createdResult.manu.name) + " ")
              ]),
              _c("el-form-item", { attrs: { label: "平台映射ERP归属公司" } }, [
                _vm._v(
                  " " + _vm._s(_vm.createdResult.subsidiary.companyName) + " "
                )
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "0", align: "center" } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }