var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading"
        }
      ],
      ref: "form",
      attrs: {
        model: _vm.form,
        rules: _vm.formRules,
        "label-width": "0px",
        "show-message": false,
        "label-suffix": ":"
      },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.formSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "deliveryOrderNo", "label-width": "0px" } },
        [
          _c("el-input", {
            ref: "importInputBoxRef",
            staticClass: "import-input-box",
            attrs: { placeholder: "请输入、扫描出库单号", clearable: "" },
            model: {
              value: _vm.form.deliveryOrderNo,
              callback: function($$v) {
                _vm.$set(_vm.form, "deliveryOrderNo", $$v)
              },
              expression: "form.deliveryOrderNo"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }