import _objectSpread from "D:/wwwroot/ERP\u524D\u7AEF/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/purchase',
  name: 'purchase',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'index',
    name: 'purchase-index',
    component: _import('purchase/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购订单'
    })
  }, {
    path: 'create',
    name: 'purchase-create',
    component: _import('purchase/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增订单'
    })
  }, {
    path: 'detail/:id',
    name: 'purchase-detail',
    component: _import('purchase/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '订单详情'
    }),
    props: true
  }, {
    path: 'receipt',
    name: 'purchase-receipt',
    component: _import('purchase/receipt'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '收货单'
    })
  }, {
    path: 'receipt/create/:purchaseOrderId',
    name: 'purchase-receipt-create',
    component: _import('purchase/receipt/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '创建收货单'
    }),
    props: true
  }, {
    path: 'receipt/detail/:id',
    name: 'purchase-receipt-detail',
    component: _import('purchase/receipt/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '收货单详情'
    }),
    props: true
  }, {
    path: 'report',
    name: 'purchase-report',
    component: _import('purchase/report'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '验收单'
    })
  }, {
    path: 'report/detail/:id',
    name: 'purchase-report-detail',
    component: _import('purchase/report/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '验收单详情'
    }),
    props: true
  }, {
    path: 'payment/index',
    name: 'purchase-payment-index',
    component: _import('purchase/payment/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '付款申请'
    }),
    props: true
  }, {
    path: 'payment/records',
    name: 'purchase-payment-records',
    component: _import('purchase/payment/records'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '付款申请记录'
    }),
    props: true
  }, {
    path: 'dues',
    name: 'purchase-dues-index',
    component: _import('purchase/dues'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '应付管理'
    }),
    props: true
  }, {
    path: 'balance',
    name: 'purchase-balance-index',
    component: _import('purchase/balance'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '余额管理'
    }),
    props: true
  }]
};