import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import Vue from 'vue';

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

var req = require.context('./icons', false, /\.svg$/);

var iconMap = requireAll(req);
Vue.prototype.$IconSvg = iconMap.map(function (e) {
  return e.default.id.slice(3);
});