var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.searchForm,
            "label-width": "100px",
            size: "small",
            inline: true,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchFormSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "批号" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.searchForm.batch_number,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "batch_number", $$v)
                  },
                  expression: "searchForm.batch_number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "序列号" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.searchForm.trackingCode,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "trackingCode", $$v)
                  },
                  expression: "searchForm.trackingCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.searchFormSubmit }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "replenishTable",
          attrs: {
            data: _vm.stockList,
            size: "mini",
            border: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "批号", prop: "batch_number", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "序列号", prop: "trackingCode", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产日期",
              prop: "manufacturer_date",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "有效期", prop: "validity_date", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "可用数量",
              prop: "normalQuantity",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "耗材包可用数",
              prop: "quantityUsed",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "补充数量", prop: "addQuantity", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input-number", {
                      attrs: { size: "mini", min: 0 },
                      model: {
                        value: scope.row.addQuantity,
                        callback: function($$v) {
                          _vm.$set(scope.row, "addQuantity", _vm._n($$v))
                        },
                        expression: "scope.row.addQuantity"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, total",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize
            },
            on: { "current-change": _vm.searchFormPageChange }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: { align: "center" }
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }