import { request } from '@/api/_service.js';
import Qs from 'qs'; // 库存总览

export function listStockSummary(params) {
  return request({
    url: '/stock/getStockMainGroupList',
    method: 'get',
    params: params
  });
} // 库存总览，导出EXCEL

export function listStockSummaryExport(params) {
  return request({
    url: '/stock/getStockMainGroupList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 库存明细

export function listStockDetail(params) {
  return request({
    url: '/stock/getStockMainList',
    method: 'get',
    params: params
  });
} // 库存明细，导出EXCEL

export function listStockDetailExport(params) {
  return request({
    url: '/stock/getStockMainList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 下载模板

export function downloadWarningTemplate(params) {
  return request({
    url: '/stockMainWarningErp/getTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 库存汇总（批号）

export function listStockSummaryBatch(params) {
  return request({
    url: '/stock/getStockMainBatchNoList',
    method: 'get',
    params: params
  });
} // 库存汇总（批号），导出EXCEL

export function listStockSummaryBatchExport(params) {
  return request({
    url: '/stock/getStockMainBatchNoList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 库存汇总（规格）

export function listStockSummarySpecial(params) {
  return request({
    url: '/stock/getStockMainSpecList',
    method: 'get',
    params: params
  });
} // 库存汇总（规格），导出EXCEL

export function listStockSummarySpecialExport(params) {
  return request({
    url: '/stock/getStockMainSpecList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 入库单列表

export function listInWarehouse(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseList',
    method: 'get',
    params: params
  });
} // 入库单列表

export function listInWarehouseExport(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 效期预警/库存预警
// validType（不传就是库存预警）:0=库存预警,1=已过期，2=7天内过期，3=1个月内过期，4=3个月内过期，5=6个月内过期，6=9个月内过期，7=12个月内过期，8=大于12个月内过期

export function listWarning(params) {
  return request({
    url: '/stock/stockMainValidPeriodWarning',
    method: 'get',
    params: params
  });
}
export function listWarningExport(params) {
  return request({
    url: '/stock/stockMainValidPeriodWarning',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 更新库存高低储和摆放位置

export function updateWarning(data) {
  return request({
    url: '/stock/updateStockMain',
    method: 'put',
    data: data
  });
} // 入库单列表

export function listInwarehouseOrder(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseList',
    method: 'get',
    params: params
  });
} // 创建入库单

export function createInwarehouseOrder(data) {
  return request({
    url: '/erpInWarehouse/createFaErpInWarehouse',
    method: 'post',
    data: data
  });
} // 更新入库单

export function updateInwarehouseOrder(data) {
  return request({
    url: '/erpInWarehouse/updateFaErpInWarehouse',
    method: 'put',
    data: data
  });
}
export function deleteInwarehouse(data) {
  return request({
    url: '/erpInWarehouse/deleteFaErpInWarehouse',
    method: 'delete',
    data: data
  });
} // 审核、记账入库单
// inWarehouseStatus入库状态 1.暂存 2.已提交 3.已审核 4.已记账 5.已撤销 6.已作废

export function checkInwarehouseOrder(data) {
  return request({
    url: '/erpInWarehouse/examineFaErpInWarehouse',
    method: 'put',
    data: data
  });
} // 查找入库单

export function findInwarehouseOrder(params) {
  return request({
    url: '/erpInWarehouse/findFaErpInWarehouse',
    method: 'get',
    params: params
  });
} // 耗材入库明细列表

export function listInwarehouseDetail(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoList',
    method: 'get',
    params: params
  });
} // 创建耗材入库明细

export function createInwarehouseDetail(data) {
  return request({
    url: '/erpInWarehouse/createFaErpInWarehouseInfo',
    method: 'post',
    data: data
  });
}
export function createInwarehouseDetails(data) {
  return request({
    url: '/erpInWarehouse/createFaErpInWarehouseInfos',
    method: 'post',
    data: data
  });
} // 更新耗材入库明细

export function updateInwarehouseDetail(data) {
  return request({
    url: '/erpInWarehouse/updateFaErpInWarehouseInfo',
    method: 'put',
    data: data
  });
}
export function updateInwarehouseDetails(data) {
  return request({
    url: '/erpInWarehouse/updateFaErpInWarehouseInfos',
    method: 'post',
    data: data
  });
} // 查询入库明细列表

export function findInwarehouseDetail(params) {
  return request({
    url: '/erpInWarehouse/findFaErpInWarehouseInfo',
    method: 'get',
    params: params
  });
} // 删除耗材入库明细

export function deleteInwarehouseDetail(data) {
  return request({
    url: '/erpInWarehouse/deleteFaErpInWarehouseInfo',
    method: 'delete',
    data: data
  });
} // 批量删除耗材入库明细

export function deleteInwarehouseDetailByIds(data) {
  return request({
    url: '/erpInWarehouse/deleteFaErpInWarehouseInfoByIds',
    method: 'delete',
    data: data
  });
} // 出库单列表

export function listOutWarehouseOrder(params) {
  return request({
    url: '/stock/getStockoutList',
    method: 'get',
    params: params
  });
}
export function listOutWarehouseOrderExport(params) {
  return request({
    url: '/stock/getStockoutList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 查找出库单

export function findOutWarehouseOrder(params) {
  return request({
    url: '/stock/findStockout',
    method: 'get',
    params: params
  });
} // 创建出库单

export function createOutWarehouseOrder(data) {
  return request({
    url: '/stock/createStockout',
    method: 'post',
    data: data
  });
} // 更新出库单

export function updateOutWarehouseOrder(data) {
  return request({
    url: '/stock/updateStockout',
    method: 'put',
    data: data
  });
} // 审核出库单

export function checkOutWarehouseOrder(data) {
  return request({
    url: '/stock/updateStockoutStatus',
    method: 'put',
    data: data
  });
} // 部分回库

export function updateStockoutPartialReturn(data) {
  return request({
    url: '/stock/updateStockoutPartialReturn',
    method: 'put',
    data: data
  });
} // 全部回库

export function endStockoutPartialReturn(data) {
  return request({
    url: '/stock/endStockoutPartialReturn',
    method: 'put',
    data: data
  });
} // 检测出库提醒状态

export function updateStockoutStatusContinue(data) {
  return request({
    url: '/stock/updateStockoutStatusContinue',
    method: 'put',
    data: data
  });
} // 删除出库订单

export function deleteOutWarehouseOrder(data) {
  return request({
    url: '/stock/deleteStockoutByIds',
    method: 'delete',
    data: data
  });
} // 耗材出库明细

export function listOutWarehouseDetail(params) {
  return request({
    url: '/stock/getStockoutInfoList',
    method: 'get',
    params: params
  });
} // 新增耗材出库明细

export function createOutWarehouseDetail(data) {
  return request({
    url: '/stock/createStockoutInfo',
    method: 'post',
    data: data
  });
} // 批量新增耗材出库明细

export function createOutWarehouseDetails(data) {
  return request({
    url: '/stock/createStockoutInfos',
    method: 'post',
    data: data
  });
}
export function createSellOrderDetails(data) {
  return request({
    url: '/sellOrderDetailErp/createSellOrderDetails',
    method: 'post',
    data: data
  });
} // 修改耗材出库明细

export function updateOutWarehouseDetail(data) {
  return request({
    url: '/stock/updateStockoutInfo',
    method: 'put',
    data: data
  });
} // 删除耗材出库明细

export function deleteOutWarehouseDetail(data) {
  return request({
    url: '/stock/deleteStockoutInfo',
    method: 'delete',
    data: data
  });
} // 批量删除耗材出库明细

export function deleteOutWarehouseDetailByIds(data) {
  return request({
    url: '/stock/deleteStockoutInfoByIds',
    method: 'delete',
    data: data
  });
}
export function deleteOutWarehouseDetailById(data) {
  return request({
    url: '/stock/deleteStockoutInfoById',
    method: 'delete',
    data: data
  });
} // 调拨出库列表

export function listAllocateOutWarehouse(params) {
  return request({
    url: '/AlloErp/getAllocationList',
    method: 'get',
    params: params
  });
}
export function listAllocateOutWarehouseExport(params) {
  return request({
    url: '/AlloErp/getAllocationList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 调拨出库创建

export function createAllocateOutWarehouse(data) {
  return request({
    url: '/AlloErp/createAllocation',
    method: 'post',
    data: data
  });
} // 调拨出库更新

export function updateAllocateOutWarehouse(data) {
  return request({
    url: '/AlloErp/updateAllocation',
    method: 'put',
    data: data
  });
} // 调拨出库审核

export function checkAllocateOutWarehouse(data) {
  return request({
    url: '/AlloErp/updateDeliveryAllocation',
    method: 'put',
    data: data
  });
} // 调拨出库查询

export function findAllocateOutWarehouse(params) {
  return request({
    url: '/AlloErp/findAllocation',
    method: 'get',
    params: params
  });
} // 调拨入库审核

export function checkAllocateInWarehouse(data) {
  return request({
    url: '/AlloErp/updateWarehousingAllocation',
    method: 'put',
    data: data
  });
} // 调拨出库明细列表

export function listAllocateOutWarehouseDetail(params) {
  return request({
    url: '/allocationInfoErp/getAllocationInfoList',
    method: 'get',
    params: params
  });
}
export function listAllocateOutWarehouseDetailExport(params) {
  return request({
    url: '/allocationInfoErp/getAllocationInfoList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 调拨出库明细新增

export function createAllocateOutWarehouseDetail(data) {
  return request({
    url: '/allocationInfoErp/createAllocationInfo',
    method: 'post',
    data: data
  });
} // 调拨出库明细新增(批量)

export function createAllocateOutWarehouseDetails(data) {
  return request({
    url: '/allocationInfoErp/createAllocationInfos',
    method: 'post',
    data: data
  });
} // 调拨出库明细删除

export function deleteAllocateOutWarehouseDetail(data) {
  return request({
    url: '/allocationInfoErp/deleteAllocationInfo',
    method: 'delete',
    data: data
  });
} // 调拨出库明细查找

export function findAllocateOutWarehouseDetail(params) {
  return request({
    url: '/allocationInfoErp/findAllocationInfo',
    method: 'get',
    params: params
  });
} // 调拨出库明细更新

export function updateAllocateOutWarehouseDetail(data) {
  return request({
    url: '/allocationInfoErp/updateAllocationInfo',
    method: 'put',
    data: data
  });
} // 有台出库

export function youtaiOutAssign(data) {
  return request({
    url: 'http://testapi.y01.site/api/logistics/express_send',
    method: 'post',
    data: data
  });
} // 有台回库

export function youtaiInAssign(data) {
  return request({
    url: 'http://testapi.y01.site/api/logistics/express_send',
    method: 'post',
    data: data
  });
} // 库存盘点列表

export function listStockCheck(params) {
  return request({
    url: '/inventoryCountingErp/getInventoryCountingList',
    method: 'get',
    params: params
  });
} // 库存盘点创建

export function createStockCheck(data) {
  return request({
    url: '/inventoryCountingErp/createInventoryCounting',
    method: 'post',
    data: data
  });
} // 库存盘点更新

export function updateStockCheck(data) {
  return request({
    url: '/inventoryCountingErp/updateInventoryCounting',
    method: 'put',
    data: data
  });
} // 库存盘点审核

export function checkStockCheck(data) {
  return request({
    url: '/inventoryCountingErp/updateInventoryCountingDocumentStatus',
    method: 'put',
    data: data
  });
} // 库存盘点删除

export function deleteStockCheck(data) {
  return request({
    url: '/inventoryCountingErp/deleteInventoryCounting',
    method: 'delete',
    data: data
  });
} // 库存盘点查找

export function findStockCheck(params) {
  return request({
    url: '/inventoryCountingErp/findInventoryCounting',
    method: 'get',
    params: params
  });
} // 库存盘点明细列表

export function listStockCheckDetail(params) {
  return request({
    url: '/inventoryCountingInfoErp/getInventoryCountingInfoList',
    method: 'get',
    params: params
  });
} // 库存盘点明细查找

export function findStockCheckDetail(params) {
  return request({
    url: '/inventoryCountingInfoErp/findInventoryCountingInfo',
    method: 'get',
    params: params
  });
} // 库存盘点明细创建

export function createStockCheckDetail(data) {
  return request({
    url: '/inventoryCountingInfoErp/createInventoryCountingInfo',
    method: 'post',
    data: data
  });
} // 库存盘点明细批量创建

export function createStockCheckDetails(data) {
  return request({
    url: '/inventoryCountingInfoErp/createInventoryCountingInfos',
    method: 'post',
    data: data
  });
} // 库存盘点明细删除

export function deleteStockCheckDetail(data) {
  return request({
    url: '/inventoryCountingInfoErp/deleteInventoryCountingInfo',
    method: 'delete',
    data: data
  });
} // 库存盘点明细更新

export function updateStockCheckDetail(data) {
  return request({
    url: '/inventoryCountingInfoErp/updateInventoryCountingInfo',
    method: 'put',
    data: data
  });
} // 采购退货单列表

export function listReturnWarehouse(params) {
  return request({
    url: '/erpReturnWarehouse/getFaErpReturnWarehouseList',
    method: 'get',
    params: params
  });
}
export function listReturnWarehouseExport(params) {
  return request({
    url: '/erpReturnWarehouse/getFaErpReturnWarehouseList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 采购退货单查找

export function findReturnWarehouse(params) {
  return request({
    url: '/erpReturnWarehouse/findFaErpReturnWarehouse',
    method: 'get',
    params: params
  });
} // 采购退货单创建

export function createReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouse',
    method: 'post',
    data: data
  });
} // 采购退货单更新

export function updateReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouse/updateFaErpReturnWarehouse',
    method: 'put',
    data: data
  });
} // 采购退货单审核

export function checkReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouse/updateFaErpReturnWarehouseStatus',
    method: 'put',
    data: data
  });
} // 采购退货单删除

export function deleteReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouse/deleteFaErpReturnWarehouse',
    method: 'delete',
    data: data
  });
} // 创建平台寄售入库退货单

export function createFaErpReturnWarehouseDealer(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouseDealer',
    method: 'post',
    data: data
  });
} // 采购退货单批量删除

export function deleteReturnWarehouseByIds(data) {
  return request({
    url: '/erpReturnWarehouse/deleteFaErpReturnWarehouseByIds',
    method: 'delete',
    data: data
  });
} // 采购退货单明细列表

export function listReturnWarehouseDetail(params) {
  return request({
    url: '/erpReturnWarehouse/getFaErpReturnWarehouseInfoList',
    method: 'get',
    params: params
  });
} // 采购退货单明细列表

export function findReturnWarehouseDetail(params) {
  return request({
    url: '/erpReturnWarehouseInfo/findFaErpReturnWarehouseInfo',
    method: 'get',
    params: params
  });
} // 采购退货单明细创建

export function createReturnWarehouseDetail(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouseInfo',
    method: 'post',
    data: data
  });
} // 采购退货单明细批量创建

export function createReturnWarehouseDetails(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouseInfos',
    method: 'post',
    data: data
  });
} // 采购退货单明细更新

export function updateReturnWarehouseDetail(data) {
  return request({
    url: '/erpReturnWarehouse/updateFaErpReturnWarehouseInfo',
    method: 'put',
    data: data
  });
} // 采购退货单删除

export function deleteReturnWarehouseDetail(data) {
  return request({
    url: '/erpReturnWarehouse/deleteFaErpReturnWarehouseInfo',
    method: 'delete',
    data: data
  });
} // 采购退货单批量删除

export function deleteReturnWarehouseDetailByIds(data) {
  return request({
    url: '/erpReturnWarehouse/deleteFaErpReturnWarehouseInfoByIds',
    method: 'delete',
    data: data
  });
} // 销售退货单列表

export function listSaleReturn(params) {
  return request({
    url: '/salseReturnErp/getSalseReturnList',
    method: 'get',
    params: params
  });
}
export function listSaleReturnExport(params) {
  return request({
    url: '/salseReturnErp/getSalseReturnList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 销售退货单查找

export function findSaleReturn(params) {
  return request({
    url: '/salseReturnErp/findSalseReturn',
    method: 'get',
    params: params
  });
} // 销售退货单创建

export function createSaleReturn(data) {
  return request({
    url: '/salseReturnErp/createSalseReturn',
    method: 'post',
    data: data
  });
} // 销售退货单更新

export function updateSaleReturn(data) {
  return request({
    url: '/salseReturnErp/updateSalseReturn',
    method: 'put',
    data: data
  });
} // 销售退货单审核

export function checkSaleReturn(data) {
  return request({
    url: '/salseReturnErp/updateSalseReturnStatus',
    method: 'put',
    data: data
  });
} // 销售退货单删除

export function deleteSaleReturn(data) {
  return request({
    url: '/salseReturnErp/deleteSalseReturn',
    method: 'delete',
    data: data
  });
} // 销售退货单明细列表

export function listSaleReturnDetail(params) {
  return request({
    url: '/salseReturnInfoErp/getSalseReturnInfoList',
    method: 'get',
    params: params
  });
} // 销售退货单明细查找

export function findSaleReturnDetail(params) {
  return request({
    url: '/salseReturnInfoErp/findSalseReturnInfo',
    method: 'get',
    params: params
  });
} // 销售退货单明细创建

export function createSaleReturnDetail(data) {
  return request({
    url: '/salseReturnInfoErp/createSalseReturnInfo',
    method: 'post',
    data: data
  });
} // 销售退货单明细批量创建

export function createSaleReturnDetails(data) {
  return request({
    url: '/salseReturnInfoErp/createSalseReturnInfos',
    method: 'post',
    data: data
  });
} // 销售退货单明细更新

export function updateSaleReturnDetail(data) {
  return request({
    url: '/salseReturnInfoErp/updateSalseReturnInfo',
    method: 'put',
    data: data
  });
} // 销售退货单明细删除

export function deleteSaleReturnDetail(data) {
  return request({
    url: '/salseReturnInfoErp/deleteSalseReturnInfo',
    method: 'delete',
    data: data
  });
} // 销售单列表

export function listSales(params) {
  return request({
    url: '/sale/getSalesList',
    method: 'get',
    params: params
  });
} // 销售单查找

export function findSales(params) {
  return request({
    url: '/sale/findSales',
    method: 'get',
    params: params
  });
} // 销售单更新

export function updateSales(data) {
  return request({
    url: '/sale/updateSales',
    method: 'put',
    data: data
  });
} // 销售单审核

export function checkSales(data) {
  return request({
    url: '/sale/updateSalesStatus',
    method: 'put',
    data: data
  });
} // 销售单明细列表

export function listSalesDetail(params) {
  return request({
    url: '/sale/getSalesInfoList',
    method: 'get',
    params: params
  });
} // 销售单明细创建

export function createSalesDetail(data) {
  return request({
    url: '/sale/createSalesInfo',
    method: 'post',
    data: data
  });
} // 销售单明细删除

export function deleteSalesDetail(data) {
  return request({
    url: '/sale/deleteSalesInfo',
    method: 'delete',
    data: data
  });
} // 销售单明细更新

export function updateSalesDetail(data) {
  return request({
    url: '/sale/updateSalesInfo',
    method: 'put',
    data: data
  });
} // 下载库存模板

export function downloadStockTemplate(params) {
  return request({
    url: '/inside/stockDownloadTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 导入库存

export function importStockExcel(data) {
  return request({
    url: '/inside/uploadStockExcel',
    method: 'post',
    data: data
  });
}
export function importOpeningStockExcel(data) {
  return request({
    url: '/inside/uploadStocksExcel',
    method: 'post',
    data: data
  });
} // 撤消回库

export function cancelStockout(data) {
  return request({
    url: '/stock/cancelStockout',
    method: 'put',
    data: data
  });
}
export function updateOutWarehouseDetails(data) {
  return request({
    url: '/stock/updateStockoutInfos',
    method: 'put',
    data: data
  });
} // 更新出库单回库备注

export function updateStockoutReturnRemarks(data) {
  return request({
    url: '/stock/updateStockoutReturnRemarks',
    method: 'put',
    data: data
  });
} // 下载调拨出库模板

export function downloadAllocateTemplate(params) {
  return request({
    url: '/allocationInfoErp/getAllocationInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 创建销售退货单

export function createSalseReturnReport(data) {
  return request({
    url: '/salseReturnErp/createSalseReturnReport',
    method: 'post',
    data: data
  });
} // 审核销售退货单

export function updateSalseReturnStatus(data) {
  return request({
    url: '/salseReturnErp/updateSalseReturnStatus',
    method: 'put',
    data: data
  });
} // 批量创建销售退货单详情-V2

export function createSalseReturnInfosReport(data) {
  return request({
    url: '/salseReturnInfoErp/createSalseReturnInfosReport',
    method: 'post',
    data: data
  });
} // 创建采购退货单，原createInwarehouseOrder已弃用

export function createInwarehouseOrder2(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouseReport',
    method: 'post',
    data: data
  });
} // 批量创建采购退货单明细

export function createInwarehouseOrderDetails(data) {
  return request({
    url: '/erpReturnWarehouse/createFaErpReturnWarehouseInfosReport',
    method: 'post',
    data: data
  });
} // 下载耗材出库明细模板

export function downloadStockoutInfoTemplate(params) {
  return request({
    url: '/stock/getStockoutInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 查询订单流程

export function listProcessDetail(params) {
  return request({
    url: '/manageRecordErp/getManageRecordList',
    method: 'get',
    params: params
  });
}
export function getReturnWarehouseInfoTemplate(params) {
  return request({
    url: '/erpReturnWarehouse/getReturnWarehouseInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function getFaErpInWarehouseInfoTemplate(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listInWarehouseAcceptDetail(params) {
  return request({
    url: '/inWarehouseInfoAcceptErp/getInWarehouseInfoAcceptList',
    method: 'get',
    params: params
  });
}
export function deleteInWarehouseInfoAccept(data) {
  return request({
    url: '/inWarehouseInfoAcceptErp/deleteInWarehouseInfoAccept',
    method: 'delete',
    data: data
  });
}
export function listInWarehouseAcceptBatch(params) {
  return request({
    url: '/erpInWarehouse/getFaErpInWarehouseInfoList',
    method: 'get',
    params: params
  });
}
export function createInWarehouseAcceptBatch(data) {
  return request({
    url: '/erpInWarehouse/createFaErpInWarehouseInfo',
    method: 'post',
    data: data
  });
}
export function returnTransferStockoutInfo(data) {
  return request({
    url: '/stock/returnTransferStockoutInfo',
    method: 'put',
    data: data
  });
}
export function getSellOrderDetailDeliveryList(params) {
  return request({
    url: '/sellOrderDetailDeliveryErp/getSellOrderDetailDeliveryList',
    method: 'get',
    params: params
  });
}
export function createSellOrderDetailDeliverysStockout(data) {
  return request({
    url: '/sellOrderDetailDeliveryErp/createSellOrderDetailDeliverysStockout',
    method: 'post',
    data: data
  });
}
export function updateSellOrderDetailDelivery(data) {
  return request({
    url: '/sellOrderDetailDeliveryErp/updateSellOrderDetailDelivery',
    method: 'put',
    data: data
  });
}
export function listRestockOrder(params) {
  return request({
    url: '/replenishmentErp/getReplenishmentList',
    method: 'get',
    params: params
  });
}
export function listRestockOrderExport(params) {
  return request({
    url: '/replenishmentErp/getReplenishmentList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function createRestockOrder(data) {
  return request({
    url: '/replenishmentErp/createReplenishment',
    method: 'post',
    data: data
  });
}
export function updateRestockOrder(data) {
  return request({
    url: '/replenishmentErp/updateReplenishment',
    method: 'put',
    data: data
  });
}
export function checkRestockOrder(data) {
  return request({
    url: '/replenishmentErp/updateReplenishmentStatus',
    method: 'put',
    data: data
  });
}
export function deleteRestockOrder(data) {
  return request({
    url: '/replenishmentErp/deleteReplenishment',
    method: 'delete',
    data: data
  });
}
export function findRestockOrder(params) {
  return request({
    url: '/replenishmentErp/findReplenishment',
    method: 'get',
    params: params
  });
}
export function createRestockOrderDetail(data) {
  return request({
    url: '/replenishmentProErp/createReplenishmentPro',
    method: 'post',
    data: data
  });
}
export function updateRestockOrderDetail(data) {
  return request({
    url: '/replenishmentProErp/updateReplenishmentPro',
    method: 'put',
    data: data
  });
}
export function deleteRestockOrderDetail(data) {
  return request({
    url: '/replenishmentProErp/deleteReplenishmentPro',
    method: 'delete',
    data: data
  });
}
export function findRestockOrderDetail(params) {
  return request({
    url: '/replenishmentProErp/findReplenishmentPro',
    method: 'get',
    params: params
  });
}
export function listRestockOrderDetail(params) {
  return request({
    url: '/replenishmentProErp/getReplenishmentProList',
    method: 'get',
    params: params
  });
}
export function listRestockOrderDetailBatch(params) {
  return request({
    url: '/replenishmentDetailErp/getReplenishmentDetailList',
    method: 'get',
    params: params
  });
}
export function createRestockOrderDetailBatch(data) {
  return request({
    url: '/replenishmentDetailErp/createReplenishmentDetail',
    method: 'post',
    data: data
  });
}
export function updateRestockOrderDetailBatch(data) {
  return request({
    url: '/replenishmentDetailErp/updateReplenishmentDetail',
    method: 'put',
    data: data
  });
}
export function deleteRestockOrderDetailBatch(data) {
  return request({
    url: '/replenishmentDetailErp/deleteReplenishmentDetail',
    method: 'delete',
    data: data
  });
}
export function findDealerOrderErp(params) {
  return request({
    url: process.env.VUE_APP_FCS_API + '/dealerOrderErp/findDealerOrderErp',
    method: 'get',
    params: params
  });
}
export function createReplenishmentDetailRepId(data) {
  return request({
    url: '/replenishmentDetailErp/createReplenishmentDetailRepId',
    method: 'post',
    data: data
  });
}
export function findDealerPackingByNumber(params) {
  return request({
    url: process.env.VUE_APP_FCS_API + '/dealerPackingErp/findDealerPackingByNumber',
    method: 'get',
    params: params
  });
}
export function setEpDay(data) {
  return request({
    url: '/stock/setEpDay',
    method: 'put',
    data: data
  });
}
export function findStockMain(params) {
  return request({
    url: '/stock/findStockMain',
    method: 'get',
    params: params
  });
}
export function getStockMainWarningList(params) {
  return request({
    url: '/stockMainWarningErp/getStockMainWarningList',
    method: 'get',
    params: params
  });
}
export function getStockMainWarningListExport(params) {
  return request({
    url: '/stockMainWarningErp/getStockMainWarningList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 判断库存预警表中规格型号是否存在

export function specsIdsExists(params) {
  return request({
    url: '/stockMainWarningErp/specsIdsExists',
    method: 'get',
    params: params
  });
}
export function createStockMainWarning(data) {
  return request({
    url: '/stockMainWarningErp/createStockMainWarning',
    method: 'post',
    data: data
  });
}
export function batchCreateStockMainWarning(data) {
  return request({
    url: '/stockMainWarningErp/batchCreateStockMainWarning',
    method: 'post',
    data: data
  });
}
export function updateStockMainWarning(data) {
  return request({
    url: '/stockMainWarningErp/updateStockMainWarning',
    method: 'put',
    data: data
  });
} // 删除库存预警

export function deleteStockMainWarning(data) {
  return request({
    url: '/stockMainWarningErp/deleteStockMainWarning',
    method: 'delete',
    data: data
  });
}
export function deleteStockMainWarningByIds(data) {
  return request({
    url: '/stockMainWarningErp/deleteStockMainWarningByIds',
    method: 'delete',
    data: data
  });
}
export function listAgentSellOrderDetailDelivery(params) {
  return request({
    url: '/agentSellOrderDetailDeliveryErp/getAgentSellOrderDetailDeliveryList',
    method: 'get',
    params: params
  });
}
export function stockQualificationFiles(data) {
  return request({
    url: '/stock/stockQualificationFiles',
    method: 'put',
    data: data
  });
}
export function getFaErpReturnWarehouseInfoList(params) {
  return request({
    url: '/erpReturnWarehouse/getFaErpReturnWarehouseInfoList',
    method: 'get',
    params: params
  });
} // 分页获取有台销售单推荐产品规格列表

export function listOrderSaleReDetail(params) {
  return request({
    url: '/stock/getOrderSaleReDetailList',
    method: 'get',
    params: params
  });
}
export function getErpPrintingTemplatePolicyList(params) {
  return request({
    url: '/erpPrintingTemplatePolicy/getErpPrintingTemplatePolicyList',
    method: 'get',
    params: params
  });
}