var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("调拨管理")]),
              _c("el-breadcrumb-item", [_vm._v("调拨入库")]),
              _c("el-breadcrumb-item", [_vm._v("调拨入库单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              "label-suffix": ":",
              loading: _vm.formLoading,
              disabled: true
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "来源仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.sourceWarehouse))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "目标仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.targetWarehouse))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "归属公司" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.subsidiary.name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "配送方式" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.shippingMethodName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "快递单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.courierNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "运输费" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.freight))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货单位" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.consignee))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货地址" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.shipAddress))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "remark", label: "备注" } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.remark))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "font-size": "14px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c("div", [
              _c("span", [
                _vm._v("数量合计："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.sumTotalQuantity()))
                ])
              ]),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("成本合计："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.sumTotalAmount()))
                ])
              ])
            ])
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            slotIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.detailList.slice(
              (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
              _vm.detailForm.page * _vm.detailForm.pageSize
            ),
            rowClassName: _vm.rowClassName
          },
          on: {
            "row-click": _vm.detailRowClick,
            pageChange: _vm.pageChange,
            sizeChange: _vm.sizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var index = ref.index
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detailForm.total -
                          (_vm.detailForm.page - 1) * _vm.detailForm.pageSize -
                          index
                      ) +
                      " "
                  )
                ]
              }
            }
          ])
        })
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-row",
              {
                staticStyle: {
                  width: "100%",
                  "font-size": "12px",
                  color: "#666",
                  "margin-bottom": "10px"
                }
              },
              [
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v(" 入库人员：" + _vm._s(_vm.form.inWarehouseName) + " ")
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(" 入库时间：" + _vm._s(_vm.form.warehousingTime) + " ")
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v(
                    " 审核人：" + _vm._s(_vm.form.warehousingReviewer) + " "
                  )
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(
                    " 审核时间：" + _vm._s(_vm.form.receiptAuditTime) + " "
                  )
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v(
                    " 记账人：" + _vm._s(_vm.form.warehousingAccount) + " "
                  )
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v(
                    " 记账时间：" +
                      _vm._s(_vm.form.warehousingAccountTime) +
                      " "
                  )
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.warehousingStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formCheck }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.form.warehousingStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.toReturn }
                          },
                          [_vm._v("退回")]
                        )
                      : _vm._e(),
                    _vm.form.warehousingStatus == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnCheck }
                          },
                          [
                            _c("i", { staticClass: "el-icon-refresh-left" }),
                            _vm._v(" 撤销审核")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.warehousingStatus == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formAccount }
                          },
                          [_vm._v("记账")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.detailPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }