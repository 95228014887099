var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "label-width": "120px",
            "label-suffix": ":",
            "show-message": false,
            loading: _vm.formLoading
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                size: "mini",
                "highlight-current-row": "",
                "header-cell-style": { borderColor: "#F2F3F5", color: "#333" },
                data: _vm.form.invoiceList,
                "max-height": 500
              }
            },
            [
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票号",
                                      prop:
                                        "invoiceList." +
                                        $index +
                                        ".invoiceNumber",
                                      rules: _vm.formRules.invoiceNumber
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      ref:
                                        "invoiceList-" +
                                        $index +
                                        "-invoiceNumber",
                                      attrs: { size: "mini", clearable: "" },
                                      on: {
                                        change: function($event) {
                                          return _vm.invoiceSaveItem(row)
                                        }
                                      },
                                      model: {
                                        value: row.invoiceNumber,
                                        callback: function($$v) {
                                          _vm.$set(row, "invoiceNumber", $$v)
                                        },
                                        expression: "row.invoiceNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票代码",
                                      prop:
                                        "invoiceList." + $index + ".invoiceCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "发票代码",
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.invoiceSaveItem(row)
                                        }
                                      },
                                      model: {
                                        value: row.invoiceCode,
                                        callback: function($$v) {
                                          _vm.$set(row, "invoiceCode", $$v)
                                        },
                                        expression: "row.invoiceCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "发票类型",
                                      prop:
                                        "invoiceList." + $index + ".invoiceType"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          change: function($event) {
                                            return _vm.invoiceSaveItem(row)
                                          }
                                        },
                                        model: {
                                          value: row.invoiceType,
                                          callback: function($$v) {
                                            _vm.$set(row, "invoiceType", $$v)
                                          },
                                          expression: "row.invoiceType"
                                        }
                                      },
                                      _vm._l(_vm.invoiceTypeList, function(
                                        item,
                                        idx
                                      ) {
                                        return _c("el-option", {
                                          key: "invoice-item-" + idx,
                                          attrs: {
                                            label: item.name,
                                            value: item.ID
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "开票日期" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "选择日期",
                                        "value-format": "yyyy-MM-dd",
                                        format: "yyyy-MM-dd",
                                        editable: false
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.invoiceSaveItem(row)
                                        }
                                      },
                                      model: {
                                        value: row.invoicingDate,
                                        callback: function($$v) {
                                          _vm.$set(row, "invoicingDate", $$v)
                                        },
                                        expression: "row.invoicingDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "金额（含税）" } },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "enter-number",
                                          rawName:
                                            "v-enter-number:[{min:0,precision:2}]",
                                          arg: { min: 0, precision: 2 }
                                        }
                                      ],
                                      attrs: {
                                        size: "mini",
                                        placeholder: "金额（含税）",
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.invoiceSaveItem(row)
                                        }
                                      },
                                      model: {
                                        value: row.amountIncludingTax,
                                        callback: function($$v) {
                                          _vm.$set(
                                            row,
                                            "amountIncludingTax",
                                            $$v
                                          )
                                        },
                                        expression: "row.amountIncludingTax"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "税率" } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        directives: [
                                          {
                                            name: "enter-number",
                                            rawName:
                                              "v-enter-number:[{min:0,precision:2}]",
                                            arg: { min: 0, precision: 2 }
                                          }
                                        ],
                                        attrs: {
                                          size: "mini",
                                          placeholder: "税率",
                                          clearable: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.invoiceSaveItem(row)
                                          }
                                        },
                                        model: {
                                          value: row.taxRate,
                                          callback: function($$v) {
                                            _vm.$set(row, "taxRate", $$v)
                                          },
                                          expression: "row.taxRate"
                                        }
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            attrs: { slot: "suffix" },
                                            slot: "suffix"
                                          },
                                          [_vm._v("%")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "税额" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "28px" }
                                      },
                                      [_vm._v(_vm._s(row.taxAmount.toFixed(2)))]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "金额（不含税）" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "28px" }
                                      },
                                      [_vm._v(_vm._s(row.money.toFixed(2)))]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "校验码(后六位)" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        placeholder: "校验码",
                                        maxlength: 6,
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.invoiceSaveItem(row)
                                        }
                                      },
                                      model: {
                                        value: row.validationCode,
                                        callback: function($$v) {
                                          _vm.$set(row, "validationCode", $$v)
                                        },
                                        expression: "row.validationCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "附件" } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        attrs: {
                                          action: _vm.uploadAction,
                                          headers: _vm.uploadToken,
                                          "on-preview": _vm.handlePreview,
                                          "on-remove": function(
                                            file,
                                            fileList
                                          ) {
                                            return _vm.handleRemove(
                                              file,
                                              fileList,
                                              row
                                            )
                                          },
                                          "on-success": function(
                                            res,
                                            file,
                                            fileList
                                          ) {
                                            return _vm.uploadSuccess(
                                              res,
                                              file,
                                              fileList,
                                              row
                                            )
                                          },
                                          "file-list": row.attachment,
                                          "show-file-list": true
                                        }
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          { attrs: { icon: "el-icon-plus" } },
                                          [_vm._v("上传附件")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "",
                    label: "操作",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.invoiceDeleteItem(row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            underline: false
                          },
                          on: { click: _vm.invoiceAddItem }
                        },
                        [_vm._v("新增发票")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }