var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      model: {
        value: _vm.form,
        callback: function($$v) {
          _vm.form = $$v
        },
        expression: "form"
      }
    },
    [
      _c(
        "el-input",
        {
          attrs: {
            placeholder: "请输入货位编码",
            size: "small",
            clearable: ""
          },
          model: {
            value: _vm.form.locationNumber,
            callback: function($$v) {
              _vm.$set(_vm.form, "locationNumber", $$v)
            },
            expression: "form.locationNumber"
          }
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: {
              click: function($event) {
                return _vm.formSubmit()
              }
            },
            slot: "append"
          })
        ],
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore"
            }
          ],
          staticClass: "infinite-list",
          attrs: { "infinite-scroll-disabled": _vm.loadMoreDisabled() }
        },
        [
          _c(
            "div",
            {
              staticClass: "list-table",
              staticStyle: {
                "font-weight": "bold",
                "border-bottom": "1px solid #eee"
              }
            },
            [
              _c("div", { staticClass: "location-number" }, [
                _vm._v("货位编号")
              ]),
              _c("div", { staticClass: "row" }, [_vm._v("行")]),
              _c("div", { staticClass: "col" }, [_vm._v("列")]),
              _c("div", { staticClass: "capacity" }, [_vm._v("容量")])
            ]
          ),
          _vm._l(_vm.dataList, function(row, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.selectConfirm(row)
                  }
                }
              },
              [
                _c("div", { staticClass: "list-table" }, [
                  _c("div", { staticClass: "location-number" }, [
                    _vm._v(_vm._s(row.locationNumber))
                  ]),
                  _c("div", { staticClass: "row" }, [_vm._v(_vm._s(row.row))]),
                  _c("div", { staticClass: "col" }, [_vm._v(_vm._s(row.col))]),
                  _c("div", { staticClass: "capacity" }, [
                    _vm._v(_vm._s(row.capacity))
                  ])
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm.loading
        ? _c("el-divider", [
            _c("i", { staticClass: "el-icon-loading" }),
            _vm._v("加载中...")
          ])
        : _vm._e(),
      _vm.noMore() === true
        ? _c("el-divider", [_vm._v("没有更多了")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }