var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("财务设定")]),
              _c("el-breadcrumb-item", [_vm._v("回款周期设置")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.form,
                      inline: true,
                      "label-suffix": ":",
                      size: "small"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.formSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.name,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", icon: "el-icon-search" },
                            on: { click: _vm.formSubmit }
                          },
                          [_vm._v("查询")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dataLoading,
                  expression: "dataLoading"
                }
              ],
              key: "dataTable" + _vm.timestamp,
              ref: "dataTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.form,
                columns: _vm.columns,
                data: _vm.dataList,
                routeName: _vm.dataTableCacheName
              },
              on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange },
              scopedSlots: _vm._u([
                {
                  key: "paymentDay",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v("回款周期：" + _vm._s(row.paymentDay) + "天")
                          ]),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toSetDay(row)
                                }
                              }
                            },
                            [_vm._v("设置")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "handleType",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function() {
                              return _vm.handleTypeChange(row)
                            }
                          },
                          model: {
                            value: row.handleType,
                            callback: function($$v) {
                              _vm.$set(row, "handleType", $$v)
                            },
                            expression: "row.handleType"
                          }
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "只预警",
                                width: "200",
                                trigger: "hover",
                                content:
                                  "超过设置的回款周期，该笔发票出现在首页预警列表内"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { slot: "reference", label: 1 },
                                  slot: "reference"
                                },
                                [_vm._v("只预警")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-popover",
                            {
                              staticStyle: { margin: "0 30px" },
                              attrs: {
                                placement: "top-start",
                                title: "发货提醒",
                                width: "200",
                                trigger: "hover",
                                content:
                                  "超期既有首页预警同时在销售订货单、寄售订货单提交时出现弹窗提醒，各类出库复核时出现相关提醒（可选择复核通过继续发货）"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { slot: "reference", label: 2 },
                                  slot: "reference"
                                },
                                [_vm._v("发货提醒")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "禁止发货",
                                width: "200",
                                trigger: "hover",
                                content:
                                  "超期既有首页预警同时在出库复核时出现相关提醒且无法选择通过（只能选择不通过）"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { slot: "reference", label: 3 },
                                  slot: "reference"
                                },
                                [_vm._v("禁止发货")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c("DayComponent", {
          ref: "DayComponentRef",
          on: {
            reload: function($event) {
              return _vm.loadData()
            }
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }