var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-table",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "120px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称", prop: "customerName" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.customerName))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司税号", prop: "companyTaxNumber" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.companyTaxNumber))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "bank" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bank))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "bankAccount" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bankAccount))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位地址", prop: "workAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.workAddress))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位电话", prop: "telephone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.telephone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "期望发票类型", prop: "invoiceType" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.getInvoiceTypeName(_vm.form.invoiceType))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票联系方式", prop: "ticketPhone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketPhone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票地址", prop: "ticketAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketAddress))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: {
            size: "mini",
            data: _vm.invoiceRow,
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            border: ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发票号",
              prop: "invoiceNumber",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发票代码",
              prop: "invoiceCode",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发票类型",
              prop: "invoiceType",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getInvoiceTypeName(scope.row.invoiceType)) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开票日期",
              prop: "invoicingDate",
              width: "100"
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "金额(不含税)",
                prop: "money",
                width: "100"
              }
            },
            [
              _c("template", { slot: "header" }, [
                _c("span", [_vm._v("金额"), _c("br"), _vm._v("(不含税)")])
              ])
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "税率",
              prop: "taxRate",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.taxRate) + "% ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "税额",
              prop: "taxAmount",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "含税金额",
              prop: "amountIncludingTax",
              width: "100"
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "校验代码(后6位)",
                prop: "validationCode",
                width: "100"
              }
            },
            [
              _c("template", { slot: "header" }, [
                _c("span", [_vm._v("校验代码"), _c("br"), _vm._v("(后6位)")])
              ])
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开票公司",
              prop: "invoicingCompany",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开票人",
              prop: "invoiceIssuer",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              prop: "remarks",
              width: "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "附件",
              prop: "attachment",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          title: "附件列表",
                          width: "500",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: row.attachment,
                              size: "mini",
                              border: "",
                              stripe: "",
                              "header-cell-style": {
                                background: "#f5f7fa",
                                borderColor: "#ebeef5",
                                color: "#333"
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "url", label: "附件路径" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              type: "primary",
                                              underline: false
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.attachmentPreview(
                                                  row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(row.url))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              slot: "reference",
                              underline: false,
                              type: "primary"
                            },
                            slot: "reference"
                          },
                          [
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(row.attachment.length))
                            ]),
                            _vm._v(" 个附件 ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-danger", staticStyle: { margin: "10px 0" } },
        [_vm._v("红字发票")]
      ),
      _c(
        "el-form",
        {
          ref: "invoiceForm",
          staticClass: "form-table",
          attrs: {
            model: _vm.invoiceForm,
            "show-message": false,
            rules: _vm.invoiceFormRules,
            "label-width": "140px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.invoiceFormSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票号", prop: "invoiceNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          maxlength: "50",
                          "suffix-icon": "el-icon-edit"
                        },
                        model: {
                          value: _vm.invoiceForm.invoiceNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.invoiceForm, "invoiceNumber", $$v)
                          },
                          expression: "invoiceForm.invoiceNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发票代码",
                        prop: "invoiceCode",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          maxlength: "50",
                          "suffix-icon": "el-icon-edit"
                        },
                        model: {
                          value: _vm.invoiceForm.invoiceCode,
                          callback: function($$v) {
                            _vm.$set(_vm.invoiceForm, "invoiceCode", $$v)
                          },
                          expression: "invoiceForm.invoiceCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发票类型",
                        prop: "invoiceType",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.invoiceForm.invoiceType,
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceForm, "invoiceType", $$v)
                            },
                            expression: "invoiceForm.invoiceType"
                          }
                        },
                        _vm._l(
                          _vm.invoiceTypeList.filter(function(item) {
                            return item.ID
                          }),
                          function(item, index) {
                            return _c("el-option", {
                              key: "invoiceType" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开票日期",
                        prop: "invoicingDate",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "请选择开票日期"
                        },
                        model: {
                          value: _vm.invoiceForm.invoicingDate,
                          callback: function($$v) {
                            _vm.$set(_vm.invoiceForm, "invoicingDate", $$v)
                          },
                          expression: "invoiceForm.invoicingDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金额(不含税)", prop: "money" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            height: "100%"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, controls: false },
                            on: { change: _vm.moneyChange },
                            model: {
                              value: _vm.invoiceForm.money,
                              callback: function($$v) {
                                _vm.$set(_vm.invoiceForm, "money", $$v)
                              },
                              expression: "invoiceForm.money"
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: {
                              "font-size": "14px",
                              width: "30px",
                              color: "#C0C4CC"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "税率",
                        prop: "taxRate",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.taxRate) + "%")
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "税额",
                        prop: "taxAmount",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.taxAmount.toFixed(2)))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "含税金额",
                        prop: "amountIncludingTax",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            height: "100%"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, controls: false },
                            on: { change: _vm.amountIncludingTaxChange },
                            model: {
                              value: _vm.invoiceForm.amountIncludingTax,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.invoiceForm,
                                  "amountIncludingTax",
                                  $$v
                                )
                              },
                              expression: "invoiceForm.amountIncludingTax"
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: {
                              "font-size": "14px",
                              width: "30px",
                              color: "#C0C4CC"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "校验代码(后6位)",
                        prop: "validationCode"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          maxlength: "6",
                          "suffix-icon": "el-icon-edit"
                        },
                        model: {
                          value: _vm.invoiceForm.validationCode,
                          callback: function($$v) {
                            _vm.$set(_vm.invoiceForm, "validationCode", $$v)
                          },
                          expression: "invoiceForm.validationCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "附件",
                        prop: "attachment",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticStyle: { margin: "0 10px 0 15px" },
                              attrs: {
                                action: _vm.uploadAction,
                                headers: _vm.uploadToken,
                                "on-success": _vm.uploadSuccess,
                                "file-list": _vm.fileList,
                                "show-file-list": false
                              }
                            },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    icon: "el-icon-upload2",
                                    type: "primary",
                                    underline: false
                                  }
                                },
                                [_vm._v("点击上传附件")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "margin-left": "20px"
                              }
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top",
                                    title: "附件列表",
                                    width: "500",
                                    trigger: "click"
                                  }
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        data: _vm.invoiceForm.attachment,
                                        size: "mini",
                                        border: "",
                                        stripe: "",
                                        "header-cell-style": {
                                          background: "#f5f7fa",
                                          borderColor: "#ebeef5",
                                          color: "#333"
                                        }
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "url",
                                          label: "附件路径"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      underline: false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.attachmentPreview(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(row.url))]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          width: "60",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "danger",
                                                      underline: false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeAttachment(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        underline: false,
                                        type: "primary"
                                      },
                                      slot: "reference"
                                    },
                                    [
                                      _vm._v(" 已上传 "),
                                      _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.invoiceForm.attachment.length
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" 个，点击查看 ")
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票公司", prop: "subsidiaryId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "开票公司",
                            clearable: "",
                            filterable: ""
                          },
                          model: {
                            value: _vm.invoiceForm.subsidiaryId,
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceForm, "subsidiaryId", $$v)
                            },
                            expression: "invoiceForm.subsidiaryId"
                          }
                        },
                        _vm._l(_vm.subsidiaryList, function(item, index) {
                          return _c("el-option", {
                            key: "subsidiaryId" + index,
                            attrs: { label: item.companyName, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开票人",
                        prop: "invoiceIssuer",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "开票人",
                            clearable: "",
                            filterable: ""
                          },
                          model: {
                            value: _vm.invoiceForm.invoiceIssuer,
                            callback: function($$v) {
                              _vm.$set(_vm.invoiceForm, "invoiceIssuer", $$v)
                            },
                            expression: "invoiceForm.invoiceIssuer"
                          }
                        },
                        _vm._l(_vm.userList, function(item, index) {
                          return _c("el-option", {
                            key: "invoiceIssuer" + index,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        prop: "remarks",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "备注",
                          "suffix-icon": "el-icon-edit",
                          clearable: ""
                        },
                        model: {
                          value: _vm.invoiceForm.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.invoiceForm, "remarks", $$v)
                          },
                          expression: "invoiceForm.remarks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "10px", width: "100%" },
          attrs: {
            size: "small",
            data: _vm.detailList,
            "highlight-current-row": "",
            border: "",
            stripe: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "销售单号",
              prop: "salesOrderNo",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "productNumber",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票名称",
              prop: "productName",
              "min-width": "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specificationModel",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票金额",
              prop: "invoicePriceOri",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票数量",
              prop: "invoicNumOri",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "已红冲数量",
              prop: "redNum",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "已红冲金额",
              prop: "redPrice",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "红冲数量",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          max:
                            row.quantityUsed -
                            row.redNum -
                            row.returnedQuantity,
                          disabled:
                            row.redNum >= row.quantityUsed ? true : false,
                          controls: false,
                          size: "mini"
                        },
                        on: {
                          change: function($event) {
                            return _vm.toInvoiceNumChange(row)
                          }
                        },
                        model: {
                          value: row.toInvoiceNum,
                          callback: function($$v) {
                            _vm.$set(row, "toInvoiceNum", $$v)
                          },
                          expression: "row.toInvoiceNum"
                        }
                      })
                    ]
                  }
                }
              ])
            },
            [
              _c("template", { slot: "header" }, [
                _c(
                  "div",
                  { staticStyle: { "line-height": "15px" } },
                  [
                    _vm._v("红冲数量"),
                    _c("br"),
                    _c(
                      "el-tag",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          size: "mini",
                          effect: "dark",
                          type: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.toAddAll()
                          }
                        }
                      },
                      [_vm._v("全部添加")]
                    )
                  ],
                  1
                )
              ])
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "红冲金额",
              prop: "toInvoiceAmount",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.invoiceFormSubmit }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.uploadPreviewImageUrl,
              "z-index": 9999
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }