var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      model: {
        value: _vm.form,
        callback: function($$v) {
          _vm.form = $$v
        },
        expression: "form"
      }
    },
    [
      _c(
        "el-input",
        {
          attrs: {
            placeholder: "请输入货架名称",
            size: "small",
            clearable: ""
          },
          model: {
            value: _vm.form.shelfName,
            callback: function($$v) {
              _vm.$set(_vm.form, "shelfName", $$v)
            },
            expression: "form.shelfName"
          }
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: {
              click: function($event) {
                return _vm.formSubmit()
              }
            },
            slot: "append"
          })
        ],
        1
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadMore,
              expression: "loadMore"
            }
          ],
          staticClass: "infinite-list",
          attrs: { "infinite-scroll-disabled": _vm.loadMoreDisabled() }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "space-between",
                height: "35px",
                "align-items": "center",
                "font-weight": "bold",
                "border-bottom": "1px solid #eee"
              }
            },
            [
              _c("div", { staticStyle: { flex: "1" } }, [_vm._v("货架名称")]),
              _c("div", [_vm._v("货架编号")])
            ]
          ),
          _vm._l(_vm.dataList, function(row, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.selectConfirm(row)
                  }
                }
              },
              [
                _c("div", { staticStyle: { flex: "1" } }, [
                  _vm._v(" " + _vm._s(row.shelfName) + " ")
                ]),
                _c("div", [_vm._v(" " + _vm._s(row.shelfNumber) + " ")])
              ]
            )
          })
        ],
        2
      ),
      _vm.loading
        ? _c("el-divider", [
            _c("i", { staticClass: "el-icon-loading" }),
            _vm._v("加载中...")
          ])
        : _vm._e(),
      _vm.noMore() === true
        ? _c("el-divider", [_vm._v("没有更多了")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }