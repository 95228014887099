var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("el-card", { attrs: { shadow: "never" } }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
              "border-bottom": "1px solid #eee",
              height: "30px"
            }
          },
          [
            _c("div", { staticStyle: { flex: "1" } }, [
              _c("h4", [_vm._v("新版")])
            ]),
            _c("div", { staticStyle: { flex: "1" } }, [
              _c("h4", [_vm._v("旧版")])
            ])
          ]
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "center" } },
          [
            _c(
              "div",
              { staticStyle: { flex: "1" } },
              [
                _c(
                  "el-collapse",
                  {
                    staticStyle: { height: "500px", "overflow-y": "auto" },
                    attrs: { accordion: "" },
                    model: {
                      value: _vm.newCategoryActiveName,
                      callback: function($$v) {
                        _vm.newCategoryActiveName = $$v
                      },
                      expression: "newCategoryActiveName"
                    }
                  },
                  _vm._l(_vm.categoryList2, function(item, index) {
                    return _c(
                      "el-collapse-item",
                      {
                        key: index,
                        attrs: { name: item.ID, id: "new-" + index },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        }
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            [
                              _vm.selectedCheckNew(item)
                                ? _c("i", {
                                    staticClass: "el-icon-success check-icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.checkboxChange2(item)
                                      }
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-circle-check check-icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.checkboxChange2(item)
                                      }
                                    }
                                  })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "48px",
                                "line-height": "normal",
                                display: "flex",
                                "align-items": "center",
                                "flex-direction": "row"
                              }
                            },
                            [_vm._v(_vm._s(item.num) + " " + _vm._s(item.name))]
                          )
                        ]),
                        _c(
                          "el-row",
                          _vm._l(item.children, function(item2, index2) {
                            return _c(
                              "el-col",
                              {
                                key: index + "-" + index2,
                                attrs: { span: 24 }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { margin: "5px 20px" } },
                                  [
                                    _vm._v(
                                      "【" +
                                        _vm._s(item2.num) +
                                        "】" +
                                        _vm._s(item2.name)
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      2
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { flex: "1" } },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: {
                      height: "500px",
                      "overflow-y": "auto",
                      "border-top": "1px solid #eee",
                      "border-bottom": "1px solid #eee"
                    }
                  },
                  _vm._l(_vm.categoryList1, function(item, index) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: 24, id: "old-" + index } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "5px 0",
                                    display: "flex",
                                    "flex-direction": "row",
                                    "align-items": "center",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.checkboxChange1(item)
                                    }
                                  }
                                },
                                [
                                  _vm.selectedCheckOld(item)
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-success check-icon"
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-circle-check check-icon"
                                      }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "25px",
                                        "line-height": "normal"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.num) +
                                          _vm._s(item.name) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" }, attrs: { align: "center" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: { click: _vm.selectFormSubmit }
              },
              [_vm._v("确定选中分类数据")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }