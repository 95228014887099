import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { downloadProductTemplate2 } from '@/api/product.js';
import util from '@/libs/util.js';
export default {
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.uploadURL = process.env.VUE_APP_API + '/product/uploadExcel2';
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web'
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    handleUpload: function handleUpload() {},
    download: function download() {
      downloadProductTemplate2({
        fileName: 'goodsDicImport.xlsx'
      }).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '物资及重量导入模板.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    uploadChange: function uploadChange(file, fileList) {
      var that = this;
      var FileExt = file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.$message.error('不允许上传此类型文件');
        return false;
      }
    },
    uploadProgress: function uploadProgress() {},
    uploadSuccess: function uploadSuccess(res) {
      var that = this;

      if (res.code !== 0) {
        that.$message.error(res.msg);
      } else {
        that.$notify.success(res.msg);
        that.$emit('reload');
      }
    },
    uploadError: function uploadError(e) {}
  }
};