//
//
//
import CommonComponent from './form.vue';
export default {
  name: 'stock-inwarehouse-consignment-return-create',
  components: {
    CommonComponent: CommonComponent
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {}
  }
};