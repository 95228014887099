import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listStockSummaryBatch, listStockSummaryBatchExport } from '@/api/stock.js';
import { listWarehouse } from '@/api/warehouse.js';
import { listClass } from '@/api/class.js';
import YTable from '@/views/components/drag-table/table.vue';
export default {
  name: 'stock-summary-batch',
  components: {
    YTable: YTable
  },
  data: function data() {
    return {
      loading: false,
      props: {
        multiple: false,
        value: 'name',
        label: 'name',
        emitPath: false
      },
      warehouseList: [],
      classList: [],
      supplierList: [],
      warehouseIds: [],
      pageForm: {
        productName: '',
        productLine: '',
        warehouseId: null,
        batch_number: null,
        productNumber: null,
        specificationModel: null,
        page: 1,
        pageSize: 10,
        total: 1
      },
      tableData: [],
      stockQuantityCount: 0,
      searchFormExpand: false,
      columns: [{
        label: '产品编号',
        prop: 'productNumber',
        checked: true,
        width: 200
      }, {
        label: '物资名称',
        prop: 'productName',
        checked: true,
        width: 200
      }, {
        label: '通用名称',
        prop: 'genericName',
        checked: true,
        width: 200
      }, {
        label: '规格型号',
        prop: 'specificationModel',
        checked: true,
        width: 100
      }, {
        label: '生产批号',
        prop: 'batchNumber',
        checked: true,
        width: 100
      }, {
        label: '库存数量',
        prop: 'stockQuantity',
        checked: true,
        width: 100
      }, {
        label: '商品包锁定',
        prop: 'productPackageLocking',
        checked: true,
        width: 100
      }, {
        label: '出库锁定',
        prop: 'issueLocking',
        checked: true,
        width: 100
      }, {
        label: '可用数量',
        prop: 'normalQuantity',
        checked: true,
        width: 100
      }, {
        label: '注册证号',
        prop: 'registrationNumber',
        checked: true,
        width: 200
      }, {
        label: '生产厂家',
        prop: 'manufacturer',
        checked: true,
        width: 200
      }, {
        label: '品牌',
        prop: 'brandName',
        checked: true,
        width: 150
      }, {
        label: '产品线',
        prop: 'productLine',
        checked: true,
        width: 200
      }, {
        label: '分类',
        prop: 'classification',
        checked: true,
        width: 200
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadWarehouse();
      that.loadClass();
      that.loadData();
    },
    loadWarehouse: function loadWarehouse() {
      var that = this;
      listWarehouse({
        page: 1,
        pageSize: 9999,
        isVirtualWarehouse: 1
      }).then(function (res) {
        that.warehouseList = res.list;
      });
    },
    loadClass: function loadClass() {
      var that = this;
      listClass({
        page: 1,
        pageSize: 9999
      }).then(function (res) {
        res.manuList.forEach(function (item, index) {
          if (res.manuList[index].children.length === 0) {
            res.manuList[index].children = null;
          } else {
            res.manuList[index].children.forEach(function (item2, index2) {
              if (res.manuList[index].children[index2].children.length === 0) {
                res.manuList[index].children[index2].children = null;
              } else {
                res.manuList[index].children[index2].children.forEach(function (item3, index3) {
                  if (res.manuList[index].children[index2].children[index3].children.length === 0) {
                    res.manuList[index].children[index2].children[index3].children = null;
                  } else {
                    res.manuList[index].children[index2].children[index3].children.forEach(function (item4, index4) {
                      res.manuList[index].children[index2].children[index3].children[index4].children = null;
                    });
                  }
                });
              }
            });
          }
        });
        that.classList = res.manuList;
      });
    },
    loadData: function loadData() {
      var that = this;
      that.loading = true;
      that.tableData = [];
      var form = JSON.parse(JSON.stringify(that.pageForm));

      if (form.productLine) {
        var arr = form.productLine.split(',');
        form.productLine = arr[arr.length - 1];
      }

      listStockSummaryBatch(form).then(function (res) {
        that.tableData = that.listFormatter(res.list);
        that.stockQuantityCount = res.other.stockQuantityCount;
        that.pageForm.total = res.total;
      }).finally(function () {
        that.loading = false;
      });
    },
    listFormatter: function listFormatter(dataList) {
      var resultList = [];
      dataList.forEach(function (row) {
        resultList.push({
          ID: row.ID,
          warehouseName: row.warehouse.name,
          productNumber: row.specs.productNumber,
          productName: row.main.productName,
          genericName: row.specs.genericName,
          specificationModel: row.specs.specificationModel,
          batchNumber: row.batch_number,
          trackingCode: row.trackingCode,
          stockQuantity: row.stock_quantity,
          productPackageLocking: row.productPackageLocking,
          issueLocking: row.issueLocking,
          normalQuantity: row.normalQuantity,
          registrationNumber: row.main.registrationNumber,
          manufacturer: row.main.manufacturer,
          brandName: row.main.brandName,
          productLine: row.main.productLine,
          classification: row.main.classification
        });
      });
      return resultList;
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.pageForm.page = 1;
      that.loadData();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadData();
    },
    dataExport: function dataExport() {
      var that = this;
      var form = JSON.parse(JSON.stringify(that.pageForm));
      form.pageSize = form.total;
      form.toExcel = 1;

      if (form.productLine) {
        var arr = form.productLine.split(',');
        form.productLine = arr[arr.length - 1];
      }

      listStockSummaryBatchExport(form).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '库存汇总（批号）.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    warehouseChange: function warehouseChange(val) {
      var that = this;
      that.pageForm.warehouseIds = val.toString();
    },
    classificationFormatter: function classificationFormatter(classification) {
      if (!classification) {
        return '';
      }

      var classList = JSON.parse(classification);
      var result = [];
      classList.newCategory.map(function (row) {
        result.push('【' + row.num + '】' + row.name);
      });
      classList.oldCategory.map(function (row) {
        result.push(row.num + row.name);
      });
      return result.toString();
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    },
    pageSizeChange: function pageSizeChange(val) {
      var that = this;
      that.pageForm.page = 1;
      that.pageForm.pageSize = val;
      that.loadData();
    }
  }
};