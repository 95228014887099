var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      staticClass: "form-table",
      attrs: { "label-suffix": ":", "label-width": "100px" }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "物资名称" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.productName))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "产品编号" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.productNumber))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "通用名称" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.genericName))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "序列号" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.trackingCode))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "生产批号" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.batchNumber))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "库存数量" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.stockQuantity))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "可用数量" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.normalQuantity))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "销售锁定" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.salesPendingApproval))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "入库数量" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.receiptQuantity))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "出库锁定" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.issueLocking))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "消耗数量" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.consumedQuantity))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "包装规格" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.packNum) +
                      _vm._s(_vm.data.unit) +
                      " / " +
                      _vm._s(_vm.data.packUnit) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "成本价" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.afterTaxPrice))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "单位" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.unit))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "注册证号" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.registrationNumber))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "有效期" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.validityDate))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "生产日期" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.manufacturerDate))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "产地/品牌" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.data.productPlace) +
                      " / " +
                      _vm._s(_vm.data.brandName) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "生产厂家" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.manufacturer))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "灭菌批号" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.sterilizationDate))
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-form-item", { attrs: { label: "储运条件" } }, [
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.data.storage))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }