import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listRetailer } from '@/api/retailer.js';
import { listClass } from '@/api/class.js';
import { listWarehouse } from '@/api/warehouse.js';
import EditorFormComponents from '@/views/sales/agent/retailer/components/editor_form/index.vue';
export default {
  components: {
    EditorFormComponents: EditorFormComponents
  },
  data: function data() {
    return {
      pageForm: {
        productName: '',
        productLine: '',
        page: 1,
        pageSize: 10,
        total: 1
      },
      tableData: [],
      warehouseList: [],
      classList: [],
      props: {
        multiple: false,
        value: 'name',
        label: 'name',
        emitPath: false
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadRetailer();
      that.loadClass();
      that.loadWarehouse();
    },
    loadRetailer: function loadRetailer() {
      var that = this;
      that.tableData = [];
      listRetailer({
        page: 1,
        pageSize: 9999,
        all_examine_status: true
      }).then(function (res) {
        res.list.forEach(function (row) {
          that.tableData.push(row);
        });
        that.pageForm.total = res.total;
      });
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.pageForm.page = 1;
      that.loadRetailer();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadRetailer();
    },
    loadClass: function loadClass() {
      var that = this;
      listClass({
        page: 1,
        pageSize: 9999
      }).then(function (res) {
        res.manuList.forEach(function (item, index) {
          if (res.manuList[index].children.length === 0) {
            res.manuList[index].children = null;
          } else {
            res.manuList[index].children.forEach(function (item2, index2) {
              if (res.manuList[index].children[index2].children.length === 0) {
                res.manuList[index].children[index2].children = null;
              } else {
                res.manuList[index].children[index2].children.forEach(function (item3, index3) {
                  if (res.manuList[index].children[index2].children[index3].children.length === 0) {
                    res.manuList[index].children[index2].children[index3].children = null;
                  } else {
                    res.manuList[index].children[index2].children[index3].children.forEach(function (item4, index4) {
                      res.manuList[index].children[index2].children[index3].children[index4].children = null;
                    });
                  }
                });
              }
            });
          }
        });
        that.classList = res.manuList;
      });
    },
    loadWarehouse: function loadWarehouse() {
      var that = this;
      listWarehouse({
        page: 1,
        pageSize: 9999
      }).then(function (res) {
        that.warehouseList = res.list;
      });
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    }
  }
};