var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("发票管理")]),
              _c("el-breadcrumb-item", [_vm._v("进项发票")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "发票号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "发票号", clearable: "" },
                      model: {
                        value: _vm.searchForm.invoiceNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "invoiceNumber", $$v)
                        },
                        expression: "searchForm.invoiceNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售方" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.manufacturerName,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerName", $$v)
                          },
                          expression: "searchForm.manufacturerName"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item) {
                        return _c("el-option", {
                          key: "manu-" + item.ID,
                          attrs: { label: item.name, value: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "发票类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.invoiceType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "invoiceType", $$v)
                          },
                          expression: "searchForm.invoiceType"
                        }
                      },
                      _vm._l(_vm.invoiceTypeList, function(item) {
                        return _c("el-option", {
                          key: "state-" + item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "开票日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            align: "right",
                            "unlink-panels": "",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "picker-options": _vm.pickerOptions,
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList
          },
          on: { "row-dblclick": _vm.viewInvoice },
          scopedSlots: _vm._u([
            {
              key: "orderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.orderNumber.length > 1
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "left",
                            width: "230",
                            trigger: "hover"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "el-list" },
                            _vm._l(row.orderNumber, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: "orderNumber-" + index,
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewPurchaseOrder(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { slot: "reference", type: "primary" },
                              slot: "reference"
                            },
                            [
                              _vm._v(_vm._s(row.orderNumber[0])),
                              _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v("+" + _vm._s(row.orderNumber.length - 1))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : row.orderNumber.length === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewPurchaseOrder(row.orderNumber[0])
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.orderNumber[0]))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewInvoice(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.deleteInvoice(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "page-sizes": [5, 10, 20, 50, 100],
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize
            },
            on: {
              "current-change": _vm.orderPageChange,
              "size-change": _vm.orderSizeChange
            }
          })
        ],
        1
      ),
      _c("InvoiceViewComponent", { ref: "InvoiceViewComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }