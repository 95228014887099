var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("手术管理")]),
              _c("el-breadcrumb-item", [_vm._v("订单管理（跟台员）")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "mini",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售订单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入销售订单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.orderNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "orderNumber", $$v)
                            },
                            expression: "searchForm.orderNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.sellUid,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "sellUid", $$v)
                              },
                              expression: "searchForm.sellUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "sellUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "购货者" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.searchForm.distributorId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "distributorId",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.distributorId"
                                }
                              },
                              _vm._l(_vm.retailerList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "订单状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.searchForm.state,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "state", $$v)
                                  },
                                  expression: "searchForm.state"
                                }
                              },
                              _vm._l(_vm.statusList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "发货状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.searchForm.deliveryState,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "deliveryState",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.deliveryState"
                                }
                              },
                              _vm._l(_vm.deliveryStateList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "产品编码" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入产品编码",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.productNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "productNumber", $$v)
                                },
                                expression: "searchForm.productNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "产品名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入产品名称",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.productName,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "productName", $$v)
                                },
                                expression: "searchForm.productName"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "规格型号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入规格型号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.specificationModel,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchForm,
                                    "specificationModel",
                                    $$v
                                  )
                                },
                                expression: "searchForm.specificationModel"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "备注" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入备注",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "remarks", $$v)
                                },
                                expression: "searchForm.remarks"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c("el-button", { on: { click: _vm.dataExport } }, [
                          _vm._v("导出")
                        ]),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-bottom": "10px",
                  "font-size": "14px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "flex-wrap": "wrap"
                }
              },
              [
                _c("div"),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                      "align-items": "center",
                      "font-size": "12px",
                      flex: "1"
                    }
                  },
                  [
                    _c("div", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v("订单产品合计："),
                      _c("span", { staticStyle: { color: "#f00" } }, [
                        _vm._v(_vm._s(0))
                      ])
                    ]),
                    _c("div", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v("金额合计："),
                      _c("span", { staticStyle: { color: "#f00" } }, [
                        _vm._v(_vm._s(0))
                      ])
                    ])
                  ]
                )
              ]
            ),
            _c("h2", [_vm._v("功能开发中……")])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }