var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "选择品牌",
            visible: _vm.centerDialogVisible,
            loading: _vm.loading,
            top: "10vh",
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.centerDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticClass: "left-content el-transfer-panel" },
                      [
                        _c("p", { staticClass: "el-transfer-panel__header" }, [
                          _vm._v("品牌列表")
                        ]),
                        _c("el-input", {
                          staticStyle: { margin: "10px 0" },
                          attrs: { clearable: "", placeholder: "请输入内容" },
                          model: {
                            value: _vm.leftFilterText,
                            callback: function($$v) {
                              _vm.leftFilterText = $$v
                            },
                            expression: "leftFilterText"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "brandContent" },
                          _vm._l(_vm.brandListSort, function(item) {
                            return _vm.leftFilterText == null ||
                              _vm.leftFilterText == undefined ||
                              _vm.leftFilterText == "" ||
                              (item.shortName
                                ? item.shortName.includes(_vm.leftFilterText)
                                : false)
                              ? _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass:
                                      "el-checkbox-group brandContentGroup",
                                    attrs: { "data-value": item.id }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "el-checkbox__label",
                                        staticStyle: {
                                          "text-align": "left",
                                          "padding-left": "0px"
                                        }
                                      },
                                      [
                                        _vm._v(_vm._s(item.shortName)),
                                        item.id === _vm.form.manufacturerId
                                          ? _c(
                                              "span",
                                              { staticStyle: { color: "red" } },
                                              [_vm._v("（本品牌）")]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                        attrs: {
                                          size: "mini",
                                          type: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.selectBrand(item)
                                          }
                                        }
                                      },
                                      [_vm._v("复制到")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }