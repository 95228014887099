var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true,
        align: "center"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "checkForm",
          attrs: { model: _vm.form, "label-width": "0", "label-suffix": ":" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "status", label: "", align: "center" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("通过审核请求")
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v("驳回审核请求")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status === 2,
                  expression: "form.status===2"
                }
              ],
              attrs: { prop: "rejectReason", align: "center" }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  placeholder: "请输入驳回原因"
                },
                model: {
                  value: _vm.form.rejectReason,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rejectReason", $$v)
                  },
                  expression: "form.rejectReason"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { align: "center" } },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  ref: "addButton",
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }