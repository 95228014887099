var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "系统设置",
        visible: _vm.dialogVisible,
        width: "400px",
        "destroy-on-close": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "130px",
            size: "mini",
            "label-suffix": ":"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否关闭语音提醒" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 1, "inactive-value": 0 },
                model: {
                  value: _vm.form.disabledSound,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "disabledSound", $$v)
                  },
                  expression: "form.disabledSound"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录过期时间" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.logOutTimeout,
                    callback: function($$v) {
                      _vm.logOutTimeout = $$v
                    },
                    expression: "logOutTimeout"
                  }
                },
                _vm._l(_vm.timeoutList, function(item, index) {
                  return _c("el-option", {
                    key: "logOutTimeout" + index,
                    attrs: { label: item + "个小时", value: item }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("保存设置")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.dialogClose } },
            [_vm._v("取消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }