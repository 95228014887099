var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "append-to-body": true,
        id: "pasteUploadDialog",
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "16px",
            color: "#f00",
            "text-align": "center"
          }
        },
        [_vm._v("请按 CTRL + V 键进行粘贴")]
      ),
      _vm.previewURL != ""
        ? _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c("el-image", {
                staticStyle: { width: "400px" },
                attrs: { src: _vm.previewURL, fit: "contain" }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("确定上传")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }