var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购退货出库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "采购退货出库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              "label-suffix": ":",
              loading: _vm.formLoading,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货单位", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.supplierList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务部门", prop: "departmentId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.departmentId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "departmentId", $$v)
                              },
                              expression: "form.departmentId"
                            }
                          },
                          _vm._l(_vm.departmentList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.departmentName,
                                value: item.ID
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退回原因", prop: "returnReason" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.returnReason,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "returnReason", $$v)
                              },
                              expression: "form.returnReason"
                            }
                          },
                          _vm._l(_vm.returnReasonList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.codeText, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递单号", prop: "deliveryNumber" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { maxlength: "50", clearable: "" },
                            model: {
                              value: _vm.form.deliveryNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryNumber", $$v)
                              },
                              expression: "form.deliveryNumber"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { maxlength: "150", clearable: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectProduct }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.importProduct }
                  },
                  [_vm._v("模板导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _vm._v(" 退货数量合计：（"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.countQuantity()))
                ]),
                _vm._v("） "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: !_vm.form.id
                    },
                    on: { click: _vm.orderPrint }
                  },
                  [
                    _c("i", { staticClass: "fa fa-print" }),
                    _vm._v(" 打印退货单")
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.detailList,
              border: "",
              stripeDELETE: "",
              "cell-class-name": _vm.cellClassName,
              size: "mini",
              "highlight-current-row": "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              "max-height": "470"
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("物资名称")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: scope.row.productName,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.productName))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("产品编号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.productNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("通用名称")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.genericName))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("序列号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.trackingCode))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("生产批号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.batchNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("库存数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.stockQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("可用数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.normalQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("销售锁定")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.salesPendingApproval))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("入库数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.receiptQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("出库锁定")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.issueLocking))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("消耗数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.consumedQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("包装规格")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(scope.row.packNum) +
                                    _vm._s(scope.row.unit) +
                                    " / " +
                                    _vm._s(scope.row.packUnit)
                                )
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("成本价")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.afterTaxPrice))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("单位")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.unit))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("注册证号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.registrationNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("有效期")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.validityDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("生产日期：")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.manufacturerDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("产地/品牌")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        scope.row.productPlace +
                                        "/" +
                                        scope.row.brandName,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.productPlace) +
                                          " / " +
                                          _vm._s(scope.row.brandName)
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("生产厂家")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: scope.row.manufacturer,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.manufacturer))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("灭菌批号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(scope.row.sterilizationBatchNumber)
                                )
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("灭菌效期")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.sterilizationDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("储运条件")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.storage))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("货位")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.placementPosition))
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "80",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.expireDays <= 90 && scope.row.expireDays > 0
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content:
                                  "该产品还有" +
                                  scope.row.expireDays +
                                  "天过期",
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    position: "relative"
                                  }
                                },
                                [
                                  _c("d2-icon-svg", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "14px",
                                      position: "absolute",
                                      left: "0"
                                    },
                                    attrs: { name: "warning-yellow" }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.indexMethod(
                                          scope.$index,
                                          _vm.detailForm
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      scope.row.expireDays <= 0
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content:
                                  "该产品已过期" +
                                  Math.abs(scope.row.expireDays) +
                                  "天",
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    position: "relative"
                                  }
                                },
                                [
                                  _c("d2-icon-svg", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "14px",
                                      position: "absolute",
                                      left: "0"
                                    },
                                    attrs: { name: "warning-red" }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.indexMethod(
                                          scope.$index,
                                          _vm.detailForm
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      scope.row.expireDays > 90
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.indexMethod(scope.$index, _vm.detailForm)
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "物资名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "genericName",
                label: "通用名称",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "生产批号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "returnWarehouseQuantity",
                label: "退货数量",
                width: "150",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              min: 0,
                              label: "退货数量",
                              size: "mini"
                            },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.returnWarehouseQuantity,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row,
                                  "returnWarehouseQuantity",
                                  $$v
                                )
                              },
                              expression: "scope.row.returnWarehouseQuantity"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(scope.row.returnWarehouseQuantity))
                          ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "returnPrice",
                label: "退货价",
                width: "150",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input-number", {
                            attrs: {
                              "controls-position": "right",
                              min: 0,
                              precision: 2,
                              step: 0.1,
                              label: "退货价",
                              size: "mini",
                              "suffix-icon": "el-icon-edit"
                            },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.returnPrice,
                              callback: function($$v) {
                                _vm.$set(scope.row, "returnPrice", $$v)
                              },
                              expression: "scope.row.returnPrice"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.returnPrice))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "afterTaxPrice",
                label: "成本价",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockQuantity",
                label: "库存数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "normalQuantity",
                label: "可用数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "returnedQuantity",
                label: "已退货数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "inWarehouseQuantity",
                label: "入库数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "consumedQuantity",
                label: "累计消耗数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "receiptQuantity",
                label: "累计入库数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "issueLocking",
                label: "出库锁定",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "salesPendingApproval",
                label: "销售待审",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                width: "100",
                property: "productPackageLocking",
                label: "商品包锁定",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                width: "100",
                align: "center"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormPageChange }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-breadcrumb",
            {
              staticStyle: {
                "font-size": "12px",
                "margin-bottom": "10px",
                width: "100%"
              },
              attrs: { separator: " " }
            },
            [
              _c("el-breadcrumb-item", [
                _vm._v("制单人：" + _vm._s(_vm.form.returnName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("制单时间：" + _vm._s(_vm.form.CreatedAt))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核人：" + _vm._s(_vm.form.nameReviewer))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账人：" + _vm._s(_vm.form.accountName))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("退货总数量：" + _vm._s(_vm.countQuantity()))
              ])
            ],
            1
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: !(
                              _vm.form.status == 1 && _vm.detailRowIndex >= 0
                            )
                          },
                          on: { click: _vm.detailRowRemove }
                        },
                        [_vm._v("删除行")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1 && _vm.form.id
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: { click: _vm.orderInvalid }
                        },
                        [_vm._v("作废")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.checkLoading(_vm.formStorage)
                            }
                          }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.checkLoading(_vm.formSubmit)
                            }
                          }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.id && _vm.form.status == 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.formUnSubmit()
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-refresh-left" }),
                          _vm._v(" 撤消提交")
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.orderPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ImportProductComponents", {
        ref: "ImportProductComponents",
        on: { confirm: _vm.importFromExcel }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }