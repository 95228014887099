export default {
  path: '/base',
  title: '基础数据',
  icon: 'databse',
  children: [{
    title: '客户信息管理',
    icon: 'users',
    children: [{
      path: '/base/hospital/index',
      title: '医院管理',
      icon: 'hospital-o'
    }, {
      path: '/base/supplier/index',
      title: '供应商管理',
      icon: 'server'
    }, {
      path: '/base/retailer/index',
      title: '经销商管理',
      icon: 'sitemap'
    }, {
      path: '/base/manufacturer/index',
      title: '生产商管理',
      icon: 'users'
    }]
  }, {
    title: '产品信息管理',
    icon: 'list',
    children: [{
      path: '/base/brand/index',
      title: '品牌维护',
      icon: 'th-list'
    }, {
      path: '/base/class/index',
      title: '产品线及分类',
      icon: 'list-ol'
    }, {
      path: '/base/product/index',
      title: '物资字典管理',
      icon: 'database'
    }, {
      path: '/base/registration/index',
      title: '注册证管理',
      icon: 'registered'
    }]
  }, {
    title: '内部信息管理',
    icon: 'info',
    children: [{
      path: '/base/company/index',
      title: '本公司信息',
      icon: 'home'
    }, {
      path: '/base/department/index',
      title: '部门管理',
      icon: 'cubes'
    }, {
      path: '/base/personnel/index',
      title: '员工管理',
      icon: 'user-circle'
    }, {
      path: '/base/warehouse/index',
      title: '仓库管理',
      icon: 'bank'
    }, {
      path: '/base/warehouse/auth',
      title: '人员仓库权限',
      icon: 'bank'
    }]
  }, {
    title: '权限管理',
    icon: 'lock',
    children: [{
      path: '/base/roles/index',
      title: '角色管理',
      icon: 'user-secret'
    }]
  }]
};