import { request } from '@/api/_service.js';
export function listSubsidiary(params) {
  return request({
    url: '/subsidiaryErp/getSubsidiaryList',
    method: 'get',
    params: params
  });
}
export function findSubsidiary(params) {
  return request({
    url: '/subsidiaryErp/findSubsidiary',
    method: 'get',
    params: params
  });
}
export function createSubsidiary(data) {
  return request({
    url: '/subsidiaryErp/createSubsidiary',
    method: 'post',
    data: data
  });
}
export function updateSubsidiary(data) {
  return request({
    url: '/subsidiaryErp/updateSubsidiary',
    method: 'put',
    data: data
  });
}
export function deleteSubsidiary(data) {
  return request({
    url: '/subsidiaryErp/deleteSubsidiary',
    method: 'delete',
    data: data
  });
}
export function findFaOrgDistributorSubsidiaryRelationList(params) {
  return request({
    url: '/erpFaOrgDistributor/findFaOrgDistributorSubsidiaryRelationList',
    method: 'get',
    params: params
  });
}
export function updateFaOrgDistributorSubsidiaryRelation(data) {
  return request({
    url: '/erpFaOrgDistributor/updateFaOrgDistributorSubsidiaryRelation',
    method: 'put',
    data: data
  });
}