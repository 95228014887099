var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("补货出库")]),
              _c("el-breadcrumb-item", [_vm._v("出库详情")])
            ],
            1
          ),
          _vm.orderRow.orderNo
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [_vm._v(" 出库单号：" + _vm._s(_vm.orderRow.orderNo) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "label-suffix": ":",
              "show-message": false,
              disabled: _vm.form.status !== 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            on: { change: _vm.warehouseIdChange },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: "warehouseId" + item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "配送状态" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "0 10px 0 15px",
                            "font-size": "12px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.orderRow.expressStatus))]
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "医院", prop: "hospitalId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            on: { change: _vm.loadPackType },
                            model: {
                              value: _vm.form.hospitalId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "hospitalId", $$v)
                              },
                              expression: "form.hospitalId"
                            }
                          },
                          _vm._l(_vm.hospitalList, function(item) {
                            return _c("el-option", {
                              key: "hospital" + item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: item.status === 1
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "耗材包名称", prop: "typeId" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c(
                              "el-select",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.packLoading,
                                    expression: "packLoading"
                                  }
                                ],
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择",
                                  filterable: ""
                                },
                                on: { change: _vm.packTypeChange },
                                model: {
                                  value: _vm.form.typeId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "typeId", $$v)
                                  },
                                  expression: "form.typeId"
                                }
                              },
                              _vm._l(_vm.packTypeList, function(item) {
                                return _c("el-option", {
                                  key: "pack" + item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "150px",
                                  "text-align": "center",
                                  "font-size": "12px",
                                  "border-left": "1px solid #ebeef5"
                                }
                              },
                              [
                                _vm._v(
                                  " 容量:" +
                                    _vm._s(_vm.currentPackRow.usage) +
                                    "/" +
                                    _vm._s(_vm.currentPackRow.capacity) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售人员", prop: "nameSalesperson" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.nameSalesperson,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nameSalesperson", $$v)
                              },
                              expression: "form.nameSalesperson"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "salespersonUid-" + item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "跟台人员", prop: "uidAttendant" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.uidAttendant,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "uidAttendant", $$v)
                              },
                              expression: "form.uidAttendant"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "nameAttendant-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术时间", prop: "operationTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.operationTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "operationTime", $$v)
                            },
                            expression: "form.operationTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "手术医生", prop: "surgeon" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "手术医生",
                              maxlength: "30",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.surgeon,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "surgeon", $$v)
                              },
                              expression: "form.surgeon"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "病人姓名", prop: "patientName" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "病人姓名",
                              maxlength: "30",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.patientName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "patientName", $$v)
                              },
                              expression: "form.patientName"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 24, xl: 18 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "备注",
                              clearable: "",
                              maxlength: "500"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div", [
              _vm.sellProcessMode === 1
                ? _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus",
                            disabled: _vm.form.status !== 1
                          },
                          on: { click: _vm.selectStock }
                        },
                        [_vm._v("增加产品")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            size: "mini",
                            disabled: _vm.form.status !== 1
                          },
                          on: { click: _vm.scanInput }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-barcode",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" 扫码添加")
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-plus",
                            disabled: _vm.form.status !== 1
                          },
                          on: { click: _vm.packageRestock }
                        },
                        [_vm._v("添加缺货产品")]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right-start",
                            width: "450",
                            trigger: "click"
                          }
                        },
                        [
                          _c("PackageListComponents", {
                            ref: "PackageListComponents"
                          }),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "margin-left": "50px" },
                              attrs: { slot: "reference", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.showPackage()
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v("查看耗材包")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v(" 订单产品数合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countQuantity()))
                  ])
                ]),
                _c("div", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(" 出库数量合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countDeliveryQuantity()))
                  ])
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.detailListForm,
                "route-name": _vm.$route.name + "detail-list",
                columns: _vm.columns,
                data: _vm.detailListForm.data
              },
              on: {
                "row-click": _vm.detailRowClick,
                pageChange: _vm.detailListFormPageChange,
                sizeChange: _vm.detailFormSizeChange
              },
              scopedSlots: _vm._u([
                {
                  key: "batch",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.batchManager(scope.row)
                                }
                              }
                            },
                            [_vm._v("添加批次")]
                          )
                        : _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.batchManager(scope.row)
                                }
                              }
                            },
                            [_vm._v("查看批次")]
                          )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex < 0
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", disabled: !_vm.form.id },
                            on: { click: _vm.unFormSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitCheck("storage")
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitCheck("submit")
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.status === 4 &&
                    (!_vm.orderRow.expressStatus ||
                      _vm.orderRow.expressStatus === "尚未指派配送员")
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", disabled: !_vm.form.id },
                            on: { click: _vm.unFormCheck }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销审核")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formCheck }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.form.status === 4 &&
                    (!_vm.orderRow.expressStatus ||
                      _vm.orderRow.expressStatus === "尚未指派配送员")
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.toDelivery }
                          },
                          [_vm._v("安排配送")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印出库单")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.tagPrint }
                      },
                      [_vm._v("打印标签")]
                    ),
                    _vm.form.id && _vm.form.status === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelOrder }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList, reload: _vm.loadDetail }
      }),
      _c("ExpireDialogComponents", {
        ref: "ExpireDialogComponents",
        on: { confirm: _vm.expireConfirm }
      }),
      _c("BatchManagerComponents", {
        ref: "BatchManagerComponents",
        on: { confirm: _vm.batchUpdate }
      }),
      _c("PackageComponents", {
        ref: "PackageComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("PrintTagComponents", { ref: "PrintTagComponents" }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.loadOrder }
      }),
      _c("DeliveryComponents", {
        ref: "DeliveryComponents",
        on: { confirm: _vm.loadOrder }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }