var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("盘存单审核")]),
              _c("el-breadcrumb-item", [_vm._v("盘存单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.remark))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "",
                          "label-width": "0",
                          prop: "",
                          align: "right"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "justify-content": "space-between",
                              padding: "0 10px"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "盈亏总数:(" +
                                  _vm._s(_vm.form.profitLossQuantity) +
                                  ")"
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "盈亏总额:(" +
                                  _vm._s(_vm.form.profitLossAmount) +
                                  ")"
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", [
                  _vm._v("总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("）")
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "tab-list-grey" },
          _vm._l(_vm.tabList, function(item, index) {
            return _c(
              "div",
              {
                key: "tab" + index,
                class:
                  _vm.activeTabId === item.ID ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(item)
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c("span", [
                  _vm._v("（"),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(item.number))
                  ]),
                  _vm._v("）")
                ])
              ]
            )
          }),
          0
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            staticStyle: { width: "100%" },
            attrs: {
              id: "detailListTable",
              data: _vm.detailList,
              border: "",
              stripeDELETE: "",
              size: "mini",
              "highlight-current-row": "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName
            }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("物资名称")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: scope.row.productName,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.productName))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("产品编号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.productNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("通用名称")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.genericName))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("序列号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.trackingCode))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("生产批号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.batchNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("库存数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.stockQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("可用数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.normalQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("销售锁定")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.salesPendingApproval))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("入库数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.receiptQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("出库锁定")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.issueLocking))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("消耗数量")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.consumedQuantity))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("包装规格")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(scope.row.packNum) +
                                    _vm._s(scope.row.unit) +
                                    " / " +
                                    _vm._s(scope.row.packUnit)
                                )
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("单价")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.unitPrice))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("单位")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.unit))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("注册证号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.registrationNumber))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("有效期")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.validityDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("生产日期：")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.manufacturerDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("产地/品牌")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content:
                                        scope.row.productPlace +
                                        "/" +
                                        scope.row.brandName,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.productPlace) +
                                          " / " +
                                          _vm._s(scope.row.brandName)
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "expand-item" },
                              [
                                _c("div", { staticClass: "label" }, [
                                  _vm._v("生产厂家")
                                ]),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: scope.row.manufacturer,
                                      placement: "top"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.manufacturer))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("灭菌批号")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(
                                  _vm._s(scope.row.sterilizationBatchNumber)
                                )
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("灭菌效期")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.sterilizationDate))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("储运条件")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.storage))
                              ])
                            ])
                          ]),
                          _c("el-col", { attrs: { sm: 12, lg: 8, xl: 4 } }, [
                            _c("div", { staticClass: "expand-item" }, [
                              _c("div", { staticClass: "label" }, [
                                _vm._v("货位")
                              ]),
                              _c("div", { staticClass: "value" }, [
                                _vm._v(_vm._s(scope.row.placementPosition))
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "物资名称",
                width: "180",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "genericName",
                label: "通用名称",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "生产批号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockQuantity",
                label: "库存数量",
                width: "80",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "issueLocking",
                label: "锁定数量",
                width: "80",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "normalQuantity",
                label: "可用数量",
                width: "80",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "physicalInventory",
                label: "盘点数量",
                width: "150",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "profitLossQuantity",
                label: "盈亏数量",
                width: "80",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "profitLossAmount",
                label: "盈亏金额",
                width: "80",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unitPrice",
                label: "单价",
                width: "150",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "序列号",
                width: "100",
                align: "center",
                prop: "trackingCode"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "生产日期",
                width: "150",
                align: "center",
                prop: "manufacturerDate"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "有效期",
                width: "150",
                align: "center",
                prop: "expirationDate"
              }
            }),
            _c("el-table-column", {
              attrs: {
                label: "原因",
                width: "150",
                align: "center",
                prop: "reason"
              }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-breadcrumb",
            {
              staticStyle: {
                "font-size": "12px",
                "margin-bottom": "10px",
                width: "100%"
              },
              attrs: { separator: " " }
            },
            [
              _c("el-breadcrumb-item", [
                _vm._v("盘点人：" + _vm._s(_vm.form.inventorier))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("盘点时间：" + _vm._s(_vm.form.inventoryTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核人：" + _vm._s(_vm.form.nameReviewer))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("审核时间：" + _vm._s(_vm.form.auditTime))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账人：" + _vm._s(_vm.form.bookkeeper))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("记账时间：" + _vm._s(_vm.form.postingDate))
              ]),
              _c("el-breadcrumb-item", [
                _vm._v("总数量：" + _vm._s(_vm.totalQuantity()))
              ])
            ],
            1
          ),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.id && _vm.form.documentStatus == 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-check",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" 审核")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.id && _vm.form.documentStatus == 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: { click: _vm.formUnCheck }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-undo",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" 撤消审核")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.id && _vm.form.documentStatus == 3
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formAccount }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-cny",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(" 记账")
                        ]
                      )
                    : _vm._e(),
                  _vm.form.id && _vm.form.documentStatus > 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.toPrint }
                        },
                        [_vm._v("打印")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponent", { ref: "PrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }