var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售报价")]),
              _c("el-breadcrumb-item", [_vm._v("寄售销售单")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "mini",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "报台单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入报台单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.salesOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "salesOrderNo", $$v)
                            },
                            expression: "searchForm.salesOrderNo"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "经销商",
                              "allow-create": "",
                              filterable: "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.searchForm.distributorName,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "distributorName", $$v)
                              },
                              expression: "searchForm.distributorName"
                            }
                          },
                          _vm._l(_vm.retailerList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.status,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "status", $$v)
                              },
                              expression: "searchForm.status"
                            }
                          },
                          _vm._l(_vm.statusList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: " 产品编号", clearable: "" },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "制单日期" },
                                model: {
                                  value: _vm.searchForm.timeType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "timeType", $$v)
                                  },
                                  expression: "searchForm.timeType"
                                }
                              },
                              _vm._l(_vm.timeTypeList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "unlink-panels": true,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.dateRange,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "dateRange", $$v)
                                },
                                expression: "searchForm.dateRange"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "产品批号" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "产品批号", clearable: "" },
                              model: {
                                value: _vm.searchForm.batchNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "batchNumber", $$v)
                                },
                                expression: "searchForm.batchNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "序列号" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "序列号", clearable: "" },
                              model: {
                                value: _vm.searchForm.trackingCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "trackingCode", $$v)
                                },
                                expression: "searchForm.trackingCode"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.orderList
              },
              on: {
                "row-click": _vm.orderRowClick,
                "row-dblclick": _vm.orderRowDbClick
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      (row.isRejectReason === 1 || row.rejectReason) &&
                      row.status === 1
                        ? _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "驳回原因：" + row.rejectReason,
                                placement: "top"
                              }
                            },
                            [
                              _c(
                                "el-link",
                                { attrs: { type: "danger", underline: false } },
                                [_vm._v("驳回")]
                              )
                            ],
                            1
                          )
                        : _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.statusFormatter(row))
                            }
                          })
                    ]
                  }
                },
                {
                  key: "salesOrderNo",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "barCodePopover" + row.ID,
                          attrs: { placement: "right", trigger: "click" }
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: { id: "barCodeImage" + row.ID }
                            })
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                underline: false,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.makeBarCode(
                                    row.ID,
                                    row.salesOrderNo
                                  )
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.salesOrderNo))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "operations",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.orderEdit(row)
                                }
                              }
                            },
                            [_vm._v("订单详情")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.searchForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.searchForm.pageSize
                  },
                  on: {
                    "current-change": _vm.orderPageChange,
                    "size-change": _vm.orderSizeChange
                  }
                })
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  stripeDELETE: "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.productNumber",
                    label: "产品编号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.productName",
                    label: "物资名称",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.medCode",
                    label: "医保编码",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.specificationModel",
                    width: "100",
                    label: "规格型号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inventory.batch_number",
                    label: "生产批号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "quantityUsed",
                    width: "100",
                    label: "销售数量",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.registrationNumber",
                    width: "150",
                    label: "注册证号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inventory.normalQuantity",
                    width: "150",
                    label: "在库数量",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inventory.manufacturer_date",
                    width: "150",
                    label: "生产日期",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inventory.validity_date",
                    width: "150",
                    label: "有效期",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.brandName",
                    width: "150",
                    label: "品牌",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.unit",
                    width: "150",
                    label: "单位",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    width: "150",
                    label: "包装规格",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.specs.packNum) +
                              _vm._s(scope.row.specs.unit) +
                              "/" +
                              _vm._s(scope.row.specs.packUnit) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.manufacturer",
                    width: "150",
                    label: "生产厂家",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.detailForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.detailForm.page,
                    "page-size": _vm.detailForm.pageSize
                  },
                  on: {
                    "current-change": _vm.detailPageChange,
                    "size-change": _vm.detailSizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }