import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js'; // 耗材组套相关接口

export function listConsumSet(params) {
  return request({
    url: '/erpConsumSet/getConsumSetList',
    method: 'get',
    params: params
  });
}
export function findConsumSet(params) {
  return request({
    url: '/erpConsumSet/findConsumSet',
    method: 'get',
    params: params
  });
}
export function deleteConsumSet(data) {
  return request({
    url: '/erpConsumSet/deleteConsumSet',
    method: 'delete',
    data: data
  });
}
export function createConsumSet(data) {
  return request({
    url: '/erpConsumSet/createConsumSet',
    method: 'post',
    data: data
  });
}
export function updateConsumSet(data) {
  return request({
    url: '/erpConsumSet/updateConsumSet',
    method: 'put',
    data: data
  });
}
export function listConsumSetInfo(params) {
  return request({
    url: '/consumSetInfoErp/getConsumSetInfoList',
    method: 'get',
    params: params
  });
}
export function findConsumSetInfo(params) {
  return request({
    url: '/consumSetInfoErp/findConsumSetInfo',
    method: 'get',
    params: params
  });
}
export function deleteConsumSetInfo(data) {
  return request({
    url: '/consumSetInfoErp/deleteConsumSetInfo',
    method: 'delete',
    data: data
  });
}
export function createConsumSetInfo(data) {
  return request({
    url: '/consumSetInfoErp/createConsumSetInfo',
    method: 'post',
    data: data
  });
}
export function updateConsumSetInfo(data) {
  return request({
    url: '/consumSetInfoErp/updateConsumSetInfo',
    method: 'put',
    data: data
  });
} // 新接口列表，将所有Set改为Group，参数不变。

export function listConsumGroup(params) {
  return request({
    url: '/erpConsumGroup/getConsumGroupList',
    method: 'get',
    params: params
  });
}
export function findConsumGroup(params) {
  return request({
    url: '/erpConsumGroup/findConsumGroup',
    method: 'get',
    params: params
  });
}
export function deleteConsumGroup(data) {
  return request({
    url: '/erpConsumGroup/deleteConsumGroup',
    method: 'delete',
    data: data
  });
}
export function createConsumGroup(data) {
  return request({
    url: '/erpConsumGroup/createConsumGroup',
    method: 'post',
    data: data
  });
}
export function updateConsumGroup(data) {
  return request({
    url: '/erpConsumGroup/updateConsumGroup',
    method: 'put',
    data: data
  });
}
export function listConsumGroupInfo(params) {
  return request({
    url: '/consumGroupInfoErp/getConsumGroupInfoList',
    method: 'get',
    params: params
  });
}
export function findConsumGroupInfo(params) {
  return request({
    url: '/consumGroupInfoErp/findConsumGroupInfo',
    method: 'get',
    params: params
  });
}
export function deleteConsumGroupInfo(data) {
  return request({
    url: '/consumGroupInfoErp/deleteConsumGroupInfo',
    method: 'delete',
    data: data
  });
}
export function createConsumGroupInfo(data) {
  return request({
    url: '/consumGroupInfoErp/createConsumGroupInfo',
    method: 'post',
    data: data
  });
}
export function updateConsumGroupInfo(data) {
  return request({
    url: '/consumGroupInfoErp/updateConsumGroupInfo',
    method: 'put',
    data: data
  });
}
export function listConsumGroupStock(params) {
  return request({
    url: '/consumGroupInfoErp/getConsumGroupStockList',
    method: 'get',
    params: params
  });
}