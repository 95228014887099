import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'purchase-income-invoice-view',
  components: {},
  data: function data() {
    return {
      dialogTitle: '查看进项发票信息',
      dialogVisible: false,
      loading: false,
      invoiceTypeList: [{
        ID: 1,
        name: '增值税普通发票'
      }, {
        ID: 2,
        name: '增值税专用发票'
      }, {
        ID: 3,
        name: '电子增值税普通发票'
      }, {
        ID: 4,
        name: '电子增值税专用发票'
      }],
      formLoading: false,
      uploadAction: process.env.VUE_APP_API + '/inside/upload',
      uploadToken: {},
      uploadFilePrefixURL: null,
      row: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;
      that.row = JSON.parse(JSON.stringify(row));
      that.row.attachment = JSON.parse(that.row.attachment);
      that.uploadFilePrefixURL = process.env.VUE_APP_OSS_URL + '/';
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    viewFile: function viewFile(file) {
      var that = this;
      var link = document.createElement('a');
      link.style.display = 'none';
      link.href = that.uploadFilePrefixURL + file.url;
      link.setAttribute('download', file.name);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    },
    invoiceTypeFormatter: function invoiceTypeFormatter(invoiceType) {
      var that = this;
      var findRow = that.invoiceTypeList.find(function (item) {
        return item.ID === invoiceType;
      });

      if (findRow) {
        return findRow.name;
      } else {
        return '';
      }
    }
  }
};