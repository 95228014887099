var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("耗材组套")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { height: "100%", overflow: "auto" } },
          [
            _c(
              "el-aside",
              {
                class: _vm.consumSetVisiable ? "move_right" : "move_left",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  border: "1px solid #DCDFE6",
                  "border-radius": "5px",
                  position: "relative"
                },
                attrs: { width: _vm.consumSetVisiable ? "200px" : "20px" }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.consumSetVisiable,
                        expression: "consumSetVisiable"
                      }
                    ],
                    staticClass: "tree-class-container"
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c(
                        "div",
                        { staticClass: "top-buttons" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetAdd }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 新增 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetEdit }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-edit",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 编辑 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetInvalid }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-trash",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 删除 ")
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "border-bottom": "1px solid #eee",
                          padding: "5px"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.warehouseLoading,
                                expression: "warehouseLoading"
                              }
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择",
                              size: "small",
                              clearable: ""
                            },
                            on: { change: _vm.warehouseChange },
                            model: {
                              value: _vm.currentWarehouseId,
                              callback: function($$v) {
                                _vm.currentWarehouseId = $$v
                              },
                              expression: "currentWarehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "tree" },
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.consumSetLoading,
                              expression: "consumSetLoading"
                            }
                          ],
                          ref: "consumSetTree",
                          staticClass: "filter-tree",
                          attrs: {
                            data: _vm.consumSetList,
                            props: _vm.consumSetProps,
                            "default-expand-all": "",
                            "highlight-current": true,
                            "expand-on-click-node": false,
                            "node-key": "ID"
                          },
                          on: { "node-click": _vm.consumSetRowClick },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c("span", {}, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      data.ID === 0
                                        ? _c("i", {
                                            class: node.expanded
                                              ? "folder el-icon-folder-opened"
                                              : "folder el-icon-folder"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-medkit",
                                            attrs: { "aria-hidden": "true" }
                                          }),
                                      _vm._v(" " + _vm._s(data.name))
                                    ]
                                  )
                                ])
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      right: "0"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "20px",
                          height: "60px",
                          background: "#eee",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          "border-top-left-radius": "5px",
                          "border-bottom-left-radius": "5px"
                        },
                        on: { click: _vm.consumSetVisiableChange }
                      },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "info", underline: false } },
                          [
                            _vm.consumSetVisiable
                              ? _c("i", {
                                  staticClass: "el-icon-caret-left",
                                  attrs: { "aria-hidden": "true" }
                                })
                              : _c("i", {
                                  staticClass: "el-icon-caret-right",
                                  attrs: { "aria-hidden": "true" }
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "el-aside",
              {
                class: _vm.consumSubSetVisiable ? "move_right2" : "move_left2",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  border: "1px solid #DCDFE6",
                  "border-radius": "5px",
                  position: "relative",
                  "margin-left": "10px"
                },
                attrs: { width: _vm.consumSubSetVisiable ? "200px" : "20px" }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.consumSubSetVisiable,
                        expression: "consumSubSetVisiable"
                      }
                    ],
                    staticClass: "tree-class-container"
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c(
                        "div",
                        { staticClass: "top-buttons" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetAdd }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 新增 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetEdit }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-edit",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 编辑 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetInvalid }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-trash",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 删除 ")
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.consumSubSetLoading,
                            expression: "consumSubSetLoading"
                          }
                        ],
                        ref: "consumSubSetListTable",
                        attrs: {
                          data: _vm.consumSubSetList,
                          size: "mini",
                          stripeDELETE: "",
                          "highlight-current-row": "",
                          "row-key": "sub" + _vm.rowKey,
                          "row-class-name": _vm.rowClassName
                        },
                        on: { "row-click": _vm.consumSubSetRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "ID",
                            label: "ID",
                            width: "80",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "名称",
                            align: "center"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      right: "0"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "20px",
                          height: "60px",
                          background: "#eee",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          "border-top-left-radius": "5px",
                          "border-bottom-left-radius": "5px"
                        },
                        on: { click: _vm.consumSubSetVisiableChange }
                      },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "info", underline: false } },
                          [
                            _vm.consumSubSetVisiable
                              ? _c("i", {
                                  staticClass: "el-icon-caret-left",
                                  attrs: { "aria-hidden": "true" }
                                })
                              : _c("i", {
                                  staticClass: "el-icon-caret-right",
                                  attrs: { "aria-hidden": "true" }
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "el-container",
              {
                staticStyle: { border: "1px solid #eee", "margin-left": "10px" }
              },
              [
                _c(
                  "el-header",
                  {
                    staticStyle: {
                      height: "fit-content",
                      display: "flex",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "search-bar" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "stockForm",
                            attrs: {
                              inline: true,
                              "label-width": "70px",
                              size: "mini",
                              "show-message": false,
                              "label-suffix": ":"
                            },
                            nativeOn: {
                              submit: function($event) {
                                $event.preventDefault()
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.stockFormSubmit.apply(
                                  null,
                                  arguments
                                )
                              }
                            },
                            model: {
                              value: _vm.stockForm,
                              callback: function($$v) {
                                _vm.stockForm = $$v
                              },
                              expression: "stockForm"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 10 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "编码" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          model: {
                                            value: _vm.stockForm.productNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.stockForm,
                                                "productNumber",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "stockForm.productNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "名称" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          model: {
                                            value: _vm.stockForm.productName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.stockForm,
                                                "productName",
                                                $$v
                                              )
                                            },
                                            expression: "stockForm.productName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "通用" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          model: {
                                            value: _vm.stockForm.genericName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.stockForm,
                                                "genericName",
                                                $$v
                                              )
                                            },
                                            expression: "stockForm.genericName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "批号" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          model: {
                                            value: _vm.stockForm.batchNumber,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.stockForm,
                                                "batchNumber",
                                                $$v
                                              )
                                            },
                                            expression: "stockForm.batchNumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "序列号" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "100px" },
                                          model: {
                                            value: _vm.stockForm.trackingCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.stockForm,
                                                "trackingCode",
                                                $$v
                                              )
                                            },
                                            expression: "stockForm.trackingCode"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { sm: 12, lg: 8, xl: 4 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: { click: _vm.stockFormSubmit }
                                          },
                                          [_vm._v("添加")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("el-input", {
                                      ref: "barcodeInput",
                                      staticClass: "barcode-input",
                                      attrs: { placeholder: "请扫描产品条码" },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.barCodeSearch.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.stockForm.barCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.stockForm,
                                            "barCode",
                                            $$v
                                          )
                                        },
                                        expression: "stockForm.barCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-popover",
                  {
                    ref: "stockPopover",
                    attrs: {
                      placement: "bottom-start",
                      title: "查找结果",
                      width: "1000",
                      trigger: "manual"
                    },
                    model: {
                      value: _vm.stockPopoverVisible,
                      callback: function($$v) {
                        _vm.stockPopoverVisible = $$v
                      },
                      expression: "stockPopoverVisible"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-close",
                      staticStyle: {
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        cursor: "pointer"
                      },
                      on: { click: _vm.stockPopoverClose }
                    }),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.stockList,
                          border: "",
                          stripe: "",
                          "highlight-current-row": "",
                          size: "mini",
                          "max-height": "260px",
                          "header-cell-style": { background: "#F5F7FA" },
                          onselectstart: "return false"
                        },
                        on: { "row-dblclick": _vm.stockRowDbClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "ID",
                            label: "序号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "productNumber",
                            label: "产品编号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "200",
                            property: "productName",
                            label: "物资名称",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "specificationModel",
                            label: "规格型号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "batchNumber",
                            label: "生产批号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "stockQuantity",
                            label: "库存数量",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "normalQuantity",
                            label: "可用数量",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "consumedQuantity",
                            label: "累计消耗数量",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "receiptQuantity",
                            label: "累计入库数量",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "issueLocking",
                            label: "出库锁定",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "salesPendingApproval",
                            label: "销售待审",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "productPackageLocking",
                            label: "商品包锁定",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "unit",
                            label: "单位",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "200",
                            property: "registrationNumber",
                            label: "注册证号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "manufacturerDate",
                            label: "生产日期",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "validityDate",
                            label: "有效期",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "",
                            label: "产地/品牌",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    _vm._s(scope.row.productPlace) +
                                      "/" +
                                      _vm._s(scope.row.brandName)
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "sterilizationBatchNumber",
                            label: "灭菌批号",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100",
                            property: "sterilizationDate",
                            label: "灭菌效期",
                            align: "center"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0" } },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            layout: "prev, pager, next, total",
                            align: "center",
                            total: _vm.stockForm.total,
                            "current-page": _vm.stockForm.page,
                            "page-size": _vm.stockForm.pageSize
                          },
                          on: { "current-change": _vm.stockListPageChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-main",
                  { staticStyle: { "margin-top": "0" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.consumLoading,
                            expression: "consumLoading"
                          }
                        ],
                        ref: "boxTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.consumList,
                          size: "small",
                          "highlight-current-row": "",
                          stripeDELETE: "",
                          border: "",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          }
                        },
                        on: { "row-click": _vm.consumRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "序号",
                            width: "80",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.productNumber",
                            width: "100",
                            label: "产品编号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "main.productName",
                            width: "200",
                            label: "物资名称",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "total",
                            label: "组套总数",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    attrs: { size: "mini", min: 0 },
                                    on: {
                                      change: function($event) {
                                        return _vm.totalChange(row)
                                      }
                                    },
                                    model: {
                                      value: row.total,
                                      callback: function($$v) {
                                        _vm.$set(row, "total", $$v)
                                      },
                                      expression: "row.total"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.genericName",
                            width: "100",
                            label: "通用名称",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.specificationModel",
                            width: "100",
                            label: "规格型号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.batch_number",
                            width: "100",
                            label: "生产批号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.trackingCode",
                            width: "100",
                            label: "序列号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sleeveLocking",
                            label: "组套锁定",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "available",
                            label: "组套可用",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.normalQuantity",
                            label: "库存可用",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.manufacturer_date",
                            label: "生产日期",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.validity_date",
                            label: "有效期",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.sterilization_batch_number",
                            label: "灭菌批号",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inventory.sterilization_date",
                            label: "灭菌效期",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "产地/品牌",
                            width: "200",
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.main.productPlace) +
                                      "/" +
                                      _vm._s(row.main.brandName) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "main.manufacturer",
                            width: "200",
                            label: "生产厂家",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.consumRemove(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-footer",
                  {
                    staticStyle: {
                      height: "53px",
                      "border-top": "1px solid #eee",
                      padding: "10px"
                    }
                  },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        total: _vm.consumPageForm.total,
                        "current-page": _vm.consumPageForm.page,
                        "page-size": _vm.consumPageForm.pageSize
                      },
                      on: {
                        "current-change": _vm.consumPageFormPageChange,
                        "size-change": _vm.consumPageFormPageSizeChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("ConsumSetFormComponents", {
        ref: "ConsumSetFormComponents",
        on: {
          reload: function($event) {
            return _vm.loadConsumSet()
          }
        }
      }),
      _c("ConsumSubSetFormComponents", {
        ref: "ConsumSubSetFormComponents",
        on: {
          reload: function($event) {
            return _vm.loadConsumSubSet()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }