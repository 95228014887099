var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("付款申请记录")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "采购单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "采购单号", clearable: "" },
                      on: { change: _vm.purchaseOrderNumbersChange },
                      model: {
                        value: _vm.searchForm.purchaseOrderNumbers,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "purchaseOrderNumbers", $$v)
                        },
                        expression: "searchForm.purchaseOrderNumbers"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "申请单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.appOrderNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "appOrderNumber", $$v)
                        },
                        expression: "searchForm.appOrderNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "付款公司" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "付款公司", clearable: "" },
                      model: {
                        value: _vm.searchForm.paymentCompany,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "paymentCompany", $$v)
                        },
                        expression: "searchForm.paymentCompany"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c("el-form-item", { attrs: { label: "" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-right": "10px" },
                              model: {
                                value: _vm.searchForm.timeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "timeType", $$v)
                                },
                                expression: "searchForm.timeType"
                              }
                            },
                            _vm._l(_vm.timeTypeList, function(item, index) {
                              return _c("el-option", {
                                key: "timeType-" + index,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          ),
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "unlink-panels": true,
                              "range-separator": "至",
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期"
                            },
                            model: {
                              value: _vm.searchForm.dateRange,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "dateRange", $$v)
                              },
                              expression: "searchForm.dateRange"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "appOrderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makeBarCode(row.ID, row.appOrderNumber)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.appOrderNumber))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "purchaseOrderNumbers",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.purchaseOrderNumbers.length > 1
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "auto",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "el-list" },
                            _vm._l(row.purchaseOrderNumbers, function(
                              item,
                              idx
                            ) {
                              return _c(
                                "div",
                                { key: "purchaseOrderNumbers-" + idx },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewPurchaseOrder(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                underline: false
                              },
                              slot: "reference"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(row.purchaseOrderNumbers[0]) + " "
                              ),
                              row.purchaseOrderNumbers.length > 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f00" } },
                                    [
                                      _vm._v(
                                        "+" +
                                          _vm._s(
                                            row.purchaseOrderNumbers.length - 1
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : row.purchaseOrderNumbers.length === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.viewPurchaseOrder(
                                row.purchaseOrderNumbers[0]
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.purchaseOrderNumbers[0]))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewApply(row)
                            }
                          }
                        },
                        [_vm._v("查看申请单")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewVoucher(row)
                            }
                          }
                        },
                        [_vm._v("查看相关凭证")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.orderPageChange,
                "size-change": _vm.orderSizeChange
              }
            })
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              size: "small",
              data: _vm.detailList,
              "default-expand-all": true,
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand", width: "100", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-table",
                        { attrs: { data: scope.row.detail, size: "mini" } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "100",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编号",
                              prop: "specs.productNumber",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "main.productName",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格型号",
                              prop: "specs.specificationModel",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              prop: "specs.unit",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购数量",
                              prop: "purchaseQuantity",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购单价",
                              prop: "purchaseUnitPrice",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购金额",
                              prop: "productNumber",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.purchaseQuantity *
                                              scope.row.purchaseUnitPrice
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "orderNumber",
                label: "采购单号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "buyer",
                label: "采购人",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseDate",
                label: "采购日期",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseAmount",
                label: "订单金额",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "paidAmountSum",
                label: "已付金额",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseQuantity",
                "min-width": "100",
                label: "数量",
                align: "center",
                "show-overflow-tooltip": ""
              }
            })
          ],
          1
        )
      ],
      _c("VoucherComponent", { ref: "VoucherComponentRef" }),
      _c("PaymentFormComponent", {
        ref: "PaymentFormComponent",
        on: { reload: _vm.loadData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }