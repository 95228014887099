import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { barcodeParse } from '@/api/product.js';
import { listSpecial } from '@/api/special.js';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '扫码收货',
      dialogVisible: false,
      loading: false,
      form: {
        pi: '',
        // 11开头，长度小于20位
        di: '',
        // 01开头，长度小于20位
        dipi: '',
        // 长度大于20位
        barCode: ''
      },
      barInputPlaceholder: '请扫描产品条形码',
      lastType: '',
      specsForm: {
        barCode: '',
        page: 1,
        pageSize: 1,
        warehouseId: 0
      },
      tips: '',
      barCodeRs: {
        batchNumber: null,
        trackingCode: null,
        validityDate: null,
        manufacturerDate: null
      },
      readonly: false,
      inputType: '手动'
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(warehouseId) {
      var that = this;
      that.dialogVisible = true;
      that.form.warehouseId = warehouseId;
      that.form.barCode = '';
      that.tips = '';
      that.inputType = '手动';
      that.readonly = false;
      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    barCodeSearch: function barCodeSearch() {
      var that = this;

      if (that.form.barCode) {
        that.form.barCode = that.form.barCode.replace(/[^a-zA-Z0-9]/g, '');
        var di = '';
        var dipi = '';

        if (that.form.barCode.substr(0, 2) === '01' && that.form.barCode.length <= 20) {
          di = that.form.barCode.substr(0, 16);
          barcodeParse({
            di: di
          }).then(function (res) {
            // 用产品主码查找产品，并添加到列表
            that.specsForm.barCode = res.barCode;
            listSpecial(that.specsForm).then(function (res2) {
              if (res2.total === 0) {
                that.$message.error('未找到相匹配的产品');
              } else {
                res2.list = that.specsListFormat(res2.list);
                res2.list[0].barCode = res.barCode;
                res2.list[0].batchNumber = res.batch_number ? res.batch_number : res.trackingCode;
                res2.list[0].validityDate = res.expirationDate ? '20' + res.expirationDate.substr(0, 2) + '-' + res.expirationDate.substr(2, 2) + '-' + res.expirationDate.substr(4, 2) : '';
                res2.list[0].manufacturerDate = res.manufacturer_date ? '20' + res.manufacturer_date.substr(0, 2) + '-' + res.manufacturer_date.substr(2, 2) + '-' + res.manufacturer_date.substr(4, 2) : '';
                res2.list[0].trackingCode = res.trackingCode;
                that.$emit('add', res2.list[0]);
                that.$nextTick(function () {
                  that.$refs.barCodeInput.focus();
                });
              }
            }).catch(function (e2) {
              that.$message.error(e2.message);
            });
          });
        } else if (that.form.barCode.substr(0, 2) === '01' && that.form.barCode.length > 20) {
          dipi = that.form.barCode;
          barcodeParse({
            dipi: dipi
          }).then(function (res) {
            that.specsForm.barCode = res.barCode; // that.specsForm.batchNumber = res.batch_number

            listSpecial(that.specsForm).then(function (res2) {
              if (res2.total === 0) {
                that.$message.error('未找到相匹配的产品');
              } else {
                res2.list = that.specsListFormat(res2.list);
                res2.list[0].barCode = res.barCode;
                res2.list[0].batchNumber = res.batch_number ? res.batch_number : res.trackingCode;
                res2.list[0].validityDate = res.expirationDate ? '20' + res.expirationDate.substr(0, 2) + '-' + res.expirationDate.substr(2, 2) + '-' + res.expirationDate.substr(4, 2) : '';
                res2.list[0].manufacturerDate = res.manufacturer_date ? '20' + res.manufacturer_date.substr(0, 2) + '-' + res.manufacturer_date.substr(2, 2) + '-' + res.manufacturer_date.substr(4, 2) : '';
                res2.list[0].trackingCode = res.trackingCode;
                that.$emit('add', res2.list[0]);
              }
            });
          });
          that.tips = '';
        }
      } else {
        that.$message.error('请扫描、输入条码');
      }

      that.form.barCode = '';
      return false;
    },
    specsListFormat: function specsListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          ID: row.ID,
          mainId: row.mainId,
          specsId: row.ID,
          rowIndex: index,
          productNumber: row.productNumber,
          unit: row.unit,
          packUnit: row.packUnit,
          packNum: row.packNum,
          unitPrice: row.wholesalePrice,
          wholesalePurchasePrice: row.wholesalePurchasePrice,
          consignmentPurchasePrice: row.consignmentPurchasePrice,
          marketPrice: row.marketPrice,
          consignmentPrice: row.consignmentPrice,
          wholesalePrice: row.wholesalePrice,
          sterilizationWays: row.sterilizationWays,
          specificationModel: row.specificationModel,
          batchNumber: row.stockMain.batch_number,
          stockQuantity: row.stockMain.stock_quantity,
          normalQuantity: row.stockMain.normalQuantity,
          consumedQuantity: row.stockMain.consumedQuantity,
          receiptQuantity: row.stockMain.receiptQuantity,
          issueLocking: row.stockMain.issueLocking,
          salesPendingApproval: row.stockMain.salesPendingApproval,
          productPackageLocking: row.stockMain.productPackageLocking,
          manufacturerDate: row.stockMain.manufacturer_date,
          validityDate: row.stockMain.validity_date,
          sterilizationBatchNumber: row.stockMain.sterilization_batch_number,
          sterilizationDate: row.stockMain.sterilization_date,
          placementPosition: row.stockMain.placement_position,
          inventoryId: row.stockMain.ID,
          trackingCode: row.trackingCode ? row.trackingCode : '',
          productName: row.main.productName,
          registrationNumber: row.main.registrationNumber,
          expirationDate: row.main.expirationDate,
          productPlace: row.main.productPlace,
          brandName: row.main.brandName,
          manufacturerName: row.stockMain.manufacturerName,
          afterTaxPrice: Number(row.stockMain.afterTaxPrice),
          inWarehouseQuantity: 1
        });
      });
      return resultRows;
    },
    inputParse: function inputParse(e) {
      var that = this;

      if (that.readonly) {
        var filterList = 'abcdefghijklmnopqrstuvwxyz0123456789+-*/,./<>?;:"\'[]{}\\|=_)(&^%$#@!~`)';

        if (filterList.indexOf(e.key.toLowerCase()) !== -1) {
          that.form.barCode = that.form.barCode + e.key;
        }
      }
    },
    inputTypeClick: function inputTypeClick() {
      var that = this;

      if (that.inputType === '手动') {
        that.inputType = '扫码';
        that.readonly = true;
      } else {
        that.inputType = '手动';
        that.readonly = false;
      }

      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    },
    focusInput: function focusInput() {
      var that = this;

      if (that.dialogVisible) {
        setTimeout(function () {
          that.$refs.barCodeInput.focus();
        }, 0);
      } else {
        console.log('窗口已关');
      }

      that.$nextTick(function () {
        that.$refs.barCodeInput.focus();
      });
    }
  }
};