import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listPersonnel } from '@/api/personnel.js';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '选择用户',
      dialogVisible: false,
      rowIndex: 0,
      loading: false,
      userList: [],
      itemForm: {
        page: 1,
        pageSize: 5,
        total: 1
      },
      type: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(type) {
      var that = this;
      that.dialogVisible = true;
      that.rowIndex = 0;
      that.type = type;
      that.loadUser();
    },
    loadUser: function loadUser() {
      var that = this;
      that.loading = true;
      listPersonnel(that.itemForm).then(function (res) {
        that.userList = res.list;
        that.itemForm.total = res.total;
      }).finally(function () {
        that.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.itemForm.page = page;
      that.loadUser();
    },
    rowClick: function rowClick(row, column) {
      var that = this;
      that.rowIndex = row.index;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var that = this;

      if (that.rowIndex < 1) {
        that.$message.error('请选择用户');
      } else {
        var row = that.userList[that.rowIndex - 1];
        that.$emit('reload', {
          row: row,
          type: that.type
        });
        that.dialogClose();
      }
    },
    rowClassName: function rowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;
      row.index = rowIndex + 1;
    }
  }
};