import { request } from '@/api/_service.js'; // 温度湿度列表

export function listWeather(params) {
  return request({
    url: '/weatherConditionsErp/getWeatherConditionsList',
    method: 'get',
    params: params
  });
} // 温度湿度列表导出EXCEL

export function listWeatherExport(params) {
  return request({
    url: '/stock/getStockMainGroupList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 温度湿度查询

export function findWeather(params) {
  return request({
    url: '/weatherConditionsErp/findWeatherConditions',
    method: 'get',
    params: params
  });
} // 创建温度湿度记录

export function createWeather(data) {
  return request({
    url: '/weatherConditionsErp/createWeatherConditions',
    method: 'post',
    data: data
  });
} // 批量创建温度湿度记录

export function createWeathers(data) {
  return request({
    url: '/weatherConditionsErp/createWeatherConditions2',
    method: 'post',
    data: data
  });
} // 更新温度湿度记录

export function updateWeather(data) {
  return request({
    url: '/weatherConditionsErp/updateWeatherConditions',
    method: 'put',
    data: data
  });
} // 删除温度湿度记录

export function deleteWeather(data) {
  return request({
    url: '/weatherConditionsErp/deleteWeatherConditions',
    method: 'delete',
    data: data
  });
} // 批量删除温度湿度记录

export function deleteWeatherByIds(data) {
  return request({
    url: '/weatherConditionsErp/deleteWeatherConditionsByIds',
    method: 'delete',
    data: data
  });
} // 温度湿度列表明细

export function listWeatherDetail(params) {
  return request({
    url: '/erpWeatherRecordsErp/getErpWeatherRecordsList',
    method: 'get',
    params: params
  });
} // 温度湿度列表明细查询

export function findWeatherDetail(params) {
  return request({
    url: '/erpWeatherRecordsErp/findErpWeatherRecords',
    method: 'get',
    params: params
  });
} // 创建温度湿度记录明细

export function createWeatherDetail(data) {
  return request({
    url: '/erpWeatherRecordsErp/createErpWeatherRecords',
    method: 'post',
    data: data
  });
} // 更新温度湿度记录明细

export function updateWeatherDetail(data) {
  return request({
    url: '/erpWeatherRecordsErp/updateErpWeatherRecords',
    method: 'put',
    data: data
  });
} // 删除温度湿度记录明细

export function deleteWeatherDetail(data) {
  return request({
    url: '/erpWeatherRecordsErp/deleteErpWeatherRecords',
    method: 'delete',
    data: data
  });
} // 批量删除温度湿度记录明细

export function deleteWeatherDetailByIds(data) {
  return request({
    url: '/erpWeatherRecordsErp/deleteErpWeatherRecordsByIds',
    method: 'delete',
    data: data
  });
}