//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';

function onUpload(event) {
  var items = event.clipboardData && event.clipboardData.items;
  var file = null;

  if (items && items.length) {
    // 检索剪切板items
    for (var i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        file = items[i].getAsFile();
        var imgContent = event.target.result;
        var reader = new FileReader();

        reader.onload = function (event) {};

        reader.readAsDataURL(file);
        break;
      }
    }
  }
}

export default {
  name: 'paste-upload',
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      previewURL: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogTitle = '粘贴上传';
      that.dialogVisible = true; // document.body.addEventListener('paste', (event)=>{})
    },
    uploadSuccess: function uploadSuccess() {},
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
      document.getElementById('pasteUploadDialog').removeEventListener('paste');
    }
  }
};