var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            size: "small",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            data: _vm.dataList
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "发票号",
              align: "center",
              width: "200",
              prop: "invoiceNumber",
              "show-message-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.viewInvoice(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.invoiceNumber))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "采购单位",
              align: "center",
              prop: "paymentCompany",
              "show-message-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "含税金额",
              align: "center",
              prop: "amountIncludingTax",
              "show-message-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "税额",
              align: "center",
              prop: "taxAmount",
              "show-message-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "不含税金额",
              align: "center",
              prop: "money",
              "show-message-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票日期",
              align: "center",
              prop: "invoicingDate",
              "show-message-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "发票类型",
              align: "center",
              prop: "invoiceTypeName",
              "show-message-tooltip": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "page-sizes": [10, 20, 50, 100],
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" }, attrs: { align: "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
            [_vm._v("关闭")]
          )
        ],
        1
      ),
      _c("InvoiceViewComponent", { ref: "InvoiceViewComponentRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }