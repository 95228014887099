import { render, staticRenderFns } from "./add_return.vue?vue&type=template&id=815d4afe&scoped=true&"
import script from "./add_return.vue?vue&type=script&lang=js&"
export * from "./add_return.vue?vue&type=script&lang=js&"
import style0 from "./add_return.vue?vue&type=style&index=0&id=815d4afe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "815d4afe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP前端\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('815d4afe')) {
      api.createRecord('815d4afe', component.options)
    } else {
      api.reload('815d4afe', component.options)
    }
    module.hot.accept("./add_return.vue?vue&type=template&id=815d4afe&scoped=true&", function () {
      api.rerender('815d4afe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/outwarehouse/procurement/components/product/add_return.vue"
export default component.exports