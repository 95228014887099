import { request } from '@/api/_service.js';
export function listRegistration(params) {
  return request({
    url: '/product/getCertList',
    method: 'get',
    params: params
  });
}
export function findRegistration(params) {
  return request({
    url: '/product/findCert',
    method: 'get',
    params: params
  });
}
export function createRegistration(data) {
  return request({
    url: '/product/createCert',
    method: 'post',
    data: data
  });
}
export function updateRegistration(data) {
  return request({
    url: '/product/updateCert',
    method: 'put',
    data: data
  });
}
export function deleteRegistration(data) {
  return request({
    url: '/product/deleteCert',
    method: 'delete',
    data: data
  });
}
export function deleteRegistrationByIds(data) {
  return request({
    url: '/product/deleteCertByIds',
    method: 'delete',
    data: data
  });
}
export function findAllRegistration(params) {
  return request({
    url: '/product/findAllMainsCert',
    method: 'get',
    params: params
  });
}
export function findMainRegistration(params) {
  return request({
    url: '/product/findMainsCert',
    method: 'get',
    params: params
  });
}
export function downloadCertTemplate(params) {
  return request({
    url: '/product/getCertTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}