var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-suffix": ":",
            "label-width": "90px",
            size: "small"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "经销商", prop: "manuId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "" },
                  model: {
                    value: _vm.form.manuId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "manuId", $$v)
                    },
                    expression: "form.manuId"
                  }
                },
                _vm._l(_vm.manufacturerList, function(item, index) {
                  return _c("el-option", {
                    key: "manuId" + index,
                    attrs: {
                      label: item.name,
                      value: item.ID,
                      disabled: item.show_status === false ? true : false
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户类型", prop: "clientType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "" },
                  model: {
                    value: _vm.form.clientType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "clientType", $$v)
                    },
                    expression: "form.clientType"
                  }
                },
                _vm._l(_vm.clientTypeList, function(item, index) {
                  return _c("el-option", {
                    key: "clientType" + index,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户名称", prop: "username" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  placeholder: "请输入客户名称",
                  clearable: "",
                  maxlength: "255",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.username,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "mark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入备注",
                  clearable: "",
                  maxlength: "1000",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.mark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "mark", $$v)
                  },
                  expression: "form.mark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }