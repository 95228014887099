import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listSupplier } from '@/api/supplier.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '供货商许可证预警';
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        is_warning: 1
      };
      that.loadSupplier();
    },
    loadSupplier: function loadSupplier() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      listSupplier(that.form).then(function (res) {
        res.list.forEach(function (row) {
          var expire = that.expireData(row);
          row.expireDate = expire.expireDate;
          row.expireDays = expire.expireDays;
          row.validityCategory = that.validateFormatter(row);
          that.dbList.push(row);
        });
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadSupplier();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toSupplier: function toSupplier(row) {
      var that = this;
      that.$router.push({
        name: 'base-supplier-index',
        params: {
          name: row.name
        }
      });
      that.dialogClose();
    },
    validateFormatter: function validateFormatter(row) {
      if (row.expireDays >= 1) {
        return Math.abs(row.expireDays) + '天后过期';
      } else {
        return '已过期' + Math.abs(row.expireDays) + '天';
      }
    },
    cellClassName: function cellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (column.property === 'expireDate' || column.property === 'validityCategory') {
        if (row.expireDays >= 1) {
          return 'text-warning';
        } else {
          return 'text-danger';
        }
      }
    },
    expireData: function expireData(row) {
      var date = dayjs(dayjs().format('YYYY-MM-DD'));
      var date1 = '';

      if (row.license_type === 2 || row.license_type === 3) {
        if (row.license_expired && row.end_time_business) {
          if (dayjs(row.license_expired).isBefore(dayjs(row.end_time_business))) {
            date1 = dayjs(row.license_expired);
          } else {
            date1 = dayjs(row.end_time_business);
          }
        } else {
          date1 = dayjs(row.end_time_business || row.license_expired);
        }
      } else if (row.license_type === 5) {
        if (row.manu_license_end && row.end_time_business) {
          if (dayjs(row.manu_license_end).isBefore(dayjs(row.end_time_business))) {
            date1 = dayjs(row.manu_license_end);
          } else {
            date1 = dayjs(row.end_time_business);
          }
        } else {
          date1 = dayjs(row.end_time_business || row.manu_license_end);
        }
      } else {
        date1 = dayjs(row.end_time_business);
      }

      var diff1 = date1 ? date1.diff(date, 'day') : 999;
      return {
        expireDate: dayjs(date1).format('YYYY-MM-DD'),
        expireDays: diff1
      };
    }
  }
};