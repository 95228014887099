import { request } from '@/api/_service.js';
export function listBarcode(params) {
  return request({
    url: '/dipiStructErp/getDipiList',
    method: 'get',
    params: params
  });
}
export function findBarcode(params) {
  return request({
    url: '/dipiStructErp/findDipi',
    method: 'get',
    params: params
  });
}
export function createBarcode(data) {
  return request({
    url: '/dipiStructErp/createDipi',
    method: 'post',
    data: data
  });
}
export function updateBarcode(data) {
  return request({
    url: '/dipiStructErp/updateDipi',
    method: 'put',
    data: data
  });
}
export function deleteBarcode(data) {
  return request({
    url: '/dipiStructErp/deleteDipi',
    method: 'delete',
    data: data
  });
}