var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "移动规格",
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      [
        _c(
          "el-container",
          [
            _c(
              "el-container",
              [
                _c(
                  "el-aside",
                  {
                    staticStyle: { height: "500px", overflow: "auto" },
                    attrs: { width: "250px" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "输入关键字进行过滤",
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.filterText,
                        callback: function($$v) {
                          _vm.filterText = $$v
                        },
                        expression: "filterText"
                      }
                    }),
                    _c("el-tree", {
                      ref: "classTree",
                      attrs: {
                        data: _vm.classData,
                        props: _vm.defaultProps,
                        "default-expand-all": "",
                        "highlight-current": true,
                        "filter-node-method": _vm.filterNode
                      },
                      on: { "node-click": _vm.treeClick }
                    })
                  ],
                  1
                ),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.productPageForm,
                          size: "small",
                          "label-suffix": ":"
                        },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择产品状态"
                                },
                                model: {
                                  value: _vm.productPageForm.state,
                                  callback: function($$v) {
                                    _vm.$set(_vm.productPageForm, "state", $$v)
                                  },
                                  expression: "productPageForm.state"
                                }
                              },
                              _vm._l(_vm.stateList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入产品名称",
                                clearable: ""
                              },
                              model: {
                                value: _vm.productPageForm.productName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.productPageForm,
                                    "productName",
                                    $$v
                                  )
                                },
                                expression: "productPageForm.productName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.searchSubmit }
                              },
                              [_vm._v("查询")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-tour", {
                      attrs: {
                        name: "myTour",
                        steps: _vm.steps,
                        options: _vm.tourOptions
                      }
                    }),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.productLoading,
                            expression: "productLoading"
                          }
                        ],
                        ref: "productTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          "highlight-current-row": "",
                          stripeDELETE: "",
                          id: "v-step-0",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          }
                        },
                        on: { "row-click": _vm.productRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "ID",
                            label: "序号",
                            width: "80",
                            align: "center",
                            fixed: ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productName",
                            label: "物资名称",
                            align: "center",
                            fixed: "",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productLine",
                            label: "产品线",
                            width: "150",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "brandName",
                            label: "品牌",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "registrationNumber",
                            label: "注册证号",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "state",
                            label: "状态",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.state == 1
                                    ? _c("div", [_vm._v("正常")])
                                    : _vm._e(),
                                  scope.row.state == 2
                                    ? _c(
                                        "div",
                                        { staticStyle: { color: "#f00" } },
                                        [_vm._v("作废")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0" } },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            layout: "prev, pager, next, total",
                            align: "center",
                            total: _vm.productPageForm.total,
                            "current-page": _vm.productPageForm.page,
                            "page-size": _vm.productPageForm.pageSize
                          },
                          on: { "current-change": _vm.productPageChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          attrs: { slot: "footer", align: "center" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.moveConfirm }
            },
            [_vm._v("确认移动")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }