var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("投诉处理")]),
              _c("el-breadcrumb-item", [_vm._v("召回单详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 召回编号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "召回日期", prop: "recallTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: _vm.form.recallTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "recallTime", $$v)
                            },
                            expression: "form.recallTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "受理人", prop: "acceptanceName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.acceptanceName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acceptanceName", $$v)
                              },
                              expression: "form.acceptanceName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "召回原因", prop: "recallReason" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "召回原因",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.recallReason,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "recallReason", $$v)
                            },
                            expression: "form.recallReason"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: _vm.form.status > 1
                },
                on: { click: _vm.selectProduct }
              },
              [_vm._v("添加召回产品")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              data: _vm.pageDataLoad(),
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              "max-height": "400"
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "registrationNumber",
                label: "注册证号",
                width: "200",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "brandName",
                label: "品牌",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "批号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerDate",
                label: "生产日期",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "validityDate",
                label: "有效期",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "deliveryTime",
                label: "销售时间",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "deliveryQuantity",
                label: "销售数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "recallQuantity",
                label: "召回数量",
                width: "100",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              },
                              input: function($event) {
                                return _vm.recallQuantityChange(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.recallQuantity,
                              callback: function($$v) {
                                _vm.$set(scope.row, "recallQuantity", $$v)
                              },
                              expression: "scope.row.recallQuantity"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.recallQuantity))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "recallRemark",
                label: "差额原因",
                width: "200",
                align: "center",
                fixed: "right",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.recallRemark,
                              callback: function($$v) {
                                _vm.$set(scope.row, "recallRemark", $$v)
                              },
                              expression: "scope.row.recallRemark"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.recallRemark))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, total",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormChange }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: _vm.rowIndex < 0 ? true : false
                          },
                          on: { click: _vm.rowRemove }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "info" },
                          on: { click: _vm.formStorage }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.toPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.checkConfirm }
      }),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }