import { request } from '@/api/_service.js';
export function listPlatform(params) {
  return request({
    url: '/platformErp/getPlatformList',
    method: 'get',
    params: params
  });
}
export function createPlatform(data) {
  return request({
    url: '/platformErp/createPlatform',
    method: 'post',
    data: data
  });
}
export function deletePlatform(data) {
  return request({
    url: '/platformErp/deletePlatform',
    method: 'delete',
    data: data
  });
}