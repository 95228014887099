import { request } from '@/api/_service.js';
export function listClient(params) {
  return request({
    url: '/clientErp/getErpDealerClientList',
    method: 'get',
    params: params
  });
}
export function findClient(params) {
  return request({
    url: '/clientErp/findErpDealerClient',
    method: 'get',
    params: params
  });
}
export function createClient(data) {
  return request({
    url: '/clientErp/createErpDealerClient',
    method: 'post',
    data: data
  });
}
export function updateClient(data) {
  return request({
    url: '/clientErp/updateErpDealerClient',
    method: 'put',
    data: data
  });
}
export function deleteClient(data) {
  return request({
    url: '/clientErp/deleteErpDealerClient',
    method: 'delete',
    data: data
  });
}
export function deleteClientByIds(data) {
  return request({
    url: '/clientErp/deleteErpDealerClientByIds',
    method: 'delete',
    data: data
  });
}