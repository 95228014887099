var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "复制盒子",
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "flex-direction": "row"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "520px",
                height: "100%",
                display: "flex",
                "flex-direction": "column",
                "justify-content": "flex-start"
              }
            },
            [
              _c("div", { staticStyle: { height: "30px" } }, [
                _vm._v("请选择要复制到哪个包")
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "flex-direction": "row",
                    border: "1px solid #ddd",
                    flex: "1"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "250px",
                        "border-right": "1px solid #ddd"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "center",
                            "align-items": "center",
                            height: "40px",
                            "border-bottom": "1px solid #ddd",
                            "box-sizing": "border-box"
                          }
                        },
                        [
                          _vm._v(" 仓库："),
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.warehouseLoading,
                                  expression: "warehouseLoading"
                                }
                              ],
                              attrs: {
                                placeholder: "请选择仓库",
                                size: "small",
                                clearable: ""
                              },
                              on: { change: _vm.warehouseChange },
                              model: {
                                value: _vm.form.warehouseId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "warehouseId", $$v)
                                },
                                expression: "form.warehouseId"
                              }
                            },
                            _vm._l(_vm.warehouseList, function(item) {
                              return _c("el-option", {
                                key: item.ID,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            overflow: "auto",
                            height: "480px"
                          }
                        },
                        [
                          _c("el-tree", {
                            ref: "packagesTypeTree",
                            staticClass: "filter-tree",
                            attrs: {
                              data: _vm.packageTypeList,
                              props: _vm.packagesTypeProps,
                              "default-expand-all": "",
                              "highlight-current": true,
                              "expand-on-click-node": false,
                              "node-key": "ID"
                            },
                            on: { "node-click": _vm.packageTypeClick },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var node = ref.node
                                  var data = ref.data
                                  return _c("span", {}, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "14px" } },
                                      [
                                        data.ID === 0
                                          ? _c("i", {
                                              class: node.expanded
                                                ? "folder el-icon-folder-opened"
                                                : "folder el-icon-folder"
                                            })
                                          : _c("i", {
                                              staticClass: "fa fa-medkit",
                                              attrs: { "aria-hidden": "true" }
                                            }),
                                        _vm._v(" " + _vm._s(data.name))
                                      ]
                                    )
                                  ])
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.packageSubTypeListLoading,
                              expression: "packageSubTypeListLoading"
                            }
                          ],
                          ref: "packageSubTypeListTable",
                          attrs: {
                            data: _vm.packageSubTypeList,
                            size: "mini",
                            "highlight-current-row": "",
                            "header-cell-style": {
                              background: "#f5f7fa",
                              borderColor: "#ebeef5",
                              color: "#333"
                            },
                            "row-key": _vm.rowKey,
                            "row-class-name": _vm.rowClassName,
                            "max-height": "480"
                          },
                          on: { "row-click": _vm.packSubTypeRowClick }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "名称",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "barCode",
                              label: "条码",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                "margin-left": "20px",
                display: "flex",
                "flex-direction": "column",
                "justify-content": "flex-start"
              }
            },
            [
              _c("div", { staticStyle: { height: "30px" } }, [
                _vm._v("包内已有盒子")
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #ddd",
                    flex: "1",
                    overflow: "auto"
                  }
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.packageLoading,
                          expression: "packageLoading"
                        }
                      ],
                      ref: "boxTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.packageList,
                        size: "small",
                        height: "520",
                        "max-height": "520",
                        "highlight-current-row": "",
                        "header-cell-style": {
                          background: "#f5f7fa",
                          borderColor: "#ebeef5",
                          color: "#333"
                        }
                      },
                      on: { "row-click": _vm.packageRowClick }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "ID",
                          label: "序号",
                          width: "80",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "名称", align: "center" }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "容量", width: "150", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  _vm._s(row.usage) + "/" + _vm._s(row.capacity)
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("粘 贴1")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }