import { request } from '@/api/_service.js'; // 获取我拥有的组织

export function myOrganization() {
  return request({
    url: '/erp/org_user',
    method: 'get'
  });
} // 绑定组织

export function bindOrganization(data) {
  return request({
    url: '/erp/org_user_bind',
    method: 'post',
    data: data
  });
} // 获取我拥有的角色

export function myRoles(uid, organizationId) {
  return request({
    url: '/erp/org_user_roles?uid=' + uid + '&orgid=' + organizationId,
    method: 'get'
  });
}