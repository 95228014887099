import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js'; // 耗材包分类列表

export function listPackagesType(params) {
  return request({
    url: '/consum/getConsumTypeList',
    method: 'get',
    params: params
  });
} // 耗材包分类创建

export function createPackagesType(data) {
  return request({
    url: '/consum/createConsumType',
    method: 'post',
    data: data
  });
} // 耗材包分类更新

export function updatePackagesType(data) {
  return request({
    url: '/consum/updateConsumType',
    method: 'put',
    data: data
  });
} // 耗材包分类删除

export function deletePackagesType(data) {
  return request({
    url: '/consum/deleteConsumType',
    method: 'delete',
    data: data
  });
} // 耗材包分类查找

export function findPackagesType(params) {
  return request({
    url: '/consum/findConsumType',
    method: 'get',
    params: params
  });
} // 耗材包分类获取条码

export function getPackagesBarcode(params) {
  return request({
    url: '/consum/getBarcode',
    method: 'get',
    params: params
  });
} // 耗材包列表

export function listPackages(params) {
  return request({
    url: '/consum/getConsumPackList',
    method: 'get',
    params: params
  });
} // 耗材包创建

export function createPackages(data) {
  return request({
    url: '/consum/createConsumPack',
    method: 'post',
    data: data
  });
} // 耗材包更新

export function updatePackages(data) {
  return request({
    url: '/consum/updateConsumPack',
    method: 'put',
    data: data
  });
} // 耗材包作废

export function deletePackages(data) {
  return request({
    url: '/consum/deleteConsumPack',
    method: 'delete',
    data: data
  });
} // 批量删除耗材包内产品

export function deleteConsumInfoByIds(data) {
  return request({
    url: '/consum/deleteConsumInfoByIds',
    method: 'delete',
    data: data
  });
} // 耗材包查找

export function findPackages(params) {
  return request({
    url: '/consum/findConsumPack',
    method: 'get',
    params: params
  });
} // 耗材包明细列表

export function listPackagesDetail(params) {
  return request({
    url: '/consumSetInfoErp/getConsumSetInfoList',
    method: 'get',
    params: params
  });
} // 耗材包明细查找

export function findPackagesDetail(params) {
  return request({
    url: '/consumSetInfoErp/findConsumSetInfo',
    method: 'get',
    params: params
  });
} // 耗材包明细创建

export function createPackagesDetail(data) {
  return request({
    url: '/consumSetInfoErp/createConsumSetInfo',
    method: 'post',
    data: data
  });
} // 耗材包明细更新

export function updatePackagesDetail(data) {
  return request({
    url: '/consumSetInfoErp/updateConsumSetInfo',
    method: 'put',
    data: data
  });
} // 耗材包明细删除

export function deletePackagesDetail(data) {
  return request({
    url: '/consumSetInfoErp/deleteConsumSetInfo',
    method: 'delete',
    data: data
  });
} // 盒子列表

export function listBox(params) {
  return request({
    url: '/consum/getConsumModuleList',
    method: 'get',
    params: params
  });
} // 盒子创建

export function createBox(data) {
  return request({
    url: '/consum/createConsumModule',
    method: 'post',
    data: data
  });
} // 盒子更新

export function updateBox(data) {
  return request({
    url: '/consum/updateConsumModule',
    method: 'put',
    data: data
  });
} // 盒子更新

export function deleteBox(data) {
  return request({
    url: '/consum/deleteConsumModule',
    method: 'delete',
    data: data
  });
} // 创建物资详情

export function createConsumInfo(data) {
  return request({
    url: '/consum/createConsumInfo',
    method: 'post',
    data: data
  });
} // 物资详情列表

export function listConsumInfo(params) {
  return request({
    url: '/consum/getConsumInfoList',
    method: 'get',
    params: params
  });
} // 删除物资详情

export function deleteConsumInfo(data) {
  return request({
    url: '/consum/deleteConsumInfo',
    method: 'delete',
    data: data
  });
} // 更新物资详情

export function updateConsumInfo(data) {
  return request({
    url: '/consum/updateConsumInfo',
    method: 'put',
    data: data
  });
}
export function getConsumProList(params) {
  return request({
    url: '/consumProErp/getConsumProList',
    method: 'get',
    params: params
  });
}
export function updateConsumPro(data) {
  return request({
    url: '/consumProErp/updateConsumPro',
    method: 'put',
    data: data
  });
}
export function deleteConsumPro(data) {
  return request({
    url: '/consumProErp/deleteConsumPro',
    method: 'delete',
    data: data
  });
}
export function updateConsumInfoCqs(data) {
  return request({
    url: '/consum/updateConsumInfoCqs',
    method: 'put',
    data: data
  });
}
export function getConsumInfoTemplate(params) {
  return request({
    url: '/consum/getConsumInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}