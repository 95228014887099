var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "450px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-table",
          attrs: {
            size: "mini",
            model: _vm.form,
            "label-suffix": ":",
            "label-width": "100px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.autoMateInvoice()
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "客户名称" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.customerName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款金额" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.returnAmount))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款公司" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.subsidiaryName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款日期" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.returnDate))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款方式" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.returnTypeName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "经手人" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.handledBy))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "备注" } }, [
                    _c("div", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.remarks))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "auto-height" },
                [
                  _c("el-form-item", { attrs: { label: "回款凭证" } }, [
                    _c(
                      "span",
                      { staticClass: "item-text" },
                      _vm._l(_vm.form.pz, function(item, index) {
                        return _c(
                          "div",
                          { key: "pz" + index },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  width: "200",
                                  trigger: "hover",
                                  content: item.name
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                      underline: false
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.toPreview(item)
                                      }
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.uploadPreviewImageUrl,
              "z-index": 9999
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.dialogClose()
                }
              }
            },
            [_vm._v("关 闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }