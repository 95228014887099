var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "close-on-click-modal": false,
        "before-close": _vm.dialogClose
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "80px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名", clearable: "" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入手机号码",
                          disabled: _vm.form.ID ? true : false,
                          clearable: ""
                        },
                        model: {
                          value: _vm.form.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "gender" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "gender", $$v)
                            },
                            expression: "form.gender"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("未知")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("男")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("女")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "激活状态", prop: "activeStatus" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.activeStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "activeStatus", $$v)
                            },
                            expression: "form.activeStatus"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("未激活")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("已激活")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }