import { request } from '@/api/_service.js';
/**
 * 0=生产商资质，1=供应商资质，2=经销商资质，3=本公司，4=注册证
获取本组织所有的审核流程（没有参数）
/examine/get_examine_flow
返回值说明
flow_type_name 审核名称
request_user_list 可申请审核的人员列表
examine_user_list 可审核的人员列表（二维数组）
**/

export function listExamineFlow(params) {
  return request({
    url: '/examine/get_examine_flow',
    method: 'get',
    params: params
  });
}
/**
更新审核流程
POST /examine/update_examine_flow
{
    "id": 1,
    “flow_type”: 1,
    "request_user": [
        344,
        240
    ],
    "examine_user": [
        [
            344,
            240
        ],
        [
            304,
            344
        ]
    ]
}
Id为审核id
request_user 为可申请审核的人员uid数组
examine_user 为可审核的人员uid数组（二维数组）
flow_type 审核类型

对经销商/供应商/本公司信息/生产商 进行修改或者新建，自动提交审核
**/

export function updateExamineFlow(data) {
  return request({
    url: '/examine/update_examine_flow',
    method: 'post',
    data: data
  });
}
/**
获取我的待审核列表（无参数）
/examine/get_examine_list
返回值说明
id 审核id
flow_type 流程类型
oper_id 修改内容的id
step 步骤
Name 审核名称
注：根据flow_type 和 oper_id，请求对应接口，可以获取详情
**/

export function listExamine(params) {
  return request({
    url: '/examine/get_examine_list',
    method: 'get',
    params: params
  });
}
/**
审核接口
POST /examine/examine
{
    "examine_id": 15,  // 审核id，审核列表返回的id
    "examine_ids”: [1,2,3],  // 审核id数组，审核列表返回的id，用于批量审核，与examine_id二选一，不可同时传
    "status": 1, // 审核状态 1 = 通过
    "reject_reason": “哈哈” 2 = 拒绝
}
**/

export function updateExamineState(data) {
  return request({
    url: '/examine/examine',
    method: 'post',
    data: data
  });
}
export function updateBatchExamineState(data) {
  return request({
    url: '/examine/batch_examine',
    method: 'post',
    data: data
  });
}
export function listExamineLog(params) {
  return request({
    url: '/examine/get_examine_log_list',
    method: 'get',
    params: params
  });
}
export function listExamineLine(params) {
  return request({
    url: '/examine/get_examine_line',
    method: 'get',
    params: params
  });
}
export function switchStatus(data) {
  return request({
    url: '/examine/switch_status',
    method: 'post',
    data: data
  });
}