var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存明细")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-upload2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.uploadReport()
                    }
                  }
                },
                [_vm._v("出厂检验报告上传")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: !_vm.currentRow
                  },
                  on: { click: _vm.viewFrom }
                },
                [_vm._v("产品追溯")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.pageForm,
                  size: "mini",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.warehouseChange },
                        model: {
                          value: _vm.warehouseIds,
                          callback: function($$v) {
                            _vm.warehouseIds = $$v
                          },
                          expression: "warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "物资分类" } },
                  [
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.classList,
                        props: _vm.props,
                        "show-all-levels": true,
                        filterable: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.pageForm.productLine,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "productLine", $$v)
                        },
                        expression: "pageForm.productLine"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: "",
                          multiple: "",
                          "collapse-tags": ""
                        },
                        model: {
                          value: _vm.pageForm.manufacturerIds,
                          callback: function($$v) {
                            _vm.$set(_vm.pageForm, "manufacturerIds", $$v)
                          },
                          expression: "pageForm.manufacturerIds"
                        }
                      },
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "品牌" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择品牌",
                              clearable: "",
                              filterable: "",
                              multiple: "",
                              "collapse-tags": ""
                            },
                            model: {
                              value: _vm.pageForm.brandIds,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "brandIds", $$v)
                              },
                              expression: "pageForm.brandIds"
                            }
                          },
                          _vm._l(_vm.brandList, function(item) {
                            return _c("el-option", {
                              key: "brandIds" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择类型",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.pageForm.stockType,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "stockType", $$v)
                              },
                              expression: "pageForm.stockType"
                            }
                          },
                          _vm._l(_vm.stockTypeList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "物资名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入物资名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "productName", $$v)
                            },
                            expression: "pageForm.productName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "归属公司" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择归属公司",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.pageForm.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "subsidiaryId", $$v)
                              },
                              expression: "pageForm.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: { label: item.companyName, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "摆放位置" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入摆放位置",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.placement_position,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "placement_position", $$v)
                            },
                            expression: "pageForm.placement_position"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "规格型号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入规格型号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.specificationModel,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "specificationModel", $$v)
                            },
                            expression: "pageForm.specificationModel"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "productNumber", $$v)
                            },
                            expression: "pageForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品批号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品批号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.batch_number,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "batch_number", $$v)
                            },
                            expression: "pageForm.batch_number"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "序列号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入序列号", clearable: "" },
                          model: {
                            value: _vm.pageForm.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "trackingCode", $$v)
                            },
                            expression: "pageForm.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品主码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品主码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.barCode,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "barCode", $$v)
                            },
                            expression: "pageForm.barCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c("el-form-item", { attrs: { label: "可用数范围" } }, [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入最小数",
                              min: 0,
                              controls: false,
                              precision: 0,
                              clearable: ""
                            },
                            on: { change: _vm.normalQuantityMinChange },
                            model: {
                              value: _vm.pageForm.normalQuantityMin,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "normalQuantityMin", $$v)
                              },
                              expression: "pageForm.normalQuantityMin"
                            }
                          }),
                          _c("span", { staticStyle: { margin: "0 5px" } }, [
                            _vm._v("-")
                          ]),
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入最大数",
                              min: _vm.pageForm.normalQuantityMin,
                              controls: false,
                              precision: 0,
                              clearable: ""
                            },
                            on: { change: _vm.normalQuantityMaxChange },
                            model: {
                              value: _vm.pageForm.normalQuantityMax,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "normalQuantityMax", $$v)
                              },
                              expression: "pageForm.normalQuantityMax"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "false-label": 0, "true-label": 1 },
                        model: {
                          value: _vm.pageForm.showEmpty,
                          callback: function($$v) {
                            _vm.$set(_vm.pageForm, "showEmpty", $$v)
                          },
                          expression: "pageForm.showEmpty"
                        }
                      },
                      [_vm._v("不显示0库存物资")]
                    ),
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "false-label": 0, "true-label": 1 },
                        model: {
                          value: _vm.pageForm.consign,
                          callback: function($$v) {
                            _vm.$set(_vm.pageForm, "consign", $$v)
                          },
                          expression: "pageForm.consign"
                        }
                      },
                      [_vm._v("只显示寄售库存")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "10px",
              "font-size": "14px",
              "text-align": "right",
              display: "flex",
              "justify-content": "flex-end"
            }
          },
          [
            _vm.dataAuth
              ? _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v(" 成本合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.quantityPriceSum.toFixed(2)))
                  ])
                ])
              : _vm._e(),
            _c("div", { staticStyle: { "margin-right": "20px" } }, [
              _vm._v(" 库存可用数量："),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.normalQuantitySum))
              ])
            ]),
            _c("div", [
              _vm._v(" 库存总数量："),
              _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.stockQuantityCount))
              ])
            ])
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.pageForm,
            hasPagination: true,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.tableData,
            sortClass: _vm.sortClass
          },
          on: {
            "row-click": _vm.rowClick,
            "row-dblclick": _vm.rowDblclick,
            pageChange: _vm.pageChange,
            sizeChange: _vm.pageSizeChange,
            sortChange: _vm.sortChange
          },
          scopedSlots: _vm._u([
            {
              key: "url",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.url
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toPreview(row.url)
                            }
                          }
                        },
                        [_vm._v("点击查看")]
                      )
                    : _c("span", { staticClass: "text-danger" }, [
                        _vm._v("未上传")
                      ])
                ]
              }
            },
            {
              key: "cusCode",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("span", [_vm._v(_vm._s(row.cusCode))]),
                  _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-left": "10px", cursor: "pointer" },
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function($event) {
                          return _vm.printTag(row)
                        }
                      }
                    },
                    [_vm._v("打印")]
                  )
                ]
              }
            }
          ])
        })
      ],
      _c("PrintTagComponent", { ref: "PrintTagComponentRef" }),
      _c("UploadReportComponent", {
        ref: "UploadReportComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.previewImgURL
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }