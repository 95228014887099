import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      orderInfo: null,
      form: {},
      pendingAmount: 0,
      formLoading: false,
      uploadFilePrefixURL: '',
      uploadPreviewImageUrl: []
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(orderInfo) {
      var that = this;
      that.orderInfo = orderInfo;
      that.pendingAmount = orderInfo.suppAmountSum - orderInfo.refundAmount - orderInfo.depositAmount;
      that.form = {
        id: orderInfo.ID,
        contractNumber: orderInfo.contractNumber,
        suppAmountSum: orderInfo.suppAmountSum,
        depositAmount: orderInfo.depositAmount,
        refundAmount: orderInfo.refundAmount,
        refundVoucher: orderInfo.refundVoucher ? JSON.parse(orderInfo.refundVoucher) : [],
        remark: orderInfo.remark
      };
      that.uploadFilePrefixURL = process.env.VUE_APP_OSS_URL;
      that.uploadPreviewImageUrl = [];
      that.form.refundVoucher.forEach(function (row) {
        that.uploadPreviewImageUrl.push(that.uploadFilePrefixURL + row.url);
      });
      that.dialogVisible = true;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    }
  }
};