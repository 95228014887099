var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            size: "small",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            data: _vm.dataList
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              width: "60",
              label: "序号",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderNumber",
              "min-width": "200",
              label: "采购单号",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.viewOrder(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.orderNumber))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "buyer",
              "min-width": "100",
              label: "采购人",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "purchaseDate",
              "min-width": "100",
              label: "采购日期",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invoiceSituation",
              "min-width": "100",
              label: "开票情况",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.invoiceSituationFormatter(
                            scope.row.invoiceSituation
                          )
                        )
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "purchaseQuantity",
              "min-width": "100",
              label: "采购数量",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "purchaseAmount",
              "min-width": "100",
              label: "采购金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.purchaseAmount.toFixed(2)))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "appAmount",
              "min-width": "100",
              label: "申请金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.appAmount.toFixed(2)))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paidAmountSum",
              "min-width": "100",
              label: "已付金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.paidAmountSum.toFixed(2)))]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invoiceCode",
              "min-width": "100",
              label: "未付金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        (
                          scope.row.purchaseCompletedAmount -
                          scope.row.paidAmountSum
                        ).toFixed(2)
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paidAmountSum",
              "min-width": "100",
              label: "开票金额",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(scope.row.amountIncludingTaxSum.toFixed(2)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "state",
              "min-width": "100",
              label: "收货状态",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.stateFormatter(scope.row.state))
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "page-sizes": [10, 20, 50, 100],
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" }, attrs: { align: "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }