var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("投诉处理")]),
              _c("el-breadcrumb-item", [_vm._v("销毁记录详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 销毁记录单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              {
                staticStyle: { "flex-wrap": "wrap" },
                attrs: { type: "flex", gutter: 20 }
              },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销毁日期", prop: "DestructionTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.DestructionTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "DestructionTime", $$v)
                            },
                            expression: "form.DestructionTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "处理人", prop: "acceptanceName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.acceptanceName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acceptanceName", $$v)
                              },
                              expression: "form.acceptanceName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "acceptanceName-" + item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "来源", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: "warehouseId-" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "销毁理由", prop: "destructionReason" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "销毁理由", clearable: "" },
                          model: {
                            value: _vm.form.destructionReason,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "destructionReason", $$v)
                            },
                            expression: "form.destructionReason"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销毁方式", prop: "destructionWay" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "销毁方式", clearable: "" },
                          model: {
                            value: _vm.form.destructionWay,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "destructionWay", $$v)
                            },
                            expression: "form.destructionWay"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "销毁地点", prop: "destructionAddress" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "销毁地点", clearable: "" },
                          model: {
                            value: _vm.form.destructionAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "destructionAddress", $$v)
                            },
                            expression: "form.destructionAddress"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 12, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            type: "textarea",
                            rows: 2,
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 12, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "执行人意见", prop: "executePinion" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "执行人意见",
                            type: "textarea",
                            rows: 2,
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.executePinion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "executePinion", $$v)
                            },
                            expression: "form.executePinion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 12, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "监督人意见", prop: "supervisePinion" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "监督人意见",
                            type: "textarea",
                            rows: 2,
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.supervisePinion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "supervisePinion", $$v)
                            },
                            expression: "form.supervisePinion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 12, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "药监部门人员意见", prop: "drugPinion" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "药监部门人员意见",
                            type: "textarea",
                            rows: 2,
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.drugPinion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "drugPinion", $$v)
                            },
                            expression: "form.drugPinion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "", prop: "" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "flex-start"
                            },
                            attrs: {
                              action: _vm.uploadURL,
                              headers: _vm.uploadToken,
                              "before-upload": function(file) {
                                return _vm.uploadBefore(file)
                              },
                              "on-remove": function(file, fileList) {
                                return _vm.uploadRemove(
                                  file,
                                  fileList,
                                  "licenseUploadFileList"
                                )
                              },
                              "on-success": function(res, file, fileList) {
                                return _vm.uploadSuccess(
                                  res,
                                  file,
                                  fileList,
                                  "licenseUploadFileList"
                                )
                              },
                              "on-error": _vm.uploadError,
                              "on-preview": _vm.uploadPreview,
                              accept: _vm.uploadFileType.toString(),
                              "on-exceed": _vm.uploadExceed,
                              limit: 10,
                              "show-file-list": true,
                              "file-list": _vm.uploadFileList
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [
                                    _vm._v(
                                      "只能上传" +
                                        _vm._s(
                                          _vm.uploadFileType
                                            .toString()
                                            .toUpperCase()
                                            .replace(/\./g, "")
                                            .replace(/,/g, "/")
                                        ) +
                                        "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              data: _vm.pageDataLoad()
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "registrationNumber",
                label: "注册证号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "brandName",
                label: "品牌",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "批号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerDate",
                label: "生产日期",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "validityDate",
                label: "有效期",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerName",
                label: "供应商",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "quantity",
                label: "销毁数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "remark",
                label: "备注",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, total",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormChange }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.toPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.edit }
      }),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }