var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm._l(_vm.dataList, function(item, index) {
        return _c(
          "div",
          { key: "data-list-" + index },
          [
            _c(
              "el-form",
              {
                staticClass: "form-table",
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  model: item,
                  "show-message": false,
                  "label-width": "140px",
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发票号", prop: "invoiceNumber" } },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoiceNumber))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "发票代码",
                              prop: "invoiceCode",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoiceCode))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "发票类型",
                              prop: "invoiceType",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoiceTypeName))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开票日期",
                              prop: "invoicingDate",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoicingDate))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "金额(不含税)", prop: "money" } },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.money.toFixed(2)))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "税率",
                              prop: "taxRate",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.taxRate) + "%")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "税额",
                              prop: "taxAmount",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.taxAmount.toFixed(2)))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "含税金额",
                              prop: "amountIncludingTax",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.amountIncludingTax.toFixed(2)))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "校验代码(后6位)",
                              prop: "validationCode"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.validationCode))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 17 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "附件",
                              prop: "attachment",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "item-text" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top",
                                      title: "附件列表",
                                      width: "500",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        attrs: {
                                          data: item.attachment,
                                          size: "mini",
                                          border: "",
                                          stripe: "",
                                          "header-cell-style": {
                                            background: "#f5f7fa",
                                            borderColor: "#ebeef5",
                                            color: "#333"
                                          }
                                        }
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "url",
                                            label: "附件路径"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  return [
                                                    _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          underline: false
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.attachmentPreview(
                                                              item,
                                                              row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(_vm._s(row.url))]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          underline: false,
                                          type: "primary"
                                        },
                                        slot: "reference"
                                      },
                                      [
                                        _vm._v(" 已上传 "),
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(item.attachment.length)
                                            )
                                          ]
                                        ),
                                        _vm._v(" 个，点击查看 ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "开票公司", prop: "subsidiaryId" }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoicingCompany))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "开票人",
                              prop: "invoiceIssuer",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.invoiceIssuer))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "备注",
                              prop: "remarks",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(item.remarks))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.uploadPreviewImageUrl,
              "z-index": 9999
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }