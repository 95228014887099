var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("发票管理")]),
              _c("el-breadcrumb-item", [_vm._v("开票申请")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toApplyInvoice()
                    }
                  }
                },
                [_vm._v("批量申请开票")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveId === "1",
                expression: "tabActiveId==='1'"
              }
            ],
            staticClass: "form-card",
            attrs: { shadow: "never" }
          },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.form,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.formSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户名称", clearable: "" },
                      model: {
                        value: _vm.form.customerName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "customerName", $$v)
                        },
                        expression: "form.customerName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "销售单号", clearable: "" },
                      model: {
                        value: _vm.form.salesOrderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "salesOrderNo", $$v)
                        },
                        expression: "form.salesOrderNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售日期" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100px" },
                        model: {
                          value: _vm.form.timeType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "timeType", $$v)
                          },
                          expression: "form.timeType"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "手术日期", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "销售日期", value: 2 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd"
                      },
                      model: {
                        value: _vm.form.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "dateRange", $$v)
                        },
                        expression: "form.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.formSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveId === "2",
                expression: "tabActiveId==='2'"
              }
            ],
            staticClass: "form-card",
            attrs: { shadow: "never" }
          },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.recordForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.recordFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "申请单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "申请单号", clearable: "" },
                      model: {
                        value: _vm.recordForm.requestNo,
                        callback: function($$v) {
                          _vm.$set(_vm.recordForm, "requestNo", $$v)
                        },
                        expression: "recordForm.requestNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "客户名称", clearable: "" },
                      model: {
                        value: _vm.recordForm.customerName,
                        callback: function($$v) {
                          _vm.$set(_vm.recordForm, "customerName", $$v)
                        },
                        expression: "recordForm.customerName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd"
                      },
                      model: {
                        value: _vm.recordForm.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.recordForm, "dateRange", $$v)
                        },
                        expression: "recordForm.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.recordForm.invoiceStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.recordForm, "invoiceStatus", $$v)
                              },
                              expression: "recordForm.invoiceStatus"
                            }
                          },
                          _vm._l(_vm.invoiceStatusList, function(item, index) {
                            return _c("el-option", {
                              key: "status" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.recordFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "1" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("1")
                      }
                    }
                  },
                  [
                    _vm._v("开票申请("),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.totalApply))
                    ]),
                    _vm._v(")")
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "2" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("2")
                      }
                    }
                  },
                  [
                    _vm._v("开票申请记录("),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.totalApplyRecord))
                    ]),
                    _vm._v(")")
                  ]
                )
              ]
            ),
            _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tabActiveId === "1",
                      expression: "tabActiveId==='1'"
                    }
                  ],
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "flex-end",
                    "margin-bottom": "10px",
                    "font-size": "14px"
                  }
                },
                [
                  _c("div", [
                    _vm._v("选中订单金额：" + _vm._s(_vm.selectedAmount))
                  ]),
                  _c("div", { staticStyle: { "margin-left": "20px" } }, [
                    _vm._v("订单总金额：" + _vm._s(_vm.totalSalesAmount))
                  ])
                ]
              )
            ])
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabActiveId === "1",
              expression: "tabActiveId==='1'"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasSelection: true,
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.form,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.dataList,
            selectable: _vm.selectable
          },
          on: {
            "selection-change": _vm.selectionChange,
            pageChange: _vm.pageChange,
            sizeChange: _vm.sizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      row.verificationStatus === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toApplyInvoice(row)
                                }
                              }
                            },
                            [_vm._v("申请开票")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toViewDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看明细")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabActiveId === "2",
              expression: "tabActiveId==='2'"
            }
          ],
          ref: "recordDataTableRef",
          attrs: {
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.recordForm,
            "route-name": _vm.$route.name + "record",
            columns: _vm.recordColumns,
            data: _vm.dataList
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange },
          scopedSlots: _vm._u([
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      row.invoiceStatus === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toRecordReApply(row)
                                }
                              }
                            },
                            [_vm._v("重新申请")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toRecordViewDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看明细")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toRecordViewApply(row)
                            }
                          }
                        },
                        [_vm._v("查看申请单")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toRecordViewInvoice(row)
                            }
                          }
                        },
                        [_vm._v("查看发票")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c("ReApplyComponent", { ref: "ReApplyComponentRef" }),
        _c("ViewDetailComponent", { ref: "ViewDetailComponentRef" }),
        _c("ViewInvoiceComponent", { ref: "ViewInvoiceComponentRef" })
      ],
      _c("ApplyComponent", {
        ref: "ApplyComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }