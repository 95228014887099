var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { height: "500px", overflow: "auto" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: { model: _vm.layout, "label-width": "60px", size: "mini" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            _vm._l(_vm.layout.layout, function(item, index) {
              return _c(
                "div",
                _vm._b(
                  { key: index, staticClass: "box-list" },
                  "div",
                  item,
                  false
                ),
                [
                  _c(
                    "table",
                    [
                      _c("caption", [_vm._v(_vm._s(item.name))]),
                      _c(
                        "tr",
                        { staticStyle: { height: "30px" } },
                        [
                          _c("td"),
                          _vm._l(item.xLabel, function(xItem, xIndex) {
                            return _c("td", { key: xIndex }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.xLabel[xIndex],
                                    expression: "item.xLabel[xIndex]"
                                  }
                                ],
                                staticClass: "label-input",
                                attrs: { type: "text" },
                                domProps: { value: item.xLabel[xIndex] },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      item.xLabel,
                                      xIndex,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          })
                        ],
                        2
                      ),
                      _vm._l(item.yLabel, function(yItem, yIndex) {
                        return _c(
                          "tr",
                          { key: yIndex, staticStyle: { height: "30px" } },
                          [
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.yLabel[yIndex],
                                    expression: "item.yLabel[yIndex]"
                                  }
                                ],
                                staticClass: "label-input",
                                attrs: { type: "text" },
                                domProps: { value: item.yLabel[yIndex] },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      item.yLabel,
                                      yIndex,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._l(item.xLabel, function(xItem, xIndex) {
                              return _c("td", { key: xIndex }, [
                                _c("i", {
                                  staticClass: "fa fa-circle-o",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ])
                            })
                          ],
                          2
                        )
                      })
                    ],
                    2
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.dialogClose } },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }