var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.submitForm,
            rules: _vm.submitFormRules,
            size: "small",
            inline: true
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "预警仓库选择", prop: "warehouseId" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.warehouseIdChange },
                  model: {
                    value: _vm.submitForm.warehouseId,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "warehouseId", $$v)
                    },
                    expression: "submitForm.warehouseId"
                  }
                },
                _vm._l(_vm.warehouseList, function(item) {
                  return _c("el-option", {
                    key: "warehouseId" + item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.fromChange },
                  model: {
                    value: _vm.submitForm.from,
                    callback: function($$v) {
                      _vm.$set(_vm.submitForm, "from", $$v)
                    },
                    expression: "submitForm.from"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "STOCK" } }, [
                    _vm._v("从库存列表中添加")
                  ]),
                  _c("el-radio", { attrs: { label: "MAIN" } }, [
                    _vm._v("从物资字典中添加")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticStyle: { background: "#f5f7fa", "padding-top": "10px" },
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-suffix": ":",
            "label-width": "80px",
            inline: true,
            size: "mini"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品主码" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.barCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "barCode", $$v)
                  },
                  expression: "form.barCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品编号" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.productNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productNumber", $$v)
                  },
                  expression: "form.productNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "物资名称" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "规格型号" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.form.specificationModel,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "specificationModel", $$v)
                  },
                  expression: "form.specificationModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "品牌" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", filterable: "" },
                  model: {
                    value: _vm.form.brandId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "brandId", $$v)
                    },
                    expression: "form.brandId"
                  }
                },
                _vm._l(_vm.brandList, function(item) {
                  return _c("el-option", {
                    key: "brandId" + item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.searchSubmit } },
                [_vm._v("查询")]
              ),
              _c(
                "el-link",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", underline: false },
                  on: {
                    click: function($event) {
                      _vm.searchFormExpand = !_vm.searchFormExpand
                    }
                  }
                },
                [
                  _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                  _c("i", {
                    class:
                      "el-icon-arrow-" +
                      (_vm.searchFormExpand ? "up" : "down") +
                      " el-icon--right"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataListTableRef",
          staticStyle: { width: "100%" },
          attrs: {
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "row-class-name": _vm.rowClassName,
            data: _vm.dataList,
            size: "mini"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "50",
              align: "center",
              fixed: "left",
              selectable: _vm.selectable
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              index: function(index) {
                return _vm.indexMethod(index, _vm.form)
              },
              width: "100",
              align: "center"
            }
          }),
          _vm.submitForm.from === "STOCK"
            ? _c("el-table-column", {
                attrs: {
                  label: "仓库",
                  prop: "warehouseName",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "品牌",
              prop: "brandName",
              align: "center",
              "min-width": "100",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "productNumber",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "物资名称",
              prop: "productName",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品主码",
              prop: "barCode",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _vm.submitForm.from === "STOCK"
            ? _c("el-table-column", {
                attrs: {
                  label: "可用数量",
                  prop: "normalQuantity",
                  align: "center",
                  "min-width": "150",
                  "show-overflow-tooltip": ""
                }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specificationModel",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产商",
              prop: "manufacturer",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "供应商",
              prop: "manufacturerName",
              align: "center",
              "min-width": "150",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "状态",
              prop: "state",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.state === 1
                      ? _c(
                          "el-link",
                          { attrs: { type: "success", underline: false } },
                          [_vm._v("正常")]
                        )
                      : _c(
                          "el-link",
                          { attrs: { type: "danger", underline: false } },
                          [_vm._v("禁用")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "库存高储",
              prop: "highInventory",
              align: "center",
              width: "100",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", controls: false, min: 0 },
                      model: {
                        value: row.highInventory,
                        callback: function($$v) {
                          _vm.$set(row, "highInventory", $$v)
                        },
                        expression: "row.highInventory"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "库存低储",
              prop: "lowInventory",
              align: "center",
              width: "100",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", controls: false, min: 0 },
                      model: {
                        value: row.lowInventory,
                        callback: function($$v) {
                          _vm.$set(row, "lowInventory", $$v)
                        },
                        expression: "row.lowInventory"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.pageSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer", align: "center" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.addToList }
            },
            [_vm._v("添 加")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }