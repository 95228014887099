import { request } from '@/api/_service.js';
export function listRule(params) {
  return request({
    url: '/erp/rules',
    method: 'get',
    params: params
  });
}
export function findRule(params) {
  return request({
    url: '/erp/findRule',
    method: 'get',
    params: params
  });
}
export function createRule(data) {
  return request({
    url: '/erp/createRule',
    method: 'post',
    data: data
  });
}
export function updateRule(data) {
  return request({
    url: '/erp/updateRule',
    method: 'put',
    data: data
  });
}
export function deleteRule(data) {
  return request({
    url: '/erp/deleteRule',
    method: 'delete',
    data: data
  });
}
export function deleteRuleByIds(data) {
  return request({
    url: '/erp/deleteRuleByIds',
    method: 'delete',
    data: data
  });
}