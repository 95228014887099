var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", margin: "10px 0" } },
    [
      _c(
        "el-popover",
        {
          ref: "shelfNumberPopover",
          attrs: { placement: "right-start", width: "250", trigger: "click" },
          model: {
            value: _vm.shelfNumberVisible,
            callback: function($$v) {
              _vm.shelfNumberVisible = $$v
            },
            expression: "shelfNumberVisible"
          }
        },
        [
          _c("ShelfComponents", {
            ref: "ShelfComponents",
            on: { select: _vm.shelfSelect }
          })
        ],
        1
      ),
      _c(
        "el-popover",
        {
          ref: "locationNumberPopover",
          attrs: { placement: "right-start", width: "300", trigger: "click" },
          model: {
            value: _vm.locationNumberVisible,
            callback: function($$v) {
              _vm.locationNumberVisible = $$v
            },
            expression: "locationNumberVisible"
          }
        },
        [
          _c("LocationComponents", {
            ref: "LocationComponents",
            on: { select: _vm.locationSelect }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _vm.inWarehouseStatus === 1
            ? _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.rowAdd }
                },
                [_vm._v("添加批号")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("ve-table", {
        ref: "batchTable",
        staticStyle: { width: "100%" },
        attrs: {
          "fixed-header": "",
          "max-height": _vm.tableHeight(),
          columns: _vm.columns,
          "table-data": _vm.form.data,
          "virtual-scroll-option": _vm.virtualScrollOption
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }