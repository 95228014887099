var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("内部信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("员工管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["base:personnel:index:create"],
                      expression: "['base:personnel:index:create']"
                    }
                  ],
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary"
                  },
                  on: { click: _vm.personnelAdd }
                },
                [_vm._v("新增员工")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          [
            _c(
              "el-container",
              [
                _c(
                  "el-aside",
                  { attrs: { width: "200px" } },
                  [
                    _c("el-tree", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.departmentLoading,
                          expression: "departmentLoading"
                        }
                      ],
                      ref: "classTree",
                      staticClass: "filter-tree",
                      attrs: {
                        data: _vm.classData,
                        props: _vm.defaultProps,
                        "default-expand-all": "",
                        "highlight-current": true,
                        "expand-on-click-node": false
                      },
                      on: { "node-click": _vm.treeClick }
                    })
                  ],
                  1
                ),
                _c(
                  "el-main",
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.personnelPageForm,
                          size: "small",
                          "label-suffix": ":"
                        },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "员工姓名" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "员工姓名", clearable: "" },
                              model: {
                                value: _vm.personnelPageForm.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.personnelPageForm, "name", $$v)
                                },
                                expression: "personnelPageForm.name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "员工手机号码" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "员工手机号码",
                                clearable: ""
                              },
                              model: {
                                value: _vm.personnelPageForm.mobile,
                                callback: function($$v) {
                                  _vm.$set(_vm.personnelPageForm, "mobile", $$v)
                                },
                                expression: "personnelPageForm.mobile"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.searchSubmit }
                              },
                              [_vm._v("查询")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "text-danger",
                        staticStyle: {
                          "font-size": "12px",
                          "margin-bottom": "10px"
                        },
                        attrs: { align: "right" }
                      },
                      [
                        _vm._v(
                          "注意：本页面信息只有超级管理员、管理员才能维护修改，其他角色没有修改维护权限。"
                        )
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData,
                          border: "",
                          "highlight-current-row": "",
                          stripeDELETE: "",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          },
                          size: "mini"
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            index: function(index) {
                              return _vm.indexMethod(
                                index,
                                _vm.personnelPageForm
                              )
                            },
                            label: "序号",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "姓名",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "gender",
                            label: "性别",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.gender === 2
                                    ? _c("div", [_vm._v("女")])
                                    : scope.row.gender === 1
                                    ? _c("div", [_vm._v("男")])
                                    : _c("div", [_vm._v("未知")])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "group_erps",
                            label: "菜单角色",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.groupsFormatter(row.group_erps)
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _vm.collaborative === 2
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "group_ids",
                                label: "有台角色",
                                align: "center"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.groupIdsFormatter(
                                                row.group_ids
                                              )
                                            )
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2250944767
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            prop: "department_name",
                            label: "所属部门",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "mobile",
                            label: "手机号码",
                            width: "150",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "active_status",
                            label: "状态",
                            width: "80",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-switch", {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["base:personnel:index:update"],
                                        expression:
                                          "['base:personnel:index:update']"
                                      }
                                    ],
                                    attrs: {
                                      "active-color": "#409EFF",
                                      "inactive-color": "#999",
                                      "active-value": 1,
                                      "inactive-value": 0
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.personnelStatusSwitch(
                                          scope.row
                                        )
                                      }
                                    },
                                    model: {
                                      value: scope.row.active_status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "active_status",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.active_status"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "100",
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "control-column" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: [
                                                "base:personnel:index:update"
                                              ],
                                              expression:
                                                "['base:personnel:index:update']"
                                            }
                                          ],
                                          attrs: {
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.personnelEdit(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm.profile.uid !== scope.row.uid &&
                                      !_vm.isSuper(scope.row.group_erps)
                                        ? [
                                            _c(
                                              "el-link",
                                              {
                                                directives: [
                                                  {
                                                    name: "permission",
                                                    rawName: "v-permission",
                                                    value: [
                                                      "base:personnel:index:delete"
                                                    ],
                                                    expression:
                                                      "['base:personnel:index:delete']"
                                                  }
                                                ],
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.personnelDelete(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px 0" } },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            layout: "prev, pager, next, total",
                            align: "center",
                            total: _vm.personnelPageForm.total,
                            "current-page": _vm.personnelPageForm.page,
                            "page-size": _vm.personnelPageForm.pageSize
                          },
                          on: { "current-change": _vm.personnelPageChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("PersonnelEditorFormComponent", {
        ref: "PersonnelEditorFormComponent",
        on: { reload: _vm.personnelLoad }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }