var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                icon: "el-icon-download"
              },
              on: {
                click: function($event) {
                  return _vm.dataExport()
                }
              }
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function($event) {
                  return _vm.toPrint()
                }
              }
            },
            [_vm._v("打印")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dbLoading,
              expression: "dbLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dbList,
            size: "small",
            border: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "cell-class-name": _vm.cellClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center",
              index: function(index) {
                return _vm.indexMethod(index, _vm.form)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specs.productNumber",
              label: "产品编号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "main.productName",
              label: "产品名称",
              width: "200",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specs.specificationModel",
              label: "规格型号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "warehouse.name",
              label: "仓库",
              width: "200",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validity_date",
              label: "有效期至",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "normalQuantity",
              label: "可用数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "batch_number",
              label: "批号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trackingCode",
              label: "序列号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validityCategory",
              label: "预警提醒",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "180",
              align: "center",
              "class-name": "no-print"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.toStock(row)
                          }
                        }
                      },
                      [_vm._v("跳转至该库存页面")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              align: "center",
              total: _vm.form.total,
              "page-sizes": [10, 20, 50, 100],
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper"
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "detail-table", attrs: { id: "printTable" } }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", { attrs: { width: "50" } }, [_vm._v("序号")]),
              _c("th", [_vm._v("产品名称")]),
              _c("th", [_vm._v("仓库")]),
              _c("th", { attrs: { width: "80" } }, [_vm._v("有效期至")]),
              _c("th", { attrs: { width: "80" } }, [_vm._v("可用数量")]),
              _c("th", [_vm._v("批号")]),
              _c("th", [_vm._v("序列号")]),
              _c("th", { attrs: { width: "100" } }, [_vm._v("预警提醒")])
            ])
          ]),
          _c(
            "tbody",
            _vm._l(_vm.dbList, function(item, index) {
              return _c("tr", { key: "tr" + index }, [
                _c("td", [_vm._v(_vm._s(_vm.indexMethod(index, _vm.form)))]),
                _c("td", [_vm._v(_vm._s(item.main.productName))]),
                _c("td", [_vm._v(_vm._s(item.warehouse.name))]),
                _c("td", [_vm._v(_vm._s(item.validity_date))]),
                _c("td", [_vm._v(_vm._s(item.normalQuantity))]),
                _c("td", [_vm._v(_vm._s(item.batch_number))]),
                _c("td", [_vm._v(_vm._s(item.trackingCode))]),
                _c("td", [_vm._v(_vm._s(item.validityCategory))])
              ])
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }