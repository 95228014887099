var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "复制盒子",
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c("el-col", { attrs: { span: 14 } }, [
            _c("div", { staticClass: "header" }, [
              _vm._v("选择要复制到哪个包")
            ]),
            _c(
              "div",
              { staticStyle: { display: "flex", "flex-direction": "row" } },
              [
                _c("div", { staticClass: "type-container" }, [
                  _c(
                    "div",
                    { staticClass: "header" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            size: "small",
                            clearable: "",
                            filterable: ""
                          },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tree-list",
                      staticStyle: { "overflow-y": "auto" }
                    },
                    [
                      _c("el-tree", {
                        ref: "packageTree",
                        staticClass: "filter-tree",
                        attrs: {
                          data: _vm.packageList,
                          props: _vm.packageProps,
                          "default-expand-all": "",
                          "highlight-current": true,
                          "expand-on-click-node": false,
                          "node-key": "ID"
                        },
                        on: { "node-click": _vm.packageRowClick }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "package-container" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.tableData,
                          height: "500",
                          "max-height": "500",
                          size: "mini",
                          "header-row-style": { height: "53px" },
                          "highlight-current-row": "",
                          stripeDELETE: "",
                          border: "",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "date",
                            label: "名称",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "address",
                            label: "条码",
                            align: "center"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _c("el-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "header" }, [_vm._v("包内已有盒子")]),
            _c(
              "div",
              { staticClass: "box-container" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      data: _vm.tableData,
                      height: "500",
                      "max-height": "500",
                      size: "mini",
                      "header-row-style": { height: "53px" },
                      "highlight-current-row": "",
                      stripeDELETE: "",
                      border: "",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "date",
                        width: "60",
                        label: "序号",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "名称", align: "center" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "date",
                        width: "140",
                        label: "容量",
                        align: "center"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" }
            },
            [_vm._v("粘 贴")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }