import { request } from '@/api/_service.js';
export function listInspection(params) {
  return request({
    url: '/erpInspectionErp/getErpInspectionList',
    method: 'get',
    params: params
  });
}
export function listInspectionExport(params) {
  return request({
    url: '/erpInspectionErp/getErpInspectionList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findInspection(params) {
  return request({
    url: '/erpInspectionErp/findErpInspection',
    method: 'get',
    params: params
  });
}
export function createInspection(data) {
  return request({
    url: '/erpInspectionErp/createErpInspection',
    method: 'post',
    data: data
  });
}
export function updateInspection(data) {
  return request({
    url: '/erpInspectionErp/updateErpInspection',
    method: 'put',
    data: data
  });
}
export function deleteInspection(data) {
  return request({
    url: '/erpInspectionErp/deleteErpInspection',
    method: 'delete',
    data: data
  });
}
export function deleteInspectionByIds(data) {
  return request({
    url: '/erpInspectionErp/deleteErpInspectionByIds',
    method: 'delete',
    data: data
  });
}
export function listInspectionDetail(params) {
  return request({
    url: '/erpInspectionInfoErp/getErpInspectionInfoList',
    method: 'get',
    params: params
  });
}
export function createErpInspectionInfo(data) {
  return request({
    url: '/erpInspectionInfoErp/createErpInspectionInfo',
    method: 'post',
    data: data
  });
}
export function createErpInspectionInfos(data) {
  return request({
    url: '/erpInspectionInfoErp/createErpInspectionInfos',
    method: 'post',
    data: data
  });
}
export function updateErpInspectionInfo(data) {
  return request({
    url: '/erpInspectionInfoErp/updateErpInspectionInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpInspectionInfo(data) {
  return request({
    url: '/erpInspectionInfoErp/deleteErpInspectionInfo',
    method: 'delete',
    data: data
  });
}
export function deleteErpInspectionInfoByIds(data) {
  return request({
    url: '/erpInspectionInfoErp/deleteErpInspectionInfoByIds',
    method: 'delete',
    data: data
  });
}
export function getErpInspectionInfoTemplate(params) {
  return request({
    url: '/erpInspectionInfoErp/getErpInspectionInfoTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}