import { request } from '@/api/_service.js';
export function listClass(params) {
  return request({
    url: '/product_cate/getCateList',
    method: 'get',
    params: params
  });
}
export function findClass(params) {
  return request({
    url: '/product_cate/findCate',
    method: 'get',
    params: params
  });
}
export function createClass(data) {
  return request({
    url: '/product_cate/createCate',
    method: 'post',
    data: data
  });
}
export function updateClass(data) {
  return request({
    url: '/product_cate/updateCate',
    method: 'put',
    data: data
  });
}
export function deleteClass(data) {
  return request({
    url: '/product_cate/deleteCate',
    method: 'delete',
    data: data
  });
}
export function deleteClassByIds(data) {
  return request({
    url: '/product_cate/deleteCateByIds',
    method: 'delete',
    data: data
  });
}