var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("系统设置")]),
              _c("el-breadcrumb-item", [_vm._v("流程设置")]),
              _c("el-breadcrumb-item", [_vm._v("销售流程设置")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: {
              model: _vm.form,
              "label-width": "150px",
              size: "small",
              "label-suffix": ":"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "模式选择", prop: "sellProcessMode" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.form.sellProcessMode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sellProcessMode", $$v)
                      },
                      expression: "form.sellProcessMode"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("GSP流程模式")
                    ]),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("智能模式")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.form.sellProcessMode === 1
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "sellForwardDate" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            color: "#606266"
                          }
                        },
                        [
                          _vm._v(" 自动生成销售订单的日期提前 "),
                          _c("el-input", {
                            staticStyle: { margin: "0 10px", width: "100px" },
                            attrs: {
                              size: "mini",
                              onkeyup:
                                "this.value=this.value.replace(/\\D|^0/g,'')"
                            },
                            model: {
                              value: _vm.form.sellForwardDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellForwardDate", $$v)
                              },
                              expression: "form.sellForwardDate"
                            }
                          }),
                          _vm._v(" 天 ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核人", prop: "sellUids" } },
                    [
                      _vm._l(_vm.sellUserList, function(user) {
                        return _c(
                          "el-tag",
                          {
                            key: "sellUser-" + user.uid,
                            attrs: {
                              "disable-transitions": true,
                              type: user.type,
                              closable: ""
                            },
                            on: {
                              close: function($event) {
                                return _vm.removeSellUser(user)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(user.name) + " ")]
                        )
                      }),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            title: "选择审核人",
                            width: "450",
                            trigger: "click"
                          },
                          on: { show: _vm.sellUserListShow }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              ref: "sellUserRef",
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                data: _vm.allUserList,
                                "max-height": "400"
                              },
                              on: { "selection-change": _vm.sellUidsChange }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  align: "center",
                                  width: "55"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "UID",
                                  prop: "uid",
                                  width: "100",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "手机号",
                                  prop: "mobile",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "姓名",
                                  prop: "name",
                                  width: "100",
                                  align: "center"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                size: "small"
                              },
                              slot: "reference"
                            },
                            [_vm._v("设置审核人")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "prevent-re-click",
                        rawName: "v-prevent-re-click"
                      }
                    ],
                    attrs: { type: "primary", disabled: _vm.loading },
                    on: { click: _vm.saveSettings }
                  },
                  [_vm._v("保存设置")]
                )
              ],
              1
            )
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }