import { request } from '@/api/_service.js';
export function listAgentReturn(params) {
  return request({
    url: '/erpConsignReturnErp/getErpConsignReturnList',
    method: 'get',
    params: params
  });
}
export function listAgentReturnExport(params) {
  return request({
    url: '/erpConsignReturnErp/getErpConsignReturnList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAgentReturn(params) {
  return request({
    url: '/erpConsignReturnErp/findErpConsignReturn',
    method: 'get',
    params: params
  });
}
export function createAgentReturn(data) {
  return request({
    url: '/erpConsignReturnErp/createErpConsignReturn',
    method: 'post',
    data: data
  });
}
export function updateAgentReturn(data) {
  return request({
    url: '/erpConsignReturnErp/updateErpConsignReturn',
    method: 'put',
    data: data
  });
}
export function updateAgentReturnStatus(data) {
  return request({
    url: '/erpConsignReturnErp/updateErpConsignReturnStatus',
    method: 'put',
    data: data
  });
}
export function deleteAgentReturn(data) {
  return request({
    url: '/erpConsignReturnErp/deleteErpConsignReturn',
    method: 'delete',
    data: data
  });
}
export function deleteAgentReturnByIds(data) {
  return request({
    url: '/erpConsignReturnErp/deleteErpConsignReturnByIds',
    method: 'delete',
    data: data
  });
}
export function createAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErp/createErpConsignReturnInfo',
    method: 'post',
    data: data
  });
}
export function createAgentReturnInfos(data) {
  return request({
    url: '/erpConsignReturnInfoErp/createErpConsignReturnInfos',
    method: 'post',
    data: data
  });
}
export function updateAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErp/updateErpConsignReturnInfo',
    method: 'put',
    data: data
  });
}
export function listAgentReturnInfo(params) {
  return request({
    url: '/erpConsignReturnInfoErp/getErpConsignReturnInfoList',
    method: 'get',
    params: params
  });
}
export function findAgentReturnInfo(params) {
  return request({
    url: '/erpConsignReturnInfoErp/findErpConsignReturnInfo',
    method: 'get',
    params: params
  });
}
export function deleteAgentReturnInfo(data) {
  return request({
    url: '/erpConsignReturnInfoErp/deleteErpConsignReturnInfo',
    method: 'delete',
    data: data
  });
}
export function deleteAgentReturnInfoByIds(data) {
  return request({
    url: '/erpConsignReturnInfoErp/deleteErpConsignReturnInfoByIds',
    method: 'delete',
    data: data
  });
} // 下载模板

export function downloadExcelTemplate(params) {
  return request({
    url: '/erpConsignReturnInfoErp/getErpConsignDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}