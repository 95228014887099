var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-table",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "120px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称", prop: "customerName" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.customerName))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司税号", prop: "companyTaxNumber" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.companyTaxNumber))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "bank" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bank))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "bankAccount" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bankAccount))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位地址", prop: "workAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.workAddress))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位电话", prop: "telephone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.telephone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "期望发票类型", prop: "invoiceType" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.getInvoiceTypeName(_vm.form.invoiceType))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票联系方式", prop: "ticketPhone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketPhone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票地址", prop: "ticketAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketAddress))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "invoiceForm",
          staticClass: "form-table",
          staticStyle: { "margin-top": "10px" },
          attrs: {
            model: _vm.invoiceForm,
            "show-message": false,
            "label-width": "140px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.invoiceFormSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票号", prop: "invoiceNumber" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.invoiceNumber))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发票代码",
                        prop: "invoiceCode",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.invoiceCode))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发票类型",
                        prop: "invoiceType",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getInvoiceTypeName(_vm.invoiceForm.invoiceType)
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开票日期",
                        prop: "invoicingDate",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.invoicingDate))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "金额(不含税)", prop: "money" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.money))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "税率",
                        prop: "taxRate",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.taxRate) + " %")
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "税额",
                        prop: "taxAmount",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.taxAmount))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "含税金额",
                        prop: "amountIncludingTax",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.amountIncludingTax))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "校验代码(后6位)",
                        prop: "validationCode"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.validationCode))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 17 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "附件",
                        prop: "attachment",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "item-text" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                title: "附件列表",
                                width: "500",
                                trigger: "click"
                              }
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: _vm.invoiceForm.attachment,
                                    size: "mini",
                                    border: "",
                                    stripe: "",
                                    "header-cell-style": {
                                      background: "#f5f7fa",
                                      borderColor: "#ebeef5",
                                      color: "#333"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { prop: "url", label: "附件路径" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var row = ref.row
                                          return [
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  underline: false
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.attachmentPreview(
                                                      row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(row.url))]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    slot: "reference",
                                    underline: false,
                                    type: "primary"
                                  },
                                  slot: "reference"
                                },
                                [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.invoiceForm.attachment.length)
                                    )
                                  ]),
                                  _vm._v(" 个附件 ")
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票公司", prop: "subsidiaryId" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getSubsidiaryName(_vm.invoiceForm.subsidiaryId)
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "开票人",
                        prop: "invoiceIssuer",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.invoiceIssuer))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        prop: "remarks",
                        "label-width": "90px"
                      }
                    },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.invoiceForm.remarks))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "10px", width: "100%" },
          attrs: {
            size: "small",
            data: _vm.detailList,
            "highlight-current-row": "",
            border: "",
            stripe: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "销售单号",
              prop: "salesOrderNo",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "productNumber",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票名称",
              prop: "productNameInvoice",
              "min-width": "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specificationModel",
              "min-width": "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票数量",
              prop: "invoicNumOri",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票金额",
              prop: "invoicePriceOri",
              width: "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "红冲数量",
              prop: "redNum",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "红冲金额",
              prop: "redPrice",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "有效开票数量",
              prop: "invoicNum",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "有效开票金额",
              prop: "invoicePrice",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      ),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.uploadPreviewImageUrl,
              "z-index": 9999
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }