import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
// permission.js 用户按钮权限自定义指令
import store from '@/store'; // 假设您的Vuex store实例是这样导出的

export default {
  install: function install(Vue) {
    Vue.directive('permission', {
      bind: function bind(el, binding) {
        // value 获取用户使用自定义指令绑定的内容
        var value = binding.value; // 获取用户所有的权限按钮，这里使用Vue 2的this.$store方式访问Vuex store

        var buttonPermission = store.state.d2admin.user.info.btn_permission || []; // 假设权限是这样存储在Vuex中的
        // console.log('store.state.d2admin.user.info', store.state.d2admin.user.info)
        // console.log('buttonPermission', buttonPermission)
        // 假设buttonPermission已经是一个数组，例如：['index:test', 'index:test2']
        // 如果不是，您可能需要像上面那样硬编码或根据实际情况获取
        // allPermission 是我们自定义的，代表所有权限

        var allPermission = '*:*:*';

        if (buttonPermission.includes(allPermission)) {
          return true; // 如果用户拥有所有权限，则无需进一步检查
        } // 判断用户使用自定义指令，是否使用正确了


        if (value && Array.isArray(value) && value.length > 0) {
          var hasPermission = buttonPermission.some(function (role) {
            return value.includes(role) || value.includes(allPermission);
          }); // 当用户没有这个按钮权限时，设置隐藏这个按钮

          if (!hasPermission) {
            el.style.display = 'none';
          }
        } else {
          // 注意：在Vue 2的指令中抛出错误可能不会如预期工作，通常我们会通过控制台警告来通知开发者
          console.error('need roles! Like v-permission="[\'admin\',\'editor\']"');
        }
      }
    });
  }
};