var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售订货单")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.orderCreate }
                },
                [_vm._v("新建销售订货单")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "mini",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售订货单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入销售订货单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.orderNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "orderNumber", $$v)
                            },
                            expression: "searchForm.orderNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.sellUid,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "sellUid", $$v)
                              },
                              expression: "searchForm.sellUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "sellUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "购货者" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.searchForm.distributorId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "distributorId",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.distributorId"
                                }
                              },
                              _vm._l(_vm.retailerList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "发票号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入发票号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.invoiceNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "invoiceNumber", $$v)
                                },
                                expression: "searchForm.invoiceNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "订单状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.searchForm.state,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "state", $$v)
                                  },
                                  expression: "searchForm.state"
                                }
                              },
                              _vm._l(_vm.statusList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "产品编码" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入产品编码",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.productNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "productNumber", $$v)
                                },
                                expression: "searchForm.productNumber"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "产品名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入产品名称",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.productName,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "productName", $$v)
                                },
                                expression: "searchForm.productName"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "规格型号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入规格型号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.specificationModel,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.searchForm,
                                    "specificationModel",
                                    $$v
                                  )
                                },
                                expression: "searchForm.specificationModel"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "备注" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入备注",
                                clearable: ""
                              },
                              model: {
                                value: _vm.searchForm.remarks,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "remarks", $$v)
                                },
                                expression: "searchForm.remarks"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c("el-button", { on: { click: _vm.dataExport } }, [
                          _vm._v("导出")
                        ]),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "justify-content": "flex-end",
                  "align-items": "center",
                  "font-size": "14px"
                }
              },
              [
                _c("div", [
                  _vm._v("订单数量合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.sumOrderQuantity()))
                  ])
                ]),
                _c("div", { staticStyle: { margin: "0 20px" } }, [
                  _vm._v("发货数量合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.sumDetailDeliveryQuantityAll()))
                  ])
                ]),
                _c("div", [
                  _vm._v("金额合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.sumAmount()))
                  ])
                ])
              ]
            ),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.orderList
              },
              on: {
                "row-click": _vm.orderRowClick,
                "row-dblclick": _vm.orderRowDbClick
              },
              scopedSlots: _vm._u([
                {
                  key: "orderNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "barCodePopover" + row.ID,
                          attrs: { placement: "right", trigger: "click" }
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: { id: "barCodeImage" + row.ID }
                            })
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                underline: false,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.makeBarCode(
                                    row.ID,
                                    row.orderNumber
                                  )
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.orderNumber))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "state",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "驳回原因：" + row.saleCauseOfRejection,
                            placement: "top",
                            disabled: row.state === 9 ? false : true
                          }
                        },
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.stateToTag(row.state))
                            }
                          })
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "invoiceNumbers",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap"
                              }
                            },
                            [_vm._v(_vm._s(_vm.invoiceFormatter(row.invoice)))]
                          ),
                          _c(
                            "el-tag",
                            {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                type: "",
                                effect: "dark",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addInvoice(row)
                                }
                              }
                            },
                            [_vm._v("添加")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "operations",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("div", { staticClass: "control-column" }, [
                        row.intelligent === 0
                          ? _c(
                              "div",
                              [
                                row.state === 2 &&
                                (row.sumDetailDeliveryQuantityAll <
                                  row.sumQuantity +
                                    row.sumComplimentaryQuantity ||
                                  row.sumDetailComplimentaryQuantityAll <
                                    row.sumComplimentaryQuantity)
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.toOutWarehouse(row)
                                          }
                                        }
                                      },
                                      [_vm._v("去发货")]
                                    )
                                  : _vm._e(),
                                row.state === 2 &&
                                (row.sumDetailDeliveryQuantityAll > 0 ||
                                  row.sumDetailComplimentaryQuantityAll > 0)
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        nativeOn: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.viewOutWarehouse(row)
                                          }
                                        }
                                      },
                                      [_vm._v("查看发货情况")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.orderEdit(row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.state === 1
                                          ? "修改订单"
                                          : "订单详情"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.viewOutWarehouse(row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看发货情况")]
                                ),
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.orderEdit(row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.state === 1
                                          ? "修改订单"
                                          : "订单详情"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                      ])
                    ]
                  }
                }
              ])
            }),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.searchForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.searchForm.pageSize
                  },
                  on: {
                    "current-change": _vm.orderPageChange,
                    "size-change": _vm.orderSizeChange
                  }
                })
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailList,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailForm)
                    },
                    label: "序号",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.productName",
                    label: "产品名称",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.specificationModel",
                    width: "100",
                    label: "规格型号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.registrationNumber",
                    width: "150",
                    label: "注册证号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.brandName",
                    width: "150",
                    label: "品牌",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.unit",
                    width: "80",
                    label: "单位",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sellQuantity",
                    width: "100",
                    label: "销售数量",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sellAmount",
                    width: "100",
                    label: "销售金额",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "remarks",
                    label: "备注",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.detailForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.detailForm.page,
                    "page-size": _vm.detailForm.pageSize
                  },
                  on: {
                    "current-change": _vm.detailPageChange,
                    "size-change": _vm.detailSizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("InvoiceComponent", {
        ref: "InvoiceComponentRef",
        on: { confirm: _vm.loadData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }