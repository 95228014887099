var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("期初入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              loading: _vm.formLoading,
              disabled: _vm.form.inWarehouseStatus > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "warehouseId",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务部门", prop: "departmentId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.departmentId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "departmentId", $$v)
                              },
                              expression: "form.departmentId"
                            }
                          },
                          _vm._l(_vm.departmentList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.departmentName,
                                value: item.ID
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间"
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            maxlength: "150",
                            "suffix-icon": "el-icon-edit",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.selectSpecs }
                  },
                  [_vm._v("增加产品 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.importFromExcel()
                      }
                    }
                  },
                  [_vm._v("从EXCEL中导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "font-size": "14px"
                }
              },
              [
                _c("div", [
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(" 总金额（不含税）："),
                    _c(
                      "span",
                      { staticStyle: { color: "#f00", "font-weight": "bold" } },
                      [_vm._v(_vm._s(_vm.totalAmountExclusive()))]
                    )
                  ]),
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(" 总金额（含税）："),
                    _c(
                      "span",
                      { staticStyle: { color: "#f00", "font-weight": "bold" } },
                      [_vm._v(_vm._s(_vm.totalAmount()))]
                    )
                  ]),
                  _c("span", { staticStyle: { "margin-right": "10px" } }, [
                    _vm._v(" 税额合计："),
                    _c(
                      "span",
                      { staticStyle: { color: "#f00", "font-weight": "bold" } },
                      [_vm._v(_vm._s(_vm.totalAmountTax()))]
                    )
                  ]),
                  _vm._v(" 总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("） ")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: !_vm.form.id
                        },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印入库单")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c("div", { staticStyle: { width: "450px" } }, [
                  _c("input", {
                    ref: "scanInputBoxRef",
                    staticClass: "bar-input",
                    attrs: { placeholder: "请扫描产品条码", clearable: "" },
                    on: { change: _vm.barCodeSearch }
                  })
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.detailListForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                rowClassName: _vm.rowClassName,
                data: _vm.detailListForm.data.slice(
                  (_vm.detailListForm.page - 1) * _vm.detailListForm.pageSize,
                  _vm.detailListForm.page * _vm.detailListForm.pageSize
                )
              },
              on: {
                "row-click": _vm.detailRowClick,
                pageChange: _vm.detailFormPageChange,
                sizeChange: _vm.detailFormSizeChange
              },
              scopedSlots: _vm._u([
                {
                  key: "productNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.form.inWarehouseStatus === 1 && !row.ID && !row.id
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: row.productNumber,
                              callback: function($$v) {
                                _vm.$set(row, "productNumber", $$v)
                              },
                              expression: "row.productNumber"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.productNumber))])
                    ]
                  }
                },
                {
                  key: "batchNumber",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "batchNumber" + index,
                              attrs: {
                                prop: "data." + index + ".batchNumber",
                                rules: _vm.detailListFormRules.batchNumber
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  size: "mini",
                                  placeholder: "生产批号"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "trackingCode-" + index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.batchNumber,
                                  callback: function($$v) {
                                    _vm.$set(row, "batchNumber", $$v)
                                  },
                                  expression: "row.batchNumber"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.batchNumber))])
                    ]
                  }
                },
                {
                  key: "trackingCode",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c("el-input", {
                            key: "trackingCode" + index,
                            ref: "trackingCode-" + index,
                            attrs: { size: "mini", placeholder: "序列号" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "inWarehouseQuantity-" + index,
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.trackingCode,
                              callback: function($$v) {
                                _vm.$set(row, "trackingCode", $$v)
                              },
                              expression: "row.trackingCode"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.trackingCode))])
                    ]
                  }
                },
                {
                  key: "inWarehouseQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "inWarehouseQuantity" + index,
                              attrs: {
                                prop: "data." + index + ".inWarehouseQuantity",
                                rules:
                                  _vm.detailListFormRules.inWarehouseQuantity
                              }
                            },
                            [
                              _c("el-input-number", {
                                ref: "inWarehouseQuantity-" + index,
                                attrs: {
                                  size: "mini",
                                  placeholder: "入库数量",
                                  controls: false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  },
                                  input: function($event) {
                                    return _vm.inWarehouseQuantityInput(row)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "manufacturerDate-" + index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.inWarehouseQuantity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      row,
                                      "inWarehouseQuantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "row.inWarehouseQuantity"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.inWarehouseQuantity))])
                    ]
                  }
                },
                {
                  key: "registrationNumber",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "data." + index + ".certId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择注册证号"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  model: {
                                    value: row.certId,
                                    callback: function($$v) {
                                      _vm.$set(row, "certId", $$v)
                                    },
                                    expression: "row.certId"
                                  }
                                },
                                _vm._l(row.registrationNumberList, function(
                                  item
                                ) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.ID,
                                      attrs: {
                                        label: item.regNoCn,
                                        value: item.ID
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { float: "left" } },
                                        [_vm._v(_vm._s(item.regNoCn))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.registrationNumber))])
                    ]
                  }
                },
                {
                  key: "manufacturerDate",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "manufacturerDate" + index,
                              attrs: {
                                prop: "data." + index + ".manufacturerDate",
                                rules: _vm.detailListFormRules.manufacturerDate
                              }
                            },
                            [
                              _c("el-date-picker", {
                                ref: "manufacturerDatePicker-" + index,
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "-100",
                                  width: "100%"
                                },
                                attrs: {
                                  id: "manufacturerDatePicker-" + index,
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  editable: false,
                                  type: "date"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                model: {
                                  value: row.manufacturerDate,
                                  callback: function($$v) {
                                    _vm.$set(row, "manufacturerDate", $$v)
                                  },
                                  expression: "row.manufacturerDate"
                                }
                              }),
                              _c(
                                "el-input",
                                {
                                  ref: "manufacturerDate-" + index,
                                  staticClass: "date-input",
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return function(event) {
                                        return _vm.nextFocus(
                                          "validityDate-" + index,
                                          event
                                        )
                                      }.apply(null, arguments)
                                    }
                                  },
                                  model: {
                                    value: row.manufacturerDate,
                                    callback: function($$v) {
                                      _vm.$set(row, "manufacturerDate", $$v)
                                    },
                                    expression: "row.manufacturerDate"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-date",
                                    attrs: { slot: "append" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showDatePicker(
                                          "manufacturerDatePicker-" + index
                                        )
                                      }
                                    },
                                    slot: "append"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.manufacturerDate))])
                    ]
                  }
                },
                {
                  key: "validityDate",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c(
                            "el-form-item",
                            [
                              _c("el-date-picker", {
                                ref: "validityDatePicker-" + index,
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "-100",
                                  width: "100%"
                                },
                                attrs: {
                                  id: "validityDatePicker-" + index,
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  editable: false,
                                  type: "date"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                model: {
                                  value: row.validityDate,
                                  callback: function($$v) {
                                    _vm.$set(row, "validityDate", $$v)
                                  },
                                  expression: "row.validityDate"
                                }
                              }),
                              _c(
                                "el-input",
                                {
                                  ref: "validityDate-" + index,
                                  staticClass: "date-input",
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    },
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return function(event) {
                                        return _vm.nextFocus(
                                          "afterTaxPrice-" + index,
                                          event
                                        )
                                      }.apply(null, arguments)
                                    }
                                  },
                                  model: {
                                    value: row.validityDate,
                                    callback: function($$v) {
                                      _vm.$set(row, "validityDate", $$v)
                                    },
                                    expression: "row.validityDate"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-date",
                                    attrs: { slot: "append" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showDatePicker(
                                          "validityDatePicker-" + index
                                        )
                                      }
                                    },
                                    slot: "append"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.validityDate))])
                    ]
                  }
                },
                {
                  key: "afterTaxPrice",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus === 1
                        ? _c("el-input-number", {
                            ref: "afterTaxPrice-" + index,
                            attrs: { size: "mini", min: 0, controls: false },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "sterilizationBatchNumber-" + index,
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.afterTaxPrice,
                              callback: function($$v) {
                                _vm.$set(row, "afterTaxPrice", $$v)
                              },
                              expression: "row.afterTaxPrice"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.afterTaxPrice))])
                    ]
                  }
                },
                {
                  key: "taxRate",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "data." + index + ".taxRate",
                            rules: _vm.detailListFormRules.taxRate
                          }
                        },
                        [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    ref: "taxRate-" + index,
                                    attrs: {
                                      size: "mini",
                                      precision: 2,
                                      min: 0,
                                      max: 100,
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.taxRate,
                                      callback: function($$v) {
                                        _vm.$set(row, "taxRate", $$v)
                                      },
                                      expression: "row.taxRate"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { padding: "0 5px" } },
                                    [_vm._v("%")]
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.taxRate == undefined ||
                                      row.taxRate == null
                                      ? 0
                                      : row.taxRate
                                  ) + "%"
                                )
                              ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "sterilizationBatchNumber",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus == 1
                        ? _c("el-input", {
                            key: "sterilizationBatchNumber" + index,
                            ref: "sterilizationBatchNumber-" + index,
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "sterilizationDate-" + index,
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.sterilizationBatchNumber,
                              callback: function($$v) {
                                _vm.$set(row, "sterilizationBatchNumber", $$v)
                              },
                              expression: "row.sterilizationBatchNumber"
                            }
                          })
                        : _c("span", [
                            _vm._v(_vm._s(row.sterilizationBatchNumber))
                          ])
                    ]
                  }
                },
                {
                  key: "sterilizationDate",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.inWarehouseStatus == 1
                        ? _c(
                            "el-form-item",
                            [
                              _c("el-date-picker", {
                                ref: "sterilizationDatePicker-" + index,
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "-100",
                                  width: "100%"
                                },
                                attrs: {
                                  id: "sterilizationDatePicker-" + index,
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  editable: false,
                                  type: "date"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                model: {
                                  value: row.sterilizationDate,
                                  callback: function($$v) {
                                    _vm.$set(row, "sterilizationDate", $$v)
                                  },
                                  expression: "row.sterilizationDate"
                                }
                              }),
                              _c(
                                "el-input",
                                {
                                  ref: "sterilizationDate-" + index,
                                  staticClass: "date-input",
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(row)
                                    }
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                    }
                                  },
                                  model: {
                                    value: row.sterilizationDate,
                                    callback: function($$v) {
                                      _vm.$set(row, "sterilizationDate", $$v)
                                    },
                                    expression: "row.sterilizationDate"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-date",
                                    attrs: { slot: "append" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showDatePicker(
                                          "sterilizationDatePicker-" + index
                                        )
                                      }
                                    },
                                    slot: "append"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.sterilizationDate))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("入库人：" + _vm._s(_vm.form.inWarehouseName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("入库时间：" + _vm._s(_vm.form.inWarehouseTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.form.examineName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.form.accountName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              disabled: !(
                                _vm.form.inWarehouseStatus == 1 &&
                                _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v("暂存")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v("提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤消提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-close" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 6
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.specsAddToList }
      }),
      _c("ImportFromExcelComponent", {
        ref: "ImportFromExcelComponentRef",
        on: { add: _vm.specsAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }