import { request } from '@/api/_service.js';
export function listAes(params) {
  return request({
    url: '/erpPoorErp/getErpPoorList',
    method: 'get',
    params: params
  });
}
export function listAesExport(params) {
  return request({
    url: '/erpPoorErp/getErpPoorList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAes(params) {
  return request({
    url: '/erpPoorErp/findErpPoor',
    method: 'get',
    params: params
  });
}
export function createAes(data) {
  return request({
    url: '/erpPoorErp/createErpPoor',
    method: 'post',
    data: data
  });
}
export function updateAes(data) {
  return request({
    url: '/erpPoorErp/updateErpPoor',
    method: 'put',
    data: data
  });
}
export function deleteAes(data) {
  return request({
    url: '/erpPoorErp/deleteErpPoor',
    method: 'delete',
    data: data
  });
}
export function deleteAesByIds(data) {
  return request({
    url: '/erpPoorErp/deleteErpPoorByIds',
    method: 'delete',
    data: data
  });
}