var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              key: _vm.tamptime,
              ref: "orderListTableRef",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                "highlight-current-row": "",
                "row-class-name": _vm.rowClassName,
                size: "small",
                "row-key": "ID",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(_vm.tableHead, function(col) {
            return [
              col.prop === "index"
                ? [
                    _c(
                      "el-table-column",
                      {
                        key: col.prop,
                        attrs: {
                          label: col.label,
                          prop: col.prop,
                          width: col.width,
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.indexFormatter(scope.$index))
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "点击筛选字段",
                                  placement: "top"
                                }
                              },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: { effect: "dark", size: "mini" },
                                    on: { click: _vm.tableHeadSetup }
                                  },
                                  [
                                    _vm._v("序号 "),
                                    _c("i", {
                                      staticClass: "fa fa-filter",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                : col.prop === "operations"
                ? [
                    _c("el-table-column", {
                      key: col.prop,
                      attrs: {
                        label: col.label,
                        prop: col.prop,
                        width: col.width,
                        align: "center",
                        fixed: "right"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control-column" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewRelevance(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("查看相关订单")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                : [
                    col.checked === true
                      ? _c(
                          "el-table-column",
                          _vm._b(
                            {
                              key: col.prop,
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: col.prop,
                                align: "center",
                                label: col.label
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        col.prop === "invoiced"
                                          ? _c(
                                              "div",
                                              [
                                                scope.row.invoiced > 0
                                                  ? _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary",
                                                          underline: false
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewInvoice(
                                                              scope.row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.invoiced
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.invoiced
                                                        )
                                                      )
                                                    ])
                                              ],
                                              1
                                            )
                                          : _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.columnFormatter(
                                                    col.prop,
                                                    scope.row
                                                  )
                                                )
                                              }
                                            })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            "el-table-column",
                            _vm._d({}, [_vm.attribName(col.width), col.width])
                          )
                        )
                      : _vm._e()
                  ]
            ]
          })
        ],
        2
      ),
      _c("RelevanceDialog", { ref: "RelevanceDialog" }),
      _c("InvoiceListComponent", { ref: "InvoiceListComponentRef" }),
      _c("DrawTableHeadComponent", {
        ref: "DrawTableHeadComponentRef",
        attrs: { "table-head": _vm.tableHead },
        on: { "save-table-set": _vm.saveTableSet }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }