import { request } from '@/api/_service.js';
export function ctrlShelfIndicator(data) {
  return request({
    url: 'https://192.168.1.200/wms/associate/ctrlShelfIndicator',
    method: 'post',
    data: data
  });
}
export function lightTagsLed(data) {
  return request({
    url: 'https://192.168.1.200/wms/associate/lightTagsLed',
    method: 'post',
    data: data
  });
}