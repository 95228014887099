import { request } from '@/api/_service.js';
export function listBalance(params) {
  return request({
    url: '/balanceErp/getBalanceList',
    method: 'get',
    params: params
  });
}
export function listBalanceExport(params) {
  return request({
    url: '/balanceErp/getBalanceList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findBalance(params) {
  return request({
    url: '/balanceErp/findBalance',
    method: 'get',
    params: params
  });
}
export function listBalanceLog(params) {
  return request({
    url: '/balanceLogErp/getBalanceLogList',
    method: 'get',
    params: params
  });
}
export function findBalanceLog(params) {
  return request({
    url: '/balanceLogErp/findBalanceLog',
    method: 'get',
    params: params
  });
}