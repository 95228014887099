//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listDepartment, updateDepartment, deleteDepartment } from '@/api/department.js';
import DepartmentEditorFormComponent from '@/views/base/department/components/editor_form/index.vue';
export default {
  name: 'base-department-index',
  components: {
    DepartmentEditorFormComponent: DepartmentEditorFormComponent
  },
  data: function data() {
    return {
      departmentPageForm: {
        departmentName: '',
        personCharge: '',
        pinyinCode: '',
        pageSize: 10,
        page: 1,
        total: 0
      },
      tableData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.departmentLoad();
    },
    departmentLoad: function departmentLoad() {
      var that = this;
      that.tableData = [];
      listDepartment(that.departmentPageForm).then(function (res) {
        that.tableData = res.list;
        that.departmentPageForm.total = res.total;
      });
    },
    departmentAdd: function departmentAdd() {
      var that = this;
      that.$refs.DepartmentEditorFormComponent.init();
    },
    departmentEdit: function departmentEdit(row) {
      var that = this;
      that.$refs.DepartmentEditorFormComponent.init(row);
    },
    departmentDelete: function departmentDelete(row) {
      var that = this;
      that.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteDepartment({
          id: row.ID
        }).then(function (res) {
          that.$notify({
            message: '部门删除成功',
            type: 'success'
          });
          that.departmentLoad();
        });
      }).catch(function () {});
    },
    departmentStateSwitch: function departmentStateSwitch(row) {
      var _this = this;

      var that = this;

      if (row.userCount > 0 && row.state === 2) {
        that.$confirm('该部门下有员工信息，是否确定停用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          updateDepartment({
            id: row.ID,
            state: row.state
          }).then(function (res) {
            that.$notify({
              message: '状态修改成功',
              type: 'success'
            });
          });
        }).catch(function () {
          row.state = row.state === 1 ? 2 : 1;

          _this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
      } else {
        that.$confirm('是否修改【' + row.departmentName + '】的状态?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          updateDepartment({
            id: row.ID,
            state: row.state
          }).then(function (res) {
            that.$notify({
              message: '状态修改成功',
              type: 'success'
            });
          });
        }).catch(function () {
          row.state = row.state === 1 ? 2 : 1;

          _this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
      }
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.departmentPageForm.page = 1;
      that.departmentLoad();
    },
    departmentPageChange: function departmentPageChange(page) {
      var that = this;
      that.departmentPageForm.page = page;
      that.departmentLoad();
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    }
  }
};