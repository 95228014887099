var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true,
        fullscreen: _vm.dialogFullScreen
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "custom_dialog_header" }, [
          _c("span", { staticClass: "el_dialog_title" }, [
            _vm._v(_vm._s(_vm.dialogTitle))
          ]),
          _c(
            "div",
            {
              staticClass: "custom_dialog_menu",
              on: {
                click: function($event) {
                  _vm.dialogFullScreen = !_vm.dialogFullScreen
                }
              }
            },
            [
              !_vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-maximize",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e(),
              _vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-restore",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ]
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dataLoading,
                  expression: "dataLoading"
                }
              ],
              ref: "dataTableRef",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dataList,
                size: "small",
                border: "",
                "highlight-current-row": "",
                "highlight-selection-row": "",
                stripe: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center",
                  fixed: "left"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.subsidiary.companyName",
                  label: "归属公司",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.productNumber",
                  label: "产品编号",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "main.productName",
                  label: "产品名称",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.specificationModel",
                  label: "规格型号",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.unit",
                  label: "单位",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.batch_number",
                  label: "生产批号",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.trackingCode",
                  label: "序列号",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.manufacturer_date",
                  label: "生产日期",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.validity_date",
                  label: "有效期",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.registrationNumber",
                  label: "注册/备案证",
                  "min-width": "200",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantityUsed",
                  label: "报台数量",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hospitalUnitPrice",
                  label: "医院单价",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.hospitalUnitPrice
                                ? row.hospitalUnitPrice.toFixed(2)
                                : "--"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hospitalSumPrice",
                  label: "医院售价",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.hospitalSumPrice
                                ? row.hospitalSumPrice.toFixed(2)
                                : "--"
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operationTime",
                  label: "手术日期",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "surgeon",
                  label: "医生姓名",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "patientName",
                  label: "病人姓名",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "patientGender",
                  label: "病人性别",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "patientAge",
                  label: "病人年龄",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.patientAge ? row.patientAge : "--") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "admissionNumber",
                  label: "住院号",
                  "min-width": "200",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize,
                  "page-sizes": [10, 20, 50, 100]
                },
                on: {
                  "current-change": _vm.pageChange,
                  "size-change": _vm.sizeChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }