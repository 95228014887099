import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EDescItem',
  inject: ['labelWidth', 'column', 'size', 'suffix'],
  props: {
    span: {
      type: [Number, String],
      required: false,
      default: 0
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function data() {
    return {
      // 子组件自己的span
      selfSpan: 0
    };
  },
  computed: {
    computedSpan: function computedSpan() {
      // 子组件自己的span，用于父组件计算修改span
      if (this.selfSpan) {
        return 24 / this.column * this.selfSpan;
      } else if (this.span) {
        // props传递的span
        return 24 / this.column * this.span;
      } else {
        // 未传递span时，取column
        return 24 / this.column;
      }
    }
  }
};