var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("盘存详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            attrs: {
              inline: true,
              model: _vm.pageForm,
              size: "small",
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "仓库选择" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.pageForm.warehouse,
                      callback: function($$v) {
                        _vm.$set(_vm.pageForm, "warehouse", $$v)
                      },
                      expression: "pageForm.warehouse"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "物资分类" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.pageForm.class,
                      callback: function($$v) {
                        _vm.$set(_vm.pageForm, "class", $$v)
                      },
                      expression: "pageForm.class"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "摆放位置" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入摆放位置", clearable: "" },
                  model: {
                    value: _vm.pageForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.pageForm, "name", $$v)
                    },
                    expression: "pageForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "供应商选择" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.pageForm.class,
                      callback: function($$v) {
                        _vm.$set(_vm.pageForm, "class", $$v)
                      },
                      expression: "pageForm.class"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "物资名称" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入物资名称", clearable: "" },
                  model: {
                    value: _vm.pageForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.pageForm, "name", $$v)
                    },
                    expression: "pageForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "" } },
              [_c("el-checkbox", [_vm._v("不显示0库存物资")])],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.searchSubmit }
                  },
                  [_vm._v("查询")]
                ),
                _c("el-button", [_vm._v("导出")])
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "highlight-current-row": "",
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "ID",
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                width: "150",
                label: "产品编号",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "name", label: "物资名称", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "通用名",
                width: "150",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "规格型号",
                width: "150",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "库存数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "单位",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "库存高储",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "库存低储",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "包装规格",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "供货单位",
                width: "200",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "生产厂家",
                width: "200",
                align: "center"
              }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, total",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: { "current-change": _vm.pageChange }
          })
        ],
        1
      ),
      _c("EditorFormComponents", {
        ref: "EditorFormComponents",
        on: { reload: _vm.loadRetailer }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }