var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "720px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.companyDetail.name))]),
                    _c("h3", [_vm._v("不良事件监测调查报告")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        "margin-top": "20px",
                        color: "#000"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("报告单号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.orderNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("处理人：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.acceptanceName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("报告日期：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.reportTime))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("产品编号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.productNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("产品名称：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.productName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("规格型号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.specificationModel))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("注册证号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.registrationNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("批号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.batchNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("序列号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.trackingCode))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("生产日期：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.manufacturerDate))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("数量：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.quantity))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("使用单位：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.usedCompany))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("不良事件事故情况：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.problem))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("使用单位意见：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.usedPinion))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("质量负责人意见：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.qualityPinion))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("处理情况：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.situation))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "110px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("备注：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.remark))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "form-table" }, [
                    _c("table", [
                      _c("tr", [
                        _c("th"),
                        _c("td"),
                        _c("th", [_vm._v("打印人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.user.nickName) +
                              " / " +
                              _vm._s(_vm.getCurrentTime())
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }