var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("有台出库")]),
              _c("el-breadcrumb-item", [_vm._v("创建出库单")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              }
            ],
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "show-message": false,
              "label-width": "110px",
              disabled: _vm.form.status > 1,
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-tabs",
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "订单信息" } },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "flex-wrap": "wrap" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "归属公司",
                                  prop: "subsidiaryId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.subsidiaryId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "subsidiaryId", $$v)
                                      },
                                      expression: "form.subsidiaryId"
                                    }
                                  },
                                  _vm._l(_vm.subsidiaryList, function(item) {
                                    return _c("el-option", {
                                      key: "subsidiaryId" + item.ID,
                                      attrs: {
                                        label: item.companyName,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "仓库", prop: "warehouseId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择",
                                      disabled: _vm.detailList.length > 0
                                    },
                                    model: {
                                      value: _vm.form.warehouseId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "warehouseId", $$v)
                                      },
                                      expression: "form.warehouseId"
                                    }
                                  },
                                  _vm._l(_vm.warehouseList, function(item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.ID,
                                        attrs: {
                                          label: item.name,
                                          value: item.ID,
                                          disabled: item.status === 0
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "justify-content": "space-between"
                                            }
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.name))
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.status === 0
                                                        ? true
                                                        : false,
                                                    expression:
                                                      "item.status===0?true:false"
                                                  }
                                                ],
                                                staticClass: "text-danger"
                                              },
                                              [_vm._v("(无效)")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "医院", prop: "hospitalId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    on: { change: _vm.hospitalChange },
                                    model: {
                                      value: _vm.form.hospitalId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "hospitalId", $$v)
                                      },
                                      expression: "form.hospitalId"
                                    }
                                  },
                                  _vm._l(_vm.hospitalList, function(item) {
                                    return _c("el-option", {
                                      key: item.ID,
                                      attrs: {
                                        label: item.name,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "科室",
                                  prop: "usingDepartmentId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    on: { change: _vm.departmentChange },
                                    model: {
                                      value: _vm.form.usingDepartmentId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "usingDepartmentId",
                                          $$v
                                        )
                                      },
                                      expression: "form.usingDepartmentId"
                                    }
                                  },
                                  _vm._l(_vm.departmentList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: Number(item.id)
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "医生", prop: "surgeonId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    on: { change: _vm.doctorChange },
                                    model: {
                                      value: _vm.form.surgeonId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "surgeonId", $$v)
                                      },
                                      expression: "form.surgeonId"
                                    }
                                  },
                                  _vm._l(_vm.doctorList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: Number(item.id)
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "销售人员",
                                  prop: "salespersonUid"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.salespersonUid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "salespersonUid",
                                          $$v
                                        )
                                      },
                                      expression: "form.salespersonUid"
                                    }
                                  },
                                  _vm._l(_vm.salespersonList, function(item) {
                                    return _c("el-option", {
                                      key: item.uid,
                                      attrs: {
                                        label: item.name,
                                        value: item.uid
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "维护人员",
                                  prop: "maintenancePersonnelId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.maintenancePersonnelId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "maintenancePersonnelId",
                                          $$v
                                        )
                                      },
                                      expression: "form.maintenancePersonnelId"
                                    }
                                  },
                                  _vm._l(_vm.maintenancePersonnelList, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.uid
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "跟台人员",
                                  prop: "uidAttendant"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.uidAttendant,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "uidAttendant", $$v)
                                      },
                                      expression: "form.uidAttendant"
                                    }
                                  },
                                  _vm._l(_vm.followUserList, function(item) {
                                    return _c("el-option", {
                                      key: item.uid,
                                      attrs: {
                                        label: item.name,
                                        value: Number(item.uid)
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "配送方式",
                                  prop: "shippingMethodId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.shippingMethodId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "shippingMethodId",
                                          $$v
                                        )
                                      },
                                      expression: "form.shippingMethodId"
                                    }
                                  },
                                  _vm._l(_vm.shippingMethodList, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.ID,
                                      attrs: {
                                        label: item.name,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "快递单号",
                                  prop: "courierNumber"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入快递单号",
                                      maxlength: "40"
                                    },
                                    model: {
                                      value: _vm.form.courierNumber,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "courierNumber", $$v)
                                      },
                                      expression: "form.courierNumber"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否急诊",
                                  prop: "emergencyNot"
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { margin: "0 10px 0 15px" },
                                    model: {
                                      value: _vm.form.emergencyNot,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "emergencyNot", $$v)
                                      },
                                      expression: "form.emergencyNot"
                                    }
                                  },
                                  _vm._l(_vm.emergencyNotList, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "el-radio",
                                      { key: index, attrs: { label: item.ID } },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "备货类型",
                                  prop: "hospitalStock"
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { margin: "0 10px 0 15px" },
                                    model: {
                                      value: _vm.form.hospitalStock,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "hospitalStock", $$v)
                                      },
                                      expression: "form.hospitalStock"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "选择了库房送货，会有出入库配送环节操作",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 0 } },
                                          [_vm._v("库房配送")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.remindConfig.hospitalStockStatus === 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content:
                                                "选择了医院备货，不会有出入库配送流程",
                                              placement: "top"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("医院备货")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "加班费", prop: "overtimePay" }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    directives: [
                                      {
                                        name: "enter-number",
                                        rawName: "v-enter-number",
                                        value: { min: 0, precision: 2 },
                                        expression: "{min:0,precision:2}"
                                      }
                                    ],
                                    attrs: { placeholder: "请输入加班费" },
                                    model: {
                                      value: _vm.form.overtimePay,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "overtimePay", $$v)
                                      },
                                      expression: "form.overtimePay"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "运输费", prop: "freight" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    directives: [
                                      {
                                        name: "enter-number",
                                        rawName: "v-enter-number",
                                        value: { min: 0, precision: 2 },
                                        expression: "{min:0,precision:2}"
                                      }
                                    ],
                                    attrs: { placeholder: "请输入运输费" },
                                    model: {
                                      value: _vm.form.freight,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "freight", $$v)
                                      },
                                      expression: "form.freight"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "其他费用",
                                  prop: "otherExpenses"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    directives: [
                                      {
                                        name: "enter-number",
                                        rawName: "v-enter-number",
                                        value: { min: 0, precision: 2 },
                                        expression: "{min:0,precision:2}"
                                      }
                                    ],
                                    attrs: { placeholder: "请输入其他费用" },
                                    model: {
                                      value: _vm.form.otherExpenses,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "otherExpenses", $$v)
                                      },
                                      expression: "form.otherExpenses"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "收货地址",
                                  prop: "addressManu"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入收货地址",
                                      maxlength: "250"
                                    },
                                    model: {
                                      value: _vm.form.addressManu,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "addressManu", $$v)
                                      },
                                      expression: "form.addressManu"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "收货电话", prop: "mobileManu" }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入收货电话",
                                      maxlength: "11"
                                    },
                                    model: {
                                      value: _vm.form.mobileManu,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "mobileManu", $$v)
                                      },
                                      expression: "form.mobileManu"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "收货人",
                                  prop: "contactPersonManu"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入收货人",
                                      maxlength: "50"
                                    },
                                    model: {
                                      value: _vm.form.contactPersonManu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "contactPersonManu",
                                          $$v
                                        )
                                      },
                                      expression: "form.contactPersonManu"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "回库联系人",
                                  prop: "returnContactId"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.form.returnContactId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "returnContactId",
                                          $$v
                                        )
                                      },
                                      expression: "form.returnContactId"
                                    }
                                  },
                                  _vm._l(_vm.returnContactList, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "联系人手机",
                                  prop: "cellPhone"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入联系人手机",
                                      maxlength: "30"
                                    },
                                    model: {
                                      value: _vm.form.cellPhone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "cellPhone", $$v)
                                      },
                                      expression: "form.cellPhone"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 16, xl: 16 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remarks" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { maxlength: "500" },
                                    model: {
                                      value: _vm.form.remarks,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "remarks", $$v)
                                      },
                                      expression: "form.remarks"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "手术信息" } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "手术时间",
                                  prop: "operationTime"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd HH:mm:ss",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "请选择手术时间"
                                  },
                                  model: {
                                    value: _vm.form.operationTime,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "operationTime", $$v)
                                    },
                                    expression: "form.operationTime"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "手术类型",
                                  prop: "nameOperationType"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      margin: "0 10px 0 15px",
                                      "justify-content": "space-between",
                                      "font-size": "14px",
                                      color: "#606266"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          flex: "1",
                                          "margin-right": "10px",
                                          "border-right": "1px solid #ebeef5"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              trigger: "click"
                                            }
                                          },
                                          [
                                            _vm.form.nameOperationType.length >
                                            0
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass: "surgery-list"
                                                  },
                                                  _vm._l(
                                                    _vm.form.nameOperationType,
                                                    function(item, index) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            "nameOperationType-" +
                                                            index
                                                        },
                                                        [
                                                          _vm.form.status === 1
                                                            ? _c("el-link", {
                                                                attrs: {
                                                                  underline: false,
                                                                  type:
                                                                    "danger",
                                                                  icon:
                                                                    "el-icon-remove"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.surgeryItemRemove(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e(),
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.surgeryName ||
                                                                  item.surgery_name
                                                              )
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#ccc"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "请点右侧按钮选择手术"
                                                    )
                                                  ]
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  cursor: "pointer"
                                                },
                                                attrs: { slot: "reference" },
                                                slot: "reference"
                                              },
                                              [
                                                _vm.form.nameOperationType
                                                  .length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                          "align-items":
                                                            "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              height: "40px",
                                                              flex: "1",
                                                              overflow:
                                                                "hidden",
                                                              "text-overflow":
                                                                "ellipsis",
                                                              "white-space":
                                                                "nowrap",
                                                              width: "0"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.form
                                                                  .nameOperationType[0]
                                                                  .surgeryName ||
                                                                  _vm.form
                                                                    .nameOperationType[0]
                                                                    .surgery_name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-arrow-down",
                                                          staticStyle: {
                                                            width: "40px",
                                                            "text-align":
                                                              "center",
                                                            padding: "0 10px",
                                                            color: "#C0C4CC"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "#ccc",
                                                          "font-size": "12px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "请点右侧按钮选择手术"
                                                        )
                                                      ]
                                                    )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          width: "300",
                                          trigger: "click"
                                        }
                                      },
                                      [
                                        _c("SurgeryComponents", {
                                          ref: "SurgeryComponents",
                                          on: { add: _vm.surgeryItemAdd }
                                        }),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "primary",
                                              underline: false
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("选择手术")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 24, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "X光照片", prop: "xRayPhotos" }
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      margin: "0 10px 0 15px"
                                    },
                                    attrs: {
                                      action: _vm.uploadURL,
                                      accept: _vm.uploadFileType.toString(),
                                      headers: _vm.uploadToken,
                                      "auto-upload": true,
                                      "file-list": _vm.form.xRayPhotos,
                                      "on-success": _vm.uploadSuccess,
                                      "show-file-list": false
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-upload__tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip"
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              width: "400",
                                              trigger: "click"
                                            }
                                          },
                                          [
                                            _vm.form.xRayPhotos.length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "el-upload-list el-upload-list--text"
                                                  },
                                                  _vm._l(
                                                    _vm.form.xRayPhotos,
                                                    function(file, index) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            "xRayPhotos-" +
                                                            index,
                                                          staticClass:
                                                            "el-upload-list__item is-success"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "el-upload-list__item-name"
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-document"
                                                              }),
                                                              _vm._v(
                                                                _vm._s(
                                                                  file.name
                                                                ) + " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "el-upload-list__item-status-label"
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-upload-success el-icon-circle-check"
                                                              })
                                                            ]
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-close",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.xRayPhotosRemove(
                                                                  file
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "请点击上传按钮，上传相关X光照片"
                                                    )
                                                  ]
                                                ),
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  underline: false
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _vm._v("已上传 "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#f00"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.form.xRayPhotos
                                                          ? _vm.form.xRayPhotos
                                                              .length
                                                          : 0
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" 个文件")
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          slot: "default",
                                          type: "primary",
                                          underline: false
                                        },
                                        slot: "default"
                                      },
                                      [_vm._v("点击上传")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { sm: 24, lg: 24, xl: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "手术描述",
                                  prop: "surgeryDescribe"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { maxlength: "500", clearable: "" },
                                    model: {
                                      value: _vm.form.surgeryDescribe,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "surgeryDescribe",
                                          $$v
                                        )
                                      },
                                      expression: "form.surgeryDescribe"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "病人信息" } },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "flex-wrap": "wrap" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "病人姓名",
                                  prop: "patientName"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入病人姓名",
                                      maxlength: "30",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.patientName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "patientName", $$v)
                                      },
                                      expression: "form.patientName"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "年龄", prop: "patientAge" } },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "enter-number",
                                      rawName: "v-enter-number",
                                      value: { min: 0, precision: 0 },
                                      expression: "{min:0,precision:0}"
                                    }
                                  ],
                                  attrs: {
                                    placeholder: "请输入年龄",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.form.patientAge,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "patientAge", $$v)
                                    },
                                    expression: "form.patientAge"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "性别", prop: "patientGender" }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    staticStyle: { margin: "0 10px 0 15px" },
                                    model: {
                                      value: _vm.form.patientGender,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "patientGender", $$v)
                                      },
                                      expression: "form.patientGender"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("未知")
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("男")
                                    ]),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("女")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "住院号",
                                  prop: "admissionNumber"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入住院号",
                                      maxlength: "30",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.admissionNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "admissionNumber",
                                          $$v
                                        )
                                      },
                                      expression: "form.admissionNumber"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "床号", prop: "bedNumber" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入床号",
                                      maxlength: "30",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.bedNumber,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "bedNumber", $$v)
                                      },
                                      expression: "form.bedNumber"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "病历号",
                                  prop: "medicalRecordNumber"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入病历号",
                                      maxlength: "64",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.medicalRecordNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "medicalRecordNumber",
                                          $$v
                                        )
                                      },
                                      expression: "form.medicalRecordNumber"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 8, xl: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "身份证号",
                                  prop: "patientIdentityNumber"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入身份证号",
                                      maxlength: "32",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.patientIdentityNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "patientIdentityNumber",
                                          $$v
                                        )
                                      },
                                      expression: "form.patientIdentityNumber"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 12, lg: 16, xl: 16 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "手机号码",
                                  prop: "patientMobile"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      placeholder: "请输入手机号码",
                                      maxlength: "11",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.patientMobile,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "patientMobile", $$v)
                                      },
                                      expression: "form.patientMobile"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              }
            ],
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectStock }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectConsum }
                  },
                  [_vm._v("增加耗材包")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectConsumSet }
                  },
                  [_vm._v("从耗材组套导入")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-upload2"
                    },
                    on: { click: _vm.importFromExcel }
                  },
                  [_vm._v("Excel产品明细导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", [
                  _vm._v(" 出库数量合计：（"),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countQuantity()))
                  ]),
                  _vm._v("） ")
                ])
              ]
            )
          ]
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              data: _vm.detailPageData,
              "highlight-current-row": "",
              border: "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName
            },
            on: {
              "row-click": _vm.detailRowClick,
              "expand-change": _vm.expandChange
            }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand", width: "60", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            border: "",
                            data: props.row.expands,
                            "header-cell-style": {
                              background: "#f5f7fa",
                              borderColor: "#ebeef5",
                              color: "#333"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编号",
                              prop: "productNumber",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "品牌",
                              prop: "brandName",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "productName",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格型号",
                              prop: "specificationModel",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "生产批号",
                              prop: "batchNumber",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "序列号",
                              prop: "trackingCode",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "出库数量",
                              prop: "quantityUsed",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "消耗数量",
                              prop: "consumedQuantity",
                              "show-overflow-tooltip": ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { margin: "10px 0" } },
                        [
                          _c("el-pagination", {
                            attrs: {
                              background: "",
                              layout: "prev, pager, next",
                              total: props.row.page.total,
                              "current-page": props.row.page.page,
                              "page-size": props.row.page.pageSize
                            },
                            on: {
                              "current-change": function(val) {
                                return _vm.expandRowPageChange(val, props.row)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._l(
              _vm.tableHead.filter(function(tabItem) {
                return tabItem.checked === true
              }),
              function(col) {
                return [
                  col.checked === true ||
                  (col.prop === "quantityUsed" &&
                    _vm.form.status === 3 && _vm.form.deliveryStatus === 8) ||
                    _vm.form.status === 6 ||
                    (_vm.form.status === 2 && _vm.isCancel() === true)
                    ? _c("el-table-column", {
                        key: col.prop,
                        attrs: {
                          "show-overflow-tooltip": "",
                          prop: col.prop,
                          align: col.align || "center",
                          "min-width": col.width || 100
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function(ref) {
                                var column = ref.column
                                var $index = ref.$index
                                return [
                                  col.prop === "index"
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            trigger: "click",
                                            placement: "right-start"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "el-checkbox-group dropdown_checkbox",
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "column"
                                              }
                                            },
                                            _vm._l(_vm.tableHead, function(
                                              item2,
                                              index2
                                            ) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: "checkbox-" + index2,
                                                  attrs: {
                                                    label: item2.prop,
                                                    disabled:
                                                      item2.prop === "index"
                                                  },
                                                  on: {
                                                    change: _vm.saveTableSet
                                                  },
                                                  model: {
                                                    value: item2.checked,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item2,
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item2.checked"
                                                  }
                                                },
                                                [_vm._v(_vm._s(item2.label))]
                                              )
                                            }),
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            },
                                            [
                                              _c(
                                                "el-tooltip",
                                                {
                                                  staticClass: "item",
                                                  attrs: {
                                                    effect: "dark",
                                                    content: "点击筛选字段",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        size: "mini"
                                                      }
                                                    },
                                                    [
                                                      _vm._v("序号 "),
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-filter",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "dragItem",
                                          on: {
                                            mousedown: function($event) {
                                              return _vm.handleMounseDown(
                                                $event,
                                                column,
                                                $index
                                              )
                                            },
                                            dragover: function($event) {
                                              return _vm.handleDragover(
                                                $event,
                                                column,
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(col.label) + " ")]
                                      )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  col.prop === "deliveryQuantity"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.canEditQuantity(scope.row) ===
                                          true
                                            ? _c("el-input-number", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  size: "mini",
                                                  min: 0,
                                                  max: scope.row.normalQuantity,
                                                  precision: 0,
                                                  controls: false,
                                                  disabled:
                                                    scope.row.consumTypeId > 0
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.detailSaveOne(
                                                      scope.row
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    scope.row.deliveryQuantity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "deliveryQuantity",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.deliveryQuantity"
                                                }
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.deliveryQuantity
                                                  )
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : col.prop === "remarks"
                                    ? _c(
                                        "div",
                                        [
                                          _vm.canEditQuantity(scope.row) ===
                                          true
                                            ? _c("el-input", {
                                                attrs: { size: "mini" },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.detailSaveOne(
                                                      scope.row
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: scope.row.remarks,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "remarks",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.remarks"
                                                }
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.remarks)
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : col.prop === "index"
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.expireDays <= 90 &&
                                          scope.row.expireDays > 0
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "该产品还有" +
                                                      scope.row.expireDays +
                                                      "天过期",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction": "row",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c("d2-icon-svg", {
                                                        staticStyle: {
                                                          width: "16px",
                                                          height: "14px",
                                                          position: "absolute",
                                                          left: "0"
                                                        },
                                                        attrs: {
                                                          name: "warning-yellow"
                                                        }
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.indexMethod(
                                                              scope.$index,
                                                              _vm.detailForm
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          scope.row.expireDays <= 0
                                            ? _c(
                                                "el-tooltip",
                                                {
                                                  attrs: {
                                                    effect: "dark",
                                                    content:
                                                      "该产品已过期" +
                                                      Math.abs(
                                                        scope.row.expireDays
                                                      ) +
                                                      "天",
                                                    placement: "top"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-direction": "row",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                        position: "relative"
                                                      }
                                                    },
                                                    [
                                                      _c("d2-icon-svg", {
                                                        staticStyle: {
                                                          width: "16px",
                                                          height: "14px",
                                                          position: "absolute",
                                                          left: "0"
                                                        },
                                                        attrs: {
                                                          name: "warning-red"
                                                        }
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.indexMethod(
                                                              scope.$index,
                                                              _vm.detailForm
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          scope.row.expireDays > 90
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.indexMethod(
                                                      scope.$index,
                                                      _vm.detailForm
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : col.prop === "productName"
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.consumTypeId > 0
                                            ? _c(
                                                "el-tag",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer"
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    type: "danger"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.viewPack(
                                                        scope.row,
                                                        "detail"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "耗材包-" +
                                                      _vm._s(
                                                        scope.row.productName
                                                      )
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.productName)
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : col.prop === "isConsign"
                                    ? _c(
                                        "span",
                                        [
                                          scope.row.isConsign === 1
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "success",
                                                    effect: "dark",
                                                    size: "mini"
                                                  }
                                                },
                                                [_vm._v("是")]
                                              )
                                            : _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "info",
                                                    effect: "dark",
                                                    size: "mini"
                                                  }
                                                },
                                                [_vm._v("否")]
                                              )
                                        ],
                                        1
                                      )
                                    : col.prop === "specificationModel"
                                    ? _c("div", [
                                        scope.row.consumTypeId > 0
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "el-tag",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "5px",
                                                      cursor: "pointer"
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      effect: "dark",
                                                      size: "mini"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.viewPack(
                                                          scope.row,
                                                          "detail"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("明细汇总")]
                                                )
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.specificationModel
                                                )
                                              )
                                            ])
                                      ])
                                    : col.prop === "quantityUsed"
                                    ? _c("div", [
                                        _vm.canEditUsed(scope.row) === true
                                          ? _c(
                                              "div",
                                              [
                                                scope.row.consumTypeId > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                          "justify-content":
                                                            "space-between",
                                                          border:
                                                            "1px solid #409eff",
                                                          "border-radius":
                                                            "3px",
                                                          cursor: "pointer"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewPack(
                                                              scope.row,
                                                              "box"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              flex: "1",
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              "justify-content":
                                                                "center",
                                                              "border-right":
                                                                "1px solid #409eff",
                                                              background: "#fff"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .consumedQuantitySum
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              padding: "0 5px",
                                                              background:
                                                                "#409eff",
                                                              color: "#fff",
                                                              "font-size":
                                                                "12px",
                                                              display: "flex",
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [_vm._v("填写消耗")]
                                                        )
                                                      ]
                                                    )
                                                  : _c("el-input-number", {
                                                      staticStyle: {
                                                        width: "100%"
                                                      },
                                                      attrs: {
                                                        label: "使用数量",
                                                        size: "mini",
                                                        min: 0,
                                                        precision: 0,
                                                        controls: false
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.detailSaveOne(
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .quantityUsed,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "quantityUsed",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.quantityUsed"
                                                      }
                                                    })
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              scope.row.consumTypeId
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .consumedQuantitySum
                                                      )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.quantityUsed
                                                      )
                                                    )
                                                  ])
                                            ])
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row[col.prop]) +
                                            " "
                                        )
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ]
              }
            )
          ],
          2
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, prev, pager, next",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormPageChange }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              disabled: _vm.detailRowIndex < 0
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "primary",
                              disabled: _vm.formLoading
                            },
                            on: { click: _vm.submitCheck }
                          },
                          [_vm._v("确认创建出库单")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ConsumSearchComponents", {
        ref: "ConsumSearchComponents",
        on: { confirm: _vm.consumAddToList }
      }),
      _c("ConsumSetSearchComponents", {
        ref: "ConsumSetSearchComponents",
        on: { confirm: _vm.consumSetAddToList }
      }),
      _c("ViewPackComponents", { ref: "ViewPackComponents" }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("CheckUserComponents", {
        ref: "CheckUserComponents",
        on: { confirm: _vm.formCheck }
      }),
      _c("ImportDetailComponents", {
        ref: "ImportDetailComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ExpireDialogComponents", {
        ref: "ExpireDialogComponents",
        on: { confirm: _vm.expireConfirm }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.uploadPreviewVisible,
            "append-to-body": true,
            "modal-append-to-body": false,
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.uploadPreviewVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.uploadPreviewImageUrl, alt: "" }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }