var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("新增盘存单")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            attrs: {
              model: _vm.pageForm,
              size: "small",
              "label-width": "100px"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.pageForm.warehouse,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "warehouse", $$v)
                              },
                              expression: "pageForm.warehouse"
                            }
                          },
                          _vm._l(_vm.options, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点类型" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.itemForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.itemForm, "type", $$v)
                              },
                              expression: "itemForm.type"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("按规格")
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("按价格")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 5 } },
                  [
                    _c("el-form-item", { attrs: { label: "盈亏总数" } }, [
                      _vm._v(" (0) ")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 5 } },
                  [
                    _c("el-form-item", { attrs: { label: "盈亏总额" } }, [
                      _vm._v(" (0) ")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点人" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入盘点人", clearable: "" },
                          model: {
                            value: _vm.itemForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "name", $$v)
                            },
                            expression: "itemForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点时间" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.itemForm.time,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "time", $$v)
                            },
                            expression: "itemForm.time"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核人" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入审核人", clearable: "" },
                          model: {
                            value: _vm.itemForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "name", $$v)
                            },
                            expression: "itemForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核时间" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.itemForm.time,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "time", $$v)
                            },
                            expression: "itemForm.time"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "记账人" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入记账人", clearable: "" },
                          model: {
                            value: _vm.itemForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "name", $$v)
                            },
                            expression: "itemForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 6, lg: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "记账时间" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.itemForm.time,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "time", $$v)
                            },
                            expression: "itemForm.time"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xl: 12, lg: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入备注", clearable: "" },
                          model: {
                            value: _vm.itemForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "name", $$v)
                            },
                            expression: "itemForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-upload2"
                }
              },
              [_vm._v("导入库存")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", icon: "el-icon-plus" }
              },
              [_vm._v("增加行")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "danger", icon: "el-icon-minus" }
              },
              [_vm._v("删除行")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", icon: "el-icon-check" }
              },
              [_vm._v("审核")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "info",
                  icon: "el-icon-refresh-left"
                }
              },
              [_vm._v("撤消审核")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-edit-outline"
                }
              },
              [_vm._v("记账")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "danger", icon: "el-icon-close" }
              },
              [_vm._v("作废")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              size: "small",
              "highlight-current-row": "",
              stripeDELETE: "",
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            },
            on: { "row-click": _vm.rowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "ID",
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { label: "物资名称", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.edit
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: scope.row.name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name"
                            }
                          })
                        : _c("div", [_vm._v(_vm._s(scope.row.name))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "通用名",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.edit
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: scope.row.name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name"
                            }
                          })
                        : _c("div", [_vm._v(_vm._s(scope.row.name))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                width: "150",
                label: "规格型号",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "库存数量",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.edit
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            model: {
                              value: scope.row.name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name"
                            }
                          })
                        : _c("div", [_vm._v(_vm._s(scope.row.name))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "锁定数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "可用数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "盘点数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "盈亏数量",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "单位",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "shortName",
                label: "盈亏金额",
                width: "150",
                align: "center"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "small",
                  type: "info",
                  icon: "el-icon-refresh-left"
                }
              },
              [_vm._v("撤消提交")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary", icon: "el-icon-check" }
              },
              [_vm._v("提交")]
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }