import { request } from '@/api/_service.js';
export function listWarehouse(params) {
  return request({
    url: '/inside/getWarehouseList',
    method: 'get',
    params: params
  });
}
export function listWarehouseAll(params) {
  return request({
    url: '/inside/getWarehouseListAll',
    method: 'get',
    params: params
  });
}
export function findWarehouse(params) {
  return request({
    url: '/inside/findWarehouse',
    method: 'get',
    params: params
  });
}
export function createWarehouse(data) {
  return request({
    url: '/inside/createWarehouse',
    method: 'post',
    data: data
  });
}
export function updateWarehouse(data) {
  return request({
    url: '/inside/updateWarehouse',
    method: 'put',
    data: data
  });
}
export function deleteWarehouse(data) {
  return request({
    url: '/inside/deleteWarehouse',
    method: 'delete',
    data: data
  });
}
export function deleteWarehouseByIds(data) {
  return request({
    url: '/inside/deleteWarehouseByIds',
    method: 'delete',
    data: data
  });
} // 查找人员仓库权限

export function findUserWarehouse(params) {
  return request({
    url: '/inside/findUserWarehouse',
    method: 'get',
    params: params
  });
} // 设置人员仓库权限

export function updateUserWarehouse(data) {
  return request({
    url: '/inside/setUserWarehouse',
    method: 'put',
    data: data
  });
}