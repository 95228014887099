import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listWarehouse } from '@/api/warehouse.js';
import { listStockCheck, listStockCheckDetail } from '@/api/stock.js';
import dayjs from 'dayjs';
import YTable from '@/views/components/drag-table/table.vue';
import { listDocumentStatus, listInventoryType } from '@/api/dict.js';
export default {
  name: 'stock-inventory-price',
  components: {
    YTable: YTable
  },
  data: function data() {
    var _this = this;

    return {
      orderLoading: false,
      detailLoading: false,
      searchForm: {
        page: 1,
        pageSize: 5,
        total: 0,
        warehouseId: null,
        dateRange: [],
        inventoryTimeStart: null,
        inventoryTimeEnd: null,
        documentStatus: '',
        inventoryDocumentNo: null
      },
      detailForm: {
        page: 1,
        pageSize: 5,
        total: 0,
        countingId: 0
      },
      orderList: [],
      detailList: [],
      orderListRowIndex: -1,
      inventoryTypeList: listInventoryType(),
      documentStatusList: listDocumentStatus(),
      warehouseList: [],
      searchFormExpand: false,
      columns: [{
        label: '盘存单号',
        prop: 'inventoryDocumentNo',
        checked: true,
        width: 200
      }, {
        label: '单据状态',
        prop: 'documentStatus',
        checked: true,
        width: 100,
        formatter: function formatter(val) {
          var findRow = _this.documentStatusList.find(function (item) {
            return item.ID === val;
          });

          if (findRow) {
            return '<a class="el-link el-link--' + findRow.type + '"><span class="el-link--inner">' + findRow.name + '</span></a>';
          }
        }
      }, {
        label: '盘存类型',
        prop: 'inventoryType',
        checked: true,
        width: 100,
        formatter: function formatter(val) {
          var findRow = _this.inventoryTypeList.find(function (item) {
            return item.ID === val;
          });

          if (findRow) {
            return findRow.name;
          }
        }
      }, {
        label: '库房',
        prop: 'warehouseName',
        checked: true,
        width: 200
      }, {
        label: '盘存人',
        prop: 'inventorier',
        checked: true,
        width: 100
      }, {
        label: '盘存时间',
        prop: 'inventoryTime',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '审核人',
        prop: 'nameReviewer',
        checked: true,
        width: 100
      }, {
        label: '审核时间',
        prop: 'auditTime',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '记账人',
        prop: 'bookkeeper',
        checked: true,
        width: 100
      }, {
        label: '记账时间',
        prop: 'postingDate',
        checked: true,
        width: 150,
        type: 'time'
      }, {
        label: '操作',
        prop: 'operations',
        checked: true,
        width: '100',
        fixed: 'right',
        slot: 'operations',
        dragIgnore: true,
        fixedWidth: true
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadWarehouse();
      that.loadData();
    },
    loadData: function loadData() {
      var that = this;
      that.orderListRowIndex = -1;
      var form = JSON.parse(JSON.stringify(that.searchForm));

      if (form.dateRange) {
        if (form.dateRange[0] && form.dateRange[1]) {
          form.inventoryTimeStart = form.dateRange[0];
          form.inventoryTimeEnd = form.dateRange[1];
        }

        delete form.dateRange;
      }

      that.orderLoading = true;
      that.orderList = [];
      that.detailList = [];
      listStockCheck(form).then(function (res) {
        that.orderList = res.list;
        that.searchForm.total = res.total;
      }).finally(function () {
        that.orderLoading = false;
      });
    },
    formSubmit: function formSubmit() {
      var that = this;
      that.searchForm.page = 1;
      that.searchForm.total = 0;
      that.loadData();
    },
    searchPageChange: function searchPageChange(page) {
      var that = this;
      that.searchForm.page = page;
      that.loadData();
    },
    orderRowClick: function orderRowClick(row) {
      var that = this;
      that.orderListRowIndex = row.rowIndex;
      that.detailForm.page = 1;
      that.detailForm.countingId = row.ID;
      that.loadDetail();
    },
    orderRowDbClick: function orderRowDbClick(row) {
      var that = this;
      that.orderRowClick(row);
      that.orderEdit(row);
    },
    loadDetail: function loadDetail() {
      var that = this;
      that.detailList = [];
      that.detailLoading = true;
      listStockCheckDetail(that.detailForm).then(function (res) {
        that.detailList = res.list;
        that.detailForm.total = res.total;
      }).finally(function () {
        that.detailLoading = false;
      });
    },
    detailPageChange: function detailPageChange(page) {
      var that = this;
      that.detailForm.page = page;
      that.loadDetail();
    },
    orderEditButtonClick: function orderEditButtonClick() {
      var that = this;
      var row = that.orderList[that.orderListRowIndex];
      that.orderEdit(row);
    },
    // orderInvalidButtonClick () {
    //   const that = this
    //   var row = that.orderList[that.orderListRowIndex]
    //   var form = {
    //     id: row.ID
    //   }
    //   that.$confirm('确定要作废吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     deleteStockCheck(form).then((res) => {
    //       that.$notify({
    //         message: '作废成功',
    //         type: 'success'
    //       })
    //       that.loadData()
    //     })
    //   })
    // },
    orderCreate: function orderCreate() {
      var that = this;
      that.$router.push({
        path: '/stock/inventory/price/detail/0/add'
      });
    },
    orderEdit: function orderEdit(row) {
      var that = this;
      that.$router.push({
        path: '/stock/inventory/price/detail/' + row.ID + '/edit'
      });
    },
    reload: function reload() {
      var that = this;
      that.orderList = [];
      that.detailList = [];
      that.detailForm.page = 1;
      that.detailForm.total = 0;
      that.orderListRowIndex = -1;
      that.loadData();
    },
    loadWarehouse: function loadWarehouse() {
      var that = this;
      listWarehouse({
        page: 1,
        pageSize: 9999,
        isVirtualWarehouse: 1
      }).then(function (res) {
        that.warehouseList = res.list;
      });
    },
    orderListFormat: function orderListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        var item = row;
        item.rowIndex = index;
        item.accountTime = item.accountTime ? dayjs(item.accountTime).format('YYYY-MM-DD HH:mm:ss') : '';
        item.examineTime = item.examineTime ? dayjs(item.examineTime).format('YYYY-MM-DD HH:mm:ss') : '';
        resultRows.push(item);
      });
      return resultRows;
    },
    // 格式化明细列表
    detailListFormat: function detailListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        resultRows.push({
          rowIndex: index,
          ID: row.ID
        });
      });
      return resultRows;
    },
    documentStatusFormatter: function documentStatusFormatter(status) {
      var that = this;
      var find = that.documentStatusList.filter(function (item) {
        return item.ID === status;
      });

      if (find.length > 0) {
        return '<span class="el-tag el-tag--small el-tag--light el-tag--' + find[0].type + '">' + find[0].name + '</span>';
      }
    },
    inventoryTypeFormatter: function inventoryTypeFormatter(type) {
      if (type === 1) {
        return '按规格';
      } else if (type === 2) {
        return '按批号';
      }
    },
    timeFormatter: function timeFormatter(t) {
      if (t) {
        return dayjs(t).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
    },
    rowClassName: function rowClassName(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;
      row.rowIndex = rowIndex;
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    },
    searchSizeChange: function searchSizeChange(val) {
      var that = this;
      that.searchForm.page = 1;
      that.searchForm.pageSize = val;
      that.loadData();
    },
    pageSizeChange: function pageSizeChange(val) {
      var that = this;
      that.pageForm.page = 1;
      that.pageForm.pageSize = val;
      that.loadDetail();
    }
  }
};