var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("螺钉盒转移")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { height: "100%" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  height: "100%",
                  width: "100%",
                  border: "1px solid #CFD7E5",
                  position: "relative"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      "border-right": "1px solid #EBF1F6",
                      display: "flex",
                      "flex-direction": "column",
                      "justify-content": "flex-start"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "border-bottom": "1px solid #CFD7E5",
                          background: "#EBF1F6",
                          padding: "5px"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", icon: "el-icon-plus" },
                            on: {
                              click: function($event) {
                                return _vm.selectBox("A")
                              }
                            }
                          },
                          [_vm._v("选择盒子")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box-list" },
                      _vm._l(_vm.boxListA, function(item, index) {
                        return _c(
                          "table",
                          {
                            key: "box-" + index,
                            staticStyle: {
                              border: "1px solid #EBF1F6",
                              margin: "10px 0",
                              width: "100%"
                            }
                          },
                          [
                            _c("caption", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "tr",
                              { staticStyle: { height: "30px" } },
                              [
                                _c("td"),
                                _vm._l(item.xLabel, function(xItem, xIndex) {
                                  return _c("td", { key: xIndex }, [
                                    _vm._v(
                                      " " + _vm._s(item.xLabel[xIndex]) + " "
                                    )
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm._l(item.yLabel, function(yItem, yIndex) {
                              return _c(
                                "tr",
                                {
                                  key: yIndex,
                                  staticStyle: { height: "30px" }
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(item.yLabel[yIndex]) + " "
                                    )
                                  ]),
                                  _vm._l(item.xLabel, function(xItem, xIndex) {
                                    return _c(
                                      "td",
                                      {
                                        key: xIndex,
                                        attrs: { align: "center" }
                                      },
                                      [
                                        !_vm.takenCheckA(item, xIndex, yIndex)
                                          ? _c("i", {
                                              class:
                                                "fa fa-icon fa-circle-o" +
                                                (_vm.currentPosA.id ===
                                                  item.ID &&
                                                _vm.currentPosA.x === xIndex &&
                                                _vm.currentPosA.y === yIndex
                                                  ? " fa-selected"
                                                  : ""),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.posClickA(
                                                    item,
                                                    xIndex,
                                                    yIndex
                                                  )
                                                }
                                              }
                                            })
                                          : _c("i", {
                                              class:
                                                "fa fa-icon fa-plus-circle text-primary" +
                                                (_vm.currentPosA.id ===
                                                  item.ID &&
                                                _vm.currentPosA.x === xIndex &&
                                                _vm.currentPosA.y === yIndex
                                                  ? " fa-selected"
                                                  : ""),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function(e) {
                                                  return _vm.posViewA(
                                                    item,
                                                    xIndex,
                                                    yIndex,
                                                    e
                                                  )
                                                }
                                              }
                                            })
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { width: "0px", position: "relative" } },
                  [
                    _c("i", {
                      staticClass: "fa fa-exchange",
                      staticStyle: {
                        position: "absolute",
                        cursor: "pointer",
                        top: "50%",
                        width: "50px",
                        height: "50px",
                        "font-size": "25px",
                        "border-radius": "50%",
                        background: "#5287B1",
                        color: "#fff",
                        left: "-25px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center"
                      },
                      attrs: { "aria-hidden": "true", title: "点击转移" },
                      on: { click: _vm.transferConfirm }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "1",
                      "border-right": "1px solid #EBF1F6",
                      display: "flex",
                      "flex-direction": "column",
                      "justify-content": "flex-start"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "border-bottom": "1px solid #CFD7E5",
                          background: "#EBF1F6",
                          padding: "5px"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", icon: "el-icon-plus" },
                            on: {
                              click: function($event) {
                                return _vm.selectBox("B")
                              }
                            }
                          },
                          [_vm._v("选择盒子")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "box-list" },
                      _vm._l(_vm.boxListB, function(item, index) {
                        return _c(
                          "table",
                          {
                            key: "box-" + index,
                            staticStyle: {
                              border: "1px solid #EBF1F6",
                              margin: "10px 0",
                              width: "100%"
                            }
                          },
                          [
                            _c("caption", [_vm._v(_vm._s(item.name))]),
                            _c(
                              "tr",
                              { staticStyle: { height: "30px" } },
                              [
                                _c("td"),
                                _vm._l(item.xLabel, function(xItem, xIndex) {
                                  return _c("td", { key: xIndex }, [
                                    _vm._v(
                                      " " + _vm._s(item.xLabel[xIndex]) + " "
                                    )
                                  ])
                                })
                              ],
                              2
                            ),
                            _vm._l(item.yLabel, function(yItem, yIndex) {
                              return _c(
                                "tr",
                                {
                                  key: yIndex,
                                  staticStyle: { height: "30px" }
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      " " + _vm._s(item.yLabel[yIndex]) + " "
                                    )
                                  ]),
                                  _vm._l(item.xLabel, function(xItem, xIndex) {
                                    return _c(
                                      "td",
                                      {
                                        key: xIndex,
                                        attrs: { align: "center" }
                                      },
                                      [
                                        !_vm.takenCheckB(item, xIndex, yIndex)
                                          ? _c("i", {
                                              class:
                                                "fa fa-icon fa-circle-o" +
                                                (_vm.currentPosB.id ===
                                                  item.ID &&
                                                _vm.currentPosB.x === xIndex &&
                                                _vm.currentPosB.y === yIndex
                                                  ? " fa-selected"
                                                  : ""),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.posClickB(
                                                    item,
                                                    xIndex,
                                                    yIndex
                                                  )
                                                }
                                              }
                                            })
                                          : _c("i", {
                                              class:
                                                "fa fa-icon fa-plus-circle text-primary" +
                                                (_vm.currentPosB.id ===
                                                  item.ID &&
                                                _vm.currentPosB.x === xIndex &&
                                                _vm.currentPosB.y === yIndex
                                                  ? " fa-selected"
                                                  : ""),
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function(e) {
                                                  return _vm.posViewB(
                                                    item,
                                                    xIndex,
                                                    yIndex,
                                                    e
                                                  )
                                                }
                                              }
                                            })
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            ),
            _c(
              "el-popover",
              {
                ref: "consumInfoPopover",
                attrs: {
                  placement: "right",
                  title: "物资详情",
                  width: "450",
                  trigger: "click"
                },
                model: {
                  value: _vm.consumInfoPopoverVisible,
                  callback: function($$v) {
                    _vm.consumInfoPopoverVisible = $$v
                  },
                  expression: "consumInfoPopoverVisible"
                }
              },
              [
                _c("i", {
                  staticClass: "el-icon-close",
                  staticStyle: {
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    cursor: "pointer"
                  },
                  on: { click: _vm.consumInfoPopoverClose }
                }),
                _vm.currentConsumInfo.consumInfo
                  ? _c(
                      "el-row",
                      {
                        staticStyle: { "flex-wrap": "wrap" },
                        attrs: { type: "flex" }
                      },
                      [
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("产品编号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs
                                .productNumber
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("产品名称：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.main.productName
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("生产批号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .batch_number
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("规格型号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs
                                .specificationModel
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("注册证号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.main
                                .registrationNumber
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("生产日期：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .manufacturer_date
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("使用期限：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .validity_date
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("序列号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .trackingCode
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("产品材料：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs.material
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("灭菌方式：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs
                                .sterilizationWays
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("灭菌批号：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .sterilization_batch_number
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("灭菌效期：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.inventory
                                .sterilization_date
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("包装方式：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs.packNum
                            ) +
                              _vm._s(
                                _vm.currentConsumInfo.consumInfo.specs.unit
                              ) +
                              "/" +
                              _vm._s(
                                _vm.currentConsumInfo.consumInfo.specs.packUnit
                              )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("条码主码：")
                        ]),
                        _c("el-col", { attrs: { span: 8 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.specs.barCode
                            )
                          )
                        ]),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _vm._v("生产厂家：")
                        ]),
                        _c("el-col", { attrs: { span: 20 } }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentConsumInfo.consumInfo.main.manufacturer
                            )
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ],
      _c("SelectBoxComponents", {
        ref: "SelectBoxComponents",
        on: { confirm: _vm.confirmBox }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }