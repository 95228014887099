import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js'; // 字典列表

export function listDictionary(params) {
  return request({
    url: '/dictErp/getDictList',
    method: 'get',
    params: params
  });
} // 查找字典

export function findDictionary(params) {
  return request({
    url: '/dictErp/findDict',
    method: 'get',
    params: params
  });
} // 创建字典

export function createDictionary(data) {
  return request({
    url: '/dictErp/createDict',
    method: 'post',
    data: data
  });
} // 更新字典

export function updateDictionary(data) {
  return request({
    url: '/dictErp/updateDict',
    method: 'put',
    data: data
  });
} // 删除字典

export function deleteDictionary(data) {
  return request({
    url: '/dictErp/deleteDict',
    method: 'delete',
    data: data
  });
}