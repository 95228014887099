import { request } from '@/api/_service.js';
export function listTradeOrder(params) {
  return request({
    url: '/tradeOrderErp/getErpTradeOrderList',
    method: 'get',
    params: params
  });
}
export function listTradeOrderExport(params) {
  return request({
    url: '/tradeOrderErp/getErpTradeOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findTradeOrder(params) {
  return request({
    url: '/tradeOrderErp/findErpTradeOrder',
    method: 'get',
    params: params
  });
}
export function createTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/createErpTradeOrder',
    method: 'post',
    data: data
  });
}
export function updateTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/updateErpTradeOrder',
    method: 'put',
    data: data
  });
}
export function checkTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/updateErpTradeOrderStatus',
    method: 'put',
    data: data
  });
}
export function deleteTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/deleteErpTradeOrder',
    method: 'delete',
    data: data
  });
}
export function createTradeOrderDetail(data) {
  return request({
    url: '/tradeDetailErp/createErpTradeDetail',
    method: 'post',
    data: data
  });
}
export function updateTradeOrderDetail(data) {
  return request({
    url: '/tradeDetailErp/updateErpTradeDetail',
    method: 'put',
    data: data
  });
}
export function deleteTradeOrderDetail(data) {
  return request({
    url: '/tradeDetailErp/deleteErpTradeDetail',
    method: 'delete',
    data: data
  });
}
export function deleteTradeOrderDetailByIds(data) {
  return request({
    url: '/tradeDetailErp/deleteErpTradeDetailByIds',
    method: 'delete',
    data: data
  });
}
export function findTradeOrderDetail(params) {
  return request({
    url: '/tradeDetailErp/findErpTradeDetail',
    method: 'get',
    params: params
  });
}
export function listTradeOrderDetail(params) {
  return request({
    url: '/tradeDetailErp/getErpTradeDetailList',
    method: 'get',
    params: params
  });
}
export function uploadExcel(data) {
  return request({
    url: '/tradeDetailErp/uploadExcel',
    method: 'post',
    data: data
  });
}
export function downloadTradeDetailTemplate(params) {
  return request({
    url: '/tradeDetailErp/getErpTradeDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listClient(params) {
  return request({
    url: '/clientErp/getErpDealerClientList',
    method: 'get',
    params: params
  });
}
export function downloadExcelTemplate(params) {
  return request({
    url: '/tradeDetailErpNew/getErpTradeDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listSalesGroup(params) {
  return request({
    url: '/sale/getSalesListGroup',
    method: 'get',
    params: params
  });
}
export function listSalesInfoGroup(params) {
  return request({
    url: '/sale/getSalesInfoGroup',
    method: 'get',
    params: params
  });
}
export function createPlatformErpTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/createPlatformErpTradeOrder',
    method: 'post',
    data: data
  });
}
export function listPlatformErpTradeOrder(params) {
  return request({
    url: '/tradeOrderErp/getPlatformErpTradeOrderList',
    method: 'get',
    params: params
  });
}
export function deletePlatformErpTradeOrder(data) {
  return request({
    url: '/tradeOrderErp/deletePlatformErpTradeOrder',
    method: 'delete',
    data: data
  });
}
export function listPlatformErpTradeOrderDetail(params) {
  return request({
    url: '/tradeDetailErp/getPlatformErpTradeDetailList',
    method: 'get',
    params: params
  });
}