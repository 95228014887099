var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            size: "small",
            "label-width": "100px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型ID", prop: "typeId" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.itemForm.typeId,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "typeId", $$v)
                          },
                          expression: "itemForm.typeId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型说明", prop: "typeDescription" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.itemForm.typeDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "typeDescription", $$v)
                          },
                          expression: "itemForm.typeDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "代码文字", prop: "codeText" } },
                    [
                      _c("el-input", {
                        on: { change: _vm.toPinyinCode },
                        model: {
                          value: _vm.itemForm.codeText,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "codeText", $$v)
                          },
                          expression: "itemForm.codeText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "拼音码", prop: "pinyinCode" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.itemForm.pinyinCode,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "pinyinCode", $$v)
                          },
                          expression: "itemForm.pinyinCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "代码顺序", prop: "codeSequence" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.itemForm.codeSequence,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "codeSequence", $$v)
                          },
                          expression: "itemForm.codeSequence"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "代码描述", prop: "codeDescription" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.itemForm.codeDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "codeDescription", $$v)
                          },
                          expression: "itemForm.codeDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }