import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    form: {
      type: Object,
      default: {}
    },
    value: {
      type: String
    }
  },
  data: function data() {
    return {
      errorMsg: "",
      blockNumber: {}
    };
  },
  mounted: function mounted() {
    this.init();
    this.resetBlock();
  },
  watch: {
    form: function form() {
      this.resetBlock();
    }
  },
  methods: {
    init: function init() {
      this.initBlockNumber();
    },
    initBlockNumber: function initBlockNumber() {
      this.blockNumber = {
        blockNumber0: {
          blockEmpty: "",
          blockHead: "",
          blockBody: ""
        },
        blockNumber1: {
          blockEmpty: "",
          blockHead: "",
          blockBody: ""
        },
        blockNumber2: {
          blockEmpty: "",
          blockHead: "",
          blockBody: ""
        },
        blockNumber3: {
          blockEmpty: "",
          blockHead: "",
          blockBody: ""
        }
      };
    },
    resetBlock: function resetBlock() {
      var moment = require('moment');

      var that = this;
      that.initBlockNumber();
      var blockList = [];

      if (that.form) {
        if (that.form.manufacturerDateIdent && that.form.manufacturerDateIdent.length > 1 && that.form.manufacturerDateIdentPos && that.form.manufacturerDateIdentPos > 0) {
          blockList.push({
            start: Number(that.form.manufacturerDateIdentPos),
            end: Number(that.form.manufacturerDateIdentPos) + Number(that.form.manufacturerDateIdent.length) - 1,
            form: that.form.manufacturerDateIdent
          });
        }

        if (that.form.manufacturerDateLength && that.form.manufacturerDateLength > 0 && that.form.manufacturerDateIdentPos && that.form.manufacturerDateIdentPos > 0) {
          try {
            if (that.form.manufacturerDateIdent && that.form.manufacturerDateIdent.length > 1) {
              blockList.push({
                start: Number(that.form.manufacturerDateIdentPos) + Number(that.form.manufacturerDateIdent.length),
                end: Number(that.form.manufacturerDateIdentPos) + Number(that.form.manufacturerDateIdent.length) + Number(that.form.manufacturerDateLength) - 1,
                form: moment().format(that.form.manufacturerDateFormat).substring(0, that.form.manufacturerDateLength)
              });
            }
          } catch (e) {
            that.notifySupper(e);
            return;
          }
        }

        if (that.form.validityDateIdent && that.form.validityDateIdent.length > 1 && that.form.validityDateIdentPos && that.form.validityDateIdentPos > 0) {
          blockList.push({
            start: Number(that.form.validityDateIdentPos),
            end: Number(that.form.validityDateIdentPos) + Number(that.form.validityDateIdent.length) - 1,
            form: that.form.validityDateIdent
          });
        }

        if (that.form.validityDateLength && that.form.validityDateLength > 0 && that.form.validityDateIdentPos && that.form.validityDateIdentPos > 0) {
          try {
            if (that.form.validityDateIdent && that.form.validityDateIdent.length > 1) {
              blockList.push({
                start: Number(that.form.validityDateIdentPos) + Number(that.form.validityDateIdent.length),
                end: Number(that.form.validityDateIdentPos) + Number(that.form.validityDateIdent.length) + Number(that.form.validityDateLength) - 1,
                form: moment().format(that.form.validityDateFormat).substring(0, that.form.validityDateLength)
              });
            }
          } catch (e) {
            that.notifySupper(e);
            return;
          }
        }

        if (that.form.batchNumberIdenti && that.form.batchNumberIdenti.length > 1 && that.form.batchNumberIdentiPos && that.form.batchNumberIdentiPos > 0) {
          blockList.push({
            start: Number(that.form.batchNumberIdentiPos),
            end: Number(that.form.batchNumberIdentiPos) + Number(that.form.batchNumberIdenti.length) - 1,
            form: that.form.batchNumberIdenti
          });
        }

        if (that.form.batchNumberLength && that.form.batchNumberLength > 0 && that.form.batchNumberIdentiPos && that.form.batchNumberIdentiPos > 0) {
          try {
            if (that.form.batchNumberIdenti && that.form.batchNumberIdenti.length > 1) {
              blockList.push({
                start: Number(that.form.batchNumberIdentiPos) + Number(that.form.batchNumberIdenti.length),
                end: Number(that.form.batchNumberIdentiPos) + Number(that.form.batchNumberIdenti.length) + Number(that.form.batchNumberLength) - 1,
                form: that.createNumber(that.form.batchNumberLength)
              });
            }
          } catch (e) {
            that.notifySupper(e);
            return;
          }
        }

        if (that.form.trackingCodeIdent && that.form.trackingCodeIdent.length > 1 && that.form.trackingCodeIdentPos && that.form.trackingCodeIdentPos > 0) {
          blockList.push({
            start: Number(that.form.trackingCodeIdentPos),
            end: Number(that.form.trackingCodeIdentPos) + Number(that.form.trackingCodeIdent.length) - 1,
            form: that.form.trackingCodeIdent
          });
        }

        if (that.form.trackingCodeLength && that.form.trackingCodeLength > 0 && that.form.trackingCodeIdentPos && that.form.trackingCodeIdentPos > 0) {
          try {
            if (that.form.trackingCodeIdent && that.form.trackingCodeIdent.length > 1) {
              blockList.push({
                start: Number(that.form.trackingCodeIdentPos) + Number(that.form.trackingCodeIdent.length),
                end: Number(that.form.trackingCodeIdentPos) + Number(that.form.trackingCodeIdent.length) + Number(that.form.trackingCodeLength) - 1,
                form: that.createNumber(that.form.trackingCodeLength)
              });
            }
          } catch (e) {
            that.notifySupper(e);
            return;
          }
        }

        blockList.sort(function (a, b) {
          return a.start - b.start;
        });
        var startIndex = 0;
        var endIndex = 0;

        for (var index = 0; index < blockList.length; index++) {
          var item = blockList[index];

          if (startIndex >= item.start) {
            that.notifySupper('请检查条码样例，位置：' + item.start);
            return;
          }

          if (endIndex >= item.end) {
            that.notifySupper('请检查条码样例，位置：' + item.end);
            return;
          }

          if (item.start <= endIndex) {
            that.notifySupper('请检查条码样例，位置：' + item.start + ' - ' + item.end);
            return;
          }

          if (item.start - endIndex > 1) {
            item.blockEmpty = that.createBarCode(item.start - endIndex);
          }

          startIndex = item.start;
          endIndex = item.end;

          if (startIndex >= endIndex) {
            that.notifySupper('请检查条码样例，位置：' + item.start + ' - ' + item.end);
            return;
          }

          if (index % 2 == 0) {
            if (item.blockEmpty) {
              that.blockNumber['blockNumber' + Math.floor(index / 2)].blockEmpty = item.blockEmpty;
            }

            that.blockNumber['blockNumber' + Math.floor(index / 2)].blockHead = item.form;
          } else {
            if (item.blockEmpty) {
              that.blockNumber['blockNumber' + Math.floor(index / 2)].blockEmpty = item.blockEmpty;
            }

            that.blockNumber['blockNumber' + Math.floor(index / 2)].blockBody = item.form;
          }
        }
      }

      that.notifySupper("");
    },
    createNumber: function createNumber(pos) {
      var resultPos = 0;

      if (pos && pos > 0) {
        for (var i = 0; i < pos; i++) {
          resultPos = resultPos * 10 + 1;
        }
      }

      return resultPos > 0 ? resultPos.toString() : "";
    },
    createBarCode: function createBarCode(pos) {
      var form = "";

      for (var i = 1; i < pos; i++) {
        form = "*" + form;
      }

      return form;
    },
    notifySupper: function notifySupper(errorMessage) {
      var that = this;
      that.errorMsg = errorMessage;
      that.$nextTick(function () {
        that.$emit("update:errorMsg", that.errorMsg);
        that.$emit("input", that.$refs.sampleBarcode.innerText);
        that.$emit("change", that.$refs.sampleBarcode.innerText);
      });
    }
  }
};