import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
export default {
  computed: {
    getPosition: function getPosition() {
      var position = this.cssSupport('position', 'sticky') ? 'sticky' : 'relative';
      return 'position:' + position;
    }
  },
  mounted: function mounted() {
    this.init();
  },
  deactivated: function deactivated() {
    if (this.cssSupport('position', 'sticky')) {
      return;
    } // 复位


    var elWarp = this.$el.querySelector('.sticky-warp');
    elWarp.position = 'absolute';
  },
  methods: {
    init: function init() {
      if (this.cssSupport('position', 'sticky')) {
        return;
      }

      var el = this.$el;
      var target = this.$el.parentNode;
      var elWarp = this.$el.querySelector('.sticky-warp');
      var top = this.getNumberValue(document.defaultView.getComputedStyle(el).top);
      this.addScrollListen(target, function () {
        if (el.getBoundingClientRect().top <= top) {
          elWarp.style.position = 'fixed';
        }

        if (el.getBoundingClientRect().top >= 0 && elWarp.style.position !== 'absolute') {
          elWarp.style.position = 'absolute';
        }
      });
    },
    cssSupport: function cssSupport(attr, value) {
      var element = document.createElement('div');

      if (attr in element.style) {
        element.style[attr] = value;
        return element.style[attr] === value;
      } else {
        return false;
      }
    },
    getNumberValue: function getNumberValue(pxValue) {
      var value = String(pxValue).match(/^\\-?\+?[0-9]+/g);
      return value ? Number(value) : undefined;
    },
    addScrollListen: function addScrollListen(target, cb) {
      target.addEventListener('y-scroll', function (event) {
        cb && cb(event);
      });
    }
  }
};