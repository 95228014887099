//
//
//
//
import FormComponent from './form.vue';
export default {
  name: 'stock-outwarehouse-consignment-create',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    FormComponent: FormComponent
  }
};