var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true,
        fullscreen: _vm.dialogFullScreen
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "custom_dialog_header" }, [
          _c("span", { staticClass: "el_dialog_title" }, [
            _vm._v(_vm._s(_vm.dialogTitle))
          ]),
          _c(
            "div",
            {
              staticClass: "custom_dialog_menu",
              on: {
                click: function($event) {
                  _vm.dialogFullScreen = !_vm.dialogFullScreen
                }
              }
            },
            [
              !_vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-maximize",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e(),
              _vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-restore",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ]
          )
        ])
      ]),
      _c("YTable", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.dataLoading,
            expression: "dataLoading"
          }
        ],
        ref: "dragTableRef",
        attrs: {
          hasPagination: true,
          hasIndex: true,
          pagination: _vm.form,
          "route-name": _vm.$route.name + "-detail",
          columns: _vm.columns,
          data: _vm.dataList
        }
      }),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "div",
          { attrs: { align: "center" } },
          [
            _c("el-button", { on: { click: _vm.dialogClose } }, [
              _vm._v("关 闭")
            ])
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }