import { render, staticRenderFns } from "./report.vue?vue&type=template&id=4f8d4624&scoped=true&"
import script from "./report.vue?vue&type=script&lang=js&"
export * from "./report.vue?vue&type=script&lang=js&"
import style0 from "./report.vue?vue&type=style&index=0&id=4f8d4624&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8d4624",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP前端\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4f8d4624')) {
      api.createRecord('4f8d4624', component.options)
    } else {
      api.reload('4f8d4624', component.options)
    }
    module.hot.accept("./report.vue?vue&type=template&id=4f8d4624&scoped=true&", function () {
      api.rerender('4f8d4624', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/stock/detail/components/upload/report.vue"
export default component.exports