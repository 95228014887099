import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listBox, deleteConsumInfo, createConsumInfo } from '@/api/packages.js';
import SelectBoxComponents from './components/select_box/index.vue';
export default {
  name: 'stock-box-transfer',
  components: {
    SelectBoxComponents: SelectBoxComponents
  },
  data: function data() {
    return {
      boxListA: [],
      boxListB: [],
      currentPosA: {},
      currentPosB: {},
      currentConsumInfo: {},
      consumInfoPopoverVisible: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
    },
    selectBox: function selectBox(target) {
      var that = this;
      that.$refs.SelectBoxComponents.init(target);
    },
    confirmBox: function confirmBox(val) {
      var that = this;
      listBox({
        page: 1,
        pageSize: 9999,
        packId: val.boxInfo.ID
      }).then(function (res) {
        if (val.selectBoxType === 'A') {
          // 左侧盒子
          that.boxListA = [];
          that.currentPosA = {};
        } else {
          // 右侧的盒子
          that.boxListB = [];
          that.currentPosB = {};
        }

        res.list.forEach(function (row) {
          row.xLabel = row.xLabel.split(',').map(String);
          row.yLabel = row.yLabel.split(',').map(String);

          if (val.selectBoxType === 'A') {
            // 左侧盒子
            that.boxListA.push(row);
          } else {
            // 右侧的盒子
            that.boxListB.push(row);
          }
        });
      });
    },
    loadBoxA: function loadBoxA() {
      var that = this;
      that.boxListA = [];
      listBox({
        page: 1,
        pageSize: 9999,
        packId: that.currentPosA.packId
      }).then(function (res) {
        res.list.forEach(function (row) {
          row.xLabel = row.xLabel.split(',').map(String);
          row.yLabel = row.yLabel.split(',').map(String);
          that.boxListA.push(row);
        });
        that.currentPosA = {};
      });
    },
    loadBoxB: function loadBoxB() {
      var that = this;
      that.boxListB = [];
      listBox({
        page: 1,
        pageSize: 9999,
        packId: that.currentPosB.packId
      }).then(function (res) {
        res.list.forEach(function (row) {
          row.xLabel = row.xLabel.split(',').map(String);
          row.yLabel = row.yLabel.split(',').map(String);
          that.boxListB.push(row);
        });
        that.currentPosB = {};
      });
    },
    // 转移
    transferConfirm: function transferConfirm() {
      var that = this; // A点向B点转移

      if (that.currentPosA.consumInfo && !that.currentPosB.consumInfo && that.currentPosB.id) {
        // 删除A点
        deleteConsumInfo({
          id: that.currentPosA.consumInfo.ID
        }).then(function (res) {
          var form = {
            inventoryId: that.currentPosA.consumInfo.inventoryId,
            moduleId: that.currentPosB.id,
            packId: that.currentPosB.packId,
            quantityUsed: 1,
            remarks: '',
            x: that.currentPosB.x,
            y: that.currentPosB.y
          }; // 创建B点

          createConsumInfo(form).then(function (res) {
            that.$notify.success('转移成功');
            that.loadBoxA();
            that.loadBoxB();
          });
        });
      } else if (that.currentPosB.consumInfo && !that.currentPosA.consumInfo && that.currentPosA.id) {
        // 删除A点
        deleteConsumInfo({
          id: that.currentPosB.consumInfo.ID
        }).then(function (res) {
          var form = {
            inventoryId: that.currentPosB.consumInfo.inventoryId,
            moduleId: that.currentPosA.id,
            packId: that.currentPosA.packId,
            quantityUsed: 1,
            remarks: '',
            x: that.currentPosA.x,
            y: that.currentPosA.y
          }; // 创建B点

          createConsumInfo(form).then(function (res) {
            that.$notify.success('转移成功');
            that.loadBoxA();
            that.loadBoxB();
          });
        });
      } else if (that.currentPosB.id && !that.currentPosB.consumInfo && !that.currentPosA.consumInfo) {
        that.$message.error('当前选择的两个位置都是空的');
      } else if (that.currentPosB.id && that.currentPosB.consumInfo && that.currentPosA.consumInfo) {
        that.$message.error('当前选择的两个位置都是非空');
      } else {
        that.$message.error('请选择盒子并选中要转移的孔位');
      }
    },
    // 检测孔洞是否被物资占用
    takenCheckA: function takenCheckA(item, x, y) {
      var that = this;
      var result = null;
      that.boxListA.forEach(function (row, index) {
        if (row.ID === item.ID) {
          var find = row.consumInfo.filter(function (row1) {
            return row1.x === x && row1.y === y;
          });

          if (find.length > 0) {
            result = find[0];
          } else {
            result = null;
          }
        }
      });
      return result;
    },
    takenCheckB: function takenCheckB(item, x, y) {
      var that = this;
      var result = null;
      that.boxListB.forEach(function (row, index) {
        if (row.ID === item.ID) {
          var find = row.consumInfo.filter(function (row1) {
            return row1.x === x && row1.y === y;
          });

          if (find.length > 0) {
            result = find[0];
          } else {
            result = null;
          }
        }
      });
      return result;
    },
    posClickA: function posClickA(item, x, y) {
      var that = this;
      that.currentPosA = {
        id: item.ID,
        packId: item.packId,
        x: x,
        y: y,
        consumInfo: null
      };
      that.consumInfoPopoverVisible = false;
    },
    posClickB: function posClickB(item, x, y) {
      var that = this;
      that.currentPosB = {
        id: item.ID,
        packId: item.packId,
        x: x,
        y: y,
        consumInfo: null
      };
      that.consumInfoPopoverVisible = false;
    },
    posViewA: function posViewA(item, x, y, e) {
      var that = this;
      that.currentConsumInfo = {
        id: item.ID,
        packId: item.packId,
        x: x,
        y: y,
        consumInfo: null
      };
      that.consumInfoPopoverVisible = false;
      that.boxListA.forEach(function (row) {
        if (item.ID === row.ID) {
          row.consumInfo.forEach(function (row1) {
            if (row1.x === x && row1.y === y) {
              that.currentConsumInfo.consumInfo = row1;
              that.currentPosA = that.currentConsumInfo;
              var consumInfoPopover = that.$refs.consumInfoPopover;
              consumInfoPopover.doDestroy(true);
              that.$nextTick(function () {
                that.consumInfoPopoverVisible = true;
                consumInfoPopover.referenceElm = e.target;
                consumInfoPopover.$refs.reference = e.target;
              });
            }
          });
        }
      });
    },
    posViewB: function posViewB(item, x, y, e) {
      var that = this;
      that.currentConsumInfo = {
        id: item.ID,
        packId: item.packId,
        x: x,
        y: y,
        consumInfo: null
      };
      that.consumInfoPopoverVisible = false;
      that.boxListB.forEach(function (row) {
        if (item.ID === row.ID) {
          row.consumInfo.forEach(function (row1) {
            if (row1.x === x && row1.y === y) {
              that.currentConsumInfo.consumInfo = row1;
              that.currentPosB = that.currentConsumInfo;
              var consumInfoPopover = that.$refs.consumInfoPopover;
              consumInfoPopover.doDestroy(true);
              that.$nextTick(function () {
                that.consumInfoPopoverVisible = true;
                consumInfoPopover.referenceElm = e.target;
                consumInfoPopover.$refs.reference = e.target;
              });
            }
          });
        }
      });
    },
    consumInfoPopoverClose: function consumInfoPopoverClose() {
      var that = this;
      that.consumInfoPopoverVisible = false;
    }
  }
};