var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("YTable", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.dataLoading,
                expression: "dataLoading"
              }
            ],
            ref: "dataTableRef",
            attrs: {
              showDragButton: false,
              hasPagination: true,
              hasDrag: false,
              hasIndex: true,
              pagination: _vm.form,
              "cache-name": _vm.$route.name + "detail",
              columns: _vm.columns,
              data: _vm.dataList.slice(
                (_vm.form.page - 1) * _vm.form.pageSize,
                _vm.form.page * _vm.form.pageSize
              )
            },
            on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }