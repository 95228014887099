var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("系统设置")]),
              _c("el-breadcrumb-item", [_vm._v("流程设置")]),
              _c("el-breadcrumb-item", [_vm._v("采购流程设置")])
            ],
            1
          ),
          _c("div")
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: { model: _vm.form, "label-width": "150px", size: "small" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "模式选择：", prop: "processMode" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.form.processMode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "processMode", $$v)
                      },
                      expression: "form.processMode"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("GSP流程模式")
                    ]),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("智能模式")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.form.processMode === 1
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "forwardDate" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            color: "#606266"
                          }
                        },
                        [
                          _vm._v(" 自动生成采购订单的日期提前 "),
                          _c("el-input", {
                            staticStyle: { margin: "0 10px", width: "100px" },
                            attrs: {
                              size: "mini",
                              onkeyup:
                                "this.value=this.value.replace(/\\D|^0/g,'')"
                            },
                            model: {
                              value: _vm.form.forwardDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "forwardDate", $$v)
                              },
                              expression: "form.forwardDate"
                            }
                          }),
                          _vm._v(" 天 ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "采购员：", prop: "buyerUid" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.buyerUid,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "buyerUid", $$v)
                            },
                            expression: "form.buyerUid"
                          }
                        },
                        _vm._l(_vm.usersList, function(item, idx) {
                          return _c("el-option", {
                            key: "buyerUid" + idx,
                            attrs: { label: item.name, value: item.uid }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "收货人：", prop: "consigneeUid" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.consigneeUid,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "consigneeUid", $$v)
                            },
                            expression: "form.consigneeUid"
                          }
                        },
                        _vm._l(_vm.usersList, function(item, idx) {
                          return _c("el-option", {
                            key: "consigneeUid" + idx,
                            attrs: { label: item.name, value: item.uid }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "验收人：", prop: "acceptedUid" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.acceptedUid,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "acceptedUid", $$v)
                            },
                            expression: "form.acceptedUid"
                          }
                        },
                        _vm._l(_vm.usersList, function(item, idx) {
                          return _c("el-option", {
                            key: "acceptedUid" + idx,
                            attrs: { label: item.name, value: item.uid }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "prevent-re-click",
                        rawName: "v-prevent-re-click"
                      }
                    ],
                    attrs: { type: "primary", disabled: _vm.loading },
                    on: { click: _vm.saveSettings }
                  },
                  [_vm._v("保存设置")]
                )
              ],
              1
            )
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }