import { request } from '@/api/_service.js'; // 首页相关接口

export function listWarning(params) {
  return request({
    url: '/agencyErp/warning',
    method: 'get',
    params: params
  });
}
export function listWarning2(params) {
  return request({
    url: '/agencyErp/warningV2',
    method: 'get',
    params: params
  });
} // 待办列表

export function listAgency(params) {
  return request({
    url: '/agencyErp/getAgencyList',
    method: 'get',
    params: params
  });
}
export function listAgencyV2(params) {
  return request({
    url: '/agencyErp/getAgencyListV2',
    method: 'get',
    params: params
  });
} // 待办详情

export function findAgency(params) {
  return request({
    url: '/agencyErp/findAgency',
    method: 'get',
    params: params
  });
} // 公告列表

export function listNotice(params) {
  return request({
    url: '/ergNoticeErp/getOrgNoticeList',
    method: 'get',
    params: params
  });
}
export function getAgencyNoRead(params) {
  return request({
    url: '/agencyErp/getAgencyNoRead',
    method: 'get',
    params: params
  });
}