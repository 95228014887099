var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "d2-grid-layout",
        _vm._b(
          { staticStyle: { height: "600px", overflow: "auto" } },
          "d2-grid-layout",
          _vm.layout,
          false
        ),
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                model: _vm.layout,
                rules: _vm.formRules,
                "label-width": "60px",
                size: "mini"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            _vm._l(_vm.layout.layout, function(item, index) {
              return _c(
                "d2-grid-item",
                _vm._b({ key: index }, "d2-grid-item", item, false),
                [
                  _c(
                    "el-card",
                    { staticClass: "page_card", attrs: { shadow: "never" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-between"
                          },
                          attrs: { slot: "header" },
                          slot: "header"
                        },
                        [
                          _c("div", [_vm._v(_vm._s(item.name))]),
                          _c(
                            "div",
                            [
                              _c(
                                "el-tag",
                                {
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.modeChange(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.mode === "FORM"
                                        ? "标签设置"
                                        : "盒子设置"
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-tag",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.itemRemove(index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      item.mode === "FORM"
                        ? [
                            _c(
                              "el-form-item",
                              {
                                key: "name" + index,
                                attrs: {
                                  label: "名称",
                                  prop: "layout." + index + ".name",
                                  rules: _vm.formRules.name
                                }
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: item.name,
                                    callback: function($$v) {
                                      _vm.$set(item, "name", $$v)
                                    },
                                    expression: "item.name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "尺寸", required: true } },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 10 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "",
                                              prop: "layout." + index + ".maxX",
                                              rules: _vm.formRules.maxX
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              on: {
                                                change: function($event) {
                                                  return _vm.maxChange(item)
                                                }
                                              },
                                              model: {
                                                value: item.maxX,
                                                callback: function($$v) {
                                                  _vm.$set(item, "maxX", $$v)
                                                },
                                                expression: "item.maxX"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4, align: "center" } },
                                      [_vm._v("x")]
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 10 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "",
                                              prop: "layout." + index + ".maxY",
                                              rules: _vm.formRules.maxY
                                            }
                                          },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value: item.maxY,
                                                callback: function($$v) {
                                                  _vm.$set(item, "maxY", $$v)
                                                },
                                                expression: "item.maxY"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注" } },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 2 },
                                  model: {
                                    value: item.remarks,
                                    callback: function($$v) {
                                      _vm.$set(item, "remarks", $$v)
                                    },
                                    expression: "item.remarks"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.itemInit(item)
                                      }
                                    }
                                  },
                                  [_vm._v("重新初始化标签")]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      item.mode === "CONFIG"
                        ? [
                            _c("div", { staticClass: "box" }, [
                              _c(
                                "table",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c(
                                    "tr",
                                    { staticStyle: { height: "30px" } },
                                    [
                                      _c("td"),
                                      _vm._l(item.xLabel, function(
                                        xItem,
                                        xIndex
                                      ) {
                                        return _c("td", { key: xIndex }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.xLabel[xIndex],
                                                expression:
                                                  "item.xLabel[xIndex]"
                                              }
                                            ],
                                            staticClass: "label-input",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: item.xLabel[xIndex]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item.xLabel,
                                                  xIndex,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._l(item.yLabel, function(yItem, yIndex) {
                                    return _c(
                                      "tr",
                                      {
                                        key: yIndex,
                                        staticStyle: { height: "30px" }
                                      },
                                      [
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.yLabel[yIndex],
                                                expression:
                                                  "item.yLabel[yIndex]"
                                              }
                                            ],
                                            staticClass: "label-input",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value: item.yLabel[yIndex]
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  item.yLabel,
                                                  yIndex,
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _vm._l(item.xLabel, function(
                                          xItem,
                                          xIndex
                                        ) {
                                          return _c("td", { key: xIndex }, [
                                            _c("i", {
                                              staticClass: "fa fa-circle-o",
                                              attrs: { "aria-hidden": "true" }
                                            })
                                          ])
                                        })
                                      ],
                                      2
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.itemAdd }
            },
            [_vm._v("新增模块")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }