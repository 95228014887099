var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticStyle: { color: "#f00" } }, [
        _vm._v(" 注:新销售数量不得大于原销售数量。"),
        _c("br"),
        _vm._v("如果需要调大销售数量，请使用添加功能。 ")
      ]),
      _c(
        "el-form",
        {
          attrs: {
            rules: _vm.formRules,
            "label-width": "100px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "产品编号" } }, [
            _vm._v(_vm._s(_vm.productDetail.productNumber))
          ]),
          _c("el-form-item", { attrs: { label: "物资名称" } }, [
            _vm._v(_vm._s(_vm.productDetail.productName))
          ]),
          _c("el-form-item", { attrs: { label: "规格型号" } }, [
            _vm._v(_vm._s(_vm.productDetail.specificationModel))
          ]),
          _c("el-form-item", { attrs: { label: "生产批号" } }, [
            _vm._v(_vm._s(_vm.productDetail.batchNumber))
          ]),
          _c("el-form-item", { attrs: { label: "原销售量" } }, [
            _vm._v(_vm._s(_vm.productDetail.quantityUsed))
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "新销售量" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, max: _vm.productDetail.quantityUsed - 1 },
                model: {
                  value: _vm.form.quantityUsed,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "quantityUsed", $$v)
                  },
                  expression: "form.quantityUsed"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.formSubmit } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }