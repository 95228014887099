import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js';
export function lineList(params) {
  return request({
    url: '/product/getLineList',
    method: 'get',
    params: params
  });
}
export function findLine(params) {
  return request({
    url: '/product/findLine',
    method: 'get',
    params: params
  });
}
export function createLine(data) {
  return request({
    url: '/product/createLine',
    method: 'post',
    data: data
  });
}
export function updateLine(data) {
  return request({
    url: '/product/updateLine',
    method: 'put',
    data: data
  });
}
export function deleteLine(data) {
  return request({
    url: '/product/deleteLine',
    method: 'delete',
    data: data
  });
}
export function deleteLineByIds(data) {
  return request({
    url: '/product/deleteLineByIds',
    method: 'delete',
    data: data
  });
}