import { request } from '@/api/_service.js';
export function listDepartment(params) {
  return request({
    url: '/inside/getDepartmentList',
    method: 'get',
    params: params
  });
}
export function findDepartment(params) {
  return request({
    url: '/inside/findDepartment',
    method: 'get',
    params: params
  });
}
export function createDepartment(data) {
  return request({
    url: '/inside/createDepartment',
    method: 'post',
    data: data
  });
}
export function updateDepartment(data) {
  return request({
    url: '/inside/updateDepartment',
    method: 'put',
    data: data
  });
}
export function deleteDepartment(data) {
  return request({
    url: '/inside/deleteDepartment',
    method: 'delete',
    data: data
  });
}
export function deleteDepartmentByIds(data) {
  return request({
    url: '/inside/deleteDepartmentByIds',
    method: 'delete',
    data: data
  });
}