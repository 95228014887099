var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售销售单审核")]),
              _c("el-breadcrumb-item", [_vm._v("寄售销售单详情")])
            ],
            1
          ),
          _c("div", { staticStyle: { "font-size": "12px" } }, [
            _vm._v("报台单号：" + _vm._s(_vm.orderInfo.salesOrderNo))
          ])
        ],
        1
      ),
      [
        _vm.orderInfo.ID
          ? _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "form",
                staticClass: "form-table",
                attrs: {
                  model: _vm.form,
                  rules: _vm.formRules,
                  size: "mini",
                  "label-width": "100px",
                  "label-suffix": ":",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "仓库" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.manu.name))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "经销商客户" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.customerName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台日期" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.tradeDate))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: "报台单号" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.stockout.deliveryOrderNo)
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "归属公司", prop: "subsidiaryId" }
                          },
                          [
                            _vm.orderInfo.status === 2
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    on: { change: _vm.subsidiaryIdChange },
                                    model: {
                                      value: _vm.form.subsidiaryId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "subsidiaryId", $$v)
                                      },
                                      expression: "form.subsidiaryId"
                                    }
                                  },
                                  _vm._l(_vm.subsidiaryList, function(item) {
                                    return _c("el-option", {
                                      key: "subsidiaryId" + item.ID,
                                      attrs: {
                                        label: item.companyName,
                                        value: item.ID,
                                        disabled: item.isValid === 0
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("div", { staticClass: "item-text" }, [
                                  _vm._v(
                                    _vm._s(_vm.orderInfo.subsidiary.companyName)
                                  )
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 24, lg: 24, xl: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "备注" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.remarks))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("销售总数："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  )
                ]),
                _c("div", { staticStyle: { "margin-left": "30px" } }, [
                  _vm._v("总金额："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalAmount()))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "detailListTable",
                  data: _vm.detailListForm.data,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": "500"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailListForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "物资名称",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registrationNumber",
                    label: "注册证号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "brandName",
                    width: "100",
                    label: "品牌",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "batchNumber",
                    width: "100",
                    label: "批号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "trackingCode",
                    width: "100",
                    label: "序列号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerDate",
                    width: "100",
                    label: "生产日期",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "validityDate",
                    width: "100",
                    label: "有效期",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "isConsign",
                    label: "是否寄售",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.isConsign === 1
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: "success",
                                    effect: "dark",
                                    size: "mini"
                                  }
                                },
                                [_vm._v("是")]
                              )
                            : _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: "info",
                                    effect: "dark",
                                    size: "mini"
                                  }
                                },
                                [_vm._v("否")]
                              )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unit",
                    width: "60",
                    label: "单位",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "quantityUsed",
                    label: "销售数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesUnitPrice",
                    label: "销售单价",
                    width: "160",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " + _vm._s(row.salesUnitPrice.toFixed(2)) + " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesAmount",
                    label: "销售金额",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(" " + _vm._s(row.salesAmount.toFixed(2)) + " ")
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "terUnitPrice",
                    label: "终端售价",
                    width: "120",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm._v(
                            " " + _vm._s(row.terUnitPrice.toFixed(2)) + " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturer",
                    label: "生产商",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manuLicenseCode",
                    label: "生产许可/备案凭证",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "medCode",
                    label: "医保编码",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "remarks",
                    label: "备注",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "更多", width: "60", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "600",
                                trigger: "click"
                              }
                            },
                            [
                              _c("DetailExtendComponents", {
                                key: $index,
                                ref: "DetailExtendComponents",
                                attrs: { row: row }
                              }),
                              _c("el-link", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  padding: "5px"
                                },
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-arrow-down",
                                  underline: false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showSpecs(row.specsId)
                                  }
                                },
                                slot: "reference"
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, prev, pager, next, jumper",
                    align: "center",
                    "hide-on-single-page": true,
                    total: _vm.detailListForm.total,
                    "current-page": _vm.detailListForm.page,
                    "page-size": _vm.detailListForm.pageSize
                  },
                  on: { "current-change": _vm.detailListFormPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.status == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.checkOrder }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.status == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.accountOrder }
                          },
                          [_vm._v("记账")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("CheckComponent", {
        ref: "CheckComponentRef",
        on: { confirm: _vm.checkOrderConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }