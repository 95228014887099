var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-dialog", {
    staticClass: "dialog",
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.dialogVisible,
      width: "1000px",
      "before-close": _vm.dialogClose,
      "close-on-click-modal": false,
      "modal-append-to-body": false,
      "destroy-on-close": true
    },
    on: {
      "update:visible": function($event) {
        _vm.dialogVisible = $event
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }