var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        "append-to-body": true,
        center: "",
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _vm._v("出库单号：" + _vm._s(_vm.orderInfo.deliveryOrderNo))
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _vm._v(
              "检测员：" +
                _vm._s(
                  _vm.orderInfo.nameReviewer ||
                    _vm.orderInfo.checkName ||
                    _vm.orderInfo.nameWarehousePersonnel
                )
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _vm._v(
              "检测时间：" +
                _vm._s(
                  _vm.orderInfo.auditTime ||
                    _vm.orderInfo.checkDate ||
                    _vm.orderInfo.deliveryTime
                )
            )
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", { attrs: { align: "right" } })
          ])
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList.slice(
              (_vm.form.page - 1) * _vm.form.pageSize,
              _vm.form.page * _vm.form.pageSize
            ),
            size: "small",
            "highlight-current-row": "",
            border: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              index: function(index) {
                return _vm.indexMethod(index, _vm.form)
              },
              label: "序号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNumber",
              label: "产品编号",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandName",
              label: "品牌",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              label: "物资名称",
              width: "150",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      [
                        scope.row.consumTypeId
                          ? _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "danger" } },
                              [_vm._v("耗材包")]
                            )
                          : _vm._e(),
                        _vm._v(" " + _vm._s(scope.row.productName) + " ")
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturerName",
              label: "供应商",
              width: "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specificationModel",
              width: "100",
              label: "规格型号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "medCode",
              width: "100",
              label: "医保编码",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                width: "100",
                label: "检测合格",
                align: "center",
                "show-overflow-tooltip": ""
              }
            },
            [
              [
                _c("span", { staticClass: "text-success" }, [
                  _vm._v("检测合格")
                ])
              ]
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              prop: "batchNumber",
              label: "生产批号",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trackingCode",
              label: "序列号",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliveryQuantity",
              width: "100",
              label: "出库数量",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "registrationNumber",
              width: "150",
              label: "注册证号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturerDate",
              width: "100",
              label: "生产日期",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validityDate",
              width: "100",
              label: "有效期",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unit",
              width: "100",
              label: "单位",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              width: "100",
              label: "包装规格",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.packNum) +
                        _vm._s(scope.row.unit) +
                        "/" +
                        _vm._s(scope.row.packUnit) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturer",
              width: "150",
              label: "生产厂家",
              align: "center",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }