import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { updateRegistration } from '@/api/registration';
import { listRetailer, deleteRetailer, updateRetailer } from '@/api/retailer.js';
export default {
  name: 'stock-inventory-add',
  components: {},
  data: function data() {
    return {
      pageForm: {
        name: '',
        short_name: '',
        page: 1,
        pageSize: 10,
        total: 1
      },
      tableData: [],
      itemForm: {
        type: 2
      },
      lastRow: null
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadRetailer();
    },
    loadRetailer: function loadRetailer() {
      var that = this;
      that.tableData = [];
      listRetailer({
        page: 1,
        pageSize: 9999,
        all_examine_status: true
      }).then(function (res) {
        res.list.forEach(function (row) {
          that.tableData.push(row);
        });
        that.pageForm.total = res.total;
      });
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.pageForm.page = 1;
      that.loadRetailer();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadRetailer();
    },
    rowClick: function rowClick(row) {
      var that = this;

      if (that.lastRow) {
        that.lastRow.edit = false;
      }

      row.edit = true;
      that.lastRow = row;
    }
  }
};