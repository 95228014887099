import _objectSpread from "D:/wwwroot/ERP\u524D\u7AEF/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/statistical',
  name: 'statistical',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'stock/history',
    name: 'statistical-stock-history',
    component: _import('statistical/stock/history'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '历史库存'
    })
  }, {
    path: 'inandout/inwarehouse',
    name: 'statistical-inandout-inwarehouse',
    component: _import('statistical/inandout/inwarehouse'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库报表'
    })
  }, {
    path: 'inandout/outwarehouse',
    name: 'statistical-inandout-outwarehouse',
    component: _import('statistical/inandout/outwarehouse'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库报表'
    })
  }, {
    path: 'inandout/difference',
    name: 'statistical-inandout-difference',
    component: _import('statistical/inandout/difference'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '盘点差异报表'
    })
  }, {
    path: 'inandout/incost',
    name: 'statistical-inandout-incost',
    component: _import('statistical/inandout/incost'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库成本报表'
    })
  }, {
    path: 'inandout/consume',
    name: 'statistical-inandout-consume',
    component: _import('statistical/inandout/consume'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库消耗报表'
    })
  }, {
    path: 'inandout/purchase',
    name: 'statistical-inandout-purchase',
    component: _import('statistical/inandout/purchase'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购记录'
    })
  }, {
    path: 'inandout/receipt',
    name: 'statistical-inandout-receipt',
    component: _import('statistical/inandout/receipt'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '收货记录'
    })
  }, {
    path: 'inandout/report',
    name: 'statistical-inandout-report',
    component: _import('statistical/inandout/report'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '验收记录'
    })
  }, {
    path: 'sales/report',
    name: 'statistical-sales-report',
    component: _import('statistical/sales/report'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售报表'
    })
  }, {
    path: 'sales/cost',
    name: 'statistical-sales-cost',
    component: _import('statistical/sales/cost'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '成本销售报表'
    })
  }, {
    path: 'finance/cost',
    name: 'statistical-finance-cost',
    component: _import('statistical/finance/cost'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '结转成本报表'
    })
  }, {
    path: 'finance/total',
    name: 'statistical-finance-total',
    component: _import('statistical/finance/total'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存总值报表'
    })
  }, {
    path: 'finance/price',
    name: 'statistical-finance-price',
    component: _import('statistical/finance/price'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存明细价格表'
    })
  }]
};