var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售管理中心")]),
              _c("el-breadcrumb-item", [_vm._v("寄售退货审核")]),
              _c("el-breadcrumb-item", [_vm._v("寄售退货审核详情")])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [
              _vm.orderInfo.orderNumber
                ? _c("span", [
                    _vm._v("退货单号：" + _vm._s(_vm.orderInfo.orderNumber))
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "label-suffix": ":",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "manufacturerId" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.manufacturerName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退回仓库", prop: "warehouseId" } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货人", prop: "returnedBy" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.returnedBy))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货日期", prop: "returnDate" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.returnDate))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.remark))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v(" 退货总数："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.returnSumQuantity()))
                  ])
                ]),
                _c("div", { staticStyle: { margin: "0 20px" } }, [
                  _vm._v(" 退货总金额："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.returnSumAmount()))
                  ])
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailForm",
            attrs: {
              model: _vm.detailForm,
              rules: _vm.detailFormRules,
              size: "mini",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.detailForm.data.slice(
                  (_vm.detailForm.page - 1) * _vm.detailForm.pageSize,
                  _vm.detailForm.page * _vm.detailForm.pageSize
                ),
                hasPagination: true,
                pagination: _vm.detailForm,
                rowClassName: _vm.rowClassName
              },
              on: {
                pageChange: _vm.detailFormPageChange,
                sizeChange: _vm.detailFormSizeChange
              }
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" } },
                  [
                    _vm.form.status === 2
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toExamine()
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.form.status === 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.toUnExamine()
                              }
                            }
                          },
                          [_vm._v("撤销审核")]
                        )
                      : _vm._e(),
                    _vm.form.status === 3
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.toAccount()
                              }
                            }
                          },
                          [_vm._v("记账")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.toOrderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ExamineComponent", {
        ref: "ExamineComponentRef",
        on: { confirm: _vm.examineConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }