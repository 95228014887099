var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售管理中心")]),
              _c("el-breadcrumb-item", [_vm._v("经销商客户管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.searchForm,
                      inline: true,
                      "label-suffix": ":",
                      size: "small"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "经销商" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", filterable: "" },
                            model: {
                              value: _vm.searchForm.manuId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "manuId", $$v)
                              },
                              expression: "searchForm.manuId"
                            }
                          },
                          _vm._l(_vm.retailerList, function(item, index) {
                            return _c("el-option", {
                              key: "manuId" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", filterable: "" },
                            model: {
                              value: _vm.searchForm.clientType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "clientType", $$v)
                              },
                              expression: "searchForm.clientType"
                            }
                          },
                          _vm._l(_vm.clientTypeList, function(item, index) {
                            return _c("el-option", {
                              key: "clientType" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.searchForm.username,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "username", $$v)
                            },
                            expression: "searchForm.username"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searchFormExpand,
                            expression: "searchFormExpand"
                          }
                        ],
                        attrs: { label: "备注" }
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.searchForm.mark,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "mark", $$v)
                            },
                            expression: "searchForm.mark"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "primary", icon: "el-icon-search" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.dataCreate()
                        }
                      }
                    },
                    [_vm._v("新增客户")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "mini",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          return _vm.dataDelete()
                        }
                      }
                    },
                    [_vm._v("删除")]
                  )
                ],
                1
              )
            ]),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dataLoading,
                  expression: "dataLoading"
                }
              ],
              ref: "dataTableRef",
              attrs: {
                hasSelection: true,
                hasIndex: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.dataList
              },
              on: { "row-click": _vm.rowClick, "row-dblclick": _vm.rowDbClick },
              scopedSlots: _vm._u([
                {
                  key: "operations",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.dataEdit(row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.dataDelete(row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.searchForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.searchForm.pageSize
                  },
                  on: {
                    "current-change": _vm.pageChange,
                    "size-change": _vm.sizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("FormComponent", {
        ref: "FormComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }