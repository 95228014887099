import format from '@/libs/util.format';
import dayjs from 'dayjs';
var date = {};

date.isDate = function () {
  var sdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var dateFormat = /^(\d{4})-(\d{2})-(\d{2})$/;

  if (sdate.length === 10) {
    if (dateFormat.test(sdate)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

date.isExpire = function (sdate) {
  if (sdate) {
    if (format.isDate(sdate)) {
      if (dayjs().isBefore(dayjs(sdate))) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default date;