var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("产品信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("物资字典管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.isMainPromoter && _vm.isSpecPromoter
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary"
                      },
                      on: { click: _vm.productAdd }
                    },
                    [_vm._v("新增物资")]
                  )
                : _vm._e(),
              _vm.isMainPromoter && _vm.isSpecPromoter
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload2",
                        type: "primary"
                      },
                      on: { click: _vm.productImport }
                    },
                    [_vm._v("导入物资")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-download",
                    type: "primary"
                  },
                  on: { click: _vm.productExport }
                },
                [_vm._v("导出物资")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { padding: "0", height: "100%" } },
          [
            _c("el-main", { staticStyle: { padding: "0" } }, [
              _c("div", { staticClass: "myBox" }, [
                _c(
                  "div",
                  {
                    style:
                      "width:" +
                      (_vm.showAside === true ? "250" : "10") +
                      "px;",
                    attrs: { id: "sliderLeft" }
                  },
                  [
                    _vm.showAside
                      ? _c(
                          "div",
                          {
                            staticClass: "menuList",
                            staticStyle: { height: "100%" }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { height: "40px" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "输入关键字进行过滤",
                                    size: "small",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.filterText,
                                    callback: function($$v) {
                                      _vm.filterText = $$v
                                    },
                                    expression: "filterText"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: { flex: "1", "overflow-y": "auto" }
                              },
                              [
                                _c("el-tree", {
                                  ref: "classTree",
                                  staticClass: "filter-tree",
                                  staticStyle: { background: "none" },
                                  attrs: {
                                    data: _vm.classData,
                                    props: _vm.defaultProps,
                                    "node-key": "id",
                                    "default-expanded-keys":
                                      _vm.classExpandedKeys,
                                    "highlight-current": true,
                                    "filter-node-method": _vm.filterNode,
                                    "expand-on-click-node": false,
                                    id: "v-step-0"
                                  },
                                  on: { "node-click": _vm.treeClick },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            {
                                              staticClass: "custom-tree-node",
                                              staticStyle: {
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                                "align-items": "center",
                                                flex: "1"
                                              },
                                              attrs: { title: node.label }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  data.type == "PRODUCT_LINE"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-folder-opened pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  data.type == "CLASS"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-document-copy pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  data.type == "SUB_CLASS"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-tickets pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(node.label) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              [
                                                "BRAND",
                                                "PRODUCT_LINE"
                                              ].includes(data.type) &&
                                              data.id !== 0
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px"
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        effect: "plain",
                                                        type: "info"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addNode(
                                                            data
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-plus"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    880362966
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "move",
                            rawName: "v-move",
                            value: { showAsideSet: _vm.showAsideSet },
                            expression: "{showAsideSet: showAsideSet}"
                          }
                        ],
                        staticClass: "moveBtn"
                      },
                      [
                        _c("i", {
                          class: _vm.showAside
                            ? "el-icon-caret-left"
                            : "el-icon-caret-right",
                          staticStyle: {
                            position: "absolute",
                            top: "50%",
                            color: "#666",
                            cursor: "pointer",
                            "font-size": "20px",
                            height: "40px",
                            background: "#eee",
                            "border-radius": "5px",
                            display: "flex",
                            "align-items": "center"
                          },
                          on: { click: _vm.switchAside }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "sliderRight" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "form-card",
                          attrs: { shadow: "never" }
                        },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: {
                                inline: true,
                                model: _vm.productPageForm,
                                size: "small",
                                "label-suffix": ":"
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { size: "mini" },
                                      on: { change: _vm.listTypeChange },
                                      model: {
                                        value: _vm.listType,
                                        callback: function($$v) {
                                          _vm.listType = $$v
                                        },
                                        expression: "listType"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 1 } },
                                        [_vm._v("分屏")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: 2 } },
                                        [_vm._v("单屏")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品状态" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择产品状态"
                                      },
                                      model: {
                                        value: _vm.productPageForm.state,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.productPageForm,
                                            "state",
                                            $$v
                                          )
                                        },
                                        expression: "productPageForm.state"
                                      }
                                    },
                                    _vm._l(_vm.stateList, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品名称" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入产品名称",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.productPageForm.productName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.productPageForm,
                                          "productName",
                                          $$v
                                        )
                                      },
                                      expression: "productPageForm.productName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品编码" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入产品编码",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.productPageForm.productNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.productPageForm,
                                          "productNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "productPageForm.productNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "产品主码" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入产品主码",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.productPageForm.barCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productPageForm,
                                              "barCode",
                                              $$v
                                            )
                                          },
                                          expression: "productPageForm.barCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "规格型号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入规格型号",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.productPageForm
                                              .specificationModel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.productPageForm,
                                              "specificationModel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "productPageForm.specificationModel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.searchSubmit }
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.searchFormExpand = !_vm.searchFormExpand
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.searchFormExpand ? "合并" : "展开"
                                        )
                                      ),
                                      _c("i", {
                                        class:
                                          "el-icon-arrow-" +
                                          (_vm.searchFormExpand
                                            ? "up"
                                            : "down") +
                                          " el-icon--right"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.listType === 1
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.productLoading,
                                  expression: "productLoading"
                                }
                              ],
                              key: _vm.mainTableKey,
                              ref: "productTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                border: "",
                                "highlight-current-row": "",
                                "header-cell-style": {
                                  background: "#f5f7fa",
                                  borderColor: "#ebeef5",
                                  color: "#333"
                                },
                                size: "mini"
                              },
                              on: { "row-click": _vm.productRowClick }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  index: function(index) {
                                    return _vm.indexMethod(
                                      index,
                                      _vm.productPageForm
                                    )
                                  },
                                  label: "序号",
                                  width: "100",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productName",
                                  label: "物资名称",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productLine2",
                                  label: "产品线",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "brandName",
                                  label: "品牌",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "type",
                                  label: "物资类型",
                                  width: "100",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.type === "工具"
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    effect: "plain",
                                                    type: "primary",
                                                    size: "small"
                                                  }
                                                },
                                                [_vm._v("工具")]
                                              )
                                            : _c("span", [_vm._v("耗材")])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  690171915
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "registrationNumber",
                                  label: "注册证号",
                                  width: "150",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "supplier",
                                  label: "供应商",
                                  width: "150",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "state",
                                  label: "状态",
                                  width: "100",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.statusFormatter(scope.row)
                                              )
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  48707608
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "state",
                                  label: "启用",
                                  width: "80",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#409EFF",
                                              "inactive-color": "#cccccc",
                                              "active-value": 1,
                                              "inactive-value": 2,
                                              disabled:
                                                !_vm.isMainPromoter ||
                                                !scope.row.can_edit
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.statusChange(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.state,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "state",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.state"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2562397822
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "200",
                                  align: "center",
                                  fixed: "right"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.state === 1 &&
                                          _vm.isMainPromoter &&
                                          scope.row.can_edit
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "control-column"
                                                },
                                                [
                                                  scope.row.can_edit
                                                    ? _c(
                                                        "el-link",
                                                        {
                                                          attrs: {
                                                            type: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.productEdit(
                                                                scope.row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("编辑")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: { type: "danger" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.productInvalid(
                                                            scope.row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("作废")]
                                                  ),
                                                  _c(
                                                    "el-link",
                                                    {
                                                      attrs: {
                                                        type: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.specialAdd(
                                                            scope.row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("新增规格")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                { staticClass: "text-danger" },
                                                [_vm._v("不可操作")]
                                              )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1443493394
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.listType === 1
                        ? _c(
                            "div",
                            { staticStyle: { margin: "10px 0" } },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  background: "",
                                  layout: "prev, pager, next, total",
                                  align: "center",
                                  total: _vm.productPageForm.total,
                                  "current-page": _vm.productPageForm.page,
                                  "page-size": _vm.productPageForm.pageSize
                                },
                                on: { "current-change": _vm.productPageChange }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.listType === 1
                        ? _c("div", { staticClass: "special-header" }, [
                            _vm._v(" 规格列表（"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  Object.prototype.hasOwnProperty.call(
                                    _vm.currentProduct,
                                    "ID"
                                  )
                                    ? _vm.currentProduct.productName
                                    : "未选择产品"
                                )
                              )
                            ]),
                            _vm._v("） ")
                          ])
                        : _vm._e(),
                      _c("YTable", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.specialLoading,
                            expression: "specialLoading"
                          }
                        ],
                        key: "special-table-" + _vm.timestamp,
                        ref: "specialTableRef",
                        attrs: {
                          hasIndex: true,
                          hasPagination: true,
                          pagination: _vm.specialPageForm,
                          "route-name":
                            _vm.$route.name + "special" + _vm.listType,
                          columns:
                            _vm.listType === 1
                              ? _vm.specialColumns1
                              : _vm.specialColumns2,
                          data: _vm.specialTableData
                        },
                        on: {
                          pageChange: _vm.specialPageChange,
                          sizeChange: _vm.specialSizeChange
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "productName",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.type === "工具"
                                  ? _c("span", [_vm._v(_vm._s(row.toolName))])
                                  : _c("span", [
                                      _vm._v(_vm._s(row.productName))
                                    ])
                              ]
                            }
                          },
                          {
                            key: "type",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm.listType === 1
                                  ? _c(
                                      "div",
                                      [
                                        row.type === "工具"
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  effect: "plain",
                                                  type: "primary",
                                                  size: "small"
                                                }
                                              },
                                              [_vm._v("工具")]
                                            )
                                          : _c("span", [_vm._v("耗材")])
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        row.type === "工具"
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  effect: "plain",
                                                  type: "primary",
                                                  size: "small"
                                                }
                                              },
                                              [_vm._v("工具")]
                                            )
                                          : _c("span", [_vm._v("耗材")])
                                      ],
                                      1
                                    )
                              ]
                            }
                          },
                          {
                            key: "auditState",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.statusFormatter(row))
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "state",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#409EFF",
                                    "inactive-color": "#cccccc",
                                    "active-value": 1,
                                    "inactive-value": 2,
                                    disabled:
                                      !_vm.isSpecPromoter ||
                                      row.can_edit === false
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.specStatusChange(row)
                                    }
                                  },
                                  model: {
                                    value: row.state,
                                    callback: function($$v) {
                                      _vm.$set(row, "state", $$v)
                                    },
                                    expression: "row.state"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "operations",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.state === 1 &&
                                _vm.isSpecPromoter &&
                                row.can_edit
                                  ? _c(
                                      "div",
                                      { staticClass: "control-column" },
                                      [
                                        row.can_edit
                                          ? _c(
                                              "el-link",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.specialEdit(row)
                                                  }
                                                }
                                              },
                                              [_vm._v("编辑")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.specialMove(row)
                                              }
                                            }
                                          },
                                          [_vm._v("移动")]
                                        ),
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.specialInvalid(row)
                                              }
                                            }
                                          },
                                          [_vm._v("作废")]
                                        )
                                      ],
                                      1
                                    )
                                  : _c("div", { staticClass: "text-danger" }, [
                                      _vm._v("不可操作")
                                    ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ],
      _c("v-tour", {
        attrs: { name: "myTour", steps: _vm.steps, options: _vm.tourOptions }
      }),
      _c("ProducAddComponents", {
        ref: "ProducAddComponents",
        on: { reload: _vm.productLoad }
      }),
      _c("ProductImportComponents", {
        ref: "ProductImportComponents",
        on: { reload: _vm.productLoad }
      }),
      _c("SpecialMoveComponents", {
        ref: "SpecialMoveComponents",
        on: {
          reload: function($event) {
            return _vm.specialLoad(_vm.currentProduct)
          }
        }
      }),
      _c("SpecialAddComponents", {
        ref: "SpecialAddComponents",
        on: {
          reload: function($event) {
            return _vm.specialLoad(_vm.currentProduct)
          }
        }
      }),
      _c("ClassLineEditorComponent", {
        ref: "ClassLineEditorComponentRef",
        on: { reload: _vm.classLoad }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }