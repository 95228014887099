var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              key: _vm.tamptime,
              ref: "orderListTableRef",
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                "highlight-current-row": "",
                "row-class-name": _vm.rowClassName,
                size: "small",
                "row-key": "ID",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(_vm.tableHead, function(col) {
            return [
              col.prop === "index"
                ? [
                    _c(
                      "el-table-column",
                      {
                        key: col.prop,
                        attrs: {
                          label: col.label,
                          prop: col.prop,
                          width: col.width,
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    _vm._s(_vm.indexFormatter(scope.$index))
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content: "点击筛选字段",
                                  placement: "top"
                                }
                              },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    attrs: { effect: "dark", size: "mini" },
                                    on: { click: _vm.tableHeadSetup }
                                  },
                                  [
                                    _vm._v("序号 "),
                                    _c("i", {
                                      staticClass: "fa fa-filter",
                                      attrs: { "aria-hidden": "true" }
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                : col.prop === "operations"
                ? [
                    _c("el-table-column", {
                      key: col.prop,
                      attrs: {
                        label: col.label,
                        prop: col.prop,
                        width: col.width,
                        align: "center",
                        fixed: "right"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control-column" },
                                  [
                                    scope.row.showSup === 1
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.createSupplement(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("采购补差")]
                                        )
                                      : _vm._e(),
                                    scope.row.showPay
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.doPayment(scope.row)
                                              }
                                            }
                                          },
                                          [_vm._v("付款")]
                                        )
                                      : _vm._e(),
                                    scope.row.state === 2 ||
                                    scope.row.state === 4 ||
                                    scope.row.state === 5 ||
                                    scope.row.state === 6
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.viewPayment(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("查看")]
                                        )
                                      : _vm._e(),
                                    scope.row.state !== 6
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "mini"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.invoiceView(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("关联发票")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                : [
                    col.checked === true
                      ? _c(
                          "el-table-column",
                          _vm._b(
                            {
                              key: col.prop,
                              attrs: {
                                "show-overflow-tooltip": "",
                                prop: col.prop,
                                align: "center",
                                label: col.label
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        col.prop === "appOrderNumber"
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      underline: false
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.viewApplyOrder(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.appOrderNumber
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : col.prop === "purchaseOrderNumbers"
                                          ? _c(
                                              "div",
                                              [
                                                scope.row.purchaseOrderNumbers
                                                  .length > 1
                                                  ? _c(
                                                      "el-popover",
                                                      {
                                                        ref:
                                                          "popover" +
                                                          scope.$index,
                                                        refInFor: true,
                                                        attrs: {
                                                          placement: "right",
                                                          width: "auto",
                                                          trigger: "hover"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          _vm._l(
                                                            scope.row
                                                              .purchaseOrderNumbers,
                                                            function(
                                                              item,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    "purchaseOrderNumbers-" +
                                                                    idx
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-link",
                                                                    {
                                                                      attrs: {
                                                                        type:
                                                                          "primary"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.viewOrder(
                                                                            item,
                                                                            scope.$index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _c(
                                                          "el-link",
                                                          {
                                                            attrs: {
                                                              slot: "reference",
                                                              type: "primary"
                                                            },
                                                            slot: "reference"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  scope.row
                                                                    .purchaseOrderNumbers[0]
                                                                ) +
                                                                " "
                                                            ),
                                                            scope.row
                                                              .purchaseOrderNumbers
                                                              .length > 1
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle: {
                                                                      color:
                                                                        "#f00"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "+" +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .purchaseOrderNumbers
                                                                            .length -
                                                                            1
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : scope.row
                                                      .purchaseOrderNumbers
                                                      .length === 1
                                                  ? _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          type: "primary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewOrder(
                                                              scope.row
                                                                .purchaseOrderNumbers[0]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .purchaseOrderNumbers[0]
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : col.prop === "state"
                                          ? _c(
                                              "div",
                                              [
                                                scope.row.state >= 2
                                                  ? _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "right",
                                                          width: "max-content",
                                                          trigger: "click"
                                                        },
                                                        on: {
                                                          show: function(
                                                            $event
                                                          ) {
                                                            return _vm.loadLine(
                                                              scope.row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        scope.row.examineLine
                                                          ? _c(
                                                              "div",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "loading",
                                                                    rawName:
                                                                      "v-loading",
                                                                    value:
                                                                      _vm.popVisible,
                                                                    expression:
                                                                      "popVisible"
                                                                  }
                                                                ]
                                                              },
                                                              [
                                                                _c(
                                                                  "el-timeline",
                                                                  {
                                                                    staticStyle: {
                                                                      padding:
                                                                        "0"
                                                                    },
                                                                    attrs: {
                                                                      "hide-timestamp": true
                                                                    }
                                                                  },
                                                                  _vm._l(
                                                                    scope.row
                                                                      .examineLine,
                                                                    function(
                                                                      item,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "el-timeline-item",
                                                                        {
                                                                          key:
                                                                            "examine-line-" +
                                                                            idx,
                                                                          attrs: {
                                                                            icon:
                                                                              _vm
                                                                                .lineIcon[
                                                                                item
                                                                                  .status
                                                                              ],
                                                                            type:
                                                                              _vm
                                                                                .lineColor[
                                                                                item
                                                                                  .status
                                                                              ],
                                                                            size:
                                                                              "large"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.msg
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "el-link",
                                                          {
                                                            attrs: {
                                                              slot: "reference",
                                                              type: _vm.getStateType(
                                                                scope.row.state,
                                                                1
                                                              )
                                                            },
                                                            slot: "reference"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getStateType(
                                                                  scope.row
                                                                    .state,
                                                                  2
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c("span", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.statusFormatter(
                                                            scope.row.state
                                                          )
                                                        )
                                                      }
                                                    })
                                              ],
                                              1
                                            )
                                          : _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.columnFormatter(
                                                    col.prop,
                                                    scope.row
                                                  )
                                                )
                                              }
                                            })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            "el-table-column",
                            _vm._d({}, [_vm.attribName(col.width), col.width])
                          )
                        )
                      : _vm._e()
                  ]
            ]
          })
        ],
        2
      ),
      _c("PaymentConfirmComponents", {
        ref: "PaymentConfirmComponents",
        on: { reload: _vm.reload }
      }),
      _c("ViewConfirmComponents", { ref: "ViewConfirmComponents" }),
      _c("InvoiceViewComponent", { ref: "InvoiceViewComponentRef" }),
      _c("ApplyComponent", { ref: "ApplyComponentRef" }),
      _c("DrawTableHeadComponent", {
        ref: "DrawTableHeadComponentRef",
        attrs: { "table-head": _vm.tableHead },
        on: { "save-table-set": _vm.saveTableSet }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }