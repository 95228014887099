import { request } from '@/api/_service.js';
export function listAdvance(params) {
  return request({
    url: '/advanceErp/getAdvanceList',
    method: 'get',
    params: params
  });
}
export function listAdvance2(params) {
  return request({
    url: '/advanceErp/getAdvanceList2',
    method: 'get',
    params: params
  });
}
export function listAdvanceExport(params) {
  return request({
    url: '/advanceErp/getAdvanceList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listAdvanceExport2(params) {
  return request({
    url: '/advanceErp/getAdvanceList2',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findAdvance(params) {
  return request({
    url: '/advanceErp/findAdvance',
    method: 'get',
    params: params
  });
}
export function createAdvance(data) {
  return request({
    url: '/advanceErp/createAdvance',
    method: 'post',
    data: data
  });
}
export function updateAdvance(data) {
  return request({
    url: '/advanceErp/updateAdvance',
    method: 'put',
    data: data
  });
}
export function deleteAdvance(data) {
  return request({
    url: '/advanceErp/deleteAdvance',
    method: 'delete',
    data: data
  });
}
export function createRefund(data) {
  return request({
    url: '/advanceLogErp/refundAdvanceLog',
    method: 'post',
    data: data
  });
}
export function createRefund2(data) {
  return request({
    url: '/advanceLogErp/refundAdvanceLog2',
    method: 'post',
    data: data
  });
}
export function listAdvanceLog(params) {
  return request({
    url: '/advanceLogErp/getAdvanceLogList',
    method: 'get',
    params: params
  });
}
export function listAdvanceLogExport(params) {
  return request({
    url: '/advanceLogErp/getAdvanceLogList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listAdvanceLog2(params) {
  return request({
    url: '/advanceLogErp/getAdvanceLogList2',
    method: 'get',
    params: params
  });
}
export function listAdvanceLogExport2(params) {
  return request({
    url: '/advanceLogErp/getAdvanceLogList2',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listAdvance3(params) {
  return request({
    url: '/advaErp/getAdvaList',
    method: 'get',
    params: params
  });
}
export function listAdvance3Export(params) {
  return request({
    url: '/advaErp/getAdvaList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}