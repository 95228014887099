var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { align: "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.invoiceAddItem }
            },
            [_vm._v("增加行")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "label-width": "auto",
            "show-message": false,
            loading: _vm.formLoading,
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                size: "mini",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                data: _vm.form.invoiceList,
                height: 320,
                "max-height": 320
              }
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: {
                    prop: "invoiceNumber",
                    label: "",
                    width: "160",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop:
                                  "invoiceList." + $index + ".invoiceNumber",
                                rules: _vm.formRules.invoiceNumber
                              }
                            },
                            [
                              _c("el-input", {
                                ref: "invoiceList-" + $index + "-invoiceNumber",
                                attrs: { size: "mini", clearable: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.invoiceSaveItem(row)
                                  }
                                },
                                model: {
                                  value: row.invoiceNumber,
                                  callback: function($$v) {
                                    _vm.$set(row, "invoiceNumber", $$v)
                                  },
                                  expression: "row.invoiceNumber"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "header" }, [
                    _c("span", { staticStyle: { color: "#f00" } }, [
                      _vm._v("*发票号")
                    ])
                  ])
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceCode",
                  width: "160",
                  label: "发票代码",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "invoiceCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                placeholder: "发票代码",
                                clearable: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.invoiceSaveItem(row)
                                }
                              },
                              model: {
                                value: row.invoiceCode,
                                callback: function($$v) {
                                  _vm.$set(row, "invoiceCode", $$v)
                                },
                                expression: "row.invoiceCode"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoiceType",
                  width: "160",
                  label: "发票类型",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "invoiceType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "mini", clearable: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.invoiceSaveItem(row)
                                  }
                                },
                                model: {
                                  value: row.invoiceType,
                                  callback: function($$v) {
                                    _vm.$set(row, "invoiceType", $$v)
                                  },
                                  expression: "row.invoiceType"
                                }
                              },
                              _vm._l(_vm.invoiceTypeList, function(item, idx) {
                                return _c("el-option", {
                                  key: "invoice-item-" + idx,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "invoicingDate",
                  width: "160",
                  label: "开票日期",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "invoicingDate" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: {
                                position: "absolute",
                                "z-index": "-100"
                              },
                              attrs: {
                                id:
                                  "financial-invoice-invoicingDatePicker" +
                                  $index,
                                editable: true,
                                type: "date",
                                placeholder: "选择日期",
                                format: "yyyy-MM-dd",
                                size: "mini",
                                "value-format": "yyyy-MM-dd",
                                clearable: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.invoiceSaveItem(row)
                                }
                              },
                              model: {
                                value: row.invoicingDate,
                                callback: function($$v) {
                                  _vm.$set(row, "invoicingDate", $$v)
                                },
                                expression: "row.invoicingDate"
                              }
                            }),
                            _c(
                              "el-input",
                              {
                                ref: "invoicingDate" + $index,
                                staticClass: "date-input",
                                attrs: {
                                  id: "invoicingDate" + $index,
                                  size: "mini"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.invoiceSaveItem(row)
                                  }
                                },
                                model: {
                                  value: row.invoicingDate,
                                  callback: function($$v) {
                                    _vm.$set(row, "invoicingDate", $$v)
                                  },
                                  expression: "row.invoicingDate"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-date"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showDatePicker(
                                        "financial-invoice-invoicingDatePicker" +
                                          $index
                                      )
                                    }
                                  },
                                  slot: "append"
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amountIncludingTax",
                  width: "150",
                  label: "金额（含税）",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "amountIncludingTax" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                placeholder: "金额（含税）",
                                min: 0,
                                precision: 2,
                                controls: false,
                                clearable: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.invoiceSaveItem(row)
                                }
                              },
                              model: {
                                value: row.amountIncludingTax,
                                callback: function($$v) {
                                  _vm.$set(row, "amountIncludingTax", $$v)
                                },
                                expression: "row.amountIncludingTax"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taxRate",
                  width: "100",
                  label: "税率",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-form-item", { attrs: { prop: "taxRate" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: {
                                  flex: "1",
                                  "margin-right": "5px"
                                },
                                attrs: {
                                  size: "mini",
                                  placeholder: "税率",
                                  min: 0,
                                  precision: 2,
                                  controls: false,
                                  clearable: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.invoiceSaveItem(row)
                                  }
                                },
                                model: {
                                  value: row.taxRate,
                                  callback: function($$v) {
                                    _vm.$set(row, "taxRate", $$v)
                                  },
                                  expression: "row.taxRate"
                                }
                              }),
                              _c("span", [_vm._v("%")])
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taxAmount",
                  width: "150",
                  label: "税额",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-form-item", { attrs: { prop: "taxAmount" } }, [
                          _c(
                            "span",
                            { staticStyle: { "line-height": "28px" } },
                            [_vm._v(_vm._s(row.taxAmount.toFixed(2)))]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "money",
                  width: "150",
                  label: "金额(不含税)",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-form-item", { attrs: { prop: "money" } }, [
                          _c(
                            "span",
                            { staticStyle: { "line-height": "28px" } },
                            [_vm._v(_vm._s(row.money.toFixed(2)))]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validationCode",
                  width: "150",
                  label: "校验码(后六位)",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "validationCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                placeholder: "校验码",
                                maxlength: 6,
                                clearable: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.invoiceSaveItem(row)
                                }
                              },
                              model: {
                                value: row.validationCode,
                                callback: function($$v) {
                                  _vm.$set(row, "validationCode", $$v)
                                },
                                expression: "row.validationCode"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "操作",
                  width: "150",
                  fixed: "right",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              width: "500",
                              trigger: "click"
                            }
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                attrs: {
                                  action: _vm.uploadAction,
                                  headers: _vm.uploadToken,
                                  "on-preview": _vm.handlePreview,
                                  "on-remove": function(file, fileList) {
                                    return _vm.handleRemove(file, fileList, row)
                                  },
                                  "on-success": function(res, file, fileList) {
                                    return _vm.uploadSuccess(
                                      res,
                                      file,
                                      fileList,
                                      row
                                    )
                                  },
                                  "file-list": row.attachment,
                                  "show-file-list": true
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("点击上传发票附件")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [_vm._v("只能上传jpg/png文件，且不超过10MB")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-link",
                              {
                                attrs: { slot: "reference", type: "primary" },
                                slot: "reference"
                              },
                              [_vm._v("发票附件")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.invoiceDeleteItem(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0", align: "center" } },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }