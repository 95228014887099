var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "dialogForm",
          attrs: {
            model: _vm.dialogForm,
            rules: _vm.dialogFormRules,
            size: "small",
            "label-width": "50px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "",
                "label-width": "0",
                prop: "boxType",
                align: "center"
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dialogForm.boxType,
                    callback: function($$v) {
                      _vm.$set(_vm.dialogForm, "boxType", $$v)
                    },
                    expression: "dialogForm.boxType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("盒子模式")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("列表模式")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.dialogForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogForm, "name", $$v)
                  },
                  expression: "dialogForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remarks" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 2 },
                model: {
                  value: _vm.dialogForm.remarks,
                  callback: function($$v) {
                    _vm.$set(_vm.dialogForm, "remarks", $$v)
                  },
                  expression: "dialogForm.remarks"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { align: "center" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }