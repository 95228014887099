var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "form",
          staticClass: "form-table",
          attrs: {
            model: _vm.form,
            "label-width": "120px",
            size: "small",
            rules: _vm.formRules,
            "show-message": false,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请分类", prop: "appClass" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.form.appClass,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "appClass", $$v)
                                },
                                expression: "form.appClass"
                              }
                            },
                            _vm._l(_vm.appclassList, function(item, index) {
                              return _c("el-option", {
                                key: "appClass-" + index,
                                attrs: {
                                  label: item.className,
                                  value: item.className
                                }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.appClass))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款公司", prop: "paymentCompany" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.form.paymentCompany,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "paymentCompany", $$v)
                                },
                                expression: "form.paymentCompany"
                              }
                            },
                            _vm._l(_vm.subsidiaryList, function(item, index) {
                              return _c("el-option", {
                                key: "paymentCompany-" + index,
                                attrs: {
                                  label: item.companyName,
                                  value: item.companyName,
                                  disabled: item.isValid === 0
                                }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.paymentCompany))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请人", prop: "applicant" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.applicant))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请日期", prop: "appDate" } },
                    [
                      !_vm.readonly
                        ? _c("el-date-picker", {
                            staticStyle: { width: "130px" },
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd"
                            },
                            model: {
                              value: _vm.form.appDate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "appDate", $$v)
                              },
                              expression: "form.appDate"
                            }
                          })
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.appDate))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票情况", prop: "invoiceType" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.invoiceType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "invoiceType", $$v)
                                },
                                expression: "form.invoiceType"
                              }
                            },
                            _vm._l(_vm.invoiceTypeList, function(item, index) {
                              return _c("el-option", {
                                key: "applicant-" + index,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.invoiceType(_vm.form.invoiceType))
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款类型", prop: "appType" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.appTypeChange },
                              model: {
                                value: _vm.form.appType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "appType", $$v)
                                },
                                expression: "form.appType"
                              }
                            },
                            [
                              _vm.form.depositNot === 1
                                ? _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("采购付款")
                                  ])
                                : _vm._e(),
                              _vm.form.depositNot === 1 &&
                              _vm.rows.length === 1 &&
                              _vm.completed === 0
                                ? _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("采购预付款")
                                  ])
                                : _vm._e(),
                              _vm.form.depositNot === 2
                                ? _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("寄售押金")
                                  ])
                                : _vm._e(),
                              _vm.form.depositNot === 2
                                ? _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v("工具押金")
                                  ])
                                : _vm._e(),
                              _vm.form.depositNot === 2
                                ? _c("el-radio", { attrs: { label: 5 } }, [
                                    _vm._v("其他")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.appTypeName(_vm.form.appType)) +
                                " "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付金额", prop: "paymentAmount" } },
                    [
                      _vm.readonly ||
                      _vm.rows.length > 1 ||
                      _vm.form.appType === 1
                        ? _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.moneyFormatter(_vm.form.paymentAmount)
                                ) +
                                " "
                            )
                          ])
                        : _c(
                            "div",
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "enter-number",
                                    rawName: "v-enter-number",
                                    value: { min: 0, precision: 2 },
                                    expression: "{min:0,precision:2}"
                                  }
                                ],
                                attrs: {
                                  "suffix-icon": "el-icon-edit",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.paymentAmount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "paymentAmount", $$v)
                                  },
                                  expression: "form.paymentAmount"
                                }
                              })
                            ],
                            1
                          )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付方式", prop: "paymentMethod" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.paymentMethod,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "paymentMethod", $$v)
                                },
                                expression: "form.paymentMethod"
                              }
                            },
                            _vm._l(_vm.paymentMethodList, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: "applicant-" + index,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.paymentMethod(_vm.form.paymentMethod))
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "紧急程度", prop: "urgencyLevel" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.urgencyLevel,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "urgencyLevel", $$v)
                                },
                                expression: "form.urgencyLevel"
                              }
                            },
                            _vm._l(_vm.urgencyLevelList, function(item, index) {
                              return _c("el-option", {
                                key: "applicant-" + index,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.urgencyLevel(_vm.form.urgencyLevel))
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收款行账号",
                        prop: "receivingBankAccount"
                      }
                    },
                    [
                      !_vm.readonly
                        ? _c("el-input", {
                            attrs: {
                              "suffix-icon": "el-icon-edit",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.receivingBankAccount,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receivingBankAccount", $$v)
                              },
                              expression: "form.receivingBankAccount"
                            }
                          })
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.receivingBankAccount))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户行名", prop: "payeeBankName" } },
                    [
                      !_vm.readonly
                        ? _c("el-input", {
                            attrs: {
                              clearable: "",
                              "suffix-icon": "el-icon-edit"
                            },
                            model: {
                              value: _vm.form.payeeBankName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "payeeBankName", $$v)
                              },
                              expression: "form.payeeBankName"
                            }
                          })
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.payeeBankName))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收款单位", prop: "payee" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                disabled:
                                  _vm.form.depositNot === 1 ? true : false
                              },
                              model: {
                                value: _vm.form.payee,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "payee", $$v)
                                },
                                expression: "form.payee"
                              }
                            },
                            _vm._l(_vm.supplierList, function(item, index) {
                              return _c("el-option", {
                                key: "payee-" + index,
                                attrs: {
                                  label: item.name,
                                  value: item.name,
                                  disabled: !item.show_status
                                }
                              })
                            }),
                            1
                          )
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.payee))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请事由", prop: "reasonApp" } },
                    [
                      !_vm.readonly
                        ? _c("el-input", {
                            attrs: {
                              clearable: "",
                              "suffix-icon": "el-icon-edit"
                            },
                            model: {
                              value: _vm.form.reasonApp,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "reasonApp", $$v)
                              },
                              expression: "form.reasonApp"
                            }
                          })
                        : _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.form.reasonApp))
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.id,
                      expression: "form.id"
                    }
                  ],
                  attrs: { span: 24 }
                },
                [
                  _c("el-form-item", { attrs: { label: "审批人" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.form.regUser))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.id,
                      expression: "form.id"
                    }
                  ],
                  staticClass: "auto-height",
                  attrs: { span: 24 }
                },
                [
                  _c("el-form-item", { attrs: { label: "审批意见" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm.form.reg === "驳回"
                        ? _c("span", { staticStyle: { color: "#f00" } }, [
                            _vm._v(_vm._s(_vm.form.reg))
                          ])
                        : _vm._e(),
                      _vm.form.reg === "同意"
                        ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                            _vm._v(_vm._s(_vm.form.reg))
                          ])
                        : _vm._e()
                    ])
                  ])
                ],
                1
              ),
              _vm.form.id && _vm.form.depositNot === 1
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "相关产品" } }, [
                        _c(
                          "span",
                          { staticClass: "item-text" },
                          [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: { click: _vm.viewProductList }
                              },
                              [_vm._v("查看相关产品")]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.readonly === false
        ? _c(
            "div",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { align: "center" }
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _vm._e(),
      _c("ProductListComponents", { ref: "ProductListComponents" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }