//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listCompany } from '@/api/company.js';
import { findOutWarehouseOrder, listOutWarehouseDetail } from '@/api/stock.js';
import JsBarcode from 'jsbarcode';
import html2canvas from 'html2canvas';
import printJs from 'print-js';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '打印预览',
      dialogVisible: false,
      consumDetail: [],
      tableLoading: false,
      orderDetail: {},
      companyDetail: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(id) {
      var that = this;
      that.dialogVisible = true; // 查找本公司信息

      listCompany({
        page: 1,
        pageSize: 1
      }).then(function (res) {
        if (res.list.length > 0) {
          that.companyDetail = res.list[0];
        } else {
          that.$message.error('请填写本公司信息（基础数据/内部信息管理/本公司信息）');
        }
      }); // 查找出库单

      findOutWarehouseOrder({
        id: id
      }).then(function (res) {
        that.orderDetail = res.restockout;
        that.$nextTick(function () {
          JsBarcode('#barcode', that.orderDetail.deliveryOrderNo, {
            height: 45,
            margin: 0
          });
        });
      }); // 查找出库单明细

      listOutWarehouseDetail({
        page: 1,
        pageSize: 9999,
        deliveryId: id
      }).then(function (res) {
        that.consumDetail = res.list;
      });
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    printClick: function printClick() {
      var printContent = window.document.getElementById('printContainer'); // 获取dom 宽度 高度

      var width = printContent.clientWidth;
      var height = printContent.clientHeight; // 创建一个canvas节点

      var canvas = document.createElement('canvas');
      var scale = 4; // 定义任意放大倍数，支持小数；越大，图片清晰度越高，生成图片越慢。

      canvas.width = width * scale; // 定义canvas 宽度 * 缩放

      canvas.height = height * scale; // 定义canvas高度 *缩放

      canvas.style.width = width * scale + 'px';
      canvas.style.height = height * scale + 'px';
      canvas.getContext('2d').scale(scale, scale); // 获取context,设置scale

      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动轴滚动的长度

      var scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft; // 获取水平滚动轴的长度

      html2canvas(printContent, {
        canvas: canvas,
        backgroundColor: null,
        useCORS: true,
        windowHeight: document.body.scrollHeight,
        scrollX: -scrollLeft,
        // 解决水平偏移问题，防止打印的内容不全
        scrollY: -scrollTop
      }).then(function (canvas) {
        var url = canvas.toDataURL('image/png');
        printJs({
          printable: url,
          type: 'image',
          documentTitle: '',
          // 标题
          style: '@page{size:auto;margin: 1cm 1cm 0cm 1cm;}' // 去除页眉页脚

        });
      }).catch(function (err) {
        console.error(err);
      });
    }
  }
};