var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "120px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入角色名称",
                          disabled: _vm.itemForm.name === "库管员",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "name", $$v)
                          },
                          expression: "itemForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.itemForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "status", $$v)
                            },
                            expression: "itemForm.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "normal" } }, [
                            _vm._v("启用")
                          ]),
                          _c("el-radio", { attrs: { label: "hidden" } }, [
                            _vm._v("停用")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单权限设置", required: true } },
                    [
                      _c("el-tree", {
                        ref: "ruleTreeRef",
                        staticStyle: {
                          "max-height": "400px",
                          "overflow-y": "auto"
                        },
                        attrs: {
                          data: _vm.ruleTreeData,
                          "node-key": "id",
                          props: _vm.defaultProps,
                          "default-expand-all": "",
                          "show-checkbox": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c(
                                "div",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c("div", [_vm._v(_vm._s(node.label))]),
                                  data.permission.length > 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("按扭权限：")]
                                          ),
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              staticStyle: { flex: "1" },
                                              attrs: {
                                                disabled: !_vm.includesId(
                                                  data.id
                                                )
                                              },
                                              model: {
                                                value:
                                                  _vm.itemForm.btn_permission,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.itemForm,
                                                    "btn_permission",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "itemForm.btn_permission"
                                              }
                                            },
                                            _vm._l(data.permission, function(
                                              item,
                                              index
                                            ) {
                                              return _c(
                                                "el-checkbox",
                                                {
                                                  key: "btn_permission" + index,
                                                  attrs: { label: item.key }
                                                },
                                                [_vm._v(_vm._s(item.name))]
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据权限设置" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.itemForm.dataAuth,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "dataAuth", $$v)
                            },
                            expression: "itemForm.dataAuth"
                          }
                        },
                        [_vm._v("查看成本价")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }