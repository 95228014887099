var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "450px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        align: "center",
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dbLoading,
              expression: "dbLoading"
            }
          ],
          ref: "itemForm",
          attrs: { model: _vm.form, "label-width": "100px" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "20px 0" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, align: "left" } },
                [
                  _c("el-form-item", { attrs: { label: "新增数量：" } }, [
                    _vm._v(_vm._s(_vm.successNum))
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, align: "left" } },
                [
                  _c("el-form-item", { attrs: { label: "导入失败：" } }, [
                    _vm._v(_vm._s(_vm.failNum))
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, align: "left" } },
                [
                  _c("el-form-item", { attrs: { label: "重复数量：" } }, [
                    _vm._v(_vm._s(_vm.repeatNum))
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, align: "left" } },
                [
                  _c("el-form-item", { attrs: { label: "已检测数量：" } }, [
                    _vm._v(_vm._s(_vm.checkedNum))
                  ])
                ],
                1
              ),
              _vm.dbList.length > 0
                ? _c(
                    "el-col",
                    {
                      staticStyle: { color: "#f00" },
                      attrs: { span: 24, align: "center" }
                    },
                    [
                      _vm._v(
                        " 检测到远程数据 " +
                          _vm._s(_vm.dbList.length) +
                          " 条，请点击更新按钮开始更新数据 "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.dbList.length === 0
                ? _c(
                    "el-col",
                    {
                      staticStyle: { color: "#f00" },
                      attrs: { span: 24, align: "center" }
                    },
                    [_vm._v(" 未检测到远程数据，请稍后再试 ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0", align: "center" } },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("更 新")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }