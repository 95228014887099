var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.command === "ADD"
        ? _c("div", {
            staticStyle: { "margin-bottom": "10px" },
            attrs: { align: "right" }
          })
        : _vm._e(),
      _c(
        "el-form",
        {
          attrs: {
            size: "small",
            model: _vm.form,
            inline: true,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.loadBatch.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "批号", prop: "batch_number" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.loadBatch.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.batch_number,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "batch_number", $$v)
                  },
                  expression: "form.batch_number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "序列号", prop: "trackingCode" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.loadBatch.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.form.trackingCode,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "trackingCode", $$v)
                  },
                  expression: "form.trackingCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.loadBatch } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "batchForm",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "show-message": false
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                margin: "10px 0",
                "text-align": "right",
                "font-size": "14px"
              }
            },
            [
              _c("span", [
                _vm._v("产品名："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(
                    _vm._s(_vm.sellOrderDetailRow.productName) +
                      "(" +
                      _vm._s(_vm.sellOrderDetailRow.specificationModel) +
                      ")"
                  )
                ])
              ]),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("订单产品数："),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v(_vm._s(_vm.sellOrderDetailRow.deliveryQuantity))
                ])
              ])
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "batchListTable",
              attrs: {
                width: "100%",
                border: "",
                data: _vm.getListData(),
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                size: "mini",
                "row-key": _vm.detailRowKey,
                "highlight-current-row": "",
                "max-height": "460"
              },
              on: { "row-click": _vm.rowClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  width: "60",
                  align: "center",
                  fixed: "left",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.form)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  prop: "batchNumber",
                  "min-width": "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序列号",
                  prop: "trackingCode",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产日期",
                  prop: "manufacturerDate",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "有效期",
                  prop: "validityDate",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "可用数量",
                  prop: "normalQuantity",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "出库数量",
                  prop: "deliveryQuantity",
                  "min-width": "170",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.command === "ADD"
                          ? _c(
                              "el-form-item",
                              {
                                key: "deliveryQuantity" + $index,
                                attrs: {
                                  prop: "data." + $index + ".deliveryQuantity",
                                  rules: _vm.formRules.deliveryQuantity
                                }
                              },
                              [
                                _c("el-input", {
                                  ref: "deliveryQuantity" + $index,
                                  attrs: {
                                    id: "deliveryQuantity" + $index,
                                    size: "mini",
                                    placeholder: "入库数量",
                                    disabled: row.normalQuantity < 1
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return function(event) {
                                        return _vm.nextFocus(
                                          "deliveryQuantity" + $index,
                                          event
                                        )
                                      }.apply(null, arguments)
                                    }
                                  },
                                  model: {
                                    value: row.deliveryQuantity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "deliveryQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "row.deliveryQuantity"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.deliveryQuantity))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否寄售",
                  prop: "isConsign",
                  "min-width": "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.isConsign === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("否")]
                            )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌批号",
                  prop: "sterilizationBatchNumber",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌效期",
                  prop: "sterilizationDate",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              "hide-on-single-page": true,
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.formPageChange,
              "size-change": _vm.formSizeChange
            }
          })
        ],
        1
      ),
      _vm.command === "ADD"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              attrs: { align: "center" }
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
                [_vm._v("关闭")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }