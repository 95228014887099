var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "infinite-scroll",
              rawName: "v-infinite-scroll",
              value: _vm.loadPackageMore,
              expression: "loadPackageMore"
            }
          ],
          staticClass: "infinite-list",
          attrs: { "infinite-scroll-disabled": _vm.loadMoreDisabled() }
        },
        _vm._l(_vm.packageList, function(item, index) {
          return _c("li", { key: index }, [
            _c(
              "div",
              { staticClass: "name" },
              [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      effect: "dark",
                      content: item.name,
                      placement: "top"
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(item.name))])]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "control" },
              [
                _c(
                  "el-link",
                  {
                    attrs: { underline: false, type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.manageProduct(item)
                      }
                    }
                  },
                  [_vm._v("管理物资")]
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { underline: false, type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.manageTemplate(item)
                      }
                    }
                  },
                  [_vm._v("管理模板")]
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm.loading
        ? _c("el-divider", [
            _c("i", { staticClass: "el-icon-loading" }),
            _vm._v("加载中...")
          ])
        : _vm._e(),
      _vm.noMore() === true
        ? _c("el-divider", [_vm._v("没有更多了")])
        : _vm._e(),
      _c("TemplateManageComponents", { ref: "TemplateManageComponents" }),
      _c("ManageProductComponents", { ref: "ManageProductComponents" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }