var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "840px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("vue-esign", {
        ref: "esign",
        attrs: {
          width: 800,
          height: 300,
          isCrop: _vm.isCrop,
          format: "image/png",
          quality: 0.1,
          lineWidth: _vm.lineWidth,
          lineColor: _vm.lineColor,
          bgColor: _vm.bgColor,
          isClearBgColor: false
        },
        on: {
          "update:bgColor": function($event) {
            _vm.bgColor = $event
          },
          "update:bg-color": function($event) {
            _vm.bgColor = $event
          }
        }
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" }, attrs: { align: "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
            [_vm._v("清空")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleGenerate } },
            [_vm._v("签名确认")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }