var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "720px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-popover",
            {
              ref: "optionsPopover",
              attrs: { placement: "bottom", trigger: "click" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "el-checkbox-group dropdown_checkbox",
                  staticStyle: { display: "flex", "flex-direction": "column" }
                },
                _vm._l(_vm.localColumnsList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: "field-checkbox-" + index,
                      attrs: { label: item.field },
                      on: { change: _vm.saveTableSet },
                      model: {
                        value: item.checked,
                        callback: function($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked"
                      }
                    },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "reference", type: "primary", size: "mini" },
                  slot: "reference"
                },
                [_vm._v("字段自定义")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.companyDetail.name))]),
                    _c("h3", [_vm._v("不合格品退货记录单")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        "margin-top": "20px",
                        color: "#000"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("来源：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.warehouseName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("退回单位：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.returnCompany))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("退回理由：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.reasonFormatter(_vm.orderInfo.returnReason)
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("物流信息：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.deliveryInfo))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("备注：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.remark))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "detail-table" }, [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.localColumnsList, function(item, index) {
                              return [
                                item.checked === true
                                  ? _c("th", { key: "field-" + index }, [
                                      _vm._v(_vm._s(item.text))
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.detailList, function(item, index) {
                            return _c(
                              "tr",
                              { key: "data-col-" + index },
                              [
                                _vm._l(_vm.localColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          { key: "data-item-" + index2 },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.field],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._l(_vm.getSummary(), function(item, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                _vm._l(_vm.localColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          {
                                            key: "summary-" + index2,
                                            staticClass: "summary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.field],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "form-table" }, [
                    _c("table", [
                      _c("tr", [
                        _c("th", [_vm._v("审核人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.orderInfo.examineName) +
                              " / " +
                              _vm._s(_vm.orderInfo.examineTime)
                          )
                        ]),
                        _c("th", [_vm._v("打印人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.user.nickName) +
                              " / " +
                              _vm._s(_vm.getCurrentTime())
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }