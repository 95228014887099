var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true,
        "destroy-on-close": true,
        fullscreen: _vm.dialogFullScreen
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "custom_dialog_header" }, [
          _c("span", { staticClass: "el_dialog_title" }, [
            _vm._v(_vm._s(_vm.dialogTitle))
          ]),
          _c(
            "div",
            {
              staticClass: "custom_dialog_menu",
              on: {
                click: function($event) {
                  return _vm.fullscreen()
                }
              }
            },
            [
              !_vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-maximize",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e(),
              _vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-restore",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ]
          )
        ])
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dbLoading,
              expression: "dbLoading"
            }
          ],
          key: "table-" + _vm.timestamp,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dbList,
            size: "small",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "cell-class-name": _vm.cellClassName
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center",
              fixed: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "appOrderNumber",
              label: "申请单号",
              width: "180",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "appType",
              label: "付款类型",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.appTypeFormatter(row.appType))
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "appClass",
              label: "申请分类",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentCompany",
              label: "付款公司",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "payee",
              label: "收款单位",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "state",
              label: "状态",
              width: "80",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.stateFormatter(row.state))
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "appDate",
              label: "预付款日期",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ep_day",
              label: "预警提醒",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.expirationDay < 0
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "已过期" +
                              _vm._s(Math.abs(scope.row.expirationDay)) +
                              "天"
                          )
                        ])
                      : _vm._e(),
                    scope.row.expirationDay >= 0
                      ? _c("span", { staticClass: "text-warning" }, [
                          _vm._v(_vm._s(scope.row.expirationDay) + "天后过期")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentAmount",
              label: "订单金额",
              width: "120",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "paymentEnd",
              label: "已付金额",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "180",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.toAppPay(row)
                          }
                        }
                      },
                      [_vm._v("跳转至采购付款页面")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              "page-sizes": [10, 20, 30, 40, 50, 100],
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }