var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        "append-to-body": true,
        center: "",
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "label-width": "100px",
            "show-message": false
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "复核人选择", prop: "uid" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.id,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "id", $$v)
                    },
                    expression: "form.id"
                  }
                },
                _vm._l(_vm.dbList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: {
                      label: item.name,
                      value: item.id,
                      disabled: item.uid === _vm.profile.uid
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }