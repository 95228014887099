var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "top-header" }, [
        _c(
          "div",
          { staticClass: "search-bar" },
          [
            _c(
              "el-form",
              {
                ref: "searchForm",
                attrs: { inline: true, size: "mini", "label-suffix": ":" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.searchForm,
                  callback: function($$v) {
                    _vm.searchForm = $$v
                  },
                  expression: "searchForm"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "产品编号" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.productNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productNumber", $$v)
                        },
                        expression: "searchForm.productNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品名称" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "规格型号" } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.searchForm.specificationModel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "specificationModel", $$v)
                        },
                        expression: "searchForm.specificationModel"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("搜索")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "consum-list" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.consumInfoLoading,
                  expression: "consumInfoLoading"
                }
              ],
              ref: "consumInfoListTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.consumInfoList,
                size: "small",
                "highlight-current-row": "",
                stripeDELETE: "",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.productNumber",
                  label: "产品编号",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "main.productName",
                  label: "产品名称",
                  width: "200",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.specificationModel",
                  label: "规格型号",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.unit",
                  label: "单位",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantityUsed",
                  label: "数量",
                  width: "170",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "capacity",
                  label: "容量",
                  width: "170",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "280",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.changeCapacity(row)
                              }
                            }
                          },
                          [_vm._v("修改容量")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.replenishQuantity(row)
                              }
                            }
                          },
                          [_vm._v("补充数量")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.removeProduct(row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-sizes": [5, 10, 20, 50, 100],
              "page-size": _vm.searchForm.pageSize
            },
            on: {
              "current-change": _vm.searchFormPageChange,
              "size-change": _vm.searchFormPageSizeChange
            }
          })
        ],
        1
      ),
      _c("CapacityComponents", {
        ref: "CapacityComponents",
        on: { reload: _vm.capacityConfirm }
      }),
      _c("ReplenishComponents", {
        ref: "ReplenishComponents",
        on: { reload: _vm.loadConsumInfo }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }