var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popover" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.row,
            "label-width": "100px",
            "label-suffix": ":",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { gutter: 20, type: "flex" }
            },
            [
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "物资名称" } }, [
                    _vm._v(" " + _vm._s(_vm.row.productName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "产品编号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.productNumber) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "通用名称" } }, [
                    _vm._v(" " + _vm._s(_vm.row.genericName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "规格型号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.specificationModel) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "序列号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.trackingCode) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "生产批号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.batchNumber) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "库存数量" } }, [
                    _vm._v(" " + _vm._s(_vm.row.stockQuantity) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "库存可用数量" } }, [
                    _vm._v(" " + _vm._s(_vm.row.normalQuantity) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "销售锁定" } }, [
                    _vm._v(" " + _vm._s(_vm.row.salesPendingApproval) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "入库数量" } }, [
                    _vm._v(" " + _vm._s(_vm.row.receiptQuantity) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "出库锁定" } }, [
                    _vm._v(" " + _vm._s(_vm.row.issueLocking) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "消耗数量" } }, [
                    _vm._v(" " + _vm._s(_vm.row.consumedQuantity) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "包装规格" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.row.packNum) +
                        _vm._s(_vm.row.unit) +
                        " / " +
                        _vm._s(_vm.row.packUnit) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "成本价" } }, [
                    _vm._v(" " + _vm._s(_vm.row.afterTaxPrice) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "单位" } }, [
                    _vm._v(" " + _vm._s(_vm.row.unit) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "注册证号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.registrationNumber) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "有效期" } }, [
                    _vm._v(" " + _vm._s(_vm.row.validityDate) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "生产日期" } }, [
                    _vm._v(" " + _vm._s(_vm.row.manufacturerDate) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "产地/品牌" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.row.productPlace) +
                        " / " +
                        _vm._s(_vm.row.brandName) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "生产厂家" } }, [
                    _vm._v(" " + _vm._s(_vm.row.manufacturer) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "灭菌批号" } }, [
                    _vm._v(" " + _vm._s(_vm.row.sterilizationBatchNumber) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "灭菌效期" } }, [
                    _vm._v(" " + _vm._s(_vm.row.sterilizationDate) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "储运条件" } }, [
                    _vm._v(" " + _vm._s(_vm.row.storage) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }