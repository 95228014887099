//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogVisible: false,
      gridData: [],
      type: null
    };
  },
  methods: {
    init: function init(gridData, type) {
      var that = this;
      that.dialogVisible = true;
      that.gridData = gridData;
      that.type = type;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var that = this;
      that.dialogClose();
      that.$emit('confirm', that.type);
    }
  }
};