var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.apppayLoading,
                  expression: "apppayLoading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.apppayList,
                border: "",
                size: "small",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "经手人",
                  prop: "czName",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "付款方式",
                  prop: "paymentMethod",
                  "min-width": "100",
                  align: "center",
                  formatter: function(row) {
                    return _vm.paymentMethodListFormatter(row.payment_method)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作日期",
                  prop: "czDate",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "付款金额",
                  prop: "czAmount",
                  "min-width": "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.czAmount.toFixed(2)))]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作类型",
                  prop: "czType",
                  "min-width": "100",
                  align: "center",
                  formatter: function(row) {
                    return _vm.czTypeFormatter(row.czType)
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _vm.orderInfo.appPay
              ? _c(
                  "div",
                  [
                    _vm.orderInfo.appPay.length === 1
                      ? _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                return _vm.viewApply(_vm.orderInfo.appPay[0].ID)
                              }
                            }
                          },
                          [_vm._v("查看付款申请单")]
                        )
                      : _c(
                          "el-popover",
                          { attrs: { placement: "right", trigger: "hover" } },
                          [
                            _c(
                              "div",
                              _vm._l(_vm.orderInfo.appPay, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: "apppay-item-" + index },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewApply(item.ID)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item.appOrderNumber))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "el-link",
                              {
                                attrs: { slot: "reference", type: "primary" },
                                slot: "reference"
                              },
                              [_vm._v("查看付款申请单")]
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("div", { staticClass: "mytab" }, [
        _c(
          "div",
          {
            class: _vm.tabActiveId === 1 ? "item active" : "item",
            on: {
              click: function($event) {
                return _vm.tabChange(1)
              }
            }
          },
          [_vm._v("相关产品")]
        ),
        _c(
          "div",
          {
            class: _vm.tabActiveId === 2 ? "item active" : "item",
            on: {
              click: function($event) {
                return _vm.tabChange(2)
              }
            }
          },
          [_vm._v("补差产品")]
        )
      ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _vm.tabActiveId === 1
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "small",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    data: _vm.detailList,
                    "default-expand-all": true
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "50", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: scope.row.children,
                                    size: "mini"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "50",
                                      label: "序号",
                                      type: "index"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "产品编号",
                                      prop: "specs.productNumber",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "产品名称",
                                      prop: "main.productName",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "规格型号",
                                      prop: "specs.specificationModel",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "60",
                                      label: "单位",
                                      prop: "specs.unit"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "80",
                                      label: "采购数量",
                                      prop: "purchaseQuantity"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "100",
                                      label: "单价",
                                      prop: "purchaseUnitPrice"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.purchaseUnitPrice.toFixed(
                                                    2
                                                  )
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "100",
                                      label: "采购金额",
                                      prop: "sumAmount"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.sumAmount.toFixed(2)
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1551363861
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单号",
                      prop: "orderNumber",
                      "min-width": "150",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购人",
                      prop: "buyer",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购日期",
                      prop: "purchaseDate",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单金额",
                      prop: "purchaseAmount",
                      "min-width": "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(scope.row.purchaseAmount.toFixed(2))
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1596633098
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      prop: "purchaseQuantity",
                      "min-width": "100",
                      align: "center"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.tabActiveId === 2
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "small",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    data: _vm.detailList,
                    "default-expand-all": true
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand", width: "50", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    data: scope.row.children,
                                    size: "mini"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "50",
                                      label: "序号",
                                      type: "index"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "产品编号",
                                      prop: "specs.productNumber",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "产品名称",
                                      prop: "main.productName",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      "min-width": "100",
                                      label: "规格型号",
                                      prop: "specs.specificationModel",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "60",
                                      label: "单位",
                                      prop: "specs.unit"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "80",
                                      label: "补差数量",
                                      prop: "suppQuantity"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "100",
                                      label: "单价",
                                      prop: "purchaseUnitPrice"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.purchaseUnitPrice.toFixed(
                                                    2
                                                  )
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "100",
                                      label: "采购金额",
                                      prop: "sumAmount"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.sumAmount.toFixed(2)
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2968073209
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购单号",
                      prop: "orderNumber",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购人",
                      prop: "buyer",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "采购日期",
                      prop: "purchaseDate",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "补差金额",
                      prop: "compensationAmount",
                      "min-width": "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "数量",
                      prop: "compensationQuantity",
                      "min-width": "100",
                      align: "center"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      ),
      _c("PaymentComponents", { ref: "PaymentComponents" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }