var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售报台中心")]),
              _c("el-breadcrumb-item", [_vm._v("寄售报台服务")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.batchCreateReport()
                    }
                  }
                },
                [_vm._v("批量创建报台单")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "index-container" },
          [
            _c(
              "el-card",
              { staticClass: "form-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "mini",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售单号" } },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.searchForm.salesOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "salesOrderNo", $$v)
                            },
                            expression: "searchForm.salesOrderNo"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "供应商" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "供应商",
                                  "allow-create": "",
                                  filterable: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.searchForm.manufacturerName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "manufacturerName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.manufacturerName"
                                }
                              },
                              _vm._l(_vm.supplierList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.name }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.searchFormExpand
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "发货仓库" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "发货仓库",
                                  filterable: "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.searchForm.warehouseId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "warehouseId", $$v)
                                  },
                                  expression: "searchForm.warehouseId"
                                }
                              },
                              _vm._l(_vm.warehouseList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            model: {
                              value: _vm.searchForm.timeType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "timeType", $$v)
                              },
                              expression: "searchForm.timeType"
                            }
                          },
                          _vm._l(_vm.timeTypeList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function($event) {
                                _vm.searchFormExpand = !_vm.searchFormExpand
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                            ),
                            _c("i", {
                              class:
                                "el-icon-arrow-" +
                                (_vm.searchFormExpand ? "up" : "down") +
                                " el-icon--right"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "10px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "font-size": "14px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-list-grey",
                    staticStyle: { margin: "0" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(1)
                          }
                        }
                      },
                      [_vm._v("全部单据")]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(2)
                          }
                        }
                      },
                      [_vm._v("未报台")]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 3 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(3)
                          }
                        }
                      },
                      [_vm._v("已报台")]
                    )
                  ]
                )
              ]
            ),
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.orderLoading,
                  expression: "orderLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasSelection: true,
                selectable: _vm.selectable,
                hasIndex: true,
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.orderList
              },
              on: { "row-dblclick": _vm.orderRowDbClick },
              scopedSlots: _vm._u([
                {
                  key: "salesOrderNo",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-popover",
                        {
                          ref: "barCodePopover" + row.ID,
                          attrs: { placement: "right", trigger: "click" }
                        },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: { id: "barCodeImage" + row.ID }
                            })
                          ]),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                underline: false,
                                type: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.makeBarCode(
                                    row.ID,
                                    row.salesOrderNo
                                  )
                                }
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.salesOrderNo))]
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "operations",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-link",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: row.tradeStatus !== 3,
                                  expression: "row.tradeStatus!==3"
                                }
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.createReportOrder(row)
                                }
                              }
                            },
                            [_vm._v("创建报台单")]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewReportDetail(row)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.searchForm.total,
                    "page-sizes": [5, 10, 20, 50, 100],
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.searchForm.pageSize
                  },
                  on: {
                    "current-change": _vm.orderPageChange,
                    "size-change": _vm.orderSizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("FormComponent", {
          ref: "FormComponentRef",
          on: {
            reload: function($event) {
              return _vm.loadData()
            }
          }
        }),
        _c("BatchFormComponent", {
          ref: "BatchFormComponentRef",
          on: {
            reload: function($event) {
              return _vm.loadData()
            }
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }