import { request } from '@/api/_service.js';
export function listSupplement(params) {
  return request({
    url: '/suppErp/getSuppList',
    method: 'get',
    params: params
  });
}
export function findSupplement(params) {
  return request({
    url: '/suppErp/findSupp',
    method: 'get',
    params: params
  });
}
export function createSupplement(data) {
  return request({
    url: '/appPayErp/updateAppPaySup',
    method: 'put',
    data: data
  });
}
export function updateSupplement(data) {
  return request({
    url: '/suppErp/updateSupp',
    method: 'put',
    data: data
  });
}
export function checkSupplement(data) {
  return request({
    url: '/suppErp/updateSuppStatus',
    method: 'put',
    data: data
  });
}
export function updateSupp(data) {
  return request({
    url: '/suppErp/updateSupp',
    method: 'put',
    data: data
  });
}
export function listSupplementDetail(params) {
  return request({
    url: '/supplementErp/getSupplementList',
    method: 'get',
    params: params
  });
}