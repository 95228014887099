var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("虚拟库存明细")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-upload2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.uploadReport()
                    }
                  }
                },
                [_vm._v("出厂检验报告上传")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { padding: "0", height: "100%" } },
          [
            _c("el-main", { staticStyle: { padding: "0" } }, [
              _c("div", { staticClass: "myBox" }, [
                _c(
                  "div",
                  {
                    style:
                      "width:" + (_vm.showAside === true ? "250" : "5") + "px;",
                    attrs: { id: "sliderLeft" }
                  },
                  [
                    _vm.showAside
                      ? _c(
                          "div",
                          {
                            staticClass: "menuList",
                            staticStyle: { height: "100%" }
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { height: "40px" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "输入关键字进行过滤",
                                    size: "small",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.filterText,
                                    callback: function($$v) {
                                      _vm.filterText = $$v
                                    },
                                    expression: "filterText"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: { flex: "1", "overflow-y": "auto" }
                              },
                              [
                                _c("el-tree", {
                                  ref: "classTree",
                                  staticClass: "filter-tree",
                                  staticStyle: { background: "none" },
                                  attrs: {
                                    data: _vm.classData,
                                    props: _vm.defaultProps,
                                    "default-expand-all": true,
                                    "node-key": "ID",
                                    "highlight-current": true,
                                    "filter-node-method": _vm.filterNode,
                                    "expand-on-click-node": false,
                                    id: "v-step-0"
                                  },
                                  on: { "node-click": _vm.treeClick },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var node = ref.node
                                          var data = ref.data
                                          return _c(
                                            "span",
                                            {
                                              staticClass: "custom-tree-node",
                                              attrs: { title: node.label }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  data.type == "PRODUCT_LINE"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-folder-opened pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  data.type == "CLASS"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-document-copy pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  data.type == "SUB_CLASS"
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-tickets pre-icon"
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " + _vm._s(node.label)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1210108983
                                  )
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "move",
                            rawName: "v-move",
                            value: { showAsideSet: _vm.showAsideSet },
                            expression: "{showAsideSet: showAsideSet}"
                          }
                        ],
                        staticClass: "moveBtn"
                      },
                      [
                        _c("i", {
                          class: _vm.showAside
                            ? "el-icon-caret-left"
                            : "el-icon-caret-right",
                          staticStyle: {
                            position: "absolute",
                            top: "50%",
                            color: "#666",
                            cursor: "pointer",
                            "font-size": "20px",
                            height: "40px",
                            background: "#eee",
                            "border-radius": "5px",
                            display: "flex",
                            "align-items": "center"
                          },
                          on: { click: _vm.switchAside }
                        })
                      ]
                    )
                  ]
                ),
                _c("div", { staticClass: "sliderRight" }, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "form-card",
                          attrs: { shadow: "never" }
                        },
                        [
                          _c(
                            "el-form",
                            {
                              attrs: {
                                inline: true,
                                model: _vm.pageForm,
                                size: "small",
                                "label-suffix": ":"
                              },
                              nativeOn: {
                                submit: function($event) {
                                  $event.preventDefault()
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.searchSubmit.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "品牌" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择品牌",
                                            clearable: "",
                                            filterable: ""
                                          },
                                          model: {
                                            value: _vm.pageForm.brandId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.pageForm,
                                                "brandId",
                                                $$v
                                              )
                                            },
                                            expression: "pageForm.brandId"
                                          }
                                        },
                                        _vm._l(_vm.brandList, function(item) {
                                          return _c("el-option", {
                                            key: "brandId" + item.ID,
                                            attrs: {
                                              label: item.name,
                                              value: item.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "供应商" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                        filterable: ""
                                      },
                                      model: {
                                        value: _vm.pageForm.supplier,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.pageForm,
                                            "supplier",
                                            $$v
                                          )
                                        },
                                        expression: "pageForm.supplier"
                                      }
                                    },
                                    _vm._l(_vm.supplierList, function(item) {
                                      return _c("el-option", {
                                        key: item.ID,
                                        attrs: {
                                          label: item.name,
                                          value: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "分类" } },
                                [
                                  _c("el-cascader", {
                                    attrs: {
                                      options: _vm.classList,
                                      props: _vm.props,
                                      "show-all-levels": true,
                                      filterable: "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.pageForm.productLine,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.pageForm,
                                          "productLine",
                                          $$v
                                        )
                                      },
                                      expression: "pageForm.productLine"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "物资名称" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入物资名称",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.pageForm.productName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "productName",
                                              $$v
                                            )
                                          },
                                          expression: "pageForm.productName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "规格型号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入规格型号",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.pageForm.specificationModel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "specificationModel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageForm.specificationModel"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "产品主码" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入产品主码",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.pageForm.barCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "barCode",
                                              $$v
                                            )
                                          },
                                          expression: "pageForm.barCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "产品批号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入产品批号",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.pageForm.batch_number,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "batch_number",
                                              $$v
                                            )
                                          },
                                          expression: "pageForm.batch_number"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "序列号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入序列号",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.pageForm.trackingCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "trackingCode",
                                              $$v
                                            )
                                          },
                                          expression: "pageForm.trackingCode"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "产品编号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入产品编号",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.pageForm.productNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "productNumber",
                                              $$v
                                            )
                                          },
                                          expression: "pageForm.productNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "注册证号" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入注册证号",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.pageForm.registrationNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.pageForm,
                                              "registrationNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "pageForm.registrationNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.searchFormExpand
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "归属公司" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择归属公司",
                                            clearable: "",
                                            filterable: ""
                                          },
                                          model: {
                                            value: _vm.pageForm.subsidiaryId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.pageForm,
                                                "subsidiaryId",
                                                $$v
                                              )
                                            },
                                            expression: "pageForm.subsidiaryId"
                                          }
                                        },
                                        _vm._l(_vm.subsidiaryList, function(
                                          item
                                        ) {
                                          return _c("el-option", {
                                            key: "subsidiaryId" + item.ID,
                                            attrs: {
                                              label: item.companyName,
                                              value: item.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        "false-label": 0,
                                        "true-label": 1
                                      },
                                      model: {
                                        value: _vm.pageForm.showEmpty,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.pageForm,
                                            "showEmpty",
                                            $$v
                                          )
                                        },
                                        expression: "pageForm.showEmpty"
                                      }
                                    },
                                    [_vm._v("不显示0库存物资")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.searchSubmit }
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.dataExport } },
                                    [_vm._v("导出")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.searchFormExpand = !_vm.searchFormExpand
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.searchFormExpand ? "合并" : "展开"
                                        )
                                      ),
                                      _c("i", {
                                        class:
                                          "el-icon-arrow-" +
                                          (_vm.searchFormExpand
                                            ? "up"
                                            : "down") +
                                          " el-icon--right"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-bottom": "10px",
                            "font-size": "14px",
                            "text-align": "right",
                            display: "flex",
                            "justify-content": "flex-end"
                          }
                        },
                        [
                          _vm.dataAuth
                            ? _c(
                                "div",
                                { staticStyle: { "margin-right": "20px" } },
                                [
                                  _vm._v(" 成本合计："),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.quantityPriceSum.toFixed(2))
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "margin-right": "20px" } },
                            [
                              _vm._v(" 库存可用数量："),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.normalQuantitySum))
                              ])
                            ]
                          ),
                          _c("div", [
                            _vm._v(" 库存总数量："),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.stockQuantityCount))
                            ])
                          ])
                        ]
                      ),
                      _c("YTable", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        ref: "dragTableRef",
                        attrs: {
                          hasIndex: true,
                          pagination: _vm.pageForm,
                          "route-name": _vm.$route.name,
                          columns: _vm.columns,
                          data: _vm.tableData
                        },
                        on: {
                          "row-click": _vm.rowClick,
                          "row-dblclick": _vm.rowDblclick
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "url",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.url
                                  ? _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toPreview(row.url)
                                          }
                                        }
                                      },
                                      [_vm._v("点击查看")]
                                    )
                                  : _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("未上传")
                                    ])
                              ]
                            }
                          },
                          {
                            key: "cusCode",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c("span", [_vm._v(_vm._s(row.cusCode))]),
                                _c(
                                  "el-tag",
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      cursor: "pointer"
                                    },
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.printTag(row)
                                      }
                                    }
                                  },
                                  [_vm._v("打印")]
                                )
                              ]
                            }
                          },
                          {
                            key: "operations",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "div",
                                  { staticClass: "control-column" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.viewInvoice(row)
                                          }
                                        }
                                      },
                                      [_vm._v("查看")]
                                    ),
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteInvoice(row)
                                          }
                                        }
                                      },
                                      [_vm._v("删除")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c(
                        "div",
                        { staticStyle: { margin: "10px 0" } },
                        [
                          _c("el-pagination", {
                            attrs: {
                              background: "",
                              layout: "total, sizes, prev, pager, next, jumper",
                              align: "center",
                              total: _vm.pageForm.total,
                              "current-page": _vm.pageForm.page,
                              "page-size": _vm.pageForm.pageSize
                            },
                            on: {
                              "current-change": _vm.pageChange,
                              "size-change": _vm.pageSizeChange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      ],
      _c("UploadReportComponent", {
        ref: "UploadReportComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      }),
      _c("PrintTagComponent", { ref: "PrintTagComponentRef" }),
      _vm.showViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.previewImgURL
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }