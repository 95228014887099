var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      attrs: { size: "mini" },
      model: {
        value: _vm.form,
        callback: function($$v) {
          _vm.form = $$v
        },
        expression: "form"
      }
    },
    [
      _c(
        "el-input",
        {
          attrs: { placeholder: "请输入内容", size: "small", clearable: "" },
          on: { change: _vm.formSubmit },
          model: {
            value: _vm.form.name,
            callback: function($$v) {
              _vm.$set(_vm.form, "name", $$v)
            },
            expression: "form.name"
          }
        },
        [
          _c("el-button", {
            attrs: { slot: "append", icon: "el-icon-search" },
            on: { click: _vm.formSubmit },
            slot: "append"
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "infinite-scroll",
                  rawName: "v-infinite-scroll",
                  value: _vm.loadMore,
                  expression: "loadMore"
                }
              ],
              staticClass: "infinite-list",
              attrs: { "infinite-scroll-disabled": _vm.moreDisabled() }
            },
            _vm._l(_vm.userList, function(item, index) {
              return _c(
                "li",
                { key: index },
                [
                  _c("div", [_vm._v(" " + _vm._s(item.name) + " ")]),
                  _c("el-link", {
                    staticStyle: { "font-size": "22px" },
                    attrs: {
                      underline: false,
                      type: "primary",
                      icon: "el-icon-circle-plus"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addToList(item)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm.loading
            ? _c("el-divider", [
                _c("i", { staticClass: "el-icon-loading" }),
                _vm._v("加载中...")
              ])
            : _vm._e(),
          _vm.noMore() === true
            ? _c("el-divider", [_vm._v("没有更多了")])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }