var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("期初入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "warehouseId",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.inWarehouseStatus > 1
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.inWarehouseStatus > 1
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务部门", prop: "departmentId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.form.inWarehouseStatus > 1
                            },
                            model: {
                              value: _vm.form.departmentId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "departmentId", $$v)
                              },
                              expression: "form.departmentId"
                            }
                          },
                          _vm._l(_vm.departmentList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.departmentName,
                                value: item.ID
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间",
                            disabled: _vm.form.inWarehouseStatus > 1
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 16, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            disabled: _vm.form.inWarehouseStatus > 1
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-plus",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.selectSpecs }
                  },
                  [_vm._v("增加产品 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "small",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", [
                  _vm._v("总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("）")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          disabled: !_vm.form.id
                        },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印入库单")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.detailListForm.data,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": "400"
                },
                on: {
                  "row-click": function(row, index) {
                    return _vm.detailRowClick(row, index)
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "expand-item" },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("物资名称")
                                      ]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: scope.row.productName,
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "value" }, [
                                            _vm._v(
                                              _vm._s(scope.row.productName)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("产品编号")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.productNumber))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("通用名称")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.genericName))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("序列号")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.trackingCode))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("生产批号")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.batchNumber))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("库存数量")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.stockQuantity))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("可用数量")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.normalQuantity))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("销售锁定")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.salesPendingApproval)
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("入库数量")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.receiptQuantity))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("出库锁定")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.issueLocking))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("消耗数量")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.consumedQuantity))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("包装规格")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.packNum) +
                                          _vm._s(scope.row.unit) +
                                          " / " +
                                          _vm._s(scope.row.packUnit)
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("单价")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.unitPrice))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("单位")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.unit))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("注册证号")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.registrationNumber)
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("有效期")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.validityDate))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("生产日期：")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.manufacturerDate))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "expand-item" },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("产地/品牌")
                                      ]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              scope.row.productPlace +
                                              "/" +
                                              scope.row.brandName,
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "value" }, [
                                            _vm._v(
                                              _vm._s(scope.row.productPlace) +
                                                " / " +
                                                _vm._s(scope.row.brandName)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "expand-item" },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("生产厂家")
                                      ]),
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content: scope.row.manufacturer,
                                            placement: "top"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "value" }, [
                                            _vm._v(
                                              _vm._s(scope.row.manufacturer)
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("灭菌批号")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.sterilizationBatchNumber
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("灭菌效期")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.sterilizationDate)
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("储运条件")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(_vm._s(scope.row.storage))
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 12, lg: 8, xl: 4 } },
                                [
                                  _c("div", { staticClass: "expand-item" }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("货位")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(scope.row.placementPosition)
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "物资名称",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.form.inWarehouseStatus === 1 &&
                          !scope.row.ID && !scope.row.id
                            ? _c("el-input", {
                                attrs: {
                                  size: "small",
                                  placeholder: "输入名称回车搜索"
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.stockSearch.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row.productName,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "productName", $$v)
                                  },
                                  expression: "scope.row.productName"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.productName))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.form.inWarehouseStatus === 1 &&
                          !scope.row.ID && !scope.row.id
                            ? _c("el-input", {
                                attrs: { size: "mini" },
                                model: {
                                  value: scope.row.productNumber,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "productNumber", $$v)
                                  },
                                  expression: "scope.row.productNumber"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.productNumber))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "生产批号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "batchNumber" + $index,
                                  attrs: {
                                    prop: "data." + $index + ".batchNumber",
                                    rules: _vm.detailListFormRules.batchNumber
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "生产批号"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "trackingCode-" + $index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.batchNumber,
                                      callback: function($$v) {
                                        _vm.$set(row, "batchNumber", $$v)
                                      },
                                      expression: "row.batchNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.batchNumber))])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "序列号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c("el-input", {
                                key: "trackingCode" + $index,
                                ref: "trackingCode-" + $index,
                                attrs: { size: "mini", placeholder: "序列号" },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "inWarehouseQuantity-" + $index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.trackingCode,
                                  callback: function($$v) {
                                    _vm.$set(row, "trackingCode", $$v)
                                  },
                                  expression: "row.trackingCode"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(row.trackingCode))])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "stockQuantity",
                    label: "库存数量",
                    width: "80",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouseQuantity",
                    label: "入库数量",
                    width: "80",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "inWarehouseQuantity" + $index,
                                  attrs: {
                                    prop:
                                      "data." + $index + ".inWarehouseQuantity",
                                    rules:
                                      _vm.detailListFormRules
                                        .inWarehouseQuantity
                                  }
                                },
                                [
                                  _c("el-input", {
                                    ref: "inWarehouseQuantity-" + $index,
                                    attrs: {
                                      size: "mini",
                                      placeholder: "入库数量"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      },
                                      input: function($event) {
                                        return _vm.inWarehouseQuantityInput(row)
                                      }
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "manufacturerDate-" + $index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.inWarehouseQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "inWarehouseQuantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "row.inWarehouseQuantity"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.inWarehouseQuantity))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "normalQuantity",
                    label: "可用数量",
                    width: "80",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "包装规格",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(scope.row.packNum) +
                              _vm._s(scope.row.unit) +
                              " / " +
                              _vm._s(scope.row.packUnit) +
                              " "
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerDate",
                    label: "生产日期",
                    width: "160",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "manufacturerDate" + $index,
                                  attrs: {
                                    prop:
                                      "data." + $index + ".manufacturerDate",
                                    rules:
                                      _vm.detailListFormRules.manufacturerDate
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    ref: "manufacturerDatePicker-" + $index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100"
                                    },
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.manufacturerDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "manufacturerDate", $$v)
                                      },
                                      expression: "row.manufacturerDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "manufacturerDate-" + $index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return function(event) {
                                            return _vm.nextFocus(
                                              "validityDate-" + $index,
                                              event
                                            )
                                          }.apply(null, arguments)
                                        }
                                      },
                                      model: {
                                        value: row.manufacturerDate,
                                        callback: function($$v) {
                                          _vm.$set(row, "manufacturerDate", $$v)
                                        },
                                        expression: "row.manufacturerDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "manufacturerDatePicker-" + $index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.manufacturerDate))])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "有效期", width: "160", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    ref: "validityDatePicker-" + $index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100"
                                    },
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.validityDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "validityDate", $$v)
                                      },
                                      expression: "row.validityDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "validityDate-" + $index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        },
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return function(event) {
                                            return _vm.nextFocus(
                                              "afterTaxPrice-" + $index,
                                              event
                                            )
                                          }.apply(null, arguments)
                                        }
                                      },
                                      model: {
                                        value: row.validityDate,
                                        callback: function($$v) {
                                          _vm.$set(row, "validityDate", $$v)
                                        },
                                        expression: "row.validityDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "validityDatePicker-" + $index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.validityDate))])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "afterTaxPrice",
                    label: "成本价",
                    width: "160",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c("el-input-number", {
                                ref: "afterTaxPrice-" + $index,
                                attrs: { size: "mini", min: 0 },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "sterilizationBatchNumber-" + $index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.afterTaxPrice,
                                  callback: function($$v) {
                                    _vm.$set(row, "afterTaxPrice", $$v)
                                  },
                                  expression: "row.afterTaxPrice"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(row.afterTaxPrice))])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "灭菌批号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus == 1
                            ? _c("el-input", {
                                key: "sterilizationBatchNumber" + $index,
                                ref: "sterilizationBatchNumber-" + $index,
                                attrs: { size: "mini" },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "sterilizationDate-" + $index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.sterilizationBatchNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      row,
                                      "sterilizationBatchNumber",
                                      $$v
                                    )
                                  },
                                  expression: "row.sterilizationBatchNumber"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(row.sterilizationBatchNumber))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sterilizationDate",
                    label: "灭菌效期",
                    width: "160",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.form.inWarehouseStatus == 1
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    ref: "sterilizationDatePicker-" + $index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100"
                                    },
                                    attrs: {
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.sterilizationDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "sterilizationDate", $$v)
                                      },
                                      expression: "row.sterilizationDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "sterilizationDate-" + $index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      },
                                      model: {
                                        value: row.sterilizationDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            row,
                                            "sterilizationDate",
                                            $$v
                                          )
                                        },
                                        expression: "row.sterilizationDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "sterilizationDatePicker-" +
                                                $index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.sterilizationDate))
                              ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("入库人：" + _vm._s(_vm.form.inWarehouseName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("入库时间：" + _vm._s(_vm.form.inWarehouseTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.form.examineName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.form.accountName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              disabled: !(
                                _vm.form.inWarehouseStatus == 1 &&
                                _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v("暂存")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v("提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤消提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-close" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 6
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.specsAddToList, update: _vm.specsUpdate }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }