var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "720px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100mm",
                height: "40mm",
                overflow: "hidden",
                border: "1px solid #000"
              }
            },
            [
              _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticClass: "print-container",
                  staticStyle: { width: "100%" },
                  attrs: { id: "printContainer" + _vm.$route.name }
                },
                [
                  _c("div", [_vm._v("耗材包名称：" + _vm._s(_vm.tagRow.name))]),
                  _c("div", [
                    _vm._v("使用单位：" + _vm._s(_vm.tagRow.hospitalName))
                  ]),
                  _c("div", [
                    _vm._v("耗材包编号：" + _vm._s(_vm.tagRow.barCode))
                  ]),
                  _c("div", [
                    _c("img", {
                      style: "width:" + (_vm.settings.dimX - 5) + "mm;",
                      attrs: { id: "barcode" }
                    })
                  ])
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }