var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticStyle: { display: "flex", "flex-direction": "row" } }, [
        _c("div", { staticStyle: { width: "500px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                height: "50px",
                "font-weight": "bold",
                background: "#EBF1F6",
                "align-items": "center",
                display: "flex",
                "justify-content": "center"
              }
            },
            [_vm._v(" 选择商品包分类 ")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                border: "1px solid #EBF1F6"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "200px",
                    "border-right": "1px solid #EBF1F6"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "border-bottom": "1px solid #EBF1F6",
                        padding: "5px"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.warehouseLoading,
                              expression: "warehouseLoading"
                            }
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            size: "small",
                            clearable: ""
                          },
                          on: { change: _vm.warehouseChange },
                          model: {
                            value: _vm.currentWarehouseId,
                            callback: function($$v) {
                              _vm.currentWarehouseId = $$v
                            },
                            expression: "currentWarehouseId"
                          }
                        },
                        _vm._l(_vm.warehouseList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("el-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.packagesTypeLoading,
                            expression: "packagesTypeLoading"
                          }
                        ],
                        ref: "packagesTypeTree",
                        staticClass: "filter-tree",
                        attrs: {
                          data: _vm.packageTypeList,
                          props: _vm.packagesTypeProps,
                          "default-expand-all": "",
                          "highlight-current": true,
                          "expand-on-click-node": false,
                          "node-key": "ID"
                        },
                        on: { "node-click": _vm.packageTypeClick },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return _c("span", {}, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    data.ID === 0
                                      ? _c("i", {
                                          class: node.expanded
                                            ? "folder el-icon-folder-opened"
                                            : "folder el-icon-folder"
                                        })
                                      : _c("i", {
                                          staticClass: "fa fa-medkit",
                                          attrs: { "aria-hidden": "true" }
                                        }),
                                    _vm._v(" " + _vm._s(data.name))
                                  ]
                                )
                              ])
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.packageSubTypeListLoading,
                          expression: "packageSubTypeListLoading"
                        }
                      ],
                      ref: "packageSubTypeListTable",
                      attrs: {
                        data: _vm.packageSubTypeList,
                        size: "mini",
                        "highlight-current-row": "",
                        "row-key": _vm.rowKey,
                        "row-class-name": _vm.rowClassName
                      },
                      on: { "row-click": _vm.packSubTypeRowClick }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "名称",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "barCode",
                          label: "条码",
                          align: "center",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "60",
                          prop: "status",
                          label: "状态",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                row.state === 1
                                  ? _c(
                                      "span",
                                      { staticClass: "text-success" },
                                      [_vm._v("在库")]
                                    )
                                  : _vm._e(),
                                row.state === 2
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("已出库")
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]),
        _c("div", { staticStyle: { flex: "1", "margin-left": "20px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                height: "50px",
                "font-weight": "bold",
                background: "#EBF1F6",
                "align-items": "center",
                display: "flex",
                "justify-content": "center"
              }
            },
            [_vm._v(" 选择钉盒 ")]
          ),
          _c(
            "div",
            { staticStyle: { border: "1px solid #EBF1F6" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.packageLoading,
                      expression: "packageLoading"
                    }
                  ],
                  ref: "boxTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.packageList,
                    size: "small",
                    "highlight-current-row": ""
                  },
                  on: { "row-dblclick": _vm.packageRowClick }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "50",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "名称", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "容量", width: "150", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              _vm._s(row.usage) + "/" + _vm._s(row.capacity)
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }