var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("投诉处理")]),
              _c("el-breadcrumb-item", [_vm._v("售后服务")]),
              _c("el-breadcrumb-item", [_vm._v("售后服务单详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 售后单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "受理人", prop: "acceptanceName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.acceptanceName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acceptanceName", $$v)
                              },
                              expression: "form.acceptanceName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "服务日期", prop: "serviceTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: _vm.form.serviceTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "serviceTime", $$v)
                            },
                            expression: "form.serviceTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "customName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "客户名称", clearable: "" },
                          model: {
                            value: _vm.form.customName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "customName", $$v)
                            },
                            expression: "form.customName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系人", prop: "contactName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "联系人", clearable: "" },
                          model: {
                            value: _vm.form.contactName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "contactName", $$v)
                            },
                            expression: "form.contactName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系电话", prop: "mobile" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "联系电话", clearable: "" },
                          model: {
                            value: _vm.form.mobile,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "mobile", $$v)
                            },
                            expression: "form.mobile"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系地址", prop: "contactAddress" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "联系地址", clearable: "" },
                          model: {
                            value: _vm.form.contactAddress,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "contactAddress", $$v)
                            },
                            expression: "form.contactAddress"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "回访日期", prop: "followTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "选择日期"
                          },
                          model: {
                            value: _vm.form.followTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "followTime", $$v)
                            },
                            expression: "form.followTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "不良事件", prop: "problem" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "不良事件", clearable: "" },
                          model: {
                            value: _vm.form.problem,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "problem", $$v)
                            },
                            expression: "form.problem"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "服务内容", prop: "serviceContent" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "服务内容", clearable: "" },
                          model: {
                            value: _vm.form.serviceContent,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "serviceContent", $$v)
                            },
                            expression: "form.serviceContent"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "维护人员", prop: "recallReason" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "维护人员", clearable: "" },
                          model: {
                            value: _vm.form.recallReason,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "recallReason", $$v)
                            },
                            expression: "form.recallReason"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "维护工时", prop: "workHours" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "维护工时", clearable: "" },
                          model: {
                            value: _vm.form.workHours,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "workHours", $$v)
                            },
                            expression: "form.workHours"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "备注", clearable: "" },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "维护记录", prop: "serviceRecord" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "维护记录",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": "",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.serviceRecord,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "serviceRecord", $$v)
                            },
                            expression: "form.serviceRecord"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-upload",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "flex-start"
                            },
                            attrs: {
                              action: _vm.uploadURL,
                              headers: _vm.uploadToken,
                              "before-upload": function(file) {
                                return _vm.uploadBefore(file)
                              },
                              "on-remove": function(file, fileList) {
                                return _vm.uploadRemove(
                                  file,
                                  fileList,
                                  "licenseUploadFileList"
                                )
                              },
                              "on-success": function(res, file, fileList) {
                                return _vm.uploadSuccess(
                                  res,
                                  file,
                                  fileList,
                                  "licenseUploadFileList"
                                )
                              },
                              "on-error": _vm.uploadError,
                              "on-preview": _vm.uploadPreview,
                              accept: _vm.uploadFileType.toString(),
                              "on-exceed": _vm.uploadExceed,
                              limit: 10,
                              "show-file-list": true,
                              "file-list": _vm.uploadFileList
                            }
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("点击上传")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "el-upload__tip",
                                attrs: { slot: "tip" },
                                slot: "tip"
                              },
                              [
                                _vm._v(
                                  "只能上传" +
                                    _vm._s(
                                      _vm.uploadFileType
                                        .toString()
                                        .toUpperCase()
                                        .replace(/\./g, "")
                                        .replace(/,/g, "/")
                                    ) +
                                    "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  disabled: _vm.form.status !== 1 ? true : false
                },
                on: { click: _vm.selectProduct }
              },
              [_vm._v("添加产品")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              data: _vm.pageDataLoad(),
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              "max-height": "400"
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "registrationNumber",
                label: "注册证号",
                "min-width": "200",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "brandName",
                label: "品牌",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "批号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "afterQuantity",
                label: "数量",
                width: "100",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        directives: [
                          {
                            name: "enter-number",
                            rawName: "v-enter-number:[{min:0,precision:0}]",
                            arg: { min: 0, precision: 0 }
                          }
                        ],
                        attrs: { size: "mini" },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.afterQuantity,
                          callback: function($$v) {
                            _vm.$set(scope.row, "afterQuantity", _vm._n($$v))
                          },
                          expression: "scope.row.afterQuantity"
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, total",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormChange }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            disabled: _vm.rowIndex < 0 ? true : false
                          },
                          on: { click: _vm.rowRemove }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "info" },
                          on: { click: _vm.formStorage }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.toPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.checkConfirm }
      }),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }