var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "append-to-body": true,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-checkbox-group",
        {
          model: {
            value: _vm.storageSelected,
            callback: function($$v) {
              _vm.storageSelected = $$v
            },
            expression: "storageSelected"
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "el-row", attrs: { type: "flex", gutter: 20 } },
            [
              _vm._l(_vm.storageList, function(item, index) {
                return _c(
                  "el-col",
                  {
                    key: "storage-" + index,
                    staticStyle: { margin: "5px 0" },
                    attrs: { span: 6 }
                  },
                  [_c("el-checkbox", { attrs: { label: item } })],
                  1
                )
              }),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { span: 24, align: "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }