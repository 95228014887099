var barcode = {};
/**
 * @description 解析条码
 * @param {String} barCode 条码
 */

barcode.parse = function () {
  var barCode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var headCode = barCode.substr(0, 2);
  var result = {
    mainCode: null,
    // 主码
    auxiliaryCode: null,
    // 副码
    trackingCode: null,
    // 序列号
    batchNumber: null,
    // 批号
    expirationDate: null,
    // 有效期
    manufacturerDate: null // 生产日期

  };

  if (headCode === '01') {
    // 主码，取14位
    result.mainCode = barCode.substring(2, 16); // 如果整体长度大于16位，再取6位，判断是生产日期、有效期

    if (barCode.length > 16) {
      var moreCode = barCode.substring(16);

      if (moreCode.length >= 8) {
        var idCode = moreCode.substring(0, 2);
        var dateVal = moreCode.substring(2, 8);
        dateVal = '20' + dateVal.substring(0, 2) + '-' + dateVal.substring(2, 4) + '-' + dateVal.substring(4, 6);

        if (idCode === '11') {
          result.manufacturerDate = dateVal;
        } else if (idCode === '17') {
          result.expirationDate = dateVal;
        }

        var trackingCode = moreCode.substring(8);

        if (trackingCode.length > 2) {
          if (trackingCode.substring(0, 2) === '10') {
            result.trackingCode = trackingCode.substring(2);
          }
        }
      }
    }
  } else if (headCode === '11') {// 生产日期
  } else if (headCode === '17') {// 失效日期、有效期
  }

  return result;
};

export default barcode;