import { request } from '@/api/_service.js';
export function listPersonnel(params) {
  return request({
    url: '/erp/user_list',
    method: 'get',
    params: params
  });
}
export function findPersonnel(params) {
  return request({
    url: '/erp/user_find',
    method: 'get',
    params: params
  });
}
export function createPersonnel(data) {
  return request({
    url: '/erp/user_create',
    method: 'post',
    data: data
  });
}
export function updatePersonnel(data) {
  return request({
    url: '/erp/user_update',
    method: 'put',
    data: data
  });
}
export function deletePersonnel(data) {
  return request({
    url: '/erp/user_delete',
    method: 'delete',
    data: data
  });
}