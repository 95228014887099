var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { align: "right" } },
        [
          _c("span", [_vm._v("总数量：" + _vm._s(_vm.totalQuantiy))]),
          _c("span", { staticStyle: { "margin-left": "20px" } }, [
            _vm._v("总金额：" + _vm._s(_vm.totalAmount))
          ])
        ]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList,
            border: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "highlight-current-row": "",
            "max-height": 400
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              width: "60",
              type: "index",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "specs.productNumber",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              prop: "main.productName",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specs.specificationModel",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "单位", prop: "specs.unit", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "数量", prop: "purchaseQuantity", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "采购单价",
              prop: "purchaseUnitPrice",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "采购金额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.purchaseQuantity *
                            scope.row.purchaseUnitPrice
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" }, attrs: { align: "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }