import { request } from '@/api/_service.js';
export function listInvoice(params) {
  return request({
    url: '/purchaseInvoiceErp/getPurchaseInvoiceList',
    method: 'get',
    params: params
  });
}
export function listInvoiceExport(params) {
  return request({
    url: '/purchaseInvoiceErp/getPurchaseInvoiceList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function listIncomeInvoice(params) {
  return request({
    url: '/erpApppayInvoiceErp/getErpApppayInvoiceList',
    method: 'get',
    params: params
  });
}
export function createIncomeInvoice(data) {
  return request({
    url: '/erpApppayInvoiceErp/createErpApppayInvoice',
    method: 'post',
    data: data
  });
}
export function updateIncomeInvoice(data) {
  return request({
    url: '/erpApppayInvoiceErp/updateErpApppayInvoice',
    method: 'put',
    data: data
  });
}
export function findIncomeInvoice(params) {
  return request({
    url: '/erpApppayInvoiceErp/findErpApppayInvoice',
    method: 'get',
    params: params
  });
}
export function deleteIncomeInvoice(data) {
  return request({
    url: '/erpApppayInvoiceErp/deleteErpApppayInvoice',
    method: 'delete',
    data: data
  });
} // 发票列表

export function listInvoiceNew(params) {
  return request({
    url: '/invoiceErp/getInvoiceList',
    method: 'get',
    params: params
  });
} // 发票列表导出

export function listInvoiceNewExport(params) {
  return request({
    url: '/invoiceErp/getInvoiceList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 发票条码解析

export function barcodeParsing(params) {
  return request({
    url: '/invoiceErp/barcodeParsing',
    method: 'get',
    params: params
  });
} // 创建发票

export function createInvoiceNew(data) {
  return request({
    url: '/invoiceErp/createInvoice',
    method: 'post',
    data: data
  });
} // 创建开发票(红冲)

export function createInvoiceRed(data) {
  return request({
    url: '/invoiceErp/createInvoiceRed',
    method: 'post',
    data: data
  });
} // 删除开发票

export function deleteInvoiceNew(data) {
  return request({
    url: '/invoiceErp/deleteInvoice',
    method: 'delete',
    data: data
  });
} // 批量删除开发票

export function deleteInvoiceByIdsNew(data) {
  return request({
    url: '/invoiceErp/deleteInvoiceByIds',
    method: 'delete',
    data: data
  });
} // 用id查询开发票

export function findInvoiceNew(params) {
  return request({
    url: '/invoiceErp/findInvoice',
    method: 'get',
    params: params
  });
} // 更新开发票

export function updateInvoiceNew(data) {
  return request({
    url: '/invoiceErp/updateInvoice',
    method: 'put',
    data: data
  });
} // 释放回款/作废

export function updateInvoiceStatusNew(data) {
  return request({
    url: '/invoiceErp/updateInvoiceStatus',
    method: 'put',
    data: data
  });
}
export function listInvoiceApp(params) {
  return request({
    url: '/invoiceAppErp/getInvoiceAppList',
    method: 'get',
    params: params
  });
}
export function listInvoiceAppExport(params) {
  return request({
    url: '/invoiceAppErp/getInvoiceAppList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findInvoiceApp(params) {
  return request({
    url: '/invoiceAppErp/findInvoiceApp',
    method: 'get',
    params: params
  });
} // 创建开票申请

export function createInvoiceApp(data) {
  return request({
    url: '/invoiceAppErp/createInvoiceApp',
    method: 'post',
    data: data
  });
} // 更新开票申请

export function updateInvoiceApp(data) {
  return request({
    url: '/invoiceAppErp/updateInvoiceApp',
    method: 'put',
    data: data
  });
} // 删除开票申请

export function deleteInvoiceApp(data) {
  return request({
    url: '/invoiceAppErp/deleteInvoiceApp',
    method: 'delete',
    data: data
  });
} // 批量删除开票申请

export function deleteInvoiceAppByIds(data) {
  return request({
    url: '/invoiceAppErp/deleteInvoiceAppByIds',
    method: 'delete',
    data: data
  });
}