var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增盒子",
        visible: _vm.boxDialogVisible,
        width: "600px",
        "before-close": _vm.boxDialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.boxDialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "packagesTypeForm",
          attrs: { model: _vm.boxForm, "label-width": "100px", size: "small" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模式" } },
            [
              _c(
                "el-radio-group",
                [
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("盒子模式")]),
                  _c("el-radio", { attrs: { label: 6 } }, [
                    _vm._v("列表模式(耗材)")
                  ]),
                  _c("el-radio", { attrs: { label: 9 } }, [
                    _vm._v("列表模式(工具)")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "名称" } }, [_c("el-input")], 1),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [_c("el-input", { attrs: { type: "textarea" } })],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-button", { attrs: { type: "primary" } }, [_vm._v("保存")]),
              _c("el-button", { on: { click: _vm.boxDialogClose } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }