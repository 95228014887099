import { request } from '@/api/_service.js';
export function listAppclass(params) {
  return request({
    url: '/appClassErp/getAppClassList',
    method: 'get',
    params: params
  });
}
export function findAppclass(params) {
  return request({
    url: '/appClassErp/findAppClass',
    method: 'get',
    params: params
  });
}
export function createAppclass(data) {
  return request({
    url: '/appClassErp/createAppClass',
    method: 'post',
    data: data
  });
}
export function updateAppclass(data) {
  return request({
    url: '/appClassErp/updateAppClass',
    method: 'put',
    data: data
  });
}
export function deleteAppclass(data) {
  return request({
    url: '/appClassErp/deleteAppClass',
    method: 'delete',
    data: data
  });
}