var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存汇总（规格）")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.pageForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.warehouseChange },
                        model: {
                          value: _vm.warehouseIds,
                          callback: function($$v) {
                            _vm.warehouseIds = $$v
                          },
                          expression: "warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "物资分类" } },
                  [
                    _c("el-cascader", {
                      attrs: {
                        options: _vm.classList,
                        props: _vm.props,
                        "show-all-levels": true,
                        clearable: "",
                        filterable: ""
                      },
                      model: {
                        value: _vm.pageForm.productLine,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "productLine", $$v)
                        },
                        expression: "pageForm.productLine"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "物资名称" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: "请输入物资名称", clearable: "" },
                      model: {
                        value: _vm.pageForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "productName", $$v)
                        },
                        expression: "pageForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "规格" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: "请输入规格", clearable: "" },
                          model: {
                            value: _vm.pageForm.specificationModel,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "specificationModel", $$v)
                            },
                            expression: "pageForm.specificationModel"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "编号" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "productNumber", $$v)
                            },
                            expression: "pageForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-bottom": "10px",
              "font-size": "14px",
              "text-align": "right"
            }
          },
          [
            _vm._v(" 库存总数量："),
            _c("span", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.stockQuantityCount))
            ])
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.pageForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.tableData
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.pageSizeChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }