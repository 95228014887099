import { request } from '@/api/_service.js';
export function listDefective(params) {
  return request({
    url: '/erpUnqualifiedErp/getErpUnqualifiedList',
    method: 'get',
    params: params
  });
}
export function listDefectiveExport(params) {
  return request({
    url: '/erpUnqualifiedErp/getErpUnqualifiedList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findDefective(params) {
  return request({
    url: '/erpUnqualifiedErp/findErpUnqualified',
    method: 'get',
    params: params
  });
}
export function createDefective(data) {
  return request({
    url: '/erpUnqualifiedErp/createErpUnqualified',
    method: 'post',
    data: data
  });
}
export function updateDefective(data) {
  return request({
    url: '/erpUnqualifiedErp/updateErpUnqualified',
    method: 'put',
    data: data
  });
}
export function deleteDefective(data) {
  return request({
    url: '/erpUnqualifiedErp/deleteErpUnqualified',
    method: 'delete',
    data: data
  });
}
export function deleteDefectiveByIds(data) {
  return request({
    url: '/erpUnqualifiedErp/deleteErpUnqualifiedByIds',
    method: 'delete',
    data: data
  });
}
export function listDefectiveDetail(params) {
  return request({
    url: '/erpUnqualifiedInfoErp/getErpUnqualifiedInfoList',
    method: 'get',
    params: params
  });
}
export function createErpUnqualifiedInfo(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/createErpUnqualifiedInfo',
    method: 'post',
    data: data
  });
}
export function createErpUnqualifiedInfos(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/createErpUnqualifiedInfos',
    method: 'post',
    data: data
  });
}
export function updateErpUnqualifiedInfo(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/updateErpUnqualifiedInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpUnqualifiedInfo(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/deleteErpUnqualifiedInfo',
    method: 'delete',
    data: data
  });
}
export function deleteErpUnqualifiedInfoByIds(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/deleteErpUnqualifiedInfoByIds',
    method: 'delete',
    data: data
  });
}
export function updateErpUnqualifiedInfos(data) {
  return request({
    url: '/erpUnqualifiedInfoErp/updateErpUnqualifiedInfos',
    method: 'put',
    data: data
  });
}
export function createErpReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouseErp/createErpReturnWarehouse',
    method: 'post',
    data: data
  });
}
export function deleteErpReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouseErp/deleteErpReturnWarehouse',
    method: 'delete',
    data: data
  });
}
export function deleteErpReturnWarehouseByIds(data) {
  return request({
    url: '/erpReturnWarehouseErp/deleteErpReturnWarehouseByIds',
    method: 'delete',
    data: data
  });
}
export function findErpReturnWarehouse(params) {
  return request({
    url: '/erpReturnWarehouseErp/findErpReturnWarehouse',
    method: 'get',
    params: params
  });
}
export function getErpReturnWarehouseList(params) {
  return request({
    url: '/erpReturnWarehouseErp/getErpReturnWarehouseList',
    method: 'get',
    params: params
  });
}
export function getErpReturnWarehouseListExport(params) {
  return request({
    url: '/erpReturnWarehouseErp/getErpReturnWarehouseList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateErpReturnWarehouse(data) {
  return request({
    url: '/erpReturnWarehouseErp/updateErpReturnWarehouse',
    method: 'put',
    data: data
  });
}
export function createErpReturnWarehouseInfos(data) {
  return request({
    url: '/erpReturnWarehouseInfoErp/createErpReturnWarehouseInfos',
    method: 'post',
    data: data
  });
}
export function getErpReturnWarehouseInfoList(params) {
  return request({
    url: '/erpReturnWarehouseInfoErp/getErpReturnWarehouseInfoList',
    method: 'get',
    params: params
  });
}