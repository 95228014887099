import { request } from '@/api/_service.js';
export function listComplain(params) {
  return request({
    url: '/erpComplaintsErp/getErpComplaintsList',
    method: 'get',
    params: params
  });
}
export function listComplainExport(params) {
  return request({
    url: '/erpComplaintsErp/getErpComplaintsList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findComplain(params) {
  return request({
    url: '/erpComplaintsErp/findErpComplaints',
    method: 'get',
    params: params
  });
}
export function createComplain(data) {
  return request({
    url: '/erpComplaintsErp/createErpComplaints',
    method: 'post',
    data: data
  });
}
export function updateComplain(data) {
  return request({
    url: '/erpComplaintsErp/updateErpComplaints',
    method: 'put',
    data: data
  });
}
export function deleteComplain(data) {
  return request({
    url: '/erpComplaintsErp/deleteErpComplaints',
    method: 'delete',
    data: data
  });
}
export function deleteComplainByIds(data) {
  return request({
    url: '/erpComplaintsErp/deleteErpComplaintsByIds',
    method: 'delete',
    data: data
  });
}
export function listComplainDetail(params) {
  return request({
    url: '/erpComplaintsInfoErp/getErpComplaintsInfoList',
    method: 'get',
    params: params
  });
}
export function createErpComplaintsInfo(data) {
  return request({
    url: '/erpComplaintsInfoErp/createErpComplaintsInfo',
    method: 'post',
    data: data
  });
}
export function createErpComplaintsInfos(data) {
  return request({
    url: '/erpComplaintsInfoErp/createErpComplaintsInfos',
    method: 'post',
    data: data
  });
}
export function updateErpComplaintsInfo(data) {
  return request({
    url: '/erpComplaintsInfoErp/updateErpComplaintsInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpComplaintsInfo(data) {
  return request({
    url: '/erpComplaintsInfoErp/deleteErpComplaintsInfo',
    method: 'delete',
    data: data
  });
}