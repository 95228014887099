import "core-js/modules/es.array.concat.js";
import axios from 'axios';
import { MessageBox } from 'element-ui'; // 挂载到vm实例上

var isNewVersion = function isNewVersion() {
  var url = "//".concat(window.location.host, "/version.json?t=").concat(new Date().getTime());
  axios.get(url).then(function (res) {
    if (res.status === 200) {
      var vueVersion = res.data.version;
      var localVueVersion = localStorage.getItem('vueVersion');

      if (localVueVersion !== vueVersion) {
        localStorage.setItem('vueVersion', vueVersion);
        MessageBox.alert('检测到新版本，点击确定更新', '更新提示', {
          // 模拟this.$alert
          confirmButtonText: '确定',
          callback: function callback() {
            window.location.reload();
          }
        });
      }
    }
  });
};

export default {
  isNewVersion: isNewVersion
};