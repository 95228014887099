var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("货架管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary"
                  },
                  on: { click: _vm.itemAdd }
                },
                [_vm._v("新增货架")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "space-between"
                }
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.searchForm,
                      size: "small",
                      disabled: _vm.formLoading,
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchFormSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "货架编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "货架编号", clearable: "" },
                          model: {
                            value: _vm.searchForm.shelfNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "shelfNumber", $$v)
                            },
                            expression: "searchForm.shelfNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "货架名称" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "货架名称", clearable: "" },
                          model: {
                            value: _vm.searchForm.shelfName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "shelfName", $$v)
                            },
                            expression: "searchForm.shelfName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", "native-type": "submit" },
                            on: { click: _vm.searchFormSubmit }
                          },
                          [_vm._v("查询")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.changeListType }
                      },
                      [_vm._v(_vm._s(_vm.listTypeName))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm.listType === "list"
          ? [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  attrs: {
                    data: _vm.listData,
                    border: "",
                    stripeDELETE: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.searchForm)
                      },
                      label: "序号",
                      width: "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelfNumber",
                      label: "货架编号",
                      align: "center",
                      width: "200",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shelfName",
                      label: "货架名称",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "laneLightCoding",
                      label: "巷道灯编码",
                      align: "center",
                      width: "200",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "numberOfRows",
                      label: "行",
                      align: "center",
                      width: "100",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "numberOfColumns",
                      label: "列",
                      align: "center",
                      width: "100",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "capacity",
                      label: "容量",
                      align: "center",
                      width: "150"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.capacity) +
                                  "/" +
                                  _vm._s(
                                    scope.row.numberOfRows *
                                      scope.row.numberOfColumns *
                                      scope.row.capacity
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1904045122
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isEnable",
                      label: "是否启用",
                      width: "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#409EFF",
                                  "inactive-color": "#999",
                                  "active-value": 1,
                                  "inactive-value": 2
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.itemStatusSwitch(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.isEnable,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "isEnable", $$v)
                                  },
                                  expression: "scope.row.isEnable"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4170451614
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "light",
                      label: "亮灯",
                      width: "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#409EFF",
                                  "inactive-color": "#999",
                                  "active-value": true,
                                  "inactive-value": false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.lightSwitch(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.light,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "light", $$v)
                                  },
                                  expression: "scope.row.light"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2355328012
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "260", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "control-column" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.locationList(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("货位列表")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.itemEdit(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.itemDelete(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3127615072
                    )
                  })
                ],
                1
              )
            ]
          : _vm._l(_vm.listData, function(item, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "shelves-table" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(_vm._s(item.shelfName))
                  ]),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(item.chartList, function(item2, index2) {
                      return _c(
                        "div",
                        { key: index2, staticClass: "row" },
                        _vm._l(item2, function(item3, index3) {
                          return _c(
                            "div",
                            {
                              key: index3,
                              class: _vm.locationStateClass(item3)
                            },
                            [_vm._v(_vm._s(item3.locationNumber))]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                ])
              ])
            })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "current-change": _vm.searchPageChange,
              "size-change": _vm.searchSizeChange
            }
          })
        ],
        1
      ),
      _c("FormComponents", {
        ref: "FormComponents",
        on: { confirm: _vm.loadListData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }