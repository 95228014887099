var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.inWarehouseStatus > 1,
              "label-suffix": ":",
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "warehouseId",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: "warehouseId" + item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: item.status === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0 ? true : false
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: "manufacturerId" + item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库人", prop: "inWarehouseUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.inWarehouseUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "inWarehouseUid", $$v)
                              },
                              expression: "form.inWarehouseUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "inWarehouseUid" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间"
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 18 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            maxlength: "500",
                            "suffix-icon": "el-icon-edit",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.selectSpecs }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.importFromOutOrderNumber()
                      }
                    }
                  },
                  [_vm._v("平台寄售出库单号导入")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.importFromExcel()
                      }
                    }
                  },
                  [_vm._v("从EXCEL中导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", [
                  _vm.activeTabId === 1
                    ? _c("span", [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 总金额（不含税）："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmountExclusive()))]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 总金额（含税）："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmount()))]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 税额合计："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmountTax()))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" 总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("） ")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: !_vm.form.id
                        },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印入库单")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanImportBox,
                expression: "showScanImportBox"
              }
            ]
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "importFormRef",
                attrs: {
                  model: _vm.importForm,
                  rules: _vm.importFormRules,
                  "label-width": "0px",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.importFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "deliveryOrderNo", "label-width": "0px" } },
                  [
                    _c("el-input", {
                      ref: "importInputBoxRef",
                      staticClass: "import-input-box",
                      attrs: {
                        placeholder: "请输入、扫描出库单号",
                        clearable: ""
                      },
                      model: {
                        value: _vm.importForm.deliveryOrderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.importForm, "deliveryOrderNo", $$v)
                        },
                        expression: "importForm.deliveryOrderNo"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "mini",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _vm.returnList.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "tab-list-grey",
                      staticStyle: { margin: "0" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 1
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(1)
                            }
                          }
                        },
                        [_vm._v("入库单详情")]
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 2
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(2)
                            }
                          }
                        },
                        [_vm._v("退货详情")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 1,
                    expression: "activeTabId===1"
                  }
                ]
              },
              [
                _c(
                  "virtual-scroll",
                  {
                    ref: "virtualScroll",
                    attrs: {
                      data: _vm.detailList,
                      "item-size": 42,
                      "row-key": "rowIndex",
                      "key-prop": "rowIndex"
                    },
                    on: { change: _vm.virtualScrollChange }
                  },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.detailLoading,
                            expression: "detailLoading"
                          }
                        ],
                        ref: "detailListTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "detailListTable",
                          data: _vm.detailListForm.data,
                          border: "",
                          size: "mini",
                          "highlight-current-row": "",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          },
                          "row-key": _vm.detailRowKey,
                          "row-class-name": _vm.rowClassName,
                          "max-height": "450",
                          height: "450"
                        },
                        on: { "row-click": _vm.detailRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "rowIndex",
                            label: "序号",
                            width: "60",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.rowIndex + 1))
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productNumber",
                            label: "产品编号",
                            width: "200",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "productName",
                            label: "物资名称",
                            width: "200",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specificationModel",
                            label: "规格型号",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "brandName",
                            label: "品牌",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "inWarehouseQuantity",
                            label: "入库数量",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "inWarehouseQuantity" +
                                            row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." +
                                              $index +
                                              ".inWarehouseQuantity",
                                            rules:
                                              _vm.detailListFormRules
                                                .inWarehouseQuantity
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            ref:
                                              "inWarehouseQuantity-" +
                                              row.rowIndex,
                                            attrs: {
                                              size: "mini",
                                              placeholder: "入库数量"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              },
                                              input: function($event) {
                                                return _vm.inWarehouseQuantityInput(
                                                  row
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.nextFocus(
                                                    "afterTaxPrice-" +
                                                      row.rowIndex,
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              }
                                            },
                                            model: {
                                              value: row.inWarehouseQuantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "inWarehouseQuantity",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "row.inWarehouseQuantity"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.inWarehouseQuantity))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "采购金额(含税)",
                            width: "120",
                            align: "center",
                            prop: "totalAmount"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.sumRowAmount(scope.row)) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "采购金额(不含税)",
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.sumRowAmountTaxExclusive(scope.row)
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "afterTaxPrice",
                            label: "采购单价(含税)",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c("el-input", {
                                        directives: [
                                          {
                                            name: "enter-number",
                                            rawName: "v-enter-number",
                                            value: { precision: 2, min: 0 },
                                            expression: "{precision:2,min:0}"
                                          }
                                        ],
                                        ref: "afterTaxPrice-" + $index,
                                        attrs: { size: "small" },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.afterTaxPrice,
                                          callback: function($$v) {
                                            _vm.$set(row, "afterTaxPrice", $$v)
                                          },
                                          expression: "row.afterTaxPrice"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(row.afterTaxPrice))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "taxRate",
                            label: "税率",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "data." + $index + ".taxRate",
                                        rules: _vm.detailListFormRules.taxRate
                                      }
                                    },
                                    [
                                      _vm.form.inWarehouseStatus === 1
                                        ? _c(
                                            "el-input",
                                            {
                                              directives: [
                                                {
                                                  name: "enter-number",
                                                  rawName: "v-enter-number",
                                                  value: {
                                                    precision: 2,
                                                    min: 0,
                                                    max: 100
                                                  },
                                                  expression:
                                                    "{precision:2,min:0,max:100}"
                                                }
                                              ],
                                              ref: "taxRate-" + $index,
                                              attrs: { size: "mini" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.detailSaveOne(row)
                                                }
                                              },
                                              model: {
                                                value: row.taxRate,
                                                callback: function($$v) {
                                                  _vm.$set(row, "taxRate", $$v)
                                                },
                                                expression: "row.taxRate"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("%")]
                                              )
                                            ],
                                            2
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.taxRate == undefined ||
                                                  row.taxRate == null
                                                  ? 0
                                                  : row.taxRate
                                              ) + "%"
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "税额",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.calculateTaxAmount(scope.row)
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "采购单价(不含税)",
                            width: "120",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.calculateTaxPurchaseUnitPrice(
                                          scope.row
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "生产批号",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key: "batchNumber" + row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." + $index + ".batchNumber",
                                            rules:
                                              _vm.detailListFormRules
                                                .batchNumber
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "batchNumber-" + row.rowIndex,
                                            attrs: {
                                              size: "mini",
                                              placeholder: "生产批号"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.nextFocus(
                                                    "trackingCode-" +
                                                      row.rowIndex,
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              }
                                            },
                                            model: {
                                              value: row.batchNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "batchNumber",
                                                  $$v
                                                )
                                              },
                                              expression: "row.batchNumber"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.batchNumber))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "序列号",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key: "trackingCode" + row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." + $index + ".trackingCode"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "trackingCode-" + row.rowIndex,
                                            attrs: {
                                              size: "mini",
                                              placeholder: "序列号"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.nextFocus(
                                                    "manufacturerDate-" +
                                                      row.rowIndex,
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              }
                                            },
                                            model: {
                                              value: row.trackingCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "trackingCode",
                                                  $$v
                                                )
                                              },
                                              expression: "row.trackingCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.trackingCode))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "manufacturerDate",
                            label: "生产日期",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "manufacturerDate" + row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." +
                                              $index +
                                              ".manufacturerDate",
                                            rules:
                                              _vm.detailListFormRules
                                                .manufacturerDate
                                          }
                                        },
                                        [
                                          _c("el-date-picker", {
                                            ref:
                                              "manufacturerDatePicker-" +
                                              row.rowIndex,
                                            staticStyle: {
                                              position: "absolute",
                                              "z-index": "-100"
                                            },
                                            attrs: {
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              editable: false,
                                              type: "date"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.manufacturerDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "manufacturerDate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.manufacturerDate"
                                            }
                                          }),
                                          _c(
                                            "el-input",
                                            {
                                              ref:
                                                "manufacturerDate-" +
                                                row.rowIndex,
                                              staticClass: "date-input",
                                              attrs: { size: "mini" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.detailSaveOne(row)
                                                }
                                              },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return function(event) {
                                                    return _vm.nextFocus(
                                                      "validityDate-" +
                                                        row.rowIndex,
                                                      event
                                                    )
                                                  }.apply(null, arguments)
                                                }
                                              },
                                              model: {
                                                value: row.manufacturerDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "manufacturerDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.manufacturerDate"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-date",
                                                attrs: { slot: "append" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showDatePicker(
                                                      "manufacturerDatePicker-" +
                                                        row.rowIndex
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.manufacturerDate))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "有效期",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        [
                                          _c("el-date-picker", {
                                            ref:
                                              "validityDatePicker-" +
                                              row.rowIndex,
                                            staticStyle: {
                                              position: "absolute",
                                              "z-index": "-100"
                                            },
                                            attrs: {
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              editable: false,
                                              type: "date"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.validityDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "validityDate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.validityDate"
                                            }
                                          }),
                                          _c(
                                            "el-input",
                                            {
                                              ref:
                                                "validityDate-" + row.rowIndex,
                                              staticClass: "date-input",
                                              attrs: { size: "mini" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.detailSaveOne(row)
                                                }
                                              },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return function(event) {
                                                    return _vm.nextFocus(
                                                      "sterilizationBatchNumber-" +
                                                        row.rowIndex,
                                                      event
                                                    )
                                                  }.apply(null, arguments)
                                                }
                                              },
                                              model: {
                                                value: row.validityDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "validityDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.validityDate"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-date",
                                                attrs: { slot: "append" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showDatePicker(
                                                      "validityDatePicker-" +
                                                        row.rowIndex
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.validityDate))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "货架",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key: "shelfName" + row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." + $index + ".shelfName"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "date-input",
                                              attrs: {
                                                size: "mini",
                                                readonly: true
                                              },
                                              model: {
                                                value: row.shelfName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "shelfName",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.shelfName"
                                              }
                                            },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-caret-bottom"
                                                },
                                                on: {
                                                  click: function(e, row) {
                                                    return _vm.showShelf(e, row)
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.shelfName))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "货位",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key: "locationNumber" + row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." +
                                              $index +
                                              ".locationNumber"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "date-input",
                                              attrs: {
                                                size: "mini",
                                                readonly: true
                                              },
                                              model: {
                                                value: row.locationNumber,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "locationNumber",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.locationNumber"
                                              }
                                            },
                                            [
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "append",
                                                  icon: "el-icon-caret-bottom"
                                                },
                                                on: {
                                                  click: function(e, row) {
                                                    return _vm.showLocation(
                                                      e,
                                                      row
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.locationNumber))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "灭菌批号",
                            width: "100",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "sterilizationBatchNumber" +
                                            row.rowIndex,
                                          attrs: {
                                            prop:
                                              "data." +
                                              $index +
                                              ".sterilizationBatchNumber"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            ref:
                                              "sterilizationBatchNumber-" +
                                              row.rowIndex,
                                            attrs: { size: "mini" },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.nextFocus(
                                                    "sterilizationDate-" +
                                                      row.rowIndex,
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              }
                                            },
                                            model: {
                                              value:
                                                row.sterilizationBatchNumber,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "sterilizationBatchNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.sterilizationBatchNumber"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(row.sterilizationBatchNumber)
                                        )
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "sterilizationDate",
                            label: "灭菌效期",
                            width: "160",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.form.inWarehouseStatus == 1
                                    ? _c(
                                        "el-form-item",
                                        [
                                          _c("el-date-picker", {
                                            ref:
                                              "sterilizationDatePicker-" +
                                              row.rowIndex,
                                            staticStyle: {
                                              position: "absolute",
                                              "z-index": "-100"
                                            },
                                            attrs: {
                                              format: "yyyy-MM-dd",
                                              "value-format": "yyyy-MM-dd",
                                              editable: false,
                                              type: "date"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            model: {
                                              value: row.sterilizationDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "sterilizationDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "row.sterilizationDate"
                                            }
                                          }),
                                          _c(
                                            "el-input",
                                            {
                                              ref:
                                                "sterilizationDate-" +
                                                row.rowIndex,
                                              staticClass: "date-input",
                                              attrs: { size: "mini" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.detailSaveOne(row)
                                                }
                                              },
                                              nativeOn: {
                                                keyup: function($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return function(event) {
                                                    return _vm.nextFocus(
                                                      "remark-" + row.rowIndex,
                                                      event
                                                    )
                                                  }.apply(null, arguments)
                                                }
                                              },
                                              model: {
                                                value: row.sterilizationDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "sterilizationDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "row.sterilizationDate"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-date",
                                                attrs: { slot: "append" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showDatePicker(
                                                      "sterilizationDatePicker-" +
                                                        row.rowIndex
                                                    )
                                                  }
                                                },
                                                slot: "append"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(row.sterilizationDate))
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "registrationNumber",
                            label: "注册证号",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "unit",
                            label: "单位",
                            width: "100",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _vm.form.inWarehouseStatus === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key: "remark" + row.rowIndex,
                                          attrs: {
                                            prop: "data." + $index + ".remark"
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "remark-" + row.rowIndex,
                                            attrs: {
                                              size: "mini",
                                              placeholder: "备注"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.detailSaveOne(row)
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return function(event) {
                                                  return _vm.nextFocus(
                                                    "inWarehouseQuantity-" +
                                                      (row.rowIndex + 1),
                                                    event
                                                  )
                                                }.apply(null, arguments)
                                              }
                                            },
                                            model: {
                                              value: row.remark,
                                              callback: function($$v) {
                                                _vm.$set(row, "remark", $$v)
                                              },
                                              expression: "row.remark"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v(_vm._s(row.remark))])
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 2,
                    expression: "activeTabId===2"
                  }
                ]
              },
              [
                _c(
                  "el-table",
                  {
                    key: "returnListTable" + _vm.timestamp,
                    ref: "returnListTableRef",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.returnList.slice(
                        (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                        _vm.returnForm.page * _vm.returnForm.pageSize
                      ),
                      border: "",
                      size: "small",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      "highlight-current-row": ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { width: "80", align: "center", label: "序号" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.indexMethod(
                                      scope.$index,
                                      _vm.returnForm
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productNumber",
                        label: "产品编号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.productName",
                        label: "产品名称",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.specificationModel",
                        label: "规格型号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.manufacturer",
                        label: "生产厂家",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.registrationNumber",
                        label: "注册证号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.batch_number",
                        label: "批号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.trackingCode",
                        label: "序列号",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.unit",
                        label: "单位",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnWarehouseQuantity",
                        label: "退货数量",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "returnPrice",
                        label: "单价",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货金额",
                        width: "150",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (
                                      row.returnPrice *
                                      row.returnWarehouseQuantity
                                    ).toFixed(2)
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        "page-sizes": [10, 20, 50, 100],
                        total: _vm.returnForm.total,
                        "current-page": _vm.returnForm.page,
                        "page-size": _vm.returnForm.pageSize
                      },
                      on: {
                        "size-change": _vm.returnSizeChange,
                        "current-change": _vm.returnPageChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "shelfNumberPopover",
                attrs: {
                  placement: "right-start",
                  width: "250",
                  trigger: "click"
                },
                model: {
                  value: _vm.shelfNumberVisible,
                  callback: function($$v) {
                    _vm.shelfNumberVisible = $$v
                  },
                  expression: "shelfNumberVisible"
                }
              },
              [
                _c("ShelfComponents", {
                  ref: "ShelfComponents",
                  on: { select: _vm.shelfSelect }
                })
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "locationNumberPopover",
                attrs: {
                  placement: "right-start",
                  width: "300",
                  trigger: "click"
                },
                model: {
                  value: _vm.locationNumberVisible,
                  callback: function($$v) {
                    _vm.locationNumberVisible = $$v
                  },
                  expression: "locationNumberVisible"
                }
              },
              [
                _c("LocationComponents", {
                  ref: "LocationComponents",
                  on: { select: _vm.locationSelect }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("入库人：" + _vm._s(_vm.form.inWarehouseName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("入库时间：" + _vm._s(_vm.form.inWarehouseTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.form.examineName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.form.accountName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              disabled: !(
                                _vm.form.inWarehouseStatus == 1 &&
                                _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 1 || !_vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-close" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 6
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formCheck }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-check" }),
                            _vm._v(" 审核")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnCheck }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销审核")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.specsAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.loadOrder }
      }),
      _c("ImportFromExcelComponent", {
        ref: "ImportFromExcelComponentRef",
        on: { add: _vm.specsAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }