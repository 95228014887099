import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js';
export function listRetailer(params) {
  return request({
    url: '/erpFaOrgDistributor/getFaOrgDistributorList',
    method: 'get',
    params: params
  });
}
export function listDealerUserRetailer(params) {
  return request({
    url: '/erp/get_erp_dealer_user_manufacturer_list',
    method: 'get',
    params: params
  });
}
export function findRetailer(params) {
  return request({
    url: '/erpFaOrgDistributor/findFaOrgDistributor',
    method: 'get',
    params: params
  });
}
export function createRetailer(data) {
  return request({
    url: '/erpFaOrgDistributor/createFaOrgDistributor',
    method: 'post',
    data: data
  });
}
export function updateRetailer(data) {
  return request({
    url: '/erpFaOrgDistributor/updateFaOrgDistributor',
    method: 'put',
    data: data
  });
}
export function deleteRetailer(data) {
  return request({
    url: '/erpFaOrgDistributor/deleteFaOrgDistributor',
    method: 'delete',
    data: data
  });
}
export function deleteRetailerByIds(data) {
  return request({
    url: '/erpFaOrgDistributor/deleteFaOrgDistributorByIds',
    method: 'delete',
    data: data
  });
}
export function findUserManu(params) {
  return request({
    url: '/userManuErp/findUserManu',
    method: 'get',
    params: params
  });
}
export function setUserManu(data) {
  return request({
    url: '/userManuErp/setUserManu',
    method: 'put',
    data: data
  });
}
export function listRetailerUser(params) {
  return request({
    url: '/dealerUserErp/getDealerUserList',
    method: 'get',
    params: params
  });
}
export function createRetailerUser(data) {
  return request({
    url: '/dealerUserErp/createDealerUser',
    method: 'post',
    data: data
  });
}
export function updateRetailerUser(data) {
  return request({
    url: '/dealerUserErp/updateDealerUser',
    method: 'put',
    data: data
  });
}
export function deleteRetailerUser(data) {
  return request({
    url: '/dealerUserErp/deleteDealerUserManu',
    method: 'delete',
    data: data
  });
}
export function updateDealerUserManu(data) {
  return request({
    url: '/dealerUserErp/updateDealerUserManu',
    method: 'put',
    data: data
  });
}
export function listDealerUserAddress(params) {
  return request({
    url: '/dealerUserAddressErp/getDealerUserAddressList',
    method: 'get',
    params: params
  });
}
export function downloadSupplierTemplate(params) {
  return request({
    url: '/erpFaOrgDistributor/getSupplierTemplate4',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}