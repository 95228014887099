//
//
//
//
//
//
//
//
import menu from '@/menu/modules/base';
export default {
  data: function data() {
    return {
      menu: menu,
      banner: {
        title: 'PLUGIN',
        subTitle: 'D2Admin 集成了许多实用插件'
      }
    };
  }
};