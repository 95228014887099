import { request } from '@/api/_service.js';
export function listDestroy(params) {
  return request({
    url: '/erpDestructionErp/getErpDestructionList',
    method: 'get',
    params: params
  });
}
export function listDestroyExport(params) {
  return request({
    url: '/erpDestructionErp/getErpDestructionList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findDestroy(params) {
  return request({
    url: '/erpDestructionErp/findErpDestruction',
    method: 'get',
    params: params
  });
}
export function createDestroy(data) {
  return request({
    url: '/erpDestructionErp/createErpDestruction',
    method: 'post',
    data: data
  });
}
export function updateDestroy(data) {
  return request({
    url: '/erpDestructionErp/updateErpDestruction',
    method: 'put',
    data: data
  });
}
export function deleteDestroy(data) {
  return request({
    url: '/erpDestructionErp/deleteErpDestruction',
    method: 'delete',
    data: data
  });
}
export function deleteDestroyByIds(data) {
  return request({
    url: '/erpDestructionErp/deleteErpDestructionByIds',
    method: 'delete',
    data: data
  });
}
export function listDestroyDetail(params) {
  return request({
    url: '/erpDestructionInfoErp/getErpDestructionInfoList',
    method: 'get',
    params: params
  });
}
export function createErpDestructionInfo(data) {
  return request({
    url: '/erpDestructionInfoErp/createErpDestructionInfo',
    method: 'post',
    data: data
  });
}
export function createErpDestructionInfos(data) {
  return request({
    url: '/erpDestructionInfoErp/createErpDestructionInfos',
    method: 'post',
    data: data
  });
}
export function updateErpUnqualifiedInfo(data) {
  return request({
    url: '/erpDestructionInfoErp/updateErpDestructionInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpDestructionInfo(data) {
  return request({
    url: '/erpDestructionInfoErp/deleteErpDestructionInfo',
    method: 'delete',
    data: data
  });
}
export function deleteErpUnqualifiedInfoByIds(data) {
  return request({
    url: '/erpDestructionInfoErp/deleteErpDestructionInfoByIds',
    method: 'delete',
    data: data
  });
}