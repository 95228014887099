var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "batchListTable",
              attrs: {
                data: _vm.dbList,
                width: "100%",
                border: "",
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                size: "mini",
                "row-key": _vm.detailRowKey,
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品编号",
                  prop: "productNumber",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  prop: "main.productName",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格型号",
                  prop: "specs.specificationModel",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  prop: "batchNumber",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序列号",
                  prop: "trackingCode",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "数量",
                  prop: "inWarehouseQuantity",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产日期",
                  prop: "manufacturerDate",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "有效期",
                  prop: "validityDate",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌批号",
                  prop: "sterilizationBatchNumber",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌效期",
                  prop: "sterilizationDate",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "采购单价",
                  prop: "afterTaxPrice",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单位",
                  prop: "specs.unit",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "注册证号",
                  prop: "main.registrationNumber",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.form.total,
                  "current-page": _vm.form.page,
                  "page-size": _vm.form.pageSize
                },
                on: { "current-change": _vm.formPageChange }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }