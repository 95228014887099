//
//
//
//
import FormComponent from './form.vue';
export default {
  name: 'sales-agent-report-create',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    FormComponent: FormComponent
  }
};