var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d2-layout-header-aside-group",
      class: { grayMode: _vm.grayActive },
      style: _vm.styleLayoutMainGroup
    },
    [
      _c("div", { staticClass: "d2-layout-header-aside-mask" }),
      _c(
        "div",
        {
          staticClass: "d2-layout-header-aside-content",
          attrs: { flex: "dir:top" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-theme-header",
              style: { opacity: this.searchActive ? 0.5 : 1 },
              attrs: { "flex-box": "0", flex: "" }
            },
            [
              _c(
                "router-link",
                {
                  class: {
                    "logo-group": true,
                    "logo-transition": _vm.asideTransition
                  },
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth
                  },
                  attrs: { to: "/index", "flex-box": "0" }
                },
                [
                  _vm.asideCollapse
                    ? _c("img", {
                        attrs: {
                          src:
                            _vm.$baseUrl +
                            "image/theme/" +
                            _vm.themeActiveSetting.name +
                            "/logo/icon-only.png"
                        }
                      })
                    : _c("img", {
                        attrs: {
                          src:
                            _vm.$baseUrl +
                            "image/theme/" +
                            _vm.themeActiveSetting.name +
                            "/logo/all.png"
                        }
                      })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "toggle-aside-btn",
                  attrs: { "flex-box": "0" },
                  on: { click: _vm.handleToggleAside }
                },
                [_c("d2-icon", { attrs: { name: "bars" } })],
                1
              ),
              _c("d2-menu-header", {
                attrs: { "flex-box": "1" },
                on: { click: _vm.menuHeaderClick }
              }),
              _c(
                "div",
                { staticClass: "d2-header-right", attrs: { "flex-box": "0" } },
                [
                  _c("d2-header-log"),
                  _c("d2-header-fullscreen"),
                  _c("d2-header-theme"),
                  _c("d2-header-notify", { ref: "d2HeaderNotify" }),
                  _c("d2-header-user", {
                    on: {
                      menuClose: _vm.sideClick,
                      settings: _vm.settings,
                      agencyReload: _vm.agencyReload
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d2-theme-container",
              attrs: { "flex-box": "1", flex: "" }
            },
            [
              _c(
                "div",
                {
                  ref: "aside",
                  class: {
                    "d2-theme-container-aside": true,
                    "d2-theme-container-transition": _vm.asideTransition
                  },
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth,
                    opacity: this.searchActive ? 0.5 : 1
                  },
                  attrs: { "flex-box": "0" }
                },
                [_c("d2-menu-side", { on: { click: _vm.sideClick } })],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d2-theme-container-main",
                  attrs: { "flex-box": "1", flex: "" }
                },
                [
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "" }
                          },
                          [
                            _c("d2-panel-search", {
                              ref: "panelSearch",
                              on: { close: _vm.searchPanelClose }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.menuActive,
                            expression: "menuActive"
                          }
                        ],
                        staticClass: "d2-theme-container-main-layer",
                        attrs: { flex: "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              height: "100%",
                              background: "#fff",
                              overflow: "auto"
                            }
                          },
                          _vm._l(_vm.tempMenuList, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mlist" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(item.title))
                                ]),
                                Object.prototype.hasOwnProperty.call(
                                  item,
                                  "children"
                                )
                                  ? _c(
                                      "div",
                                      { staticClass: "list" },
                                      _vm._l(item.children, function(
                                        item2,
                                        index2
                                      ) {
                                        return _c(
                                          "el-link",
                                          {
                                            key: index2,
                                            staticClass: "item",
                                            attrs: { underline: false },
                                            on: {
                                              click: function($event) {
                                                return _vm.navClick(item2.path)
                                              }
                                            }
                                          },
                                          [
                                            item2.icon.indexOf(".svg") >= 0
                                              ? _c("d2-icon-svg", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                    padding: "30px",
                                                    color: "#404243",
                                                    "border-radius": "5px"
                                                  },
                                                  style: {
                                                    background: _vm.getBackgroundColor()
                                                  },
                                                  attrs: {
                                                    name: item2.icon.substring(
                                                      0,
                                                      item2.icon.indexOf(".svg")
                                                    )
                                                  }
                                                })
                                              : _c("i", {
                                                  class: "fa fa-" + item2.icon,
                                                  style: {
                                                    background: _vm.getBackgroundColor()
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                }),
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v(_vm._s(item2.title))
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "list" },
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            key: index,
                                            staticClass: "item",
                                            attrs: { underline: false },
                                            on: {
                                              click: function($event) {
                                                return _vm.navClick(item.path)
                                              }
                                            }
                                          },
                                          [
                                            item.icon.indexOf(".svg") >= 0
                                              ? _c("d2-icon-svg", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                    padding: "30px",
                                                    color: "#404243",
                                                    "border-radius": "5px"
                                                  },
                                                  style: {
                                                    background: _vm.getBackgroundColor()
                                                  },
                                                  attrs: {
                                                    name: item.icon.substring(
                                                      0,
                                                      item.icon.indexOf(".svg")
                                                    )
                                                  }
                                                })
                                              : _c("i", {
                                                  class: "fa fa-" + item.icon,
                                                  style: {
                                                    background: _vm.getBackgroundColor()
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                }),
                                            _c("div", { staticClass: "text" }, [
                                              _vm._v(_vm._s(item.title))
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  ]),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    !_vm.searchActive
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.menuActive,
                                expression: "!menuActive"
                              }
                            ],
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "dir:top" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-header",
                                attrs: { "flex-box": "0" }
                              },
                              [_c("d2-tabs")],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-body",
                                attrs: { "flex-box": "1" }
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: _vm.transitionActive
                                        ? "fade-transverse"
                                        : ""
                                    }
                                  },
                                  [
                                    _c(
                                      "keep-alive",
                                      { attrs: { include: _vm.keepAlive } },
                                      [
                                        _c("router-view", {
                                          key: _vm.routerViewKey
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("SettingsComponents", { ref: "SettingsComponents" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }