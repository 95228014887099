var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.command === "ADD"
        ? _c("div", {
            staticStyle: { "margin-bottom": "10px" },
            attrs: { align: "right" }
          })
        : _vm._e(),
      _c(
        "el-form",
        {
          attrs: { size: "mini", inline: true, "label-suffix": ":" },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchFormSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "批号", prop: "batchNumber" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                on: { change: _vm.searchFormSubmit },
                model: {
                  value: _vm.searchForm.batchNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "batchNumber", $$v)
                  },
                  expression: "searchForm.batchNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "序列号", prop: "trackingCode" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                on: { change: _vm.searchFormSubmit },
                model: {
                  value: _vm.searchForm.trackingCode,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "trackingCode", $$v)
                  },
                  expression: "searchForm.trackingCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "归属公司", prop: "companyName" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    filterable: ""
                  },
                  on: { change: _vm.searchFormSubmit },
                  model: {
                    value: _vm.searchForm.companyName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchForm, "companyName", $$v)
                    },
                    expression: "searchForm.companyName"
                  }
                },
                _vm._l(_vm.subsidiaryList, function(item) {
                  return _c("el-option", {
                    key: "subsidiaryId" + item.ID,
                    attrs: {
                      label: item.companyName,
                      value: item.companyName,
                      disabled: !item.isValid
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.searchFormSubmit }
                },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _c(
          "div",
          {
            staticStyle: {
              margin: "10px 0",
              "text-align": "right",
              "font-size": "14px"
            }
          },
          [
            _c("span", [
              _vm._v("产品名："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(
                  _vm._s(_vm.sellOrderDetailRow.productName) +
                    "(" +
                    _vm._s(_vm.sellOrderDetailRow.specificationModel) +
                    ")"
                )
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("销售订货数量："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.sellOrderDetailRow.deliveryQuantity))
              ])
            ]),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v("赠送数量："),
              _c("span", { staticStyle: { color: "#f00" } }, [
                _vm._v(_vm._s(_vm.sellOrderDetailRow.complimentaryQuantity))
              ])
            ])
          ]
        )
      ]),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "batchForm",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "show-message": false
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-table",
            {
              key: _vm.tamptime,
              ref: "batchListTable",
              attrs: {
                width: "100%",
                border: "",
                data: _vm.getListData(),
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                size: "mini",
                "row-key": _vm.detailRowKey,
                "highlight-current-row": "",
                "max-height": "460"
              },
              on: { "row-click": _vm.rowClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  width: "60",
                  align: "center",
                  fixed: "left",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.form)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  prop: "batchNumber",
                  "min-width": "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序列号",
                  prop: "trackingCode",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产日期",
                  prop: "manufacturerDate",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "有效期",
                  prop: "validityDate",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "可用数量",
                  prop: "normalQuantity",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "销售数量",
                  prop: "deliveryQuantity",
                  "min-width": "170",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.command === "ADD"
                          ? _c(
                              "el-form-item",
                              {
                                key: "deliveryQuantity" + $index,
                                attrs: {
                                  prop: "data." + $index + ".deliveryQuantity",
                                  rules: _vm.formRules.deliveryQuantity
                                }
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "enter-number",
                                      rawName: "v-enter-number",
                                      value: {
                                        min: 0,
                                        max: row.normalQuantity,
                                        precision: 0
                                      },
                                      expression:
                                        "{min:0,max:row.normalQuantity,precision:0}"
                                    }
                                  ],
                                  ref: "deliveryQuantity" + $index,
                                  attrs: {
                                    id: "deliveryQuantity" + $index,
                                    size: "mini",
                                    placeholder: "入库数量"
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return function(event) {
                                        return _vm.nextFocus(
                                          "deliveryQuantity" + $index,
                                          event
                                        )
                                      }.apply(null, arguments)
                                    }
                                  },
                                  model: {
                                    value: row.deliveryQuantity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "deliveryQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "row.deliveryQuantity"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [_vm._v(_vm._s(row.deliveryQuantity))])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "赠送数量",
                  prop: "complimentaryQuantity",
                  "min-width": "170",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [
                        _vm.command === "ADD"
                          ? _c(
                              "el-form-item",
                              {
                                key: "complimentaryQuantity" + $index,
                                attrs: {
                                  prop:
                                    "data." + $index + ".complimentaryQuantity",
                                  rules: _vm.formRules.complimentaryQuantity
                                }
                              },
                              [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "enter-number",
                                      rawName: "v-enter-number",
                                      value: {
                                        min: 0,
                                        max: row.normalQuantity,
                                        precision: 0
                                      },
                                      expression:
                                        "{min:0,max:row.normalQuantity,precision:0}"
                                    }
                                  ],
                                  ref: "complimentaryQuantity" + $index,
                                  attrs: {
                                    id: "complimentaryQuantity" + $index,
                                    size: "mini",
                                    placeholder: "入库数量"
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return function(event) {
                                        return _vm.nextFocus(
                                          "complimentaryQuantity" + $index,
                                          event
                                        )
                                      }.apply(null, arguments)
                                    }
                                  },
                                  model: {
                                    value: row.complimentaryQuantity,
                                    callback: function($$v) {
                                      _vm.$set(
                                        row,
                                        "complimentaryQuantity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "row.complimentaryQuantity"
                                  }
                                })
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(_vm._s(row.complimentaryQuantity))
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "归属公司",
                  prop: "companyName",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否寄售",
                  prop: "isConsign",
                  width: "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.isConsign === 1
                          ? _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "success",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("是")]
                            )
                          : _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  effect: "dark",
                                  size: "mini"
                                }
                              },
                              [_vm._v("否")]
                            )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌批号",
                  prop: "sterilizationBatchNumber",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌效期",
                  prop: "sterilizationDate",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.formPageChange,
              "size-change": _vm.formSizeChange
            }
          })
        ],
        1
      ),
      _vm.command === "ADD"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              attrs: { align: "center" }
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        : _c(
            "div",
            { attrs: { align: "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
                [_vm._v("关闭")]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }