var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("el-col", { attrs: { sm: 12 } }, [
        _c(
          "div",
          { staticClass: "expand-item" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("物资名称")]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.row.productName,
                  placement: "top"
                }
              },
              [
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.row.productName))
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("产品编号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.productNumber))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("通用名称")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.genericName))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("序列号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.trackingCode))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("生产批号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.batchNumber))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("规格型号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.specificationModel))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("库存数量")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.stockQuantity))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("可用数量")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.normalQuantity))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("销售锁定")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.salesPendingApproval))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("入库数量")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.receiptQuantity))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("出库锁定")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.issueLocking))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("消耗数量")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.consumedQuantity))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("包装规格")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.row.packNum) +
                _vm._s(_vm.row.unit) +
                " / " +
                _vm._s(_vm.row.packUnit)
            )
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("单价")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.unitPrice))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("单位")]),
          _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.row.unit))])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("注册证号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.registrationNumber))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("有效期")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.validityDate))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("生产日期：")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.manufacturerDate))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c(
          "div",
          { staticClass: "expand-item" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("产地/品牌")]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.row.productPlace + "/" + _vm.row.brandName,
                  placement: "top"
                }
              },
              [
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.row.productPlace) +
                      " / " +
                      _vm._s(_vm.row.brandName)
                  )
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c(
          "div",
          { staticClass: "expand-item" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("生产厂家")]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: _vm.row.manufacturer,
                  placement: "top"
                }
              },
              [
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.row.manufacturer))
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("灭菌批号")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.sterilizationBatchNumber))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("灭菌效期")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.sterilizationDate))
          ])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("储运条件")]),
          _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.row.storage))])
        ])
      ]),
      _c("el-col", { attrs: { sm: 12 } }, [
        _c("div", { staticClass: "expand-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("货位")]),
          _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.row.placementPosition))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }