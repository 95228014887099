var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("收货单")]),
              _c("el-breadcrumb-item", [_vm._v("收货单详情")])
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "font-size": "14px", color: "#606266" } },
            [_vm._v(" 收货单号：" + _vm._s(_vm.orderInfo.receivNumber) + " ")]
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: true,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "采购单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.purchaseOrder.orderNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.orderInfo.purchaseOrder.manufacturerName)
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货日期" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.receivingDate))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货人" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.receivName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "物流信息" } }, [
                      _c(
                        "div",
                        { staticClass: "item-text" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary", underline: false },
                              on: { click: _vm.viewLogistics }
                            },
                            [_vm._v("添加/查看物流信息")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.form.remarks))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "flex-end",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("总数量："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "detailListTable",
                  data: _vm.detailListForm.data,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": "400"
                },
                on: { "row-click": _vm.detailRowClick }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailListForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productNumber",
                    label: "产品编号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registrationNumber",
                    label: "注册证号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "brandName",
                    label: "品牌",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unit",
                    label: "单位",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseQuantity",
                    label: "采购数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receivedQuantity",
                    label: "已收数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receiptQuantity",
                    label: "实收数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    prop: "remarks",
                    width: "200",
                    align: "center",
                    maxlength: 200,
                    "show-overflow-tooltip": ""
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, prev, pager, next, jumper",
                    align: "center",
                    "hide-on-single-page": true,
                    total: _vm.detailListForm.total,
                    "current-page": _vm.detailListForm.page,
                    "page-size": _vm.detailListForm.pageSize
                  },
                  on: { "current-change": _vm.detailListFormPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.accept.state < 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除收货单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("LogisticsComponents", {
        ref: "LogisticsComponents",
        on: { confirm: _vm.logisticsConfirm }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }