var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("货位管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary",
                    disabled: _vm.listLoading
                  },
                  on: { click: _vm.itemAdd }
                },
                [_vm._v("新增货位")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  disabled: _vm.formLoading,
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "货架编号", prop: "shelfId" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", filterable: "" },
                        model: {
                          value: _vm.searchForm.shelfId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "shelfId", $$v)
                          },
                          expression: "searchForm.shelfId"
                        }
                      },
                      _vm._l(_vm.shelvesList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.shelfName, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "货位编号", prop: "locationNumber" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "货位编号", clearable: "" },
                      model: {
                        value: _vm.searchForm.locationNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "locationNumber", $$v)
                        },
                        expression: "searchForm.locationNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "标签信息", prop: "labelInformation" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "标签信息", clearable: "" },
                      model: {
                        value: _vm.searchForm.labelInformation,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "labelInformation", $$v)
                        },
                        expression: "searchForm.labelInformation"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "filterLabelInformationNull" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.searchForm.filterLabelInformationNull,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "filterLabelInformationNull",
                              $$v
                            )
                          },
                          expression: "searchForm.filterLabelInformationNull"
                        }
                      },
                      [_vm._v("不显示空标签信息记录")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", "native-type": "submit" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.updateScreen }
                      },
                      [_vm._v("标签库存信息更新")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            attrs: {
              data: _vm.listData,
              border: "",
              stripeDELETE: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.searchForm)
                },
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "dealerShelf.shelfNumber",
                label: "货架编号",
                align: "center",
                width: "200",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "dealerShelf.shelfName",
                label: "货架名称",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "locationNumber",
                label: "货位编号",
                align: "center",
                width: "200",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "labelInformation",
                label: "标签信息",
                align: "center",
                width: "200",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-input", {
                        ref: "labelInformation" + scope.row.ID,
                        attrs: {
                          placeholder: "请输入标签或扫描",
                          size: "small"
                        },
                        on: {
                          change: function($event) {
                            return _vm.saveLabelInformation(scope.row)
                          },
                          blur: function($event) {
                            return _vm.blurFocus(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.labelInformation,
                          callback: function($$v) {
                            _vm.$set(scope.row, "labelInformation", $$v)
                          },
                          expression: "scope.row.labelInformation"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "row",
                label: "行",
                align: "center",
                width: "100",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "col",
                label: "列",
                align: "center",
                width: "100",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "capacity",
                label: "容量",
                align: "center",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "capacityUsed",
                label: "已使用容量",
                align: "center",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "isEnable",
                label: "是否启用",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#999",
                          "active-value": 1,
                          "inactive-value": 2
                        },
                        on: {
                          change: function($event) {
                            return _vm.itemStatusSwitch(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.isEnable,
                          callback: function($$v) {
                            _vm.$set(scope.row, "isEnable", $$v)
                          },
                          expression: "scope.row.isEnable"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "light",
                label: "亮灯",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#409EFF",
                          "inactive-color": "#999",
                          "active-value": true,
                          "inactive-value": false
                        },
                        on: {
                          change: function($event) {
                            return _vm.lightSwitch(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.light,
                          callback: function($$v) {
                            _vm.$set(scope.row, "light", $$v)
                          },
                          expression: "scope.row.light"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "150", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.itemEdit(scope.row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.itemDelete(scope.row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "current-change": _vm.searchPageChange,
              "size-change": _vm.searchSizeChange
            }
          })
        ],
        1
      ),
      _c("FormComponents", {
        ref: "FormComponents",
        on: { confirm: _vm.loadListData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }