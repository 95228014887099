var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("系统设置")]),
              _c("el-breadcrumb-item", [_vm._v("打印设置")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-tabs",
          { attrs: { type: "border-card" } },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "耗材包标签打印设置" } },
              [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.formLoading,
                        expression: "formLoading"
                      }
                    ],
                    ref: "form",
                    staticStyle: { width: "400px" },
                    attrs: {
                      model: _vm.form,
                      "label-width": "150px",
                      size: "small",
                      rules: _vm.formRules
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "打印尺寸X" } },
                      [
                        _c(
                          "el-input",
                          {
                            directives: [
                              {
                                name: "enter-number",
                                rawName: "v-enter-number:[{min:1,precision:0}]",
                                arg: { min: 1, precision: 0 }
                              }
                            ],
                            model: {
                              value: _vm.form.dimX,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dimX", $$v)
                              },
                              expression: "form.dimX"
                            }
                          },
                          [
                            _c(
                              "i",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [_vm._v("毫米")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "打印尺寸Y" } },
                      [
                        _c(
                          "el-input",
                          {
                            directives: [
                              {
                                name: "enter-number",
                                rawName: "v-enter-number:[{min:1,precision:0}]",
                                arg: { min: 1, precision: 0 }
                              }
                            ],
                            model: {
                              value: _vm.form.dimY,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dimY", $$v)
                              },
                              expression: "form.dimY"
                            }
                          },
                          [
                            _c(
                              "i",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [_vm._v("毫米")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formSubmit }
                          },
                          [_vm._v("保存设置")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }