var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "batchForm",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "show-message": false
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "耗材包：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: { change: _vm.packageChange },
                  model: {
                    value: _vm.form.packId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "packId", $$v)
                    },
                    expression: "form.packId"
                  }
                },
                _vm._l(_vm.packageList, function(item, index) {
                  return _c("el-option", {
                    key: "type-" + index,
                    attrs: { value: item.ID, label: item.name }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "dbListTable",
              attrs: {
                width: "100%",
                border: "",
                data: _vm.getListData(),
                "row-class-name": _vm.rowClassName,
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                size: "mini",
                "row-key": _vm.productRowKey,
                "highlight-current-row": "",
                "max-height": "460"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  width: "60",
                  align: "center",
                  fixed: "left",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.form)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  prop: "inventory.batch_number",
                  "min-width": "150",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序列号",
                  prop: "inventory.trackingCode",
                  "min-width": "150",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "生产日期",
                  prop: "inventory.manufacturer_date",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "有效期",
                  prop: "inventory.validity_date",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "可用数量",
                  prop: "inventory.normalQuantity",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "使用数量",
                  prop: "quantityUsed",
                  "min-width": "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "数量",
                  prop: "outboundQuantity",
                  "min-width": "100",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: { size: "mini", placeholder: "数量" },
                          model: {
                            value: row.outboundQuantity,
                            callback: function($$v) {
                              _vm.$set(row, "outboundQuantity", _vm._n($$v))
                            },
                            expression: "row.outboundQuantity"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌批号",
                  prop: "inventory.sterilization_batch_number",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "灭菌效期",
                  prop: "inventory.sterilization_date",
                  "min-width": "100",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              "hide-on-single-page": true,
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.formPageChange,
              "size-change": _vm.formSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }