import { request } from '@/api/_service.js';
export function listSupplier(params) {
  return request({
    url: '/supplier/getSupplierList',
    method: 'get',
    params: params
  });
}
export function findSupplier(params) {
  return request({
    url: '/supplier/findSupplier',
    method: 'get',
    params: params
  });
}
export function createSupplier(data) {
  return request({
    url: '/supplier/createSupplier',
    method: 'post',
    data: data
  });
}
export function updateSupplier(data) {
  return request({
    url: '/supplier/updateSupplier',
    method: 'put',
    data: data
  });
}
export function deleteSupplier(data) {
  return request({
    url: '/supplier/deleteSupplier',
    method: 'delete',
    data: data
  });
}
export function deleteSupplierByIds(data) {
  return request({
    url: '/supplier/deleteSupplierByIds',
    method: 'delete',
    data: data
  });
}
export function setEpDay(data) {
  return request({
    url: '/erpFaOrgDistributor/setEpDay',
    method: 'put',
    data: data
  });
}
export function downloadSupplierTemplate(params) {
  return request({
    url: '/supplier/getSupplierTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}