var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { staticClass: "desc-item", attrs: { span: _vm.computedSpan } },
    [
      _c("div", { staticClass: "desc-item-content", class: _vm.size }, [
        _c("label", {
          staticClass: "desc-item-label",
          style: { width: _vm.labelWidth },
          domProps: { innerHTML: _vm._s(_vm.label + _vm.suffix) }
        }),
        _vm.$slots
          ? _c(
              "div",
              { staticClass: "desc-item-value" },
              [
                _vm.$slots.default && _vm.$slots.default[0].text
                  ? _vm._t("default")
                  : _vm.$slots.content
                  ? _vm._t("content")
                  : _c("span", [_vm._v("暂无数据")])
              ],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }