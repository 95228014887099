var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("验收单")]),
              _c("el-breadcrumb-item", [_vm._v("验收单详情")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.orderInfo.state > 1,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "仓库" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.warehouse.name))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "验收单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.acceptNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "采购单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.purchaseOrder.orderNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.receiv.receivNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.orderInfo.purchaseOrder.manufacturerName)
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "验收人", prop: "acceptUid" } },
                      [
                        _vm.orderInfo.state <= 1
                          ? _c(
                              "el-select",
                              {
                                ref: "acceptUid",
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.form.acceptUid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "acceptUid", $$v)
                                  },
                                  expression: "form.acceptUid"
                                }
                              },
                              _vm._l(_vm.userList, function(item) {
                                return _c("el-option", {
                                  key: item.uid,
                                  attrs: { label: item.name, value: item.uid }
                                })
                              }),
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.acceptName))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "验收日期", prop: "acceptDate" } },
                      [
                        _vm.orderInfo.state <= 1
                          ? _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.form.acceptDate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "acceptDate", $$v)
                                },
                                expression: "form.acceptDate"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.acceptDate))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发货地址", prop: "shippingAddress" } },
                      [
                        _vm.orderInfo.state <= 1
                          ? _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "发货地址",
                                  maxlength: "250",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.shippingAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "shippingAddress", $$v)
                                  },
                                  expression: "form.shippingAddress"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit el-input__icon",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix"
                                })
                              ]
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.shippingAddress))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "联系方式", prop: "contactInformation" }
                      },
                      [
                        _vm.orderInfo.state <= 1
                          ? _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "联系方式",
                                  maxlength: "250",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.contactInformation,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "contactInformation",
                                      $$v
                                    )
                                  },
                                  expression: "form.contactInformation"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit el-input__icon",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix"
                                })
                              ]
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.contactInformation))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 24, xl: 18 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "scceptSummary", label: "验收总结" } },
                      [
                        _vm.orderInfo.state <= 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  margin: "0 0 0 0"
                                }
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: {
                                      "border-right": "1px solid #ebeef5"
                                    },
                                    attrs: { maxlength: "500", clearable: "" },
                                    model: {
                                      value: _vm.form.scceptSummary,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "scceptSummary", $$v)
                                      },
                                      expression: "form.scceptSummary"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit el-input__icon",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    })
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      "text-align": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          width: "300",
                                          trigger: "click"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          _vm._l(_vm.quickText, function(
                                            item,
                                            idx
                                          ) {
                                            return _c(
                                              "el-link",
                                              {
                                                key: "quick-text-" + idx,
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  border: "1px solid #ccc",
                                                  padding: "10px"
                                                },
                                                attrs: { underline: false },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.useQuickText(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(item))]
                                            )
                                          }),
                                          1
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "text"
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("快捷用语")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.orderInfo.scceptSummary))
                            ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px"
                }
              },
              [
                _c("span", [
                  _vm._v("收货合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.receiptQuantitySum()))
                  ])
                ]),
                _c("span", { staticStyle: { margin: "0 20px" } }, [
                  _vm._v("合格合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.qualifiedQuantitySum()))
                  ])
                ]),
                _c("span", [
                  _vm._v("不合格合计："),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(_vm.unqualifiedQuantitySum()))
                  ])
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "detailListTable",
                  data: _vm.detailListForm.data,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": "450"
                },
                on: { "row-click": _vm.detailRowClick }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailListForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registrationNumber",
                    label: "注册证号/备案号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "brandName",
                    label: "品牌",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerName",
                    label: "生产厂家",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "batchNumber",
                    label: "批号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "manufacturerDate",
                    label: "生产日期",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "validityDate",
                    label: "有效期",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unit",
                    label: "单位",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "receiptQuantity",
                    label: "收货数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "qualifiedQuantity",
                    label: "合格数量",
                    width: "150",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.orderInfo.state === 1
                            ? _c("el-input", {
                                directives: [
                                  {
                                    name: "enter-number",
                                    rawName:
                                      "v-enter-number:[{min:0,max:row.receiptQuantity,precision:0}]",
                                    arg: {
                                      min: 0,
                                      max: row.receiptQuantity,
                                      precision: 0
                                    }
                                  }
                                ],
                                ref: "qualifiedQuantity-" + $index,
                                attrs: { size: "mini" },
                                on: {
                                  change: function($event) {
                                    return _vm.qualifiedQuantityChange(row)
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "unqualifiedQuantity-" + $index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.qualifiedQuantity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      row,
                                      "qualifiedQuantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "row.qualifiedQuantity"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(row.qualifiedQuantity))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unqualifiedQuantity",
                    label: "不合格数量",
                    width: "150",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _vm.orderInfo.state === 1
                            ? _c("el-input", {
                                directives: [
                                  {
                                    name: "enter-number",
                                    rawName:
                                      "v-enter-number:[{min:0,max:row.receiptQuantity,precision:0}]",
                                    arg: {
                                      min: 0,
                                      max: row.receiptQuantity,
                                      precision: 0
                                    }
                                  }
                                ],
                                ref: "unqualifiedQuantity-" + $index,
                                attrs: { size: "mini" },
                                on: {
                                  change: function($event) {
                                    return _vm.unqualifiedQuantityChange(row)
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "remark-" + $index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.unqualifiedQuantity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      row,
                                      "unqualifiedQuantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "row.unqualifiedQuantity"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(row.unqualifiedQuantity))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "备注", width: "200", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c("el-input", {
                            ref: "remark-" + $index,
                            attrs: { size: "mini", maxlength: 200 },
                            model: {
                              value: row.remarks,
                              callback: function($$v) {
                                _vm.$set(row, "remarks", $$v)
                              },
                              expression: "row.remarks"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, prev, pager, next, jumper",
                    align: "center",
                    "hide-on-single-page": true,
                    total: _vm.detailListForm.total,
                    "current-page": _vm.detailListForm.page,
                    "page-size": _vm.detailListForm.pageSize
                  },
                  on: { "current-change": _vm.detailListFormPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.orderInfo.state == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.formStorage }
                          },
                          [_vm._v(" 暂存")]
                        )
                      : _vm._e(),
                    _vm.orderInfo.state == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "primary",
                              disabled: _vm.formLoading
                            },
                            on: { click: _vm.formSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v(" 提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.orderInfo.state == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: {
                              type: "danger",
                              disabled: _vm.formLoading
                            },
                            on: { click: _vm.formUnSubmit }
                          },
                          [_vm._v(" 撤销提交")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.orderInfo.ID },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }