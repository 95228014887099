import { request } from '@/api/_service.js';
export function listDealerUserAddress(params) {
  return request({
    url: '/dealerUserAddressErp/getDealerUserAddressList',
    method: 'get',
    params: params
  });
}
export function findDealerUserAddress(params) {
  return request({
    url: '/dealerUserAddressErp/findDealerUserAddress',
    method: 'get',
    params: params
  });
}
export function createDealerUserAddress(data) {
  return request({
    url: '/dealerUserAddressErp/createDealerUserAddress',
    method: 'post',
    data: data
  });
}
export function updateDealerUserAddress(data) {
  return request({
    url: '/dealerUserAddressErp/updateDealerUserAddress',
    method: 'put',
    data: data
  });
}
export function deleteDealerUserAddress(data) {
  return request({
    url: '/dealerUserAddressErp/deleteDealerUserAddressByIds',
    method: 'delete',
    data: data
  });
}