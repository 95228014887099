var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存明细")]),
              _c("el-breadcrumb-item", [_vm._v("产品追溯")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _vm.stockRow
          ? _c("el-card", { attrs: { shadow: "never" } }, [
              _c("div", { staticClass: "stock-detail" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("物资详情信息")]),
                  _c("span", { staticStyle: { "margin-left": "20px" } }, [
                    _vm.stockRow.main.state === 1
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("i", { staticClass: "dot-success" }),
                            _c("span", [_vm._v("正常")])
                          ]
                        )
                      : _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("i", { staticClass: "dot-danger" }),
                            _c("span", [_vm._v("禁用")])
                          ]
                        )
                  ])
                ]),
                _c("div", { staticClass: "info-list" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("仓库名称")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.warehouse.name))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("规格ID")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.specs_id))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("生产批号")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.batch_number))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("生产厂家")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.main.manufacturer))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("归属公司")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.subsidiary.companyName))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [_vm._v("品牌")]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.main.brandName))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [_vm._v("标签")]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.cusCode))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("生产日期")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.manufacturer_date))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("物资名称")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.main.productName))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("产品主码")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.specs.barCode))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("序列号")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.trackingCode))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("供应商")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.manufacturerName))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("产品线")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.main.productLine))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("包装规格")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.stockRow.specs.packNum +
                            _vm.stockRow.specs.unit +
                            "/" +
                            _vm.stockRow.specs.packUnit
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("有效期")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.validity_date))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("规格型号")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.specs.specificationModel))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("产品编码")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.specs.productNumber))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("灭菌批号")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.sterilization_batch_number))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("注册证号")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.main.registrationNumber))
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [_vm._v("分类")]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(
                        _vm._s(
                          _vm.classificationFormatter(
                            _vm.stockRow.main.classification
                          )
                        )
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("是否寄售")
                    ]),
                    _c(
                      "span",
                      { staticClass: "item-content" },
                      [
                        _vm.stockRow.isConsign === 1
                          ? _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "success" } },
                              [_vm._v("是")]
                            )
                          : _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "info" } },
                              [_vm._v("否")]
                            )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "item" }, [
                    _c("span", { staticClass: "item-title" }, [
                      _vm._v("灭菌有效期")
                    ]),
                    _c("span", { staticClass: "item-content" }, [
                      _vm._v(_vm._s(_vm.stockRow.sterilization_date))
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.stockRow
          ? _c("div", { staticClass: "statistic-list" }, [
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#56adfd", "#2d86d8"],
                      title: "当前库存数量",
                      value: _vm.stockRow.stock_quantity
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#26d5aa", "#26c887"],
                      title: "可用数量",
                      value: _vm.stockRow.normalQuantity
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#f1bc48", "#f38a2b"],
                      title: "累计消耗数量",
                      value: _vm.stockRow.consumedQuantity
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#f96d5a", "#e45d5c"],
                      title: "不合格数量",
                      value: _vm.stockRow.unqualifiedQuantity
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#c579f3", "#b75de7"],
                      title: "出库锁定",
                      value: _vm.stockRow.issueLocking
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#f1bc48", "#f38a2b"],
                      title: "销售锁定",
                      value: _vm.stockRow.salesPendingApproval
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "item" },
                [
                  _c("YTStatistic", {
                    attrs: {
                      color: ["#5579F4", "#0B2FAA"],
                      title: "商品包锁定",
                      value: _vm.stockRow.productPackageLocking
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.stockRow
          ? _c(
              "el-card",
              {
                staticClass: "form-card",
                staticStyle: { "margin-top": "10px" },
                attrs: { shadow: "never" }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.searchForm,
                          size: "small",
                          "label-suffix": ":"
                        },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "日期查询" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "unlink-panels": true,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.searchForm.dateRange,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "dateRange", $$v)
                                },
                                expression: "searchForm.dateRange"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "物品流转类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.searchForm.culaCate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "culaCate", $$v)
                                  },
                                  expression: "searchForm.culaCate"
                                }
                              },
                              _vm._l(_vm.culaCateList, function(item) {
                                return _c("el-option", {
                                  key: "culaCate" + item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "操作人" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.searchForm.culaName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "culaName", $$v)
                                  },
                                  expression: "searchForm.culaName"
                                }
                              },
                              _vm._l(_vm.userList, function(item) {
                                return _c("el-option", {
                                  key: "culaName" + item.uid,
                                  attrs: { label: item.name, value: item.name }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.searchSubmit }
                              },
                              [_vm._v("查询")]
                            ),
                            _c("el-button", { on: { click: _vm.dataExport } }, [
                              _vm._v("导出")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              icon:
                                _vm.viewMode === "TABLE"
                                  ? "el-icon-s-data"
                                  : "el-icon-s-grid"
                            },
                            on: {
                              click: function($event) {
                                return _vm.viewSwitch()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "切换为" +
                                _vm._s(
                                  _vm.viewMode === "TABLE" ? "视图" : "表格"
                                ) +
                                "模式"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.viewMode === "TABLE",
                expression: "viewMode === 'TABLE'"
              }
            ]
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                pagination: _vm.searchForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                data: _vm.tableData
              },
              scopedSlots: _vm._u([
                {
                  key: "culaOrderNo",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.viewOrder(row)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.culaOrderNo))]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.viewMode === "GRAPHICAL",
                expression: "viewMode === 'GRAPHICAL'"
              }
            ]
          },
          [
            _c("div", { staticClass: "graphical" }, [
              _c(
                "div",
                { staticClass: "in-block" },
                [
                  _vm._l(_vm.leftListData.orderList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "left-order-item" + index,
                        staticClass: "order-item",
                        style: "border: 2px solid " + _vm.leftListData.color
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "type",
                            style: "background:" + _vm.leftListData.color
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.lockTypeFormatter(item.lockType).name)
                            )
                          ]
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("div", [
                            _vm._v("流转时间：" + _vm._s(item.culaDate))
                          ]),
                          _c(
                            "div",
                            [
                              _vm._v("流转单号："),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewOrder(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.culaOrderNo))]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.lockTypeFormatter(item.lockType).numTitle
                              ) +
                                "：" +
                                _vm._s(item.inQuantity)
                            )
                          ]),
                          _c("div", [
                            _vm._v("订单状态：" + _vm._s(item.statusName))
                          ]),
                          _c("div", [
                            _vm._v("操作人员：" + _vm._s(item.culaName))
                          ])
                        ]),
                        _c("div", {
                          staticClass: "line",
                          style:
                            "border-top:2px solid " + _vm.leftListData.color
                        })
                      ]
                    )
                  }),
                  _vm.leftListData.orderList.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "more",
                          style:
                            "cursor:pointer;border: 1px solid " +
                            _vm.leftListData.color,
                          on: {
                            click: function($event) {
                              return _vm.viewMoreData({
                                culaCate: _vm.leftListData.culaCate,
                                inventoryId: _vm.inventoryId,
                                title: _vm.leftListData.title,
                                dateRange: _vm.searchForm.dateRange,
                                culaName: _vm.searchForm.culaName
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "info", underline: false } },
                            [_vm._v("更多单据")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "left-block",
                  style: "background:" + _vm.leftListData.color
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.leftListData.title))
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.leftListData.num))
                  ])
                ]
              ),
              _vm.stockRow
                ? _c("div", { staticClass: "center-block" }, [
                    _c("i", {
                      staticClass: "el-icon-right",
                      staticStyle: { color: "#409EFF" }
                    }),
                    _c("div", { staticClass: "data-list" }, [
                      _c("div", { staticClass: "main-list" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("库存数量")
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.stockRow.stock_quantity))
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: { background: "#43cf7c" }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("可用数量")
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.stockRow.normalQuantity))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: {
                              background: "#bd6aed",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadRightData(2)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("出库锁定")
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.stockRow.issueLocking))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: {
                              background: "#f2a53b",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadRightData(3)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("销售锁定")
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.stockRow.salesPendingApproval))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: {
                              background: "#5579F4",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadRightData(4)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("商品包锁定")
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.stockRow.productPackageLocking))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: {
                              background: "#ff5733",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadRightData(5)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "title" }, [
                              _vm._v("不合格数量")
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.stockRow.unqualifiedQuantity))
                            ])
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "consume",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.loadRightData(6)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "title" }, [
                            _vm._v("累计消耗")
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.stockRow.consumedQuantity))
                          ])
                        ]
                      )
                    ]),
                    _c("i", {
                      staticClass: "el-icon-right",
                      staticStyle: { color: "#ff8d1a" }
                    })
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "right-block",
                  style: "background:" + _vm.rightListData.color
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.rightListData.title))
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.rightListData.num))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "out-block" },
                [
                  _vm._l(_vm.rightListData.orderList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: "out-order-item" + index,
                        staticClass: "order-item",
                        style: "border: 2px solid " + item.color
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "type",
                            style: "background:" + item.color
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.lockTypeFormatter(item.lockType).name)
                            )
                          ]
                        ),
                        _c("div", { staticClass: "info" }, [
                          _c("div", [
                            _vm._v("流转时间：" + _vm._s(item.culaDate))
                          ]),
                          _c(
                            "div",
                            [
                              _vm._v("流转单号："),
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewOrder(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.culaOrderNo))]
                              )
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.lockTypeFormatter(item.lockType).numTitle
                              ) +
                                "：" +
                                _vm._s(item.czQuantity)
                            )
                          ]),
                          _c("div", [
                            _vm._v("订单状态：" + _vm._s(item.statusName))
                          ]),
                          _c("div", [
                            _vm._v("操作人员：" + _vm._s(item.culaName))
                          ])
                        ]),
                        _c("div", {
                          staticClass: "line",
                          style:
                            "border-top:2px solid " + _vm.rightListData.color
                        })
                      ]
                    )
                  }),
                  _vm.rightListData.orderList.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "more",
                          style:
                            "cursor:pointer;border: 1px solid " +
                            _vm.rightListData.color,
                          on: {
                            click: function($event) {
                              return _vm.viewMoreData({
                                culaCate: _vm.rightListData.culaCate,
                                inventoryId: _vm.inventoryId,
                                title: _vm.rightListData.title,
                                dateRange: _vm.searchForm.dateRange,
                                culaName: _vm.searchForm.culaName
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "info", underline: false } },
                            [_vm._v("更多单据")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        )
      ],
      _vm.viewMode === "TABLE"
        ? _c(
            "template",
            { slot: "footer" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.searchForm.total,
                  "current-page": _vm.searchForm.page,
                  "page-size": _vm.searchForm.pageSize
                },
                on: {
                  "current-change": _vm.pageChange,
                  "size-change": _vm.pageSizeChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("MoreDataComponent", {
        ref: "MoreDataComponentRef",
        on: { "view-order": _vm.viewOrder }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }