import { request } from '@/api/_service.js';
export function listReCall(params) {
  return request({
    url: '/erpRecallErp/getErpRecallList',
    method: 'get',
    params: params
  });
}
export function listReCallExport(params) {
  return request({
    url: '/erpRecallErp/getErpRecallList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findReCall(params) {
  return request({
    url: '/erpRecallErp/findErpRecall',
    method: 'get',
    params: params
  });
}
export function createReCall(data) {
  return request({
    url: '/erpRecallErp/createErpRecall',
    method: 'post',
    data: data
  });
}
export function updateReCall(data) {
  return request({
    url: '/erpRecallErp/updateErpRecall',
    method: 'put',
    data: data
  });
}
export function deleteReCall(data) {
  return request({
    url: '/erpRecallErp/deleteErpRecall',
    method: 'delete',
    data: data
  });
}
export function deleteReCallByIds(data) {
  return request({
    url: '/erpRecallErp/deleteErpRecallByIds',
    method: 'delete',
    data: data
  });
}
export function listReCallDetail(params) {
  return request({
    url: '/erpRecallInfoErp/getErpRecallInfoList',
    method: 'get',
    params: params
  });
}
export function createErpRecallInfo(data) {
  return request({
    url: '/erpRecallInfoErp/createErpRecallInfo',
    method: 'post',
    data: data
  });
}
export function createErpRecallInfos(data) {
  return request({
    url: '/erpRecallInfoErp/createErpRecallInfos',
    method: 'post',
    data: data
  });
}
export function updateErpRecallInfo(data) {
  return request({
    url: '/erpRecallInfoErp/updateErpRecallInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpRecallInfo(data) {
  return request({
    url: '/erpRecallInfoErp/deleteErpRecallInfo',
    method: 'delete',
    data: data
  });
}