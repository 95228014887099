import { request } from '@/api/_service.js';
export function listPayment(params) {
  return request({
    url: '/appPayErp/getAppPayList',
    method: 'get',
    params: params
  });
}
export function createPayment(data) {
  return request({
    url: '/appPayErp/createAppPay',
    method: 'post',
    data: data
  });
}
export function updatePayment(data) {
  return request({
    url: '/appPayErp/updateAppPay',
    method: 'put',
    data: data
  });
}
export function deletePayment(data) {
  return request({
    url: '/appPayErp/deleteAppPay',
    method: 'delete',
    data: data
  });
}
export function findPayment(params) {
  return request({
    url: '/appPayErp/findAppPay',
    method: 'get',
    params: params
  });
}
export function checkPayment(data) {
  return request({
    url: '/appPayErp/updateAppPayState',
    method: 'put',
    data: data
  });
}
export function createConfirm(data) {
  return request({
    url: '/aPayJlErp/createAppPayJl',
    method: 'post',
    data: data
  });
}
export function listConfirm(params) {
  return request({
    url: '/aPayJlErp/getAppPayJlList',
    method: 'get',
    params: params
  });
}
export function setEpDay(data) {
  return request({
    url: '/appPayErp/setEpDay',
    method: 'put',
    data: data
  });
}