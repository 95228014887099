var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form-table",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "120px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称", prop: "customerName" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.customerName))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "公司税号", prop: "companyTaxNumber" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.companyTaxNumber))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户银行", prop: "bank" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bank))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行账户", prop: "bankAccount" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.bankAccount))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位地址", prop: "workAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.workAddress))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位电话", prop: "telephone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.telephone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "期望发票类型", prop: "invoiceType" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(
                          _vm._s(_vm.getInvoiceTypeName(_vm.form.invoiceType))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票联系方式", prop: "ticketPhone" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketPhone))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收票地址", prop: "ticketAddress" } },
                    [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.ticketAddress))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { "margin-top": "10px", width: "100%" },
          attrs: {
            size: "small",
            data: _vm.detailList,
            "highlight-current-row": "",
            border: "",
            stripe: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "60",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "销售单号",
              prop: "salesOrderNo",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "productNumber",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票名称",
              prop: "productName",
              "min-width": "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specificationModel",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "数量",
              prop: "quantityUsed",
              width: "80",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "销售单价(含税)",
                prop: "salesUnitPrice",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "line-height": "15px" } }, [
                  _vm._v("销售单价"),
                  _c("br"),
                  _vm._v("(含税)")
                ])
              ])
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "销售金额(含税)",
                prop: "salesAmount",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            },
            [
              _c("template", { slot: "header" }, [
                _c("div", { staticStyle: { "line-height": "15px" } }, [
                  _vm._v("销售金额"),
                  _c("br"),
                  _vm._v("(含税)")
                ])
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }