var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "100px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入手机号码",
                          clearable: "",
                          disabled: _vm.itemForm.id ? true : false
                        },
                        model: {
                          value: _vm.itemForm.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "mobile", $$v)
                          },
                          expression: "itemForm.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名", clearable: "" },
                        on: { change: _vm.toPinyinCode },
                        model: {
                          value: _vm.itemForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "name", $$v)
                          },
                          expression: "itemForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "拼音码", prop: "pinyin_code" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入拼音码", clearable: "" },
                        model: {
                          value: _vm.itemForm.pinyin_code,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "pinyin_code", $$v)
                          },
                          expression: "itemForm.pinyin_code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "gender" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.itemForm.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "gender", $$v)
                            },
                            expression: "itemForm.gender"
                          }
                        },
                        _vm._l(_vm.genderList, function(item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属部门", prop: "department_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择所属部门",
                            "no-match-text": "no-match-text"
                          },
                          model: {
                            value: _vm.itemForm.department_id,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "department_id", $$v)
                            },
                            expression: "itemForm.department_id"
                          }
                        },
                        _vm._l(_vm.departmentList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: {
                              label: item.departmentName,
                              value: item.ID
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职务", prop: "duties" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入职务", clearable: "" },
                        model: {
                          value: _vm.itemForm.duties,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "duties", $$v)
                          },
                          expression: "itemForm.duties"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "员工工号", prop: "employee_num" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入员工工号", clearable: "" },
                        model: {
                          value: _vm.itemForm.employee_num,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "employee_num", $$v)
                          },
                          expression: "itemForm.employee_num"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号", prop: "idcard" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入身份证号", clearable: "" },
                        model: {
                          value: _vm.itemForm.idcard,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "idcard", $$v)
                          },
                          expression: "itemForm.idcard"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.itemForm.active_status,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "active_status", $$v)
                            },
                            expression: "itemForm.active_status"
                          }
                        },
                        _vm._l(_vm.statusList, function(item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.value } },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 13 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "菜单角色设定", prop: "group_id_erps" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.itemForm.group_id_erps,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "group_id_erps", $$v)
                            },
                            expression: "itemForm.group_id_erps"
                          }
                        },
                        _vm._l(_vm.rolesList, function(item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.id,
                              attrs: {
                                label: item.id,
                                disabled:
                                  item.status === "hidden" ||
                                  item.name === "超级管理员"
                                    ? true
                                    : false
                              },
                              on: { change: _vm.groupIdErpsChange }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.userRow.collaborative === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "有台角色设定", prop: "group_id" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.itemForm.group_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.itemForm, "group_id", $$v)
                                },
                                expression: "itemForm.group_id"
                              }
                            },
                            _vm._l(_vm.youtaiUserGroupList, function(item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.id,
                                  attrs: {
                                    label: item.id,
                                    disabled:
                                      item.status === "hidden" ||
                                      item.id === 0 ||
                                      item.id === 1 ||
                                      (_vm.itemForm.group_id.includes(41) &&
                                        item.id === 4) ||
                                      (_vm.itemForm.group_id.includes(4) &&
                                        item.id === 41) ||
                                      item.id === 8
                                        ? true
                                        : false
                                  },
                                  on: { change: _vm.groupIdChange }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }