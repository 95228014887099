var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购付款")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "采购单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "采购单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.purchaseOrderNumbers,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "purchaseOrderNumbers", $$v)
                        },
                        expression: "searchForm.purchaseOrderNumbers"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "收款单位" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "收款单位", clearable: "" },
                      model: {
                        value: _vm.searchForm.payee,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "payee", $$v)
                        },
                        expression: "searchForm.payee"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请人" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "申请人", clearable: "" },
                      model: {
                        value: _vm.searchForm.applicant,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "applicant", $$v)
                        },
                        expression: "searchForm.applicant"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "采购日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": true,
                        "range-separator": "至",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.searchForm.dateRange1,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "dateRange1", $$v)
                        },
                        expression: "searchForm.dateRange1"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.state,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "state", $$v)
                              },
                              expression: "searchForm.state"
                            }
                          },
                          _vm._l(_vm.stateList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "审批人" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "审批人", clearable: "" },
                          model: {
                            value: _vm.searchForm.approvedBy,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "approvedBy", $$v)
                            },
                            expression: "searchForm.approvedBy"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "审批日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.searchForm.dateRange2,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange2", $$v)
                            },
                            expression: "searchForm.dateRange2"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "申请单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "申请单号", clearable: "" },
                          model: {
                            value: _vm.searchForm.appOrderNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "appOrderNumber", $$v)
                            },
                            expression: "searchForm.appOrderNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "申请分类" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "申请分类", clearable: "" },
                          model: {
                            value: _vm.searchForm.appClass,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "appClass", $$v)
                            },
                            expression: "searchForm.appClass"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "付款公司" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "付款公司", clearable: "" },
                          model: {
                            value: _vm.searchForm.paymentCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "paymentCompany", $$v)
                            },
                            expression: "searchForm.paymentCompany"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tab-list" },
          _vm._l(_vm.tabList, function(item, index) {
            return _c(
              "div",
              {
                key: "tab" + index,
                class:
                  _vm.activeTabId === index ? "tab-item hover" : "tab-item",
                on: {
                  click: function($event) {
                    return _vm.tabChange(index)
                  }
                }
              },
              [
                index === 1
                  ? _c(
                      "el-badge",
                      {
                        attrs: {
                          value: item.number,
                          hidden: item.number > 0 ? false : true
                        }
                      },
                      [_c("span", [_vm._v(_vm._s(item.name))])]
                    )
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(item.name) +
                          "(" +
                          _vm._s(item.number) +
                          ") "
                      )
                    ])
              ],
              1
            )
          }),
          0
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "appOrderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makeBarCode(row.ID, row.appOrderNumber)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.appOrderNumber))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "purchaseOrderNumbers",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.purchaseOrderNumbers.length > 1
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "auto",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "el-list" },
                            _vm._l(row.purchaseOrderNumbers, function(
                              item,
                              idx
                            ) {
                              return _c(
                                "div",
                                { key: "purchaseOrderNumbers-" + idx },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewOrder(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                type: "primary",
                                underline: false
                              },
                              slot: "reference"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(row.purchaseOrderNumbers[0]) + " "
                              ),
                              row.purchaseOrderNumbers.length > 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f00" } },
                                    [
                                      _vm._v(
                                        "+" +
                                          _vm._s(
                                            row.purchaseOrderNumbers.length - 1
                                          )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : row.purchaseOrderNumbers.length === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary", underline: false },
                          on: {
                            click: function($event) {
                              return _vm.viewOrder(row.purchaseOrderNumbers[0])
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.purchaseOrderNumbers[0]))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "state",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.state >= 2
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "max-content",
                            trigger: "click"
                          },
                          on: {
                            show: function($event) {
                              return _vm.loadLine(row)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { key: "linePopover-" + row.ID + _vm.timestamp },
                            [
                              _c(
                                "el-timeline",
                                {
                                  staticStyle: { padding: "0" },
                                  attrs: { "hide-timestamp": true }
                                },
                                _vm._l(row.examineLine, function(item, idx) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: "examine-line-" + idx,
                                      attrs: {
                                        icon: _vm.lineIcon[item.status],
                                        type: _vm.lineColor[item.status],
                                        size: "large"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.msg) + " ")]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                slot: "reference",
                                type: _vm.getStateType(row.state, 1)
                              },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(_vm.getStateType(row.state, 2)))]
                          )
                        ],
                        1
                      )
                    : _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.stateFormatter(row.state))
                        }
                      })
                ]
              }
            },
            {
              key: "ep_day",
              fn: function(ref) {
                var row = ref.row
                return [
                  [2, 3, 4].includes(row.state)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v("超期 "),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(row.ep_day))
                            ]),
                            _vm._v(" 天")
                          ]),
                          _c(
                            "el-link",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.daySetup(row)
                                }
                              }
                            },
                            [_vm._v("设置")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      row.showSup === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.createSupplement(row)
                                }
                              }
                            },
                            [_vm._v("采购补差")]
                          )
                        : _vm._e(),
                      row.suppId
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.viewSuppOrder(row)
                                }
                              }
                            },
                            [_vm._v("查看补差单")]
                          )
                        : _vm._e(),
                      row.showPay
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.doPayment(row)
                                }
                              }
                            },
                            [_vm._v("付款")]
                          )
                        : _vm._e(),
                      row.state === 2 ||
                      row.state === 4 ||
                      row.state === 5 ||
                      row.state === 6
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.viewPayment(row)
                                }
                              }
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                      row.state !== 6
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.invoiceView(row)
                                }
                              }
                            },
                            [_vm._v("关联发票")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.orderPageChange,
                "size-change": _vm.orderSizeChange
              }
            })
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              size: "small",
              "highlight-current-row": "",
              data: _vm.detailList,
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: { type: "expand", width: "100", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: scope.row.detail, size: "mini" }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "序号",
                              width: "100",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品编号",
                              prop: "specs.productNumber",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "产品名称",
                              prop: "main.productName",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格型号",
                              prop: "specs.specificationModel",
                              "min-width": "150",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "单位",
                              prop: "specs.unit",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购数量",
                              prop: "purchaseQuantity",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购单价",
                              prop: "purchaseUnitPrice",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "采购金额",
                              prop: "productNumber",
                              "min-width": "100",
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.purchaseQuantity *
                                              scope.row.purchaseUnitPrice
                                          ) +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "orderNumber",
                label: "采购单号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "buyer",
                label: "采购人",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseDate",
                label: "采购日期",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseAmount",
                label: "订单金额",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "paidAmountSum",
                label: "已付金额",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "purchaseQuantity",
                "min-width": "100",
                label: "数量",
                align: "center",
                "show-overflow-tooltip": ""
              }
            })
          ],
          1
        )
      ],
      _c("PaymentConfirmComponents", {
        ref: "PaymentConfirmComponents",
        on: { reload: _vm.reload }
      }),
      _c("ViewConfirmComponents", { ref: "ViewConfirmComponents" }),
      _c("InvoiceViewComponent", { ref: "InvoiceViewComponentRef" }),
      _c("ApplyComponent", { ref: "ApplyComponentRef" }),
      _c("DayComponent", {
        ref: "DayComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }