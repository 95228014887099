var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售退货入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "销售退货入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "退货类型" } }, [
                      _c(
                        "div",
                        { staticStyle: { margin: "0 5px" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.customTypeChange },
                              model: {
                                value: _vm.customType,
                                callback: function($$v) {
                                  _vm.customType = $$v
                                },
                                expression: "customType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "经销商" } }),
                              _c("el-radio", { attrs: { label: "医院" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货客户", prop: "customId" } },
                      [
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.componentLoading,
                                expression: "componentLoading"
                              }
                            ],
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0
                            },
                            on: { change: _vm.customIdChange },
                            model: {
                              value: _vm.form.customId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "customId", $$v)
                              },
                              expression: "form.customId"
                            }
                          },
                          _vm._l(_vm.customList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled:
                                  _vm.customType === "经销商"
                                    ? !item.show_status
                                    : item.status === 1
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货人", prop: "returnedBy" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入退货人",
                              maxlength: "50",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.returnedBy,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "returnedBy", $$v)
                              },
                              expression: "form.returnedBy"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退货时间", prop: "returnDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            format: "yyyy-MM-dd HH:mm:ss",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            placeholder: "请选择退货时间"
                          },
                          model: {
                            value: _vm.form.returnDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "returnDate", $$v)
                            },
                            expression: "form.returnDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "快递单号", prop: "deliveryNumber" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入快递单号",
                              maxlength: "100",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.deliveryNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryNumber", $$v)
                              },
                              expression: "form.deliveryNumber"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "出库单号", prop: "deliveryOrderNo" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入出库单号",
                              maxlength: "30",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.deliveryOrderNo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryOrderNo", $$v)
                              },
                              expression: "form.deliveryOrderNo"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { maxlength: "500", clearable: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.selectProduct }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.status !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入")
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "font-size": "14px" } },
              [
                _vm._v(" 退货数量合计：（"),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.countQuantity()))
                ]),
                _vm._v("） "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: !_vm.form.id
                    },
                    on: { click: _vm.orderPrint }
                  },
                  [
                    _c("i", { staticClass: "fa fa-print" }),
                    _vm._v(" 打印退货单")
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            slotIndex: true,
            hasPagination: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            rowClassName: _vm.rowClassName,
            data: _vm.detailList
          },
          on: {
            "row-click": _vm.detailRowClick,
            pageChange: _vm.detailFormPageChange,
            sizeChange: _vm.detailFormSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var index = ref.index
                return [
                  row.expireDays <= 90 && row.expireDays > 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "该产品还有" + row.expireDays + "天过期",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-yellow" }
                              }),
                              _c("span", [_vm._v(_vm._s(index + 1))])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays <= 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              "该产品已过期" + Math.abs(row.expireDays) + "天",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-red" }
                              }),
                              _c("span", [_vm._v(_vm._s(index + 1))])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays > 90
                    ? _c("span", [_vm._v(_vm._s(index + 1))])
                    : _vm._e()
                ]
              }
            },
            {
              key: "returnQuantity",
              fn: function(scope) {
                return [
                  _vm.form.status == 1
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          min: 0,
                          precision: 0,
                          controls: false,
                          label: "退货数量",
                          size: "mini"
                        },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.returnQuantity,
                          callback: function($$v) {
                            _vm.$set(scope.row, "returnQuantity", $$v)
                          },
                          expression: "scope.row.returnQuantity"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(scope.row.returnQuantity))])
                ]
              }
            }
          ])
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          key: "remove-button",
                          attrs: {
                            type: "danger",
                            disabled: _vm.detailRowIndex < 0
                          },
                          on: { click: _vm.detailRowRemove }
                        },
                        [_vm._v("删除行")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "storage-button",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.checkLoading(_vm.formStorage)
                            }
                          }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status == 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "submit-button",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.checkLoading(_vm.formSubmit)
                            }
                          }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.status == 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "un-submit-button",
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.unFormSubmit()
                            }
                          }
                        },
                        [_vm._v("撤销提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1 && _vm.form.id
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "invalid-button",
                          attrs: { type: "danger", icon: "el-icon-delete" },
                          on: { click: _vm.orderInvalid }
                        },
                        [_vm._v("作废退货单")]
                      )
                    : _vm._e(),
                  _vm.form.status === 5 && _vm.form.id
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          key: "invalid-button",
                          attrs: { type: "danger", icon: "el-icon-delete" },
                          on: { click: _vm.orderDelete }
                        },
                        [_vm._v("删除退货单")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      key: "print-button",
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.orderPrint }
                    },
                    [_vm._v("打印退货单")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ImportComponents", {
        ref: "ImportComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }