import { render, staticRenderFns } from "./weight.vue?vue&type=template&id=134ba16c&scoped=true&"
import script from "./weight.vue?vue&type=script&lang=js&"
export * from "./weight.vue?vue&type=script&lang=js&"
import style0 from "./weight.vue?vue&type=style&index=0&id=134ba16c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134ba16c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\wwwroot\\ERP前端\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('134ba16c')) {
      api.createRecord('134ba16c', component.options)
    } else {
      api.reload('134ba16c', component.options)
    }
    module.hot.accept("./weight.vue?vue&type=template&id=134ba16c&scoped=true&", function () {
      api.rerender('134ba16c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/base/product/weight.vue"
export default component.exports