var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            size: "small",
            border: "",
            stripe: "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              width: "60",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "产品编号",
              prop: "productNumber",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "开票名称",
              prop: "invoiceName",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格型号",
              prop: "specificationModel",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "证书编号",
              prop: "registrationNumber",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "生产厂家",
              prop: "manufacturer",
              "min-width": "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "数量",
              prop: "quantity",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "销售单价",
              prop: "salePrice",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "销售金额",
              prop: "saleAmount",
              width: "100",
              align: "center"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [_c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("取消")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }