import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listWarning, listWarningExport } from '@/api/stock';
import { listWarehouse } from '@/api/warehouse.js';
import { listSupplier } from '@/api/supplier.js';
import YTable from '@/views/components/drag-table/table.vue';
import DayComponents from './components/day';
export default {
  name: 'stock-warning-validity',
  components: {
    YTable: YTable,
    DayComponents: DayComponents
  },
  data: function data() {
    return {
      classList: [],
      warehouseList: [],
      warehouseIds: [],
      pageForm: {
        warehouseId: null,
        warehouseIds: null,
        validType: 8,
        supplier: null,
        batch_number: null,
        placement_position: '',
        showEmpty: 0,
        page: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      tableLoading: false,
      supplierList: [],
      validTypeList: [{
        ID: 8,
        name: '全部'
      }, {
        ID: 1,
        name: '已过期'
      }, {
        ID: 2,
        name: '7天内过期'
      }, {
        ID: 3,
        name: '1个月内过期'
      }, {
        ID: 4,
        name: '3个月内过期'
      }, {
        ID: 5,
        name: '6个月内过期'
      }, {
        ID: 6,
        name: '9个月内过期'
      }, {
        ID: 7,
        name: '12个月内过期'
      }],
      searchFormExpand: false,
      columns: [{
        label: '效期类别',
        prop: 'validityCategory',
        checked: true,
        width: 100
      }, {
        label: '剩余天数',
        prop: 'daysRemaining',
        checked: true,
        width: 100
      }, {
        label: '物资名称',
        prop: 'productName',
        checked: true,
        width: 200
      }, {
        label: '通用名称',
        prop: 'genericName',
        checked: true,
        width: 200
      }, {
        label: '生产日期',
        prop: 'manufacturerDate',
        checked: true,
        width: 100,
        type: 'date'
      }, {
        label: '有效期',
        prop: 'validityDate',
        checked: true,
        width: 100,
        type: 'date'
      }, {
        label: '提前预警天数',
        prop: 'epDay',
        checked: true,
        width: 150,
        slot: 'epDay'
      }, {
        label: '库存数量',
        prop: 'stockQuantity',
        checked: true,
        width: 100
      }, {
        label: '累计入库数量',
        prop: 'receiptQuantity',
        checked: true,
        width: 100
      }, {
        label: '累计销售数量',
        prop: 'consumedQuantity',
        checked: true,
        width: 100
      }, {
        label: '序列号',
        prop: 'trackingCode',
        checked: true,
        width: 100
      }, {
        label: '仓库名称',
        prop: 'warehouseName',
        checked: true,
        width: 200
      }, {
        label: '规格ID',
        prop: 'specsId',
        checked: true,
        width: 100
      }, {
        label: '规格型号',
        prop: 'specificationModel',
        checked: true,
        width: 100
      }, {
        label: '产品编号',
        prop: 'productNumber',
        checked: true,
        width: 150
      }, {
        label: '生产批号',
        prop: 'batchNumber',
        checked: true,
        width: 100
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadWarehouse();
      that.loadData();
      that.loadSupplier();
    },
    loadWarehouse: function loadWarehouse() {
      var that = this;
      listWarehouse({
        page: 1,
        pageSize: 9999
      }).then(function (res) {
        that.warehouseList = res.list;
      });
    },
    loadData: function loadData() {
      var that = this;
      that.tableData = [];
      that.tableLoading = true;
      listWarning(that.pageForm).then(function (res) {
        that.tableData = that.listFormatter(res.list);
        that.pageForm.total = res.total;
      }).finally(function () {
        that.tableLoading = false;
      });
    },
    listFormatter: function listFormatter(dataList) {
      var resultList = [];
      dataList.forEach(function (row) {
        resultList.push({
          ID: row.ID,
          validityCategory: row.validityCategory,
          daysRemaining: row.daysRemaining,
          productName: row.main.productName,
          genericName: row.specs.genericName,
          stockQuantity: row.stock_quantity,
          receiptQuantity: row.receiptQuantity,
          consumedQuantity: row.consumedQuantity,
          trackingCode: row.trackingCode,
          warehouseName: row.warehouse.name,
          specsId: row.specs_id,
          specificationModel: row.specs.specificationModel,
          productNumber: row.productNumber,
          batchNumber: row.batch_number,
          manufacturerDate: row.manufacturer_date,
          validityDate: row.validity_date,
          epDay: row.epDay
        });
      });
      return resultList;
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.pageForm.page = 1;
      that.loadData();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadData();
    },
    dataExport: function dataExport() {
      var that = this;
      var form = JSON.parse(JSON.stringify(that.pageForm));
      form.pageSize = form.total;
      form.toExcel = 1;
      listWarningExport(form).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '效期预警.xls'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      }).finally(function () {});
    },
    loadSupplier: function loadSupplier() {
      var that = this;
      that.supplierList = [];
      listSupplier({
        pageSize: 9999,
        page: 1,
        all_examine_status: true
      }).then(function (res) {
        res.list.forEach(function (row) {
          that.supplierList.push(row);
        });
      });
    },
    warehouseChange: function warehouseChange(val) {
      var that = this;
      that.pageForm.warehouseIds = val.toString();
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    },
    pageSizeChange: function pageSizeChange(val) {
      var that = this;
      that.pageForm.page = 1;
      that.pageForm.pageSize = val;
      that.loadData();
    },
    editDay: function editDay(row) {
      var that = this;
      console.log('editDay');
      that.$refs.dayComponentsRef.init(row);
    }
  }
};