var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("发票管理")]),
              _c("el-breadcrumb-item", [_vm._v("开票管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "申请单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "申请单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.requestNo,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "requestNo", $$v)
                        },
                        expression: "searchForm.requestNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "客户名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入客户名称", clearable: "" },
                      model: {
                        value: _vm.searchForm.customerName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "customerName", $$v)
                        },
                        expression: "searchForm.customerName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "申请日期" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd"
                      },
                      model: {
                        value: _vm.searchForm.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "dateRange", $$v)
                        },
                        expression: "searchForm.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "1" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("1")
                      }
                    }
                  },
                  [_vm._v("全部")]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "2" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("2")
                      }
                    }
                  },
                  [
                    _vm._v("待开票("),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.invoiceCate1Total))
                    ]),
                    _vm._v(")")
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "3" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("3")
                      }
                    }
                  },
                  [
                    _vm._v("已开票("),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.invoiceCate2Total))
                    ]),
                    _vm._v(")")
                  ]
                )
              ]
            ),
            _c("div")
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.dataList
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange },
          scopedSlots: _vm._u([
            {
              key: "invoiceAfterAmount",
              fn: function(scope) {
                return [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        title: "已开票信息",
                        width: "800",
                        trigger: "click"
                      }
                    },
                    [
                      scope.row.invoiceList.length > 0
                        ? _c(
                            "el-tabs",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { shadow: "never" },
                              model: {
                                value: _vm.invoiceActiveName,
                                callback: function($$v) {
                                  _vm.invoiceActiveName = $$v
                                },
                                expression: "invoiceActiveName"
                              }
                            },
                            _vm._l(scope.row.invoiceList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "el-tab-pane",
                                {
                                  key: "invoice-item-" + index,
                                  attrs: {
                                    label: item.invoiceNumber,
                                    name: item.invoiceNumber
                                  }
                                },
                                [
                                  _c("template", { slot: "label" }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              border: "1px solid #f00",
                                              "border-radius": "10px",
                                              width: "15px",
                                              height: "15px",
                                              "font-size": "12px",
                                              "line-height": "15px",
                                              "text-align": "center"
                                            }
                                          },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.invoiceNumber))
                                        ])
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "el-form",
                                    {
                                      staticClass: "form-table",
                                      attrs: {
                                        model: item,
                                        "show-message": false,
                                        "label-width": "110px",
                                        size: "mini2",
                                        "label-suffix": ":"
                                      },
                                      nativeOn: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "flex-wrap": "wrap" },
                                          attrs: { type: "flex" }
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "发票号",
                                                    prop: "invoiceNumber"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.invoiceNumber
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "发票代码",
                                                    prop: "invoiceCode"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.invoiceCode)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "发票类型",
                                                    prop: "invoiceType"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getInvoiceTypeName(
                                                            item.invoiceType
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "开票日期",
                                                    prop: "invoicingDate"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.invoicingDate
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "金额(不含税)",
                                                    prop: "money"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [_vm._v(_vm._s(item.money))]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "税率",
                                                    prop: "taxRate"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.taxRate) +
                                                          " %"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "税额",
                                                    prop: "taxAmount"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.taxAmount)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "含税金额",
                                                    prop: "amountIncludingTax"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.amountIncludingTax
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "校验代码(后6位)",
                                                    prop: "validationCode"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.validationCode
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "附件",
                                                    prop: "attachment"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                            title: "附件列表",
                                                            width: "500",
                                                            trigger: "click"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table",
                                                            {
                                                              attrs: {
                                                                data:
                                                                  item.attachment,
                                                                size: "mini",
                                                                border: "",
                                                                stripe: "",
                                                                "header-cell-style": {
                                                                  background:
                                                                    "#f5f7fa",
                                                                  borderColor:
                                                                    "#ebeef5",
                                                                  color: "#333"
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    prop: "url",
                                                                    label:
                                                                      "附件路径"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            _c(
                                                                              "el-link",
                                                                              {
                                                                                attrs: {
                                                                                  type:
                                                                                    "primary",
                                                                                  underline: false
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.attachmentPreview(
                                                                                      row
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    row.url
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-link",
                                                            {
                                                              attrs: {
                                                                slot:
                                                                  "reference",
                                                                underline: false,
                                                                type: "primary"
                                                              },
                                                              slot: "reference"
                                                            },
                                                            [
                                                              _vm._v(" 共 "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item
                                                                        .attachment
                                                                        .length
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " 个，点击查看 "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "开票公司",
                                                    prop: "subsidiaryId"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getSubsidiaryName(
                                                            item.subsidiaryId
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "开票人",
                                                    prop: "invoiceIssuer"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.invoiceIssuer
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "备注",
                                                    prop: "remarks"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "item-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.remarks)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            }),
                            1
                          )
                        : _c("div", [_vm._v("暂无数据")]),
                      _c(
                        "el-link",
                        {
                          attrs: { slot: "reference", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.showInvoiceList(scope.row)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(scope.row.invoiceAfterAmount))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toCreateInvoice(row)
                            }
                          }
                        },
                        [_vm._v("开票")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toViewDetail(row)
                            }
                          }
                        },
                        [_vm._v("查看明细")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toExportDetail(row)
                            }
                          }
                        },
                        [_vm._v("导出开票明细")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      _c("el-image", {
        ref: "ImageViewerRef",
        staticStyle: { width: "0px", height: "0px" },
        attrs: {
          src: "",
          fit: "cover",
          "preview-src-list": _vm.uploadPreviewImageUrl
        }
      }),
      _c("CreateInvoiceComponent", {
        ref: "CreateInvoiceComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      }),
      _c("ViewDetailComponent", { ref: "ViewDetailComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }