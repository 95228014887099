import { request } from '@/api/_service.js';
export function listDeposit(params) {
  return request({
    url: '/erpFaOrgDistributor/getFaOrgDistributorList',
    method: 'get',
    params: params
  });
}
export function createDeposit(data) {
  return request({
    url: '/manufacturerCashPledgeRecordErp/createManufacturerCashPledgeRecord',
    method: 'post',
    data: data
  });
}
export function updateDeposit(data) {
  return request({
    url: '/manufacturerCashPledgeRecordErp/updateManufacturerCashPledgeRecord',
    method: 'put',
    data: data
  });
}
export function listDepositDetail(params) {
  return request({
    url: '/manufacturerCashPledgeRecordErp/getManufacturerCashPledgeRecordList',
    method: 'get',
    params: params
  });
}
export function findDepositDetail(params) {
  return request({
    url: '/manufacturerCashPledgeRecordErp/findManufacturerCashPledgeRecord',
    method: 'get',
    params: params
  });
}
export function updateDepositRatio(data) {
  return request({
    url: '/manu/updateManufacturerCashPledge',
    method: 'put',
    data: data
  });
}