var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { color: "#f00" }, attrs: { align: "center" } },
        [_vm._v(" 补差单审核通过后无法修改 请谨慎处理 ")]
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: { "label-width": "auto", size: "small", model: _vm.form }
        },
        [
          _c("el-form-item", { attrs: { label: "补差总额:" } }, [
            _vm._v(_vm._s(_vm.form.suppAmountSum))
          ]),
          _c("el-form-item", { attrs: { label: "转预付金额:" } }, [
            _vm._v(_vm._s(_vm.form.depositAmount))
          ]),
          _c("el-form-item", { attrs: { label: "退款金额:" } }, [
            _vm._v(_vm._s(_vm.form.refundAmount))
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "退款凭证:" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { gutter: 10, type: "flex" }
                },
                _vm._l(_vm.form.refundVoucher, function(item, index) {
                  return _c(
                    "el-col",
                    {
                      key: "uploadPreviewImageUrl-" + index,
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { span: 8 }
                    },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "100%",
                          height: "80px",
                          border: "1px solid #ddd",
                          "box-sizing": "border-box"
                        },
                        attrs: {
                          fit: "contain",
                          src: _vm.uploadFilePrefixURL + "/" + item.url,
                          "preview-src-list": _vm.uploadPreviewImageUrl
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核结果:", prop: "state" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.state,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "state", $$v)
                    },
                    expression: "form.state"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("通过")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("不通过")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "审核意见:", prop: "examinePinion" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "200",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.examinePinion,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "examinePinion", $$v)
                  },
                  expression: "form.examinePinion"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0", align: "center" } },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提 交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }