var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "desc", style: { margin: _vm.margin } },
    [
      _vm.title
        ? _c("h1", {
            staticClass: "desc-title",
            domProps: { innerHTML: _vm._s(_vm.title) }
          })
        : _vm._e(),
      _c("el-row", { staticClass: "desc-row" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }