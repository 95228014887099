var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("不合格品退货记录")]),
              _c("el-breadcrumb-item", [_vm._v("退货记录详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 处理单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.orderInfo,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: true
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "来源：" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.warehouseName) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "退回单位：" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.returnCompany) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "退回理由：" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.reasonFormatter(_vm.orderInfo.returnReason)
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "物流信息：" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.deliveryInfo) + " ")
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "备注：" } }, [
                      _vm._v(" " + _vm._s(_vm.orderInfo.remark) + " ")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              data: _vm.detailList,
              "max-height": 450
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "registrationNumber",
                label: "注册证号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturer",
                label: "生产厂家",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "returnWarehouseQuantity",
                label: "退货数量",
                "min-width": "100",
                align: "center"
              }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.toPrint }
            },
            [_vm._v("打印")]
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }