var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "720px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-popover",
            {
              ref: "optionsPopover",
              attrs: { placement: "bottom", trigger: "click" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "el-checkbox-group dropdown_checkbox",
                  staticStyle: { display: "flex", "flex-direction": "column" }
                },
                _vm._l(_vm.localColumnsList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: "field-checkbox-" + index,
                      attrs: { label: item.field },
                      on: { change: _vm.saveTableSet },
                      model: {
                        value: item.checked,
                        callback: function($$v) {
                          _vm.$set(item, "checked", $$v)
                        },
                        expression: "item.checked"
                      }
                    },
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "reference", type: "primary", size: "mini" },
                  slot: "reference"
                },
                [_vm._v("字段自定义")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("h3", [_vm._v(_vm._s(_vm.companyDetail.name))]),
                    _c("h3", [_vm._v("投诉处理单")]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "font-size": "12px",
                        "margin-top": "20px",
                        color: "#000"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("投诉编号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.orderNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("登记日期：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.dataFormatter(
                                  _vm.orderInfo.registrationTime,
                                  "date"
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("销售单号：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.salesOrderNumber))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("受理人：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.acceptanceName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("投诉人：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.complaintsName))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "50%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("联系方式：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.mobile))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("联系地址：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.contactAddress))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("投诉问题：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.conplaintsProblem))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticStyle: { width: "100%", display: "flex" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "70px",
                                "text-align": "right"
                              }
                            },
                            [_vm._v("审核意见：")]
                          ),
                          _c("div", { staticStyle: { flex: "1" } }, [
                            _vm._v(_vm._s(_vm.orderInfo.examinePinion))
                          ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "detail-table" },
                    [
                      _vm._l(_vm.detailList, function(item, index) {
                        return [
                          _c("table", { key: "table-" + index }, [
                            _c("thead", [
                              _c(
                                "tr",
                                [
                                  _vm._l(_vm.localColumnsList, function(
                                    item2,
                                    index2
                                  ) {
                                    return [
                                      item2.checked === true &&
                                      item2.aloneRow === false
                                        ? _c("th", { key: "field-" + index2 }, [
                                            _vm._v(_vm._s(item2.text))
                                          ])
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            ]),
                            _c(
                              "tbody",
                              [
                                _c(
                                  "tr",
                                  [
                                    _vm._l(_vm.localColumnsList, function(
                                      item2,
                                      index2
                                    ) {
                                      return [
                                        item2.checked === true &&
                                        item2.aloneRow === false
                                          ? _c(
                                              "td",
                                              {
                                                key:
                                                  "td-" + index + "-" + index2
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.dataFormatter(
                                                        item[item2.field],
                                                        item2.type
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                ),
                                _vm._l(_vm.localColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true &&
                                    item2.aloneRow === true
                                      ? _c(
                                          "tr",
                                          {
                                            key: "row-" + index + "-" + index2
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                attrs: {
                                                  colspan: _vm.countShowFields()
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      padding: "0 5px"
                                                    },
                                                    attrs: { align: "left" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item2.text) +
                                                        "：" +
                                                        _vm._s(
                                                          _vm.dataFormatter(
                                                            item[item2.field],
                                                            item2.type
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ]
                      })
                    ],
                    2
                  ),
                  _c("div", { staticClass: "footer-table" }, [
                    _c("table", [
                      _c("tr", [
                        _c("td", [
                          _c("span", [_vm._v("审核人/时间：")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.orderInfo.examineName) +
                              " / " +
                              _vm._s(_vm.orderInfo.examineTime) +
                              " "
                          )
                        ]),
                        _c("td", [
                          _c("span", [_vm._v("打印人/时间：")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.user.nickName) +
                              " / " +
                              _vm._s(_vm.getCurrentTime()) +
                              " "
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }