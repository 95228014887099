import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listRegistration } from '@/api/registration.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {},
      dialogFullScreen: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '注册证预警';
      that.dialogFullScreen = false;
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        is_warning: 1
      };
      that.loadRegistration();
    },
    loadRegistration: function loadRegistration() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      listRegistration(that.form).then(function (res) {
        that.dbList = res.list;
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadRegistration();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toRetailer: function toRetailer(row) {
      var that = this;
      that.$router.push({
        name: 'base-registration-index',
        params: {
          regNoCn: row.regNoCn
        }
      });
      that.dialogClose();
    },
    validateFormatter: function validateFormatter(time) {
      var date1 = dayjs();

      if (date1.diff(time, 'day') < 1) {
        return time;
      } else {
        return '已过期';
      }
    },
    cellClassName: function cellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (column.property === 'expirationDate' || column.property === 'validityCategory') {
        var date1 = dayjs();

        if (date1.diff(dayjs(row.expirationDate), 'day') < 1) {
          return 'text-warning';
        } else {
          return 'text-danger';
        }
      }
    }
  }
};