import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'EDesc',
  // 通过provide提供给子组件
  provide: function provide() {
    return {
      labelWidth: this.labelWidth,
      column: this.column,
      size: this.size,
      suffix: this.suffix
    };
  },
  props: {
    // 数据源，监听数据重绘
    data: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 边距
    margin: {
      type: String,
      default: '0'
    },
    // label宽度
    labelWidth: {
      type: String,
      default: '120px'
    },
    column: {
      // 每行显示的项目个数
      type: [Number, String],
      default: 3
    },
    size: {
      // 大小
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    }
  },
  watch: {
    data: {
      handler: function handler() {
        var _this = this;

        this.$nextTick(function () {
          // 筛选出子组件e-desc-item
          var dataSource = _this.$slots.default;
          var dataList = [];
          dataSource.forEach(function (item) {
            if (item.componentOptions && item.componentOptions.tag === 'e-desc-item') {
              dataList.push(item.componentInstance);
            }
          }); // 剩余span

          var leftSpan = _this.column;
          var len = dataList.length;
          dataList.forEach(function (item, index) {
            // 处理column与span之间的关系
            // 剩余的列数小于设置的span数
            var hasLeft = leftSpan <= (item.span || 1); // 当前列的下一列大于了剩余span

            var nextColumnSpan = index < len - 1 && dataList[index + 1].span >= leftSpan; // 是最后一行的最后一列

            var isLast = index === len - 1;

            if (hasLeft || nextColumnSpan || isLast) {
              // 满足以上条件，需要自动补全span，避免最后一列出现残缺的情况
              item.selfSpan = leftSpan;
              leftSpan = _this.column;
            } else {
              leftSpan -= item.span || 1;
            }
          });
        });
      },
      deep: true,
      immediate: true
    }
  }
};