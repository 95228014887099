var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("投诉处理")]),
              _c("el-breadcrumb-item", [
                _vm._v(_vm._s(_vm.form.id ? "巡检记录详情" : "新增巡检记录"))
              ])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 巡检单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              "label-suffix": ":",
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "巡检人", prop: "inspectionName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.inspectionName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "inspectionName", $$v)
                              },
                              expression: "form.inspectionName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "inspectionName-" + item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "巡检日期", prop: "inspectionTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.inspectionTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inspectionTime", $$v)
                            },
                            expression: "form.inspectionTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            on: { change: _vm.warehouseChange },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: "warehouseId-" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "温度(℃)", prop: "temperature" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "温度(℃)",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "12"
                          },
                          model: {
                            value: _vm.form.temperature,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "temperature", $$v)
                            },
                            expression: "form.temperature"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "湿度(%)", prop: "humidity" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "湿度(%)",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "12"
                          },
                          model: {
                            value: _vm.form.humidity,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "humidity", $$v)
                            },
                            expression: "form.humidity"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "库房环境", prop: "environment" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "库房环境",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.environment,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "environment", $$v)
                            },
                            expression: "form.environment"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "消防设备", prop: "device" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "消防设备",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.device,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "device", $$v)
                            },
                            expression: "form.device"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "照明", prop: "lighting" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "照明",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.lighting,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "lighting", $$v)
                            },
                            expression: "form.lighting"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "检测仪器", prop: "instrumentation" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "检测仪器",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.instrumentation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "instrumentation", $$v)
                            },
                            expression: "form.instrumentation"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            clearable: "",
                            "suffix-icon":
                              _vm.form.status > 1 ? "" : "el-icon-edit",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  disabled: _vm.form.status === 1 ? false : true
                },
                on: { click: _vm.selectProduct }
              },
              [_vm._v("添加相关产品")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  disabled: _vm.form.status === 1 ? false : true
                },
                on: { click: _vm.importFromExcel }
              },
              [_vm._v("EXCEL导入")]
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              data: _vm.pageDataLoad()
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "storage",
                label: "存储条件",
                "min-width": "100",
                align: "center",
                formatter: _vm.storageFormatter,
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "批号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "quantity",
                label: "数量",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input", {
                            directives: [
                              {
                                name: "enter-number",
                                rawName: "v-enter-number:[{min:0,precision:0}]",
                                arg: { min: 0, precision: 0 }
                              }
                            ],
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.quantity,
                              callback: function($$v) {
                                _vm.$set(scope.row, "quantity", _vm._n($$v))
                              },
                              expression: "scope.row.quantity"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.quantity))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inspectionTime",
                label: "养护日期",
                "min-width": "160",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c(
                            "div",
                            [
                              _c("el-date-picker", {
                                ref: "inspectionTimeDatePicker-" + scope.$index,
                                staticStyle: {
                                  position: "absolute",
                                  "z-index": "-100"
                                },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  editable: false,
                                  type: "date"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.inspectionTime,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "inspectionTime", $$v)
                                  },
                                  expression: "scope.row.inspectionTime"
                                }
                              }),
                              _c(
                                "el-input",
                                {
                                  ref: "inspectionTime-" + scope.$index,
                                  staticClass: "date-input",
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function($event) {
                                      return _vm.detailSaveOne(scope.row)
                                    }
                                  },
                                  model: {
                                    value: scope.row.inspectionTime,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "inspectionTime", $$v)
                                    },
                                    expression: "scope.row.inspectionTime"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-date",
                                    attrs: { slot: "append" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showDatePicker(
                                          "inspectionTimeDatePicker-" +
                                            scope.$index
                                        )
                                      }
                                    },
                                    slot: "append"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(" " + _vm._s(scope.row.inspectionTime) + " ")
                          ])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inspectionName",
                label: "养护人",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm.form.status === 1
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.inspectionName,
                              callback: function($$v) {
                                _vm.$set(scope.row, "inspectionName", $$v)
                              },
                              expression: "scope.row.inspectionName"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(scope.row.inspectionName))])
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inspectionContent",
                label: "养护结果",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.inspectionContentEdit(scope.row)
                            }
                          }
                        },
                        [_vm._v("养护结果")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, total",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: { "current-change": _vm.detailFormChange }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", disabled: _vm.rowIndex < 0 },
                          on: { click: _vm.rowRemove }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "success" },
                          on: { click: _vm.formStorage }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: { click: _vm.formUnSubmit }
                        },
                        [_vm._v("撤销提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.toPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.edit }
      }),
      _c("InspectionContentComponents", {
        ref: "InspectionContentComponents",
        on: { confirm: _vm.inspectionContentConfirm }
      }),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("ImportFromExcelComponent", {
        ref: "ImportFromExcelComponentRef",
        on: { add: _vm.stockAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }