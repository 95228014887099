var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "scan" }, [
      _c("div", { staticClass: "barCodeContent" }, [
        _vm.blockNumber.blockNumber0 &&
        _vm.blockNumber.blockNumber1 &&
        _vm.blockNumber.blockNumber2 &&
        _vm.blockNumber.blockNumber3
          ? _c("span", { ref: "sampleBarcode" }, [
              _c("span", [
                _c(
                  "span",
                  { staticClass: "emptyBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber0.blockEmpty, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "headBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber0.blockHead, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "bodyBarcode0" },
                  _vm._l(_vm.blockNumber.blockNumber0.blockBody, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                )
              ]),
              _c("span", [
                _c(
                  "span",
                  { staticClass: "emptyBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber1.blockEmpty, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "headBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber1.blockHead, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "bodyBarcode1" },
                  _vm._l(_vm.blockNumber.blockNumber1.blockBody, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                )
              ]),
              _c("span", [
                _c(
                  "span",
                  { staticClass: "emptyBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber2.blockEmpty, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "headBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber2.blockHead, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "bodyBarcode2" },
                  _vm._l(_vm.blockNumber.blockNumber2.blockBody, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                )
              ]),
              _c("span", [
                _c(
                  "span",
                  { staticClass: "emptyBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber3.blockEmpty, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "headBarcode" },
                  _vm._l(_vm.blockNumber.blockNumber3.blockHead, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                ),
                _c(
                  "span",
                  { staticClass: "bodyBarcode3" },
                  _vm._l(_vm.blockNumber.blockNumber3.blockBody, function(
                    char
                  ) {
                    return _c("span", { staticClass: "fixed-width" }, [
                      _vm._v(" " + _vm._s(char) + " ")
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "ruler" },
          _vm._l(40, function(i) {
            return _c("div", { key: i, staticClass: "rulerPerIndex" }, [
              _c("span", { staticClass: "rulerPerIndexFix" }, [_vm._v("|")]),
              _c("span", { staticClass: "rulerPerIndexNumber" }, [
                _vm._v(_vm._s(i))
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }