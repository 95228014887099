var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "close-on-click-modal": false,
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("SampleComponent", {
        key: "SampleComponent" + _vm.timestamp,
        ref: "SampleComponentRef",
        attrs: { row: _vm.form },
        on: { change: _vm.sampleChange }
      }),
      _c("el-divider"),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "auto",
            size: "mini"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c("el-form-item", { attrs: { label: "生产日期标识" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "manufacturerDateIdent",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.manufacturerDateIdent,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "manufacturerDateIdent", $$v)
                    },
                    expression: "form.manufacturerDateIdent"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "manufacturerDateIdentPos",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.manufacturerDateIdentPos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "manufacturerDateIdentPos", $$v)
                    },
                    expression: "form.manufacturerDateIdentPos"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ]),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v("(生产日期标识一般为“11”)")
                ])
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "生产日期长度" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "manufacturerDateLength",
                  staticClass: "bodyBarcode0",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.manufacturerDateLength,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "manufacturerDateLength", $$v)
                    },
                    expression: "form.manufacturerDateLength"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("生产日期格式")
                ]),
                _c("el-input", {
                  ref: "manufacturerDateFormat",
                  staticClass: "bodyBarcode0",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.manufacturerDateFormat,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "manufacturerDateFormat", $$v)
                    },
                    expression: "form.manufacturerDateFormat"
                  }
                }),
                _c(
                  "span",
                  { staticStyle: { color: "#f00", margin: "0 5px" } },
                  [_vm._v("（200101表示20年1月1日则填YYMMDD）")]
                )
              ],
              1
            )
          ]),
          _c("el-divider"),
          _c("el-form-item", { attrs: { label: "有效期标识" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "validityDateIdent",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.validityDateIdent,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "validityDateIdent", $$v)
                    },
                    expression: "form.validityDateIdent"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "validityDateIdentPos",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.validityDateIdentPos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "validityDateIdentPos", $$v)
                    },
                    expression: "form.validityDateIdentPos"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ]),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v("（有效期标识一般为“17”）")
                ])
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "有效期长度" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "validityDateLength",
                  staticClass: "bodyBarcode1",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.validityDateLength,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "validityDateLength", $$v)
                    },
                    expression: "form.validityDateLength"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("有效期格式")
                ]),
                _c("el-input", {
                  ref: "validityDateFormat",
                  staticClass: "bodyBarcode1",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.validityDateFormat,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "validityDateFormat", $$v)
                    },
                    expression: "form.validityDateFormat"
                  }
                }),
                _c(
                  "span",
                  { staticStyle: { color: "#f00", margin: "0 5px" } },
                  [_vm._v("（200101表示20年1月1日则填YYMMDD）")]
                )
              ],
              1
            )
          ]),
          _c("el-divider"),
          _c("el-form-item", { attrs: { label: "生产批号标识" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "batchNumberIdenti",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.batchNumberIdenti,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "batchNumberIdenti", $$v)
                    },
                    expression: "form.batchNumberIdenti"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "batchNumberIdentiPos",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.batchNumberIdentiPos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "batchNumberIdentiPos", $$v)
                    },
                    expression: "form.batchNumberIdentiPos"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ]),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v("（生产批号标识一般为“10”）")
                ])
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "生产批号长度" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "batchNumberLength",
                  staticClass: "bodyBarcode2",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.batchNumberLength,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "batchNumberLength", $$v)
                    },
                    expression: "form.batchNumberLength"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "batchNumberPos",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.batchNumberPos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "batchNumberPos", $$v)
                    },
                    expression: "form.batchNumberPos"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ])
              ],
              1
            )
          ]),
          _c("el-divider"),
          _c("el-form-item", { attrs: { label: "序列号标识" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "trackingCodeIdent",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.trackingCodeIdent,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "trackingCodeIdent", $$v)
                    },
                    expression: "form.trackingCodeIdent"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "trackingCodeIdentPos",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.trackingCodeIdentPos,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "trackingCodeIdentPos", $$v)
                    },
                    expression: "form.trackingCodeIdentPos"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ]),
                _c("span", { staticStyle: { color: "#f00" } }, [
                  _vm._v("（序列号标识一般为“21”）")
                ])
              ],
              1
            )
          ]),
          _c("el-form-item", { attrs: { label: "序列号长度" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _c("el-input", {
                  ref: "trackingCodeLength",
                  staticClass: "bodyBarcode3",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.trackingCodeLength,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "trackingCodeLength", $$v)
                    },
                    expression: "form.trackingCodeLength"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("从第")
                ]),
                _c("el-input", {
                  ref: "trackingCodePosition",
                  staticClass: "headBarcode",
                  staticStyle: { width: "100px" },
                  on: { change: _vm.valueChangeHandel },
                  model: {
                    value: _vm.form.trackingCodePosition,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "trackingCodePosition", $$v)
                    },
                    expression: "form.trackingCodePosition"
                  }
                }),
                _c("span", { staticStyle: { margin: "0 5px" } }, [
                  _vm._v("位开始")
                ])
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0", align: "center" } },
            [
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.dialogClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }