var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList,
            size: "small",
            "max-height": "500",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "日期",
              prop: "czDate",
              width: "100",
              align: "center",
              formatter: function(row) {
                return _vm.dateFormat(row.czDate)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "经手人",
              prop: "czName",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作金额",
              prop: "czAmount",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.czAmount > 0
                      ? _c("span", { staticStyle: { color: "#17c0b4" } }, [
                          _vm._v("+" + _vm._s(scope.row.czAmount.toFixed(2)))
                        ])
                      : scope.row.czAmount < 0
                      ? _c("span", { staticStyle: { color: "#f00" } }, [
                          _vm._v(_vm._s(scope.row.czAmount.toFixed(2)))
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(scope.row.czAmount.toFixed(2)))
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "余额",
              prop: "amount",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.amount.toFixed(2)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "相关描述",
              prop: "content",
              "min-width": "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "相关凭证",
              prop: "pz",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "bottom",
                          width: "400",
                          trigger: "click"
                        }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: scope.row.pz, size: "small" }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "相关凭证", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope2) {
                                      return [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.previewFile(
                                                  scope2.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(scope2.row.name))]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: { slot: "reference", type: "primary" },
                            slot: "reference"
                          },
                          [_vm._v("查看相关凭证")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "相关单据",
              prop: "appOrder",
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.viewAdvOrder(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.advOrder))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "page-sizes": [10, 20, 50, 100],
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }