var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-select",
            {
              attrs: { size: "small" },
              on: { change: _vm.loadData },
              model: {
                value: _vm.form.orderByPolicy,
                callback: function($$v) {
                  _vm.$set(_vm.form, "orderByPolicy", $$v)
                },
                expression: "form.orderByPolicy"
              }
            },
            [
              _c("el-option", {
                attrs: { value: 1, label: "按效期时间临近先出库" }
              }),
              _c("el-option", {
                attrs: { value: 2, label: "按效期时间临远先出库" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "detailListTable",
          attrs: {
            data: _vm.detailList.slice(
              (_vm.form.page - 1) * _vm.form.pageSize,
              _vm.form.page * _vm.form.pageSize
            ),
            size: "small",
            border: "",
            height: "400",
            "max-height": "400",
            "default-expand-all": true,
            "row-class-name": _vm.rowClassName,
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", width: "40" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { size: "small", data: props.row.stockList }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "batchNumber",
                            "min-width": "150",
                            label: "批号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "trackingCode",
                            "min-width": "150",
                            label: "序列号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "validityDate",
                            label: "效期",
                            width: "100",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "normalQuantity",
                            width: "100",
                            label: "可用数量",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "deliveryQuantity",
                            label: "出库数量",
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input-number", {
                                      attrs: {
                                        size: "mini",
                                        min: 0,
                                        max: row.normalQuantity
                                      },
                                      model: {
                                        value: row.deliveryQuantity,
                                        callback: function($$v) {
                                          _vm.$set(row, "deliveryQuantity", $$v)
                                        },
                                        expression: "row.deliveryQuantity"
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "operations",
                            width: "100",
                            label: "操作",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: { type: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeBatch(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("删除批次")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { type: "index", label: "#", width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.form.page - 1) * _vm.form.pageSize +
                            scope.$index +
                            1
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNumber",
              "min-width": "100",
              label: "产品编号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              "min-width": "150",
              label: "物资名称",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specificationModel",
              "min-width": "100",
              label: "规格型号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliveryQuantity",
              width: "160",
              label: "出库数量",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operations",
              width: "100",
              label: "操作",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.addBatch(scope.row)
                          }
                        }
                      },
                      [_vm._v("添加批次")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "current-change": _vm.formPageChange,
              "size-change": _vm.formSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer", align: "center" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.selectConfirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c("BatchSelectComponent", {
        ref: "batchSelectComponentRef",
        on: { add: _vm.addBatchConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }