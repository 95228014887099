var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售报台中心")]),
              _c("el-breadcrumb-item", [_vm._v("寄售平台绑定设置")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-plus"
                  },
                  on: {
                    click: function($event) {
                      return _vm.dataCreate()
                    }
                  }
                },
                [_vm._v("寄售平台绑定")]
              )
            ],
            1
          )
        ],
        1
      ),
      [
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dataTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            columns: _vm.columns,
            data: _vm.dataList,
            "route-name": _vm.dataTableCacheName
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange },
          scopedSlots: _vm._u([
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "danger", underline: false },
                      on: {
                        click: function($event) {
                          return _vm.dataDelete(row)
                        }
                      }
                    },
                    [_vm._v("解除绑定")]
                  )
                ]
              }
            }
          ])
        })
      ],
      _c("template", { slot: "footer" }),
      _c("FormComponent", {
        ref: "FormComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }