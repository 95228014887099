var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("投诉处理")]),
              _c("el-breadcrumb-item", [_vm._v("不合格品处理")]),
              _c("el-breadcrumb-item", [_vm._v("不合格品处理单详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 处理单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.status > 1
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 6, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "处理人", prop: "acceptanceName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            on: { change: _vm.formStorageFast },
                            model: {
                              value: _vm.form.acceptanceName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acceptanceName", $$v)
                              },
                              expression: "form.acceptanceName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "acceptanceName-" + item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 6, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "处理日期", prop: "handleTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          on: { change: _vm.formStorageFast },
                          model: {
                            value: _vm.form.handleTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "handleTime", $$v)
                            },
                            expression: "form.handleTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 6, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "来源", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled:
                                _vm.detailList.length > 0 ||
                                _vm.form.procurementAcceptance === 1
                                  ? true
                                  : false
                            },
                            on: { change: _vm.warehouseChange },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: "warehouseId-" + item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.form.procurementAcceptance === 1
                  ? _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 6, xl: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "采购验收",
                              prop: "procurementAcceptance"
                            }
                          },
                          [
                            _c("el-checkbox", {
                              attrs: {
                                "true-label": 1,
                                "false-label": "0",
                                disabled: true
                              },
                              model: {
                                value: _vm.form.procurementAcceptance,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "procurementAcceptance",
                                    $$v
                                  )
                                },
                                expression: "form.procurementAcceptance"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "备注", clearable: "" },
                          on: { change: _vm.formStorage },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _vm.form.procurementAcceptance === 0
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      disabled: _vm.form.status === 1 ? false : true
                    },
                    on: { click: _vm.selectProduct }
                  },
                  [_vm._v("添加不合格产品")]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            ref: "detailListTable",
            attrs: {
              width: "100%",
              border: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-key": _vm.detailRowKey,
              "row-class-name": _vm.rowClassName,
              data: _vm.detailList,
              "max-height": 450
            },
            on: { "row-click": _vm.detailRowClick }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "selection",
                width: "40",
                align: "center",
                selectable: _vm.selectable
              }
            }),
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "60",
                align: "center",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                }
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productName",
                label: "产品名称",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specificationModel",
                label: "规格型号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "brandName",
                label: "品牌",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "unit",
                label: "单位",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "batchNumber",
                label: "批号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "trackingCode",
                label: "序列号",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerDate",
                label: "生产日期",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "validityDate",
                label: "有效期",
                "min-width": "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "manufacturerName",
                label: "供应商",
                "min-width": "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "quantity",
                label: "不合格数量",
                "min-width": "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "returnQuantity",
                label: "已退货数量",
                "min-width": "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "qualifiedQuantity",
                label: "已复检数量",
                "min-width": "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "destructionQuantity",
                label: "已销毁数量",
                "min-width": "100",
                align: "center"
              }
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1 &&
                  _vm.form.procurementAcceptance === 0 &&
                  _vm.canDelete() === true
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", disabled: _vm.rowIndex < 0 },
                          on: { click: _vm.rowRemove }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.toReturnWarehouse }
                    },
                    [_vm._v("退货")]
                  ),
                  _vm.form.procurementAcceptance === 0
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.toRecheck }
                        },
                        [_vm._v("复检")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.toDestroy }
                    },
                    [_vm._v("销毁")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.toPrint } },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ProductAddComponents", {
        ref: "ProductAddComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.edit }
      }),
      _c("ReturnWarehouseComponents", {
        ref: "ReturnWarehouseComponents",
        on: { reload: _vm.loadDetail }
      }),
      _c("ReCheckComponents", {
        ref: "ReCheckComponents",
        on: { reload: _vm.loadDetail }
      }),
      _c("DestroyComponents", {
        ref: "DestroyComponents",
        on: { reload: _vm.loadDetail }
      }),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }