import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listRetailer } from '@/api/retailer.js';
import EditorFormComponents from '@/views/sales/agent/retailer/components/editor_form/index.vue';
export default {
  name: 'stock-inventory-detail',
  components: {
    EditorFormComponents: EditorFormComponents
  },
  data: function data() {
    return {
      pageForm: {
        name: '',
        short_name: '',
        page: 1,
        pageSize: 10,
        total: 1
      },
      tableData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadRetailer();
    },
    loadRetailer: function loadRetailer() {
      var that = this;
      that.tableData = [];
      listRetailer({
        page: 1,
        pageSize: 9999,
        all_examine_status: true
      }).then(function (res) {
        res.list.forEach(function (row) {
          that.tableData.push(row);
        });
        that.pageForm.total = res.total;
      });
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.pageForm.page = 1;
      that.loadRetailer();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadRetailer();
    }
  }
};