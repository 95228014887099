var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          attrs: { inline: true, size: "mini", "label-suffix": ":" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between"
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类" } },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.pidChange },
                          model: {
                            value: _vm.form.pid,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "pid", $$v)
                            },
                            expression: "form.pid"
                          }
                        },
                        _vm._l(_vm.consumSetList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "组套" } },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.setIdChange },
                          model: {
                            value: _vm.consumSetInfoForm.groupId,
                            callback: function($$v) {
                              _vm.$set(_vm.consumSetInfoForm, "groupId", $$v)
                            },
                            expression: "consumSetInfoForm.groupId"
                          }
                        },
                        _vm._l(_vm.consumSubSetList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "策略" } },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.loadConsum },
                          model: {
                            value: _vm.consumSetInfoForm.policy,
                            callback: function($$v) {
                              _vm.$set(_vm.consumSetInfoForm, "policy", $$v)
                            },
                            expression: "consumSetInfoForm.policy"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "按效期时间临近先出库", value: 0 }
                          }),
                          _c("el-option", {
                            attrs: { label: "按效期时间临远先出库", value: 1 }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "el-table",
        {
          ref: "consumListTableRef",
          attrs: {
            data: _vm.consumList,
            "row-key": _vm.rowKey,
            border: "",
            "span-method": _vm.objectSpanMethod,
            size: "mini",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "#",
              width: "40",
              align: "center",
              index: function(index) {
                return _vm.indexMethod(index, _vm.consumSetInfoForm)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNumber",
              "min-width": "100",
              label: "产品编号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              "min-width": "100",
              label: "物资名称",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specificationModel",
              "min-width": "100",
              label: "规格型号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "total",
              width: "70",
              label: "套内数量",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: { width: "80", label: "", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.selectBatch(scope.row)
                          }
                        }
                      },
                      [_vm._v("选择批次")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "normalQuantity",
              width: "70",
              label: "可用数量",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deliveryQuantity",
              label: "出库数量",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "80px" },
                      attrs: {
                        size: "mini",
                        min: 0,
                        "controls-position": "right"
                      },
                      model: {
                        value: row.deliveryQuantity,
                        callback: function($$v) {
                          _vm.$set(row, "deliveryQuantity", $$v)
                        },
                        expression: "row.deliveryQuantity"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "batchNumber",
              "min-width": "100",
              label: "批号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trackingCode",
              "min-width": "100",
              label: "序列号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validityDate",
              label: "效期",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              label: "操作",
              prop: "operations",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "danger",
                          disabled: scope.row.inventoryId === 0
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteBatch(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除批次")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.consumSetInfoForm.total,
              "current-page": _vm.consumSetInfoForm.page,
              "page-size": _vm.consumSetInfoForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "current-change": _vm.consumSetInfoFormPageChange,
              "size-change": _vm.consumSetInfoFormSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.selectConfirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c("BatchSelectComponent", {
        ref: "batchSelectComponentRef",
        on: { add: _vm.selectBatchConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }