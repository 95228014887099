var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-row",
        {
          ref: "printContainer",
          staticStyle: { "flex-wrap": "wrap" },
          attrs: { type: "flex", id: "printContainer" }
        },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
                "flex-direction": "column"
              },
              attrs: { span: 14 }
            },
            [
              _c("div", { staticClass: "order-department" }, [
                _vm._v(_vm._s(_vm.companyDetail.name))
              ]),
              _c("div", { staticClass: "order-type" }, [
                _vm._v("直销客户出库单")
              ])
            ]
          ),
          _c("el-col", { attrs: { span: 10, align: "right" } }, [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { id: "barcode" }
            })
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("客户名称：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.hospitalName))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("出库单号：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.deliveryOrderNo))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("仓库：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.warehouseName))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("医院名称：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.hospitalName))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("销售人员：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.nameSalesperson))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("维护人员：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.nameMaintenancePersonnel))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("跟台人员：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.nameAttendant))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("手术时间：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.operationTime))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("配送方式：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.shippingMethodId))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("快递单号：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.courierNumber))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("加班费：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.overtimePay))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("运输费：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.freight))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("其他费用：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.otherExpenses))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("收货电话：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.receivingPhone))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 16 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("收货地址：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.shipAddress))
              ])
            ])
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("耗材包：")]),
              _c("div", { staticClass: "value" })
            ])
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "table-header-item" }, [
              _c("div", { staticClass: "label" }, [_vm._v("备注：")]),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.orderDetail.remark))
              ])
            ])
          ]),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("div", {
                staticStyle: {
                  margin: "10px 0",
                  height: "2px",
                  background: "#000",
                  overflow: "hidden"
                }
              }),
              _c(
                "el-table",
                {
                  staticClass: "elTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.consumDetail,
                    border: "",
                    size: "mini",
                    "cell-style": { borderColor: "#000", color: "#000" },
                    "header-cell-style": {
                      borderColor: "#000",
                      background: "#F5F7FA",
                      color: "#000"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "productNumber", label: "产品编号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "main.productName", label: "物资名称" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specs.specificationModel",
                      label: "规格型号"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "specs.batchNumber", label: "生产批号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "deliveryQuantity", label: "出库数量" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "manufacturerDate", label: "生产日期" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "validityDate", label: "有效期" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "main.registrationNumber",
                      label: "注册证号"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "order-footer" }, [
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("验收人/时间：")
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.orderDetail.accountName) +
                        " / " +
                        _vm._s(_vm.orderDetail.accountTime)
                    )
                  ])
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("审核人/时间：")
                  ]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.orderDetail.examineName) +
                        " / " +
                        _vm._s(_vm.orderDetail.examineTime)
                    )
                  ])
                ]),
                _c("div", { staticClass: "item" }, [
                  _c("div", { staticClass: "label" }, [_vm._v("到货日期：")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.orderDetail.accountTime))
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "20px 0", "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.printClick } },
            [_vm._v("点击打印")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }