var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "800px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "100px",
                size: "small",
                "label-suffix": ":"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称", prop: "customerName" } },
                        [_vm._v(" " + _vm._s(_vm.form.customerName) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "税号", prop: "companyTaxNumber" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.companyTaxNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "companyTaxNumber", $$v)
                                  },
                                  expression: "form.companyTaxNumber"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.form.companyTaxNumber))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户银行", prop: "bank" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.bank,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "bank", $$v)
                                  },
                                  expression: "form.bank"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.bank))])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "银行账户", prop: "bankAccount" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.bankAccount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "bankAccount", $$v)
                                  },
                                  expression: "form.bankAccount"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.bankAccount))])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位地址", prop: "workAddress" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.workAddress,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "workAddress", $$v)
                                  },
                                  expression: "form.workAddress"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.workAddress))])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "开票金额", prop: "totalSalesAmount" }
                        },
                        [_vm._v(" " + _vm._s(_vm.form.totalSalesAmount) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位电话", prop: "telephone" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.telephone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "telephone", $$v)
                                  },
                                  expression: "form.telephone"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.telephone))])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发票类型", prop: "invoiceType" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.invoiceType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "invoiceType", $$v)
                                    },
                                    expression: "form.invoiceType"
                                  }
                                },
                                _vm._l(_vm.invoiceTypeList, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: "invoiceType-" + index,
                                    attrs: { label: item.name, value: item.ID }
                                  })
                                }),
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(_vm.form.invoiceTypeName))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收票地址", prop: "ticketAddress" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { clearable: "" },
                                      on: { change: _vm.ticketAddressChange },
                                      model: {
                                        value: _vm.form.ticketAddress,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "ticketAddress",
                                            $$v
                                          )
                                        },
                                        expression: "form.ticketAddress"
                                      }
                                    },
                                    _vm._l(_vm.addressList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: "ticketAddress" + index,
                                        attrs: {
                                          label: item.address,
                                          value: item.address
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        "text-align": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.createAddress()
                                            }
                                          }
                                        },
                                        [_vm._v("新增地址")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(_vm.form.ticketAddress))
                              ])
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收票人", prop: "payeeName" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.payeeName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "payeeName", $$v)
                                  },
                                  expression: "form.payeeName"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.payeeName))])
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "收票电话", prop: "ticketPhone" } },
                        [
                          _vm.command === "REAPPLY"
                            ? _c("el-input", {
                                attrs: { clearable: "" },
                                model: {
                                  value: _vm.form.ticketPhone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "ticketPhone", $$v)
                                  },
                                  expression: "form.ticketPhone"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.form.ticketPhone))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v(_vm._s(_vm.command === "REAPPLY" ? "取消" : "关 闭"))
          ]),
          _vm.command === "REAPPLY"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.formSubmit } },
                [_vm._v("提交")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("CreateAddressComponent", {
        ref: "CreateAddressComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadAddress()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }