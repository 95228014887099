var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            title: "选择生产商导入",
            visible: _vm.centerDialogVisible,
            loading: _vm.loading,
            "close-on-click-modal": false,
            top: "5vh",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.centerDialogVisible = $event
            },
            opened: _vm.handleOpen,
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "div",
                        { staticClass: "left-content el-transfer-panel" },
                        [
                          _c(
                            "p",
                            { staticClass: "el-transfer-panel__header" },
                            [_vm._v("生产商列表")]
                          ),
                          _c("el-input", {
                            staticStyle: { margin: "10px 0" },
                            attrs: { clearable: "", placeholder: "生产商名称" },
                            on: {
                              blur: _vm.initManufacturerList,
                              change: _vm.initManufacturerList,
                              clear: _vm.initManufacturerList
                            },
                            model: {
                              value: _vm.pageForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "name", $$v)
                              },
                              expression: "pageForm.name"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "brandContent" },
                            _vm._l(_vm.manufacturerList, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.ID,
                                  staticClass:
                                    "el-checkbox-group brandContentGroup",
                                  attrs: { "data-value": item.ID }
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: item.name,
                                        placement: "top"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "el-checkbox__label",
                                          staticStyle: {
                                            "text-align": "left",
                                            "padding-left": "0px",
                                            "white-space": "nowrap",
                                            "text-overflow": "ellipsis"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectManufacture(item)
                                        }
                                      }
                                    },
                                    [_vm._v("导 入")]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      ),
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "prev, pager, next, total, sizes, jumper",
                          align: "center",
                          total: _vm.pageForm.total,
                          "current-page": _vm.pageForm.page,
                          "page-size": _vm.pageForm.pageSize
                        },
                        on: {
                          "size-change": _vm.pageSizeChange,
                          "current-change": _vm.pageChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = false
                    }
                  }
                },
                [_vm._v("关 闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }