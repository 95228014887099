var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "stockForm",
          attrs: { "label-width": "70px", size: "small", "label-suffix": ":" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.stockFormSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.stockForm,
            callback: function($$v) {
              _vm.stockForm = $$v
            },
            expression: "stockForm"
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品编号", clearable: "" },
                        model: {
                          value: _vm.stockForm.productNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "productNumber", $$v)
                          },
                          expression: "stockForm.productNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物资名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "物资名称", clearable: "" },
                        model: {
                          value: _vm.stockForm.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "productName", $$v)
                          },
                          expression: "stockForm.productName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通用名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "通用名称", clearable: "" },
                        model: {
                          value: _vm.stockForm.genericName,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "genericName", $$v)
                          },
                          expression: "stockForm.genericName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格型号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "规格型号", clearable: "" },
                        model: {
                          value: _vm.stockForm.specificationModel,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "specificationModel", $$v)
                          },
                          expression: "stockForm.specificationModel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择品牌", clearable: "" },
                          model: {
                            value: _vm.stockForm.brandId,
                            callback: function($$v) {
                              _vm.$set(_vm.stockForm, "brandId", $$v)
                            },
                            expression: "stockForm.brandId"
                          }
                        },
                        _vm._l(_vm.brandList, function(item) {
                          return _c("el-option", {
                            key: item.name,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产批号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "生产批号", clearable: "" },
                        model: {
                          value: _vm.stockForm.batch_number,
                          callback: function($$v) {
                            _vm.$set(_vm.stockForm, "batch_number", $$v)
                          },
                          expression: "stockForm.batch_number"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "20px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.stockFormSubmit }
                        },
                        [_vm._v("查 询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-popover",
        {
          ref: "stockPopover",
          attrs: {
            placement: "bottom-start",
            title: "查找结果",
            width: "1000",
            trigger: "manual"
          },
          model: {
            value: _vm.stockPopoverVisible,
            callback: function($$v) {
              _vm.stockPopoverVisible = $$v
            },
            expression: "stockPopoverVisible"
          }
        },
        [
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: {
              position: "absolute",
              top: "0",
              right: "0",
              width: "50px",
              height: "50px",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              cursor: "pointer"
            },
            on: { click: _vm.stockPopoverClose }
          }),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.stockList,
                border: "",
                stripeDELETE: "",
                "highlight-current-row": "",
                size: "mini",
                "max-height": "260px",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                },
                onselectstart: "return false"
              },
              on: { "row-dblclick": _vm.stockRowDbClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "ID",
                  label: "库存ID",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "productNumber",
                  label: "产品编号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "specificationModel",
                  label: "规格型号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "batchNumber",
                  label: "生产批号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "stockQuantity",
                  label: "库存数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "normalQuantity",
                  label: "可用数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "consumedQuantity",
                  label: "累计消耗数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "receiptQuantity",
                  label: "累计入库数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "issueLocking",
                  label: "出库锁定",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "salesPendingApproval",
                  label: "销售待审",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "productPackageLocking",
                  label: "商品包锁定",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "unit",
                  label: "单位",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "registrationNumber",
                  label: "注册证号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "manufacturerDate",
                  label: "生产日期",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "validityDate",
                  label: "有效期",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "",
                  label: "产地/品牌",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.productPlace) +
                            "/" +
                            _vm._s(scope.row.brandName)
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "sterilizationBatchNumber",
                  label: "灭菌批号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "sterilizationDate",
                  label: "灭菌效期",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, total",
                  align: "center",
                  total: _vm.stockForm.total,
                  "current-page": _vm.stockForm.page,
                  "page-size": _vm.stockForm.pageSize
                },
                on: { "current-change": _vm.stockListPageChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "detailListTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.detailList,
            border: "",
            stripeDELETE: "",
            size: "mini",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "row-key": _vm.detailRowKey,
            "row-class-name": _vm.rowClassName
          },
          on: { "row-click": _vm.detailRowClick }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productNumber",
              label: "产品编号",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              label: "物资名称",
              width: "200",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "genericName",
              label: "通用名称",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specificationModel",
              label: "规格型号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "batchNumber",
              label: "生产批号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "quantityUsed",
              label: "销售数量",
              width: "100",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-input", {
                      attrs: { "aria-placeholder": "销售数量", size: "mini" },
                      model: {
                        value: scope.row.quantityUsed,
                        callback: function($$v) {
                          _vm.$set(scope.row, "quantityUsed", $$v)
                        },
                        expression: "scope.row.quantityUsed"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "stockQuantity",
              label: "库存数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "issueLocking",
              label: "锁定数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "normalQuantity",
              label: "可用数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unitPrice",
              label: "单价",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "unit",
              label: "单位",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              label: "包装规格",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.packNum) +
                        _vm._s(scope.row.unit) +
                        " / " +
                        _vm._s(scope.row.packUnit) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productPlace",
              label: "产地",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brandName",
              label: "品牌",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturerDate",
              label: "生产日期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validityDate",
              label: "有效期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "registrationNumber",
              label: "注册证号",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "trackingCode",
              label: "序列号",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sterilizationBatchNumber",
              label: "灭菌批号",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sterilizationDate",
              label: "灭菌效期",
              width: "150",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "manufacturer",
              label: "生产厂家",
              width: "100",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sterilizationWays",
              label: "储运条件",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "placementPosition",
              label: "货位",
              width: "100",
              align: "center"
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }