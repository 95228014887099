import { request } from '@/api/_service.js';
export function listGroup(params) {
  return request({
    url: '/erp/user_group',
    method: 'get',
    params: params
  });
}
export function createGroup(data) {
  return request({
    url: '/erp/user_group',
    method: 'post',
    data: data
  });
}
export function updateGroup(data) {
  return request({
    url: '/erp/user_group',
    method: 'put',
    data: data
  });
}
export function deleteGroup(data) {
  return request({
    url: '/erp/user_group?ids=' + data,
    method: 'delete',
    data: data
  });
}
export function listYoutaiUserGroup(params) {
  return request({
    url: '/erp/user_group_youtai',
    method: 'get',
    params: params
  });
}