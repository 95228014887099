var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "100px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户类型", prop: "customerType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择客户类型" },
                  on: { change: _vm.customerTypeChange },
                  model: {
                    value: _vm.form.customerType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "customerType", $$v)
                    },
                    expression: "form.customerType"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "经销商", value: 1 } }),
                  _c("el-option", { attrs: { label: "医院", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客户名称", prop: "customerId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择客户名称"
                  },
                  model: {
                    value: _vm.form.customerId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "customerId", $$v)
                    },
                    expression: "form.customerId"
                  }
                },
                [
                  _vm.form.customerType === 1
                    ? _vm._l(_vm.retailerList, function(item, index) {
                        return _c("el-option", {
                          key: "retailer" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      })
                    : _vm._e(),
                  _vm.form.customerType === 2
                    ? _vm._l(_vm.hospitalList, function(item, index) {
                        return _c("el-option", {
                          key: "hospital" + index,
                          attrs: { label: item.name, value: item.ID }
                        })
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回款金额", prop: "returnAmount" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, controls: false },
                model: {
                  value: _vm.form.returnAmount,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "returnAmount", $$v)
                  },
                  expression: "form.returnAmount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回款公司", prop: "subsidiaryId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择回款公司"
                  },
                  model: {
                    value: _vm.form.subsidiaryId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "subsidiaryId", $$v)
                    },
                    expression: "form.subsidiaryId"
                  }
                },
                _vm._l(_vm.subsidiaryList, function(item, index) {
                  return _c("el-option", {
                    key: "subsidiaryId" + index,
                    attrs: { label: item.companyName, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回款日期", prop: "returnDate" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "回款日期"
                },
                model: {
                  value: _vm.form.returnDate,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "returnDate", $$v)
                  },
                  expression: "form.returnDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回款方式", prop: "returnType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择付款方式" },
                  model: {
                    value: _vm.form.returnType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "returnType", $$v)
                    },
                    expression: "form.returnType"
                  }
                },
                _vm._l(_vm.repaymentTypeList, function(item, index) {
                  return _c("el-option", {
                    key: "returnType" + index,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remarks" } },
            [
              _c("el-input", {
                attrs: { rows: 2, type: "textarea" },
                model: {
                  value: _vm.form.remarks,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remarks", $$v)
                  },
                  expression: "form.remarks"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回款凭证", prop: "pz" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: _vm.uploadAction,
                    headers: _vm.uploadToken,
                    "on-preview": _vm.uploadPreview,
                    "on-remove": _vm.uploadRemove,
                    "on-success": _vm.uploadSuccess,
                    "file-list": _vm.fileList,
                    "show-file-list": false
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "mini" } },
                    [_vm._v("点击上传")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.fileList,
                    size: "mini",
                    "show-header": false
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "文件名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function($event) {
                                    return _vm.uploadPreview(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.name))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "danger", underline: false },
                                on: {
                                  click: function($event) {
                                    return _vm.uploadRemove(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm.showViewer
                ? _c("el-image-viewer", {
                    attrs: {
                      "on-close": _vm.closeViewer,
                      "url-list": _vm.uploadPreviewImageUrl,
                      "z-index": 9999
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary", loading: _vm.formLoading },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }