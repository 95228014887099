import { request } from '@/api/_service.js'; // 货架列表

export function listShelves(params) {
  return request({
    url: '/dealerShelfErp/getDealerShelfList',
    method: 'get',
    params: params
  });
} // 通过ID查询货架

export function findShelves(params) {
  return request({
    url: '/dealerShelfErp/findDealerShelf',
    method: 'get',
    params: params
  });
} // 创建货架

export function createShelves(data) {
  return request({
    url: '/dealerShelfErp/createDealerShelf',
    method: 'post',
    data: data
  });
} // 更新货架

export function updateShelves(data) {
  return request({
    url: '/dealerShelfErp/updateDealerShelf',
    method: 'put',
    data: data
  });
} // 删除货架

export function deleteShelves(data) {
  return request({
    url: '/dealerShelfErp/deleteDealerShelf',
    method: 'delete',
    data: data
  });
} // 货位列表

export function listLocation(params) {
  return request({
    url: '/dealerLocationErp/getDealerLocationList',
    method: 'get',
    params: params
  });
}
export function listLocationStock(params) {
  return request({
    url: '/dealerLocationErp/getDealerLocationStockList',
    method: 'get',
    params: params
  });
} // 通过ID查询货位

export function findLocation(params) {
  return request({
    url: '/dealerLocationErp/findDealerLocation',
    method: 'get',
    params: params
  });
} // 创建货位

export function createLocation(data) {
  return request({
    url: '/dealerLocationErp/createDealerLocation',
    method: 'post',
    data: data
  });
} // 更新货位

export function updateLocation(data) {
  return request({
    url: '/dealerLocationErp/updateDealerLocation',
    method: 'put',
    data: data
  });
} // 删除货位

export function deleteLocation(data) {
  return request({
    url: '/dealerLocationErp/deleteDealerLocation',
    method: 'delete',
    data: data
  });
}