var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("质量管理")]),
              _c("el-breadcrumb-item", [_vm._v("不良事件监测调查报告")]),
              _c("el-breadcrumb-item", [_vm._v("报告详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 报告单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "auto",
              "show-message": false,
              disabled: _vm.form.status !== 1 ? true : false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "处理人", prop: "acceptanceName" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.acceptanceName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "acceptanceName", $$v)
                              },
                              expression: "form.acceptanceName"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: item.uid,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "报告日期", prop: "reportTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.reportTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "reportTime", $$v)
                            },
                            expression: "form.reportTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品编号", prop: "productNumber" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "产品编号",
                            clearable: "",
                            maxlength: "40"
                          },
                          model: {
                            value: _vm.form.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productNumber", $$v)
                            },
                            expression: "form.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品名称", prop: "productName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "产品名称",
                            clearable: "",
                            maxlength: "40"
                          },
                          model: {
                            value: _vm.form.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productName", $$v)
                            },
                            expression: "form.productName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "规格型号", prop: "specificationModel" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "规格型号",
                            clearable: "",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.form.specificationModel,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "specificationModel", $$v)
                            },
                            expression: "form.specificationModel"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "注册证号", prop: "registrationNumber" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "注册证号",
                            clearable: "",
                            maxlength: "200"
                          },
                          model: {
                            value: _vm.form.registrationNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "registrationNumber", $$v)
                            },
                            expression: "form.registrationNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "批号", prop: "batchNumber" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "批号",
                            clearable: "",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.form.batchNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "batchNumber", $$v)
                            },
                            expression: "form.batchNumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "序列号", prop: "trackingCode" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "序列号",
                            clearable: "",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.form.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "trackingCode", $$v)
                            },
                            expression: "form.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "生产日期", prop: "manufacturerDate" }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "生产日期",
                            format: "YYYY-MM-DD",
                            "value-format": "YYYY-MM-DD"
                          },
                          model: {
                            value: _vm.form.manufacturerDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "manufacturerDate", $$v)
                            },
                            expression: "form.manufacturerDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "数量", prop: "quantity" } },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "enter-number",
                              rawName: "v-enter-number:[{min:1,precision:0}]",
                              arg: { min: 1, precision: 0 }
                            }
                          ],
                          attrs: { placeholder: "数量" },
                          model: {
                            value: _vm.form.quantity,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "quantity", _vm._n($$v))
                            },
                            expression: "form.quantity"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "使用单位", prop: "usedCompany" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "使用单位",
                            clearable: "",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.form.usedCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "usedCompany", $$v)
                            },
                            expression: "form.usedCompany"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "不良事件事故情况", prop: "problem" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "不良事件事故情况",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.problem,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "problem", $$v)
                            },
                            expression: "form.problem"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "使用单位意见", prop: "usedPinion" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "使用单位意见",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.usedPinion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "usedPinion", $$v)
                            },
                            expression: "form.usedPinion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "质量负责人意见",
                          prop: "qualityPinion"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "质量负责人意见",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.qualityPinion,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "qualityPinion", $$v)
                            },
                            expression: "form.qualityPinion"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "处理情况", prop: "situation" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "处理情况",
                            rows: 2,
                            type: "textarea",
                            maxlength: "500",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.situation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "situation", $$v)
                            },
                            expression: "form.situation"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            rows: 2,
                            type: "textarea",
                            maxlength: "250",
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px", width: "100%" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "info" },
                          on: { click: _vm.formStorage }
                        },
                        [_vm._v("暂存")]
                      )
                    : _vm._e(),
                  _vm.form.status === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.status === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: !_vm.form.id },
                      on: { click: _vm.toPrint }
                    },
                    [_vm._v("打印")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.edit }
      }),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }