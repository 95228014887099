//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { updateSalesDetail, deleteSalesDetail } from '@/api/stock';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      form: {},
      formRules: {
        quantityUsed: [{
          required: true,
          message: '请输入新销售量',
          trigger: 'blur'
        }]
      },
      productDetail: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '调整消耗数量';
      that.productDetail = row;
      that.form = {
        id: row.id,
        complimentaryQuantity: row.complimentaryQuantity,
        quantityUsed: row.quantityUsed > 0 ? row.quantityUsed - 1 : 0,
        remarks: row.remarks,
        salesUnitPrice: row.salesUnitPrice
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var that = this;

      if (that.form.quantityUsed > that.productDetail.quantityUsed) {
        that.$message.error('新销售量不能大于原销售量，如需增加请增加耗材');
      } else {
        if (that.form.quantityUsed > 0) {
          updateSalesDetail(that.form).then(function (res) {
            that.$emit('confirm');
            that.dialogClose();
          });
        } else {
          that.$confirm('新销售量为零，将删除此条耗材, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            deleteSalesDetail({
              id: that.form.id
            }).then(function (res) {
              that.$notify({
                message: '删除成功',
                type: 'success'
              });
              that.$emit('confirm');
              that.dialogClose();
            });
          });
        }
      }
    }
  }
};