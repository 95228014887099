import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import { listProduct } from '@/api/product.js';
import { updateSpecial } from '@/api/special.js';
import { listClass } from '@/api/class.js';
import VueTour from 'vue-tour';

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
export default {
  components: {},
  data: function data() {
    return {
      formLoading: false,
      dialogVisible: false,
      currentProductRow: {},
      specialRow: {},
      filterText: '',
      productLoading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableData: [],
      stateList: [{
        label: '全部',
        value: 0
      }, {
        label: '正常',
        value: 1
      }, {
        label: '作废',
        value: 2
      }],
      classData: [],
      productPageForm: {
        pageSize: 7,
        page: 1,
        productName: '',
        state: 0,
        productLineCateId: null,
        total: 0
      },
      tourOptions: {
        labels: {
          buttonStop: '完成'
        }
      },
      steps: [{
        target: '#v-step-0',
        // We're using document.querySelector() under the hood
        header: {
          title: '操作提示'
        },
        content: '请在表格中点击要移动到的目标物资',
        params: {
          // 这里是设置在哪个位置显示 top right ......
          placement: 'left',
          // 是否启动阴影指导(如果你不设置阴影class 它实际上只有一个框框)
          highlight: true
        },
        labels: {
          buttonStop: '关闭'
        }
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  watch: {
    filterText: function filterText(val) {
      this.$refs.classTree.filter(val);
    }
  },
  methods: {
    init: function init(row) {
      var that = this;
      that.formLoading = false;
      that.specialRow = row;
      that.dialogVisible = true;
      that.currentProductRow = {};
      that.classLoad();
      that.productLoad();
    },
    productLoad: function productLoad() {
      var that = this;
      that.productLoading = true;
      listProduct(that.productPageForm).then(function (res) {
        that.tableData = res.list;
        that.productPageForm.total = res.total;
        that.productLoading = false;
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 产品列表点击事件
    productRowClick: function productRowClick(row) {
      var that = this;
      that.currentProductRow = row;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.productPageForm.page = 1;
      that.productLoad();
    },
    moveConfirm: function moveConfirm() {
      var _this = this;

      var that = this;

      if (Object.prototype.hasOwnProperty.call(that.currentProductRow, 'productName')) {
        this.$confirm('确定要将规格【<strong>' + that.specialRow.specificationModel + '</strong>】移动到物资【<strong>' + that.currentProductRow.productName + '</strong>】中吗？规格移动后会修改相应库存的注册证信息', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(function () {
          var form = {
            id: that.specialRow.ID,
            mainId: that.currentProductRow.ID
          };
          updateSpecial(form).then(function (res) {
            that.$notify({
              message: '移动成功',
              type: 'success'
            });
            that.$emit('reload');
          });
          that.dialogVisible = false;
        }).catch(function () {
          _this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        that.$message.error('请在表格中点击要移动到的物资'); // this.$tours.myTour.start()
      }
    },
    treeClick: function treeClick(a, b, c) {
      var that = this;

      if (a.children.length === 0 && (a.type === 'SUB_CLASS' || a.type === 'CLASS')) {
        that.productPageForm.productLineCateId = a.id;
        that.productLoad();
        that.currentClass = a;
      } else {
        that.currentClass = {};
      }
    },
    classLoad: function classLoad() {
      var that = this;
      listClass().then(function (res) {
        that.classData = [];
        var nodeList = [];
        res.manuList.forEach(function (item1) {
          var node = {
            id: item1.ID,
            label: item1.name,
            type: 'MANUFACTURER',
            lineId: 0,
            manufacturer: '',
            children: []
          };

          if (item1.children.length > 0) {
            item1.children.forEach(function (item2) {
              var node2 = {
                id: item2.ID,
                label: item2.name,
                type: 'PRODUCT_LINE',
                lineId: item2.lineId,
                manufacturer: item1.name,
                children: []
              };

              if (item2.children.length > 0) {
                item2.children.forEach(function (item3) {
                  var node3 = {
                    id: item3.ID,
                    label: item3.name,
                    type: 'CLASS',
                    lineId: item3.lineId,
                    manufacturer: item1.name,
                    children: []
                  };

                  if (item3.children.length > 0) {
                    item3.children.forEach(function (item4) {
                      var node4 = {
                        id: item4.ID,
                        label: item4.name,
                        type: 'SUB_CLASS',
                        lineId: item4.lineId,
                        manufacturer: item1.name,
                        children: []
                      };
                      node3.children.push(node4);
                    });
                  }

                  node2.children.push(node3);
                });
              }

              node.children.push(node2);
            });
          }

          nodeList.push(node);
        });
        that.classData = nodeList;
      });
    },
    productPageChange: function productPageChange(page) {
      var that = this;
      that.productPageForm.page = page;
      that.productLoad();
    }
  }
};