var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticStyle: { padding: "20px", border: "1px solid #eee" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("div", { staticStyle: { width: "100px" } }, [
                    _vm._v("纸张尺寸：")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入宽度",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.size.width,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.size,
                                        "width",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.size.width"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("宽")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入高度",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.size.height,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.size,
                                        "height",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.size.height"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("高")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-top": "20px"
                  }
                },
                [
                  _c("div", { staticStyle: { width: "100px" } }, [
                    _vm._v("边距设置：")
                  ]),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入上边距",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.margin.top,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.margin,
                                        "top",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.margin.top"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("上")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入下边距",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.margin.bottom,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.margin,
                                        "bottom",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.margin.bottom"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("下")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入左边距",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.margin.left,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.margin,
                                        "left",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.margin.left"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("左")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    placeholder: "请输入右边距",
                                    size: "small"
                                  },
                                  on: { change: _vm.updateTagPrintSet },
                                  model: {
                                    value: _vm.tagPrintSet.margin.right,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.tagPrintSet.margin,
                                        "right",
                                        $$v
                                      )
                                    },
                                    expression: "tagPrintSet.margin.right"
                                  }
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _vm._v("右")
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%"
                                      },
                                      attrs: { slot: "suffix" },
                                      slot: "suffix"
                                    },
                                    [_vm._v("CM")]
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "el-table",
            {
              ref: "detailTableRef",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                border: "",
                size: "mini",
                data: _vm.detailList,
                "max-height": "450"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "产品名称",
                  align: "center",
                  width: "100",
                  prop: "productName",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格型号",
                  align: "center",
                  width: "100",
                  prop: "specificationModel",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "批号",
                  align: "center",
                  width: "100",
                  prop: "batchNumber",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "序列号",
                  align: "center",
                  width: "100",
                  prop: "trackingCode",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存可用数量",
                  width: "100",
                  align: "center",
                  prop: "normalQuantity"
                }
              }),
              _c("el-table-column", {
                attrs: { label: "条码", align: "center", prop: "cusCode" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            ref: "tagPrintContainer" + scope.$index,
                            attrs: { id: "tagPrintContainer" + scope.$index }
                          },
                          [
                            _c("img", {
                              ref: "barcode" + scope.$index,
                              attrs: { id: "barcode" + scope.$index }
                            })
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "打印数量", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "80px" },
                          attrs: {
                            "controls-position": "right",
                            size: "mini",
                            min: 0
                          },
                          model: {
                            value: scope.row.printNum,
                            callback: function($$v) {
                              _vm.$set(scope.row, "printNum", $$v)
                            },
                            expression: "scope.row.printNum"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.printClick(scope.row, scope.$index)
                              }
                            }
                          },
                          [_vm._v("打印")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }