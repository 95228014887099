import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RolesEditorFormComponent from '@/views/base/roles/components/editor_form/index.vue';
import { listGroup, updateGroup, deleteGroup } from '@/api/group.js';
import dayjs from 'dayjs';
export default {
  name: 'base-roles-index',
  components: {
    RolesEditorFormComponent: RolesEditorFormComponent
  },
  data: function data() {
    return {
      dayjs: dayjs,
      tableLoading: false,
      rolesPageForm: {
        name: '',
        status: '',
        pageSize: 10,
        page: 1,
        total: 0
      },
      statusList: [{
        value: '',
        label: '全部'
      }, {
        value: 'normal',
        label: '启用'
      }, {
        value: 'hidden',
        label: '禁用'
      }],
      tableData: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.rolesLoad();
    },
    searchSubmit: function searchSubmit() {
      var that = this;
      that.rolesPageForm.page = 1;
      that.rolesLoad();
    },
    rolesPageChange: function rolesPageChange(page) {
      var that = this;
      that.rolesPageForm.page = page;
      that.rolesLoad();
    },
    rolesLoad: function rolesLoad() {
      var that = this;
      that.tableLoading = true;
      listGroup(that.rolesPageForm).then(function (res) {
        that.tableData = res.list;
        that.rolesPageForm.total = res.total;
      }).finally(function () {
        that.tableLoading = false;
      });
    },
    rolesAdd: function rolesAdd() {
      var that = this;
      that.$refs.RolesEditorFormComponent.init();
    },
    rolesEdit: function rolesEdit(row) {
      var that = this;
      that.$refs.RolesEditorFormComponent.init(row);
    },
    rolesDelete: function rolesDelete(row) {
      var that = this;
      that.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteGroup(row.id).then(function (res) {
          that.$notify({
            message: '删除成功',
            type: 'success'
          });
          that.rolesLoad();
        });
      });
    },
    rolesStatusSwitch: function rolesStatusSwitch(row) {
      var _this = this;

      var that = this;
      that.$confirm('是否修改【' + row.name + '】的状态?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var form = {
          id: row.id,
          status: row.status
        };
        updateGroup(form).then(function (res) {
          that.$notify({
            message: '状态修改成功',
            type: 'success'
          });
        });
      }).catch(function () {
        row.status = row.status === 'normal' ? 'hidden' : 'normal';

        _this.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    dataAuthStatusSwitch: function dataAuthStatusSwitch(row) {
      var _this2 = this;

      var that = this;
      that.$confirm('是否' + (row.dataAuth ? '显示' : '隐藏') + '成本价？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var form = {
          id: row.id,
          dataAuth: row.dataAuth,
          status: row.status
        };
        updateGroup(form).then(function (res) {
          that.$notify({
            message: '状态修改成功',
            type: 'success'
          });
        });
      }).catch(function () {
        row.dataAuth = !row.dataAuth;

        _this2.$message({
          type: 'info',
          message: '已取消修改'
        });
      });
    },
    indexMethod: function indexMethod(index, pagination) {
      return (pagination.page - 1) * pagination.pageSize + index + 1;
    }
  }
};