var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售订货单")]),
              _c("el-breadcrumb-item", [_vm._v("寄售订货单详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 订货单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              disabled: !(
                _vm.form.state === 1 ||
                _vm.form.state === 10 ||
                _vm.form.state === 11
              ),
              loading: _vm.formLoading,
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "distributorId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              disabled:
                                _vm.orderInfo.source === 2 ? true : false
                            },
                            on: { change: _vm.distributorChange },
                            model: {
                              value: _vm.form.distributorId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "distributorId", $$v)
                              },
                              expression: "form.distributorId"
                            }
                          },
                          _vm._l(_vm.retailerList, function(item, index) {
                            return _c("el-option", {
                              key: "distributorId" + index,
                              attrs: {
                                value: item.ID,
                                label: item.name,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryName" } },
                      [
                        _vm.form.state === 1 ||
                        _vm.form.state === 10 ||
                        _vm.form.state === 11
                          ? _c(
                              "div",
                              [
                                _vm.subsidiaryList.length > 0
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          filterable: ""
                                        },
                                        model: {
                                          value: _vm.form.subsidiaryName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "subsidiaryName",
                                              $$v
                                            )
                                          },
                                          expression: "form.subsidiaryName"
                                        }
                                      },
                                      _vm._l(_vm.subsidiaryList, function(
                                        item,
                                        index
                                      ) {
                                        return _c("el-option", {
                                          key: "subsidiaryName" + index,
                                          attrs: {
                                            value: item.companyName,
                                            label: item.companyName,
                                            disabled: item.isValid !== 1
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  : _c("div", { staticClass: "item-text" }, [
                                      _vm._v(_vm._s(_vm.form.subsidiaryName))
                                    ])
                              ],
                              1
                            )
                          : _c("div", [
                              _c("div", { staticClass: "item-text" }, [
                                _vm._v(_vm._s(_vm.form.subsidiaryName))
                              ])
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售员", prop: "sellUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "buyerUid",
                            attrs: {
                              clearable: "",
                              placeholder: "请选择",
                              filterable: ""
                            },
                            model: {
                              value: _vm.form.sellUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellUid", $$v)
                              },
                              expression: "form.sellUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "sellUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售日期", prop: "sellDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            disabled: _vm.orderInfo.source === 2 ? true : false
                          },
                          model: {
                            value: _vm.form.sellDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sellDate", $$v)
                            },
                            expression: "form.sellDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "收货地址", prop: "receiveAddressId" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                key: "receiveAddressId" + _vm.timestamp,
                                attrs: {
                                  "default-first-option": "",
                                  "allow-create": "",
                                  filterable: "",
                                  clearable: "",
                                  disabled:
                                    _vm.orderInfo.source === 2 ||
                                    !_vm.form.distributorId
                                      ? true
                                      : false
                                },
                                on: { change: _vm.addressSelect },
                                model: {
                                  value: _vm.form.receiveAddressId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "receiveAddressId", $$v)
                                  },
                                  expression: "form.receiveAddressId"
                                }
                              },
                              _vm._l(_vm.addressList, function(item, index) {
                                return _c("el-option", {
                                  key: "receiveAddressId" + index,
                                  attrs: { value: item.ID, label: item.address }
                                })
                              }),
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100px",
                                  "border-left": "1px solid #eee",
                                  "text-align": "center"
                                }
                              },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      type: "primary",
                                      underline: false,
                                      disabled:
                                        _vm.form.state !== 1 ||
                                        _vm.orderInfo.source === 2 ||
                                        !_vm.form.distributorId
                                    },
                                    on: { click: _vm.addressAdd }
                                  },
                                  [_vm._v("新增地址")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货人", prop: "receiveName" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "收货人",
                              maxlength: "50",
                              disabled: true
                            },
                            model: {
                              value: _vm.form.receiveName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receiveName", $$v)
                              },
                              expression: "form.receiveName"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式", prop: "contactInfo" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "联系方式",
                              maxlength: "200",
                              disabled: true
                            },
                            model: {
                              value: _vm.form.contactInfo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "contactInfo", $$v)
                              },
                              expression: "form.contactInfo"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "备注", maxlength: "200" },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _vm.orderInfo.source === 1
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled:
                            (_vm.form.state !== 1 && _vm.form.state !== 10) ||
                            !_vm.form.distributorId
                        },
                        on: { click: _vm.selectStock }
                      },
                      [_vm._v("增加产品")]
                    )
                  : _vm._e(),
                _vm.orderInfo.source === 1
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled:
                            (_vm.form.state !== 1 && _vm.form.state !== 10) ||
                            !_vm.form.distributorId
                        },
                        on: { click: _vm.scanInput }
                      },
                      [_vm._v("扫码录入")]
                    )
                  : _vm._e(),
                _vm.orderInfo.source === 1
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled:
                            (_vm.form.state !== 1 && _vm.form.state !== 10) ||
                            !_vm.form.distributorId
                        },
                        on: { click: _vm.importExcel }
                      },
                      [_vm._v("EXCEL导入")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("销售总数："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  )
                ]),
                _c("div", { staticStyle: { "margin-left": "30px" } }, [
                  _vm._v("总金额："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalAmount()))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.detailListForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                rowClassName: _vm.rowClassName,
                data: _vm.detailListForm.data.slice(
                  (_vm.detailListForm.page - 1) * _vm.detailListForm.pageSize,
                  _vm.detailListForm.page * _vm.detailListForm.pageSize
                )
              },
              on: {
                "row-click": _vm.detailRowClick,
                pageChange: _vm.detailListFormPageChange,
                sizeChange: _vm.detailListFormSizeChange
              },
              scopedSlots: _vm._u([
                {
                  key: "sellQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      (_vm.form.state === 1 || _vm.form.state == 10) &&
                      _vm.orderInfo.source === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "sellQuantity" + index,
                              attrs: {
                                prop: "data." + index + ".sellQuantity",
                                rules: _vm.detailListFormRules.sellQuantity
                              }
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "enter-number",
                                    rawName: "v-enter-number:[{precision:0}]",
                                    arg: { precision: 0 }
                                  }
                                ],
                                ref: "sellQuantity-" + index,
                                attrs: {
                                  size: "mini",
                                  placeholder: "销售数量"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  },
                                  input: function($event) {
                                    return _vm.sellQuantityInput(row)
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "manufacturerDate-" + index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.sellQuantity,
                                  callback: function($$v) {
                                    _vm.$set(row, "sellQuantity", _vm._n($$v))
                                  },
                                  expression: "row.sellQuantity"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.sellQuantity))])
                    ]
                  }
                },
                {
                  key: "sellUnitPrice",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.state === 1 || _vm.form.state == 10
                        ? _c("el-input-number", {
                            ref: "sellUnitPrice-" + index,
                            attrs: { size: "mini", controls: false },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "sterilizationBatchNumber-" + index,
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.sellUnitPrice,
                              callback: function($$v) {
                                _vm.$set(row, "sellUnitPrice", $$v)
                              },
                              expression: "row.sellUnitPrice"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.sellUnitPrice))])
                    ]
                  }
                },
                {
                  key: "remarks",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.form.state === 1 || _vm.form.state == 10
                        ? _c("el-input", {
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            model: {
                              value: row.remarks,
                              callback: function($$v) {
                                _vm.$set(row, "remarks", $$v)
                              },
                              expression: "row.remarks"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.remarks))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    (_vm.form.state === 1 || _vm.form.state == 10) &&
                    _vm.orderInfo.source === 1
                      ? _c(
                          "el-button",
                          {
                            key: "remove-button",
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              disabled: !(
                                _vm.form.state == 1 && _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 1 || _vm.form.state == 10) &&
                    _vm.orderInfo.source === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "storage-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 1 || _vm.form.state == 10) &&
                    _vm.orderInfo.source === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "submit-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.state === 2 &&
                    _vm.form.id &&
                    _vm.orderInfo.source === 1 &&
                    _vm.sumDeliveryQuantity() === 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "refresh-button",
                            attrs: {
                              type: "danger",
                              icon: "el-icon-refresh-left"
                            },
                            on: {
                              click: function($event) {
                                return _vm.orderWithdraw()
                              }
                            }
                          },
                          [_vm._v("撤回")]
                        )
                      : _vm._e(),
                    _vm.form.state === 11 && _vm.orderInfo.source === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "examine-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toExamine()
                              }
                            }
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm.form.state === 2 && _vm.orderInfo.source === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "un-examine-button",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.unToExamine()
                              }
                            }
                          },
                          [_vm._v("撤销审核")]
                        )
                      : _vm._e(),
                    _vm.form.state === 2 &&
                    _vm.orderInfo.sumDetailDeliveryQuantityAll > 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "complete-button",
                            attrs: { type: "success" },
                            on: {
                              click: function($event) {
                                return _vm.completeOrder()
                              }
                            }
                          },
                          [_vm._v("结单")]
                        )
                      : _vm._e(),
                    _vm.form.state === 2 &&
                    (_vm.orderInfo.deliveryState === 2 ||
                      _vm.orderInfo.deliveryState === 3) &&
                    _vm.orderInfo.sumDetailDeliveryQuantityAll <
                      _vm.orderInfo.sumQuantity
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "warehouse-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toOutWarehouse()
                              }
                            }
                          },
                          [_vm._v("去发货")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 1 || _vm.form.state == 10) &&
                    _vm.form.id &&
                    _vm.orderInfo.source === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "invalid-button",
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 1 ||
                      _vm.form.state == 10 ||
                      _vm.form.state === 5) &&
                    _vm.form.id &&
                    _vm.orderInfo.source === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "delete-button",
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        key: "print-button",
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.stockAddToList, update: _vm.stockUpdate }
      }),
      _c("ImportExcelComponents", {
        ref: "ImportExcelComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ExamineComponent", {
        ref: "ExamineComponentRef",
        on: { confirm: _vm.confirmExamine }
      }),
      _c("AddressComponent", {
        ref: "AddressComponentRef",
        on: { reload: _vm.loadAddress }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }