//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listDictionary, deleteDictionary } from '@/api/dictionary.js';
import FormEditorComponents from '@/views/system/dictionary/components/form_editor/index.vue';
export default {
  name: 'system-dictionary-index',
  components: {
    FormEditorComponents: FormEditorComponents
  },
  data: function data() {
    return {
      pageForm: {
        page: 1,
        pageSize: 10,
        total: 1,
        typeId: 0
      },
      keyList: [],
      valueList: [],
      currentKeyRow: null,
      currentValueRow: null
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var that = this;
      that.loadKey();
      that.loadData();
    },
    loadKey: function loadKey() {
      var that = this;
      var form = {
        page: 1,
        pageSize: 9999,
        typeid: 0
      };
      listDictionary(form).then(function (res) {
        that.keyList = res.list;
        that.$nextTick(function () {
          that.$refs.keyListTable.setCurrentRow(that.keyList[0], true);
          that.keyRowClick(that.keyList[0]);
        });
      });
    },
    loadData: function loadData() {
      var that = this;
      listDictionary(that.pageForm).then(function (res) {
        that.valueList = res.list;
        that.pageForm.total = res.total;
        that.currentValueRow = null;
      });
    },
    keyRowClick: function keyRowClick(row) {
      var that = this;
      that.pageForm.typeId = row.ID;
      that.pageForm.page = 1;
      that.currentKeyRow = row;
      that.loadData();
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.pageForm.page = page;
      that.loadData();
    },
    dicAdd: function dicAdd() {
      var that = this;

      if (that.currentKeyRow) {
        that.$refs.FormEditorComponents.init(that.currentKeyRow, null);
      } else {
        that.$message.error('请在左侧选择类型KEY');
      }
    },
    dicEdit: function dicEdit() {
      var that = this;
      that.$refs.FormEditorComponents.init(that.currentKeyRow, that.currentValueRow);
    },
    dicDelete: function dicDelete() {
      var _this = this;

      var that = this;
      that.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteDictionary({
          id: that.currentValueRow.ID
        }).then(function () {
          _this.$message({
            type: 'success',
            message: '删除成功!'
          });

          that.loadData();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    valueRowClick: function valueRowClick(row) {
      var that = this;
      that.currentValueRow = row;
    }
  }
};