import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js'; // 销售单列表

export function listSales(params) {
  return request({
    url: '/sale/getSalesList',
    method: 'get',
    params: params
  });
}
export function listSalesExport(params) {
  return request({
    url: '/sale/getSalesList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
} // 销售单查询

export function findSales(params) {
  return request({
    url: '/sale/findSales',
    method: 'get',
    params: params
  });
} // 销售单更新

export function updateSales(data) {
  return request({
    url: '/sale/updateSales',
    method: 'put',
    data: data
  });
} // 驳回销售单

export function cancelSales(data) {
  return request({
    url: '/stock/cancelSales',
    method: 'put',
    data: data
  });
} // 销售单审核

export function checkSales(data) {
  return request({
    url: '/sale/updateSalesStatus',
    method: 'put',
    data: data
  });
} // 创建销售单详情

export function createSalesDetail(data) {
  return request({
    url: '/sale/createSalesInfo',
    method: 'post',
    data: data
  });
} // 更新销售单详情

export function updateSalesDetail(data) {
  return request({
    url: '/sale/updateSalesInfo',
    method: 'put',
    data: data
  });
} // 删除销售单详情

export function deleteSalesDetail(data) {
  return request({
    url: '/sale/deleteSalesInfo',
    method: 'delete',
    data: data
  });
} // 销售单详情列表

export function listSalesDetail(params) {
  return request({
    url: '/sale/getSalesInfoList',
    method: 'get',
    params: params
  });
}
export function createSellOrder(data) {
  return request({
    url: '/sellOrderErp/createSellOrder',
    method: 'post',
    data: data
  });
}
export function deleteSellOrder(data) {
  return request({
    url: '/sellOrderErp/deleteSellOrder',
    method: 'delete',
    data: data
  });
}
export function findSellOrder(params) {
  return request({
    url: '/sellOrderErp/findSellOrder',
    method: 'get',
    params: params
  });
}
export function listSellOrder(params) {
  return request({
    url: '/sellOrderErp/getSellOrderList',
    method: 'get',
    params: params
  });
}
export function listSellOrderExport(params) {
  return request({
    url: '/sellOrderErp/getSellOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateSellOrder(data) {
  return request({
    url: '/sellOrderErp/updateSellOrder',
    method: 'put',
    data: data
  });
}
export function checkSellOrder(data) {
  return request({
    url: '/sellOrderErp/updateSellOrderState',
    method: 'put',
    data: data
  });
}
export function createSellOrderInvoice(data) {
  return request({
    url: '/sellOrderInvoiceErp/createSellOrderInvoice',
    method: 'post',
    data: data
  });
}
export function deleteSellOrderInvoice(data) {
  return request({
    url: '/sellOrderInvoiceErp/deleteSellOrderInvoice',
    method: 'delete',
    data: data
  });
}
export function findSellOrderInvoice(params) {
  return request({
    url: '/sellOrderInvoiceErp/findSellOrderInvoice',
    method: 'get',
    params: params
  });
}
export function listSellOrderInvoice(params) {
  return request({
    url: '/sellOrderInvoiceErp/getSellOrderInvoiceList',
    method: 'get',
    params: params
  });
}
export function updateSellOrderInvoice(data) {
  return request({
    url: '/sellOrderInvoiceErp/updateSellOrderInvoice',
    method: 'put',
    data: data
  });
}
export function createSellOrderDetail(data) {
  return request({
    url: '/sellOrderDetailErp/createSellOrderDetail',
    method: 'post',
    data: data
  });
}
export function createSellOrderDetails(data) {
  return request({
    url: '/sellOrderDetailErp/createSellOrderDetails',
    method: 'post',
    data: data
  });
}
export function deleteSellOrderDetail(data) {
  return request({
    url: '/sellOrderDetailErp/deleteSellOrderDetail',
    method: 'delete',
    data: data
  });
}
export function deleteSellOrderDetailDelivery(data) {
  return request({
    url: '/sellOrderDetailDeliveryErp/deleteSellOrderDetailDelivery',
    method: 'delete',
    data: data
  });
}
export function findSellOrderDetail(params) {
  return request({
    url: '/sellOrderDetailErp/findSellOrderDetail',
    method: 'get',
    params: params
  });
}
export function listSellOrderDetail(params) {
  return request({
    url: '/sellOrderDetailErp/getSellOrderDetailList',
    method: 'get',
    params: params
  });
}
export function downloadSellOrderTemplate(params) {
  return request({
    url: '/sellOrderDetailErp/getSellOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function downloadAgentSellOrderTemplate(params) {
  return request({
    url: '/agentSellOrderDetailErp/getAgentSellOrderDetailTemplate',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function updateSellOrderDetail(data) {
  return request({
    url: '/sellOrderDetailErp/updateSellOrderDetail',
    method: 'put',
    data: data
  });
}
export function sellUpsertStockoutInfos(data) {
  return request({
    url: '/stock/sellUpsertStockoutInfos',
    method: 'post',
    data: data
  });
}
export function createSellOrderDetailDelivery(data) {
  return request({
    url: '/sellOrderDetailDeliveryErp/createSellOrderDetailDelivery',
    method: 'post',
    data: data
  });
}
export function createSellOrderDetailDeliverys(data) {
  return request({
    url: '/sellOrderDetailDeliveryErp/createSellOrderDetailDeliverys',
    method: 'post',
    data: data
  });
}
export function getSellOrderDetailDeliveryList(params) {
  return request({
    url: '/sellOrderDetailDeliveryErp/getSellOrderDetailDeliveryList',
    method: 'get',
    params: params
  });
}
export function updateSalesInfos(data) {
  return request({
    url: '/sale/updateSalesInfos',
    method: 'put',
    data: data
  });
}
/**
 * 向服务器发送请求以更新订单商品信息(仅供有台销售单使用)
 *
 * @param data 要更新的订单商品信息数据
 * @returns 返回请求结果
 */

export function updateOrderProduct(data) {
  return request({
    url: '/sale/updateOrderProduct',
    method: 'put',
    data: data
  });
}
/**
 * 审核订单中的商品（仅限有台销售单）
 *
 * @param data 商品信息对象
 * @returns 返回请求结果
 */

export function updateOrderProducts(data) {
  return request({
    url: '/sale/updateOrderProducts',
    method: 'put',
    data: data
  });
} // 寄售销售订单

export function listAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErp/getAgentSellOrderList',
    method: 'get',
    params: params
  });
}
export function listAgentSellOrderExport(params) {
  return request({
    url: '/agentSellOrderErp/getAgentSellOrderList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function createAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErp/createAgentSellOrder',
    method: 'post',
    data: data
  });
}
export function deleteAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErp/deleteAgentSellOrder',
    method: 'delete',
    data: data
  });
}
export function findAgentSellOrder(params) {
  return request({
    url: '/agentSellOrderErp/findAgentSellOrder',
    method: 'get',
    params: params
  });
}
export function updateAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErp/updateAgentSellOrder',
    method: 'put',
    data: data
  });
}
export function checkAgentSellOrder(data) {
  return request({
    url: '/agentSellOrderErp/updateAgentSellOrderState',
    method: 'put',
    data: data
  });
} // 销售订货单检测发货状态

export function updateSellOrderStateContinue(data) {
  return request({
    url: '/sellOrderErp/updateSellOrderStateContinue',
    method: 'put',
    data: data
  });
} // 寄售订货单检测发货状态

export function updateAgentSellOrderStateContinue(data) {
  return request({
    url: '/agentSellOrderErp/updateAgentSellOrderStateContinue   ',
    method: 'put',
    data: data
  });
}
export function listAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErp/getAgentSellOrderDetailList',
    method: 'get',
    params: params
  });
}
export function createAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErp/createAgentSellOrderDetail',
    method: 'post',
    data: data
  });
}
export function createAgentSellOrderDetails(data) {
  return request({
    url: '/agentSellOrderDetailErp/createAgentSellOrderDetails',
    method: 'post',
    data: data
  });
}
export function findAgentSellOrderDetail(params) {
  return request({
    url: '/agentSellOrderDetailErp/findAgentSellOrderDetail',
    method: 'get',
    params: params
  });
}
export function updateAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErp/updateAgentSellOrderDetail',
    method: 'put',
    data: data
  });
}
export function deleteAgentSellOrderDetail(data) {
  return request({
    url: '/agentSellOrderDetailErp/deleteAgentSellOrderDetail',
    method: 'delete',
    data: data
  });
} // 发票

export function createAgentSellOrderInvoice(data) {
  return request({
    url: '/agentSellOrderInvoiceErp/createAgentSellOrderInvoice',
    method: 'post',
    data: data
  });
}
export function deleteAgentSellOrderInvoice(data) {
  return request({
    url: '/agentSellOrderInvoiceErp/deleteAgentSellOrderInvoice',
    method: 'delete',
    data: data
  });
}
export function findAgentSellOrderInvoice(params) {
  return request({
    url: '/agentSellOrderInvoiceErp/findAgentSellOrderInvoice',
    method: 'get',
    params: params
  });
}
export function listAgentSellOrderInvoice(params) {
  return request({
    url: '/agentSellOrderInvoiceErp/getAgentSellOrderInvoiceList',
    method: 'get',
    params: params
  });
}
export function updateAgentSellOrderInvoice(data) {
  return request({
    url: '/agentSellOrderInvoiceErp/updateAgentSellOrderInvoice',
    method: 'put',
    data: data
  });
}
export function createAgentSellOrderDetailDeliverys(data) {
  return request({
    url: '/agentSellOrderDetailDeliveryErp/createAgentSellOrderDetailDeliverys',
    method: 'post',
    data: data
  });
}
export function createAgentSellOrderDetailDeliverysV2(data) {
  return request({
    url: '/agentSellOrderDetailDeliveryErp/createAgentSellOrderDetailDeliverysV2',
    method: 'post',
    data: data
  });
}
export function listTradeSales(params) {
  return request({
    url: '/tradeSalesErp/getTradeSalesList',
    method: 'get',
    params: params
  });
}
export function listTradeSalesExport(params) {
  return request({
    url: '/tradeSalesErp/getTradeSalesList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findTradeSales(params) {
  return request({
    url: '/tradeSalesErp/findTradeSales',
    method: 'get',
    params: params
  });
}
export function updateTradeSales(data) {
  return request({
    url: '/tradeSalesErp/updateTradeSales',
    method: 'put',
    data: data
  });
}
export function updateTradeSalesStatus(data) {
  return request({
    url: '/tradeSalesErp/updateTradeSalesStatus',
    method: 'put',
    data: data
  });
}
export function listTradeSalesDetail(params) {
  return request({
    url: '/tradeSalesDetailErp/getTradeSalesDetailList',
    method: 'get',
    params: params
  });
}
export function updateTradeSalesDetail(data) {
  return request({
    url: '/tradeSalesDetailErp/updateTradeSalesDetail',
    method: 'put',
    data: data
  });
}
export function cancelStockout(data) {
  return request({
    url: '/tradeSalesErp/cancelStockout',
    method: 'put',
    data: data
  });
}
export function getSalseReturnInfoList(params) {
  return request({
    url: '/salseReturnInfoErp/getSalseReturnInfoList',
    method: 'get',
    params: params
  });
}
export function salesUpdateVerStatus(data) {
  return request({
    url: '/sale/salesUpdateVerStatus',
    method: 'put',
    data: data
  });
}
export function salesUpdateVerStatus2(data) {
  return request({
    url: '/sale/salesUpdateVerStatus2',
    method: 'put',
    data: data
  });
}
/**
 * 反记账
 *
 * @param data 更新销售取消状态所需的数据
 * @returns 返回更新后的销售取消状态信息
 */

export function updateSalesCancel(data) {
  return request({
    url: '/sale/updateSalesCancel',
    method: 'put',
    data: data
  });
}