var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.addProductForm,
            "label-width": "100px",
            size: "small",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c("el-form-item", { attrs: { label: "价格设置" } }, [
            _vm.typeAction === 1
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "margin-bottom": "10px"
                    }
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 2, "false-label": 1 },
                        model: {
                          value: _vm.addProductForm.directEnable,
                          callback: function($$v) {
                            _vm.$set(_vm.addProductForm, "directEnable", $$v)
                          },
                          expression: "addProductForm.directEnable"
                        }
                      },
                      [_vm._v("直销价")]
                    ),
                    _c("el-input-number", {
                      staticStyle: { width: "150px", margin: "0px 10px" },
                      attrs: {
                        min: 0,
                        controls: false,
                        disabled: _vm.addProductForm.directEnable === 1
                      },
                      model: {
                        value: _vm.addProductForm.directPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.addProductForm, "directPrice", $$v)
                        },
                        expression: "addProductForm.directPrice"
                      }
                    }),
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.addProductForm.directEnable === 1
                        },
                        model: {
                          value: _vm.addProductForm.directType,
                          callback: function($$v) {
                            _vm.$set(_vm.addProductForm, "directType", $$v)
                          },
                          expression: "addProductForm.directType"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              label: 1,
                              disabled: !_vm.specsIds ? true : false
                            }
                          },
                          [_vm._v("固定金额")]
                        ),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("基础直销价折扣比")
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("采购进价折扣比")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "margin-bottom": "10px"
                }
              },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { "true-label": 2, "false-label": 1 },
                    model: {
                      value: _vm.addProductForm.salesEnable,
                      callback: function($$v) {
                        _vm.$set(_vm.addProductForm, "salesEnable", $$v)
                      },
                      expression: "addProductForm.salesEnable"
                    }
                  },
                  [_vm._v("销售价")]
                ),
                _c("el-input-number", {
                  staticStyle: { width: "150px", margin: "0px 10px" },
                  attrs: {
                    min: 0,
                    controls: false,
                    disabled: _vm.addProductForm.salesEnable === 1
                  },
                  model: {
                    value: _vm.addProductForm.salesPrice,
                    callback: function($$v) {
                      _vm.$set(_vm.addProductForm, "salesPrice", $$v)
                    },
                    expression: "addProductForm.salesPrice"
                  }
                }),
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.addProductForm.salesEnable === 1 },
                    model: {
                      value: _vm.addProductForm.salesType,
                      callback: function($$v) {
                        _vm.$set(_vm.addProductForm, "salesType", $$v)
                      },
                      expression: "addProductForm.salesType"
                    }
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: {
                          label: 1,
                          disabled: !_vm.specsIds ? true : false
                        }
                      },
                      [_vm._v("固定金额")]
                    ),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("基础销售价折扣比")
                    ]),
                    _c("el-radio", { attrs: { label: 3 } }, [
                      _vm._v("采购进价折扣比")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.typeAction === 2
              ? _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 2, "false-label": 1 },
                        model: {
                          value: _vm.addProductForm.sellingEnable,
                          callback: function($$v) {
                            _vm.$set(_vm.addProductForm, "sellingEnable", $$v)
                          },
                          expression: "addProductForm.sellingEnable"
                        }
                      },
                      [_vm._v("寄售价")]
                    ),
                    _c("el-input-number", {
                      staticStyle: { width: "150px", margin: "0px 10px" },
                      attrs: {
                        min: 0,
                        controls: false,
                        disabled: _vm.addProductForm.sellingEnable === 1
                      },
                      model: {
                        value: _vm.addProductForm.sellingPrice,
                        callback: function($$v) {
                          _vm.$set(_vm.addProductForm, "sellingPrice", $$v)
                        },
                        expression: "addProductForm.sellingPrice"
                      }
                    }),
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.addProductForm.sellingEnable === 1
                        },
                        model: {
                          value: _vm.addProductForm.sellingType,
                          callback: function($$v) {
                            _vm.$set(_vm.addProductForm, "sellingType", $$v)
                          },
                          expression: "addProductForm.sellingType"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              label: 1,
                              disabled: !_vm.specsIds ? true : false
                            }
                          },
                          [_vm._v("固定金额")]
                        ),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("基础寄售价折扣比")
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("采购进价折扣比")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  color: "#f00",
                  "font-size": "12px",
                  "line-height": "20px",
                  "margin-top": "10px"
                }
              },
              [
                _c("div", [
                  _vm._v(
                    " 折扣比：原价￥1000.00，折扣比95（即95折），折扣后的价格为 1000 * ( 95 / 100 ) = ￥950.00 "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    " 固定金额：选择到产品的某个具体规格型号时，才允许设置固定金额 "
                  )
                ])
              ]
            )
          ]),
          _c("el-form-item", { attrs: { label: "", "label-width": "0" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "flex-start" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "250px",
                      border: "1px solid #ebeef5",
                      overflow: "auto",
                      height: "430px"
                    }
                  },
                  [
                    _c("el-tree", {
                      ref: "treeRef",
                      attrs: {
                        data: _vm.treeData,
                        props: _vm.defaultProps,
                        "default-expand-all": true,
                        "show-checkbox": "",
                        "check-strictly": false,
                        "highlight-current": true,
                        "expand-on-click-node": false
                      },
                      on: { check: _vm.treeCheckChange }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { width: "280px", margin: "0 10px" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.productLoading,
                            expression: "productLoading"
                          }
                        ],
                        ref: "productTableRef",
                        staticStyle: { width: "100%" },
                        attrs: {
                          border: "",
                          size: "mini",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          },
                          "highlight-current-row": "",
                          stripe: "",
                          data: _vm.productList,
                          height: "400px"
                        },
                        on: { "selection-change": _vm.productSelectionChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "#",
                            width: "60",
                            type: "index",
                            align: "center",
                            index: function(index) {
                              return _vm.indexMethod(index, _vm.productForm)
                            }
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品名称",
                            prop: "productName",
                            "min-width": "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { align: "center" }
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            small: "",
                            background: "",
                            layout: "prev, pager, next",
                            total: _vm.productForm.total,
                            "pager-count": 5,
                            "page-size": _vm.productForm.pageSize,
                            "current-page": _vm.productForm.page
                          },
                          on: { "current-change": _vm.productPageChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.specsLoading,
                            expression: "specsLoading"
                          }
                        ],
                        ref: "specsTableRef",
                        staticStyle: { width: "100%" },
                        attrs: {
                          border: "",
                          size: "mini",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          },
                          "highlight-current-row": "",
                          stripe: "",
                          data: _vm.specsList,
                          height: "400px"
                        },
                        on: { "row-click": _vm.specsRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { width: "50", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm.specsIds === row.ID
                                    ? _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-success",
                                          staticStyle: {
                                            "font-size": "18px",
                                            color: "#409EFF"
                                          }
                                        })
                                      ])
                                    : _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-circle-check",
                                          staticStyle: {
                                            "font-size": "18px",
                                            color: "#ddd"
                                          }
                                        })
                                      ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "#",
                            width: "60",
                            type: "index",
                            align: "center",
                            index: function(index) {
                              return _vm.indexMethod(index, _vm.specsForm)
                            }
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "产品编码",
                            "min-width": "150",
                            prop: "productNumber",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格型号",
                            "min-width": "150",
                            prop: "specificationModel",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "5px" },
                        attrs: { align: "center" }
                      },
                      [
                        _c("el-pagination", {
                          attrs: {
                            small: "",
                            background: "",
                            layout: "sizes, prev, pager, next",
                            total: _vm.specsForm.total,
                            "pager-count": 5,
                            "page-size": _vm.specsForm.pageSize,
                            "current-page": _vm.specsForm.page
                          },
                          on: {
                            "current-change": _vm.specsPageChange,
                            "size-change": _vm.specsSizeChange
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("添 加")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }