import { request } from '@/api/_service.js';
export function listPaymentReturn(params) {
  return request({
    url: '/paymentReturnErp/getPaymentReturnList',
    method: 'get',
    params: params
  });
}
export function listPaymentReturnExport(params) {
  return request({
    url: '/paymentReturnErp/getPaymentReturnList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findPaymentReturn(params) {
  return request({
    url: '/paymentReturnErp/findPaymentReturn',
    method: 'get',
    params: params
  });
}
export function createPaymentReturn(data) {
  return request({
    url: '/paymentReturnErp/createPaymentReturn',
    method: 'post',
    data: data
  });
}
export function updatePaymentReturn(data) {
  return request({
    url: '/paymentReturnErp/updatePaymentReturn',
    method: 'put',
    data: data
  });
}
export function updatePaymentReturnStatus(data) {
  return request({
    url: '/paymentReturnErp/updatePaymentReturnStatus',
    method: 'put',
    data: data
  });
}
export function deletePaymentReturn(data) {
  return request({
    url: '/paymentReturnErp/deletePaymentReturn',
    method: 'delete',
    data: data
  });
}
export function deletePaymentReturnByIds(data) {
  return request({
    url: '/paymentReturnErp/deletePaymentReturnByIds',
    method: 'delete',
    data: data
  });
}
export function listPaymentReturnListGroup(params) {
  return request({
    url: '/paymentReturnErp/getPaymentReturnListGroup',
    method: 'get',
    params: params
  });
}