var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px", "text-align": "right" } },
        [
          _c(
            "el-checkbox",
            {
              attrs: { "true-label": 1, "false-label": 0 },
              on: { change: _vm.hideChange },
              model: {
                value: _vm.form.hid0,
                callback: function($$v) {
                  _vm.$set(_vm.form, "hid0", $$v)
                },
                expression: "form.hid0"
              }
            },
            [_vm._v("隐藏预付款余额为0的订单")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList,
            size: "small",
            "max-height": "500",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "采购单号",
              prop: "orderNumber",
              width: "250",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.viewAdvOrder(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.orderNumber))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "采购日期",
              prop: "purchaseDate",
              width: "100",
              align: "center",
              formatter: function(row) {
                return _vm.dateFormat(row.purchaseDate)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单状态",
              prop: "state",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.stateFormatter(scope.row))
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "订单金额",
              prop: "purchaseAmount",
              "min-width": "100",
              align: "center",
              formatter: function(row) {
                return _vm.amountFormatter(row.purchaseAmount)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "收货金额",
              prop: "receiveSum",
              "min-width": "100",
              align: "center",
              formatter: function(row) {
                return _vm.amountFormatter(row.receiveSum)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "付款金额",
              prop: "paidAmountSum",
              "min-width": "100",
              align: "center",
              formatter: function(row) {
                return _vm.amountFormatter(row.paidAmountSum)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "预付款金额",
              prop: "advaAmount",
              "min-width": "100",
              align: "center",
              formatter: function(row) {
                return _vm.amountFormatter(row.advaAmount)
              }
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "采购人",
              prop: "buyer",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              prop: "operations",
              width: "150",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "control-column" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              disabled: scope.row.sm >= scope.row.purchaseAmount
                            },
                            on: {
                              click: function($event) {
                                return _vm.toApply(scope.row)
                              }
                            }
                          },
                          [_vm._v("付款申请")]
                        ),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              disabled: !_vm.checkCanPay(scope.row)
                            },
                            on: {
                              click: function($event) {
                                return _vm.toPayment(scope.row)
                              }
                            }
                          },
                          [_vm._v("付款")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "page-sizes": [10, 20, 50, 100],
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.sizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      ),
      _c("ApplyComponent", {
        ref: "ApplyComponentRef",
        on: { reload: _vm.loadData }
      }),
      _c("PaymentComponent", {
        ref: "PaymentComponentRef",
        on: { reload: _vm.loadData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }