var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.canSelect,
                expression: "canSelect"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "min-width": "300px",
                  "max-width": "300px",
                  display: "flex",
                  "justify-content": "space-between",
                  border: "1px solid #F2F3F5"
                }
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.consumSetLoading,
                        expression: "consumSetLoading"
                      }
                    ],
                    ref: "consumCateTableRef",
                    attrs: {
                      data: _vm.consumSetList,
                      height: "500",
                      "max-height": "500",
                      "highlight-current-row": ""
                    },
                    on: { "row-click": _vm.cateRowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "组套类别",
                        prop: "name",
                        "show-overflow-tooltip": ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.consumSubSetLoading,
                        expression: "consumSubSetLoading"
                      }
                    ],
                    ref: "consumSubCateTableRef",
                    staticStyle: { "border-left": "1px solid #F2F3F5" },
                    attrs: {
                      data: _vm.consumSubSetList,
                      height: "500",
                      "max-height": "500",
                      "highlight-current-row": ""
                    },
                    on: { "row-click": _vm.subCateRowClick }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "组套名称",
                        prop: "name",
                        "show-overflow-tooltip": ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { "min-width": "850px", "margin-left": "10px" } },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.canSelect,
                    expression: "!canSelect"
                  }
                ],
                attrs: { inline: true, size: "mini", "label-suffix": ":" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "策略" } },
                          [
                            _c(
                              "el-select",
                              {
                                on: { change: _vm.loadConsum },
                                model: {
                                  value: _vm.consumSetInfoForm.policy,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.consumSetInfoForm,
                                      "policy",
                                      $$v
                                    )
                                  },
                                  expression: "consumSetInfoForm.policy"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    label: "按效期时间临近先出库",
                                    value: 0
                                  }
                                }),
                                _c("el-option", {
                                  attrs: {
                                    label: "按效期时间临远先出库",
                                    value: 1
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.consumSetInfoForm.productName
                                  ? true
                                  : false,
                                expression:
                                  "consumSetInfoForm.productName?true:false"
                              }
                            ],
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              type: "info",
                              size: "small",
                              closable: true
                            },
                            on: { close: _vm.productNameTagClose }
                          },
                          [
                            _vm._v(
                              "物资名称：" +
                                _vm._s(
                                  _vm.consumSetInfoForm.productName === "asc"
                                    ? "升序"
                                    : "倒序"
                                )
                            )
                          ]
                        ),
                        _c(
                          "el-tag",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.consumSetInfoForm.specificationModel
                                  ? true
                                  : false,
                                expression:
                                  "consumSetInfoForm.specificationModel?true:false"
                              }
                            ],
                            attrs: {
                              type: "info",
                              size: "small",
                              closable: true
                            },
                            on: { close: _vm.specificationModelTagClose }
                          },
                          [
                            _vm._v(
                              "规格型号：" +
                                _vm._s(
                                  _vm.consumSetInfoForm.specificationModel ===
                                    "asc"
                                    ? "升序"
                                    : "倒序"
                                )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.canSelect,
                    expression: "canSelect"
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c(
                      "el-tag",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.consumSetInfoForm.productName
                              ? true
                              : false,
                            expression:
                              "consumSetInfoForm.productName?true:false"
                          }
                        ],
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "info", size: "small", closable: true },
                        on: { close: _vm.productNameTagClose }
                      },
                      [
                        _vm._v(
                          "物资名称：" +
                            _vm._s(
                              _vm.consumSetInfoForm.productName === "asc"
                                ? "升序"
                                : "倒序"
                            )
                        )
                      ]
                    ),
                    _c(
                      "el-tag",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.consumSetInfoForm.specificationModel
                              ? true
                              : false,
                            expression:
                              "consumSetInfoForm.specificationModel?true:false"
                          }
                        ],
                        attrs: { type: "info", size: "small", closable: true },
                        on: { close: _vm.specificationModelTagClose }
                      },
                      [
                        _vm._v(
                          "规格型号：" +
                            _vm._s(
                              _vm.consumSetInfoForm.specificationModel === "asc"
                                ? "升序"
                                : "倒序"
                            )
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.consumLoading,
                        expression: "consumLoading"
                      }
                    ],
                    attrs: {
                      size: "small",
                      data: _vm.consumList,
                      border: "",
                      height: "430",
                      "max-height": "430"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        label: "#",
                        width: "80",
                        align: "center",
                        index: function(index) {
                          return _vm.indexMethod(index, _vm.consumSetInfoForm)
                        }
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.barCode",
                        "min-width": "100",
                        label: "主码",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.productNumber",
                        "min-width": "100",
                        label: "产品编号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.brandName",
                        "min-width": "100",
                        label: "品牌",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "main.productName",
                          "min-width": "150",
                          label: "物资名称",
                          align: "center",
                          "class-name": _vm.consumSetInfoForm.productName
                            ? _vm.consumSetInfoForm.productName + "ending"
                            : "",
                          "show-overflow-tooltip": ""
                        }
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                cursor: "pointer",
                                "align-items": "center"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sortChange("productName")
                                }
                              }
                            },
                            [
                              _vm._v(" 物资名称 "),
                              _c("span", { staticClass: "caret-wrapper" }, [
                                _c("i", {
                                  staticClass: "sort-caret ascending"
                                }),
                                _c("i", {
                                  staticClass: "sort-caret descending"
                                })
                              ])
                            ]
                          )
                        ])
                      ],
                      2
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "specs.specificationModel",
                          "min-width": "100",
                          label: "规格型号",
                          align: "center",
                          "class-name": _vm.consumSetInfoForm.specificationModel
                            ? _vm.consumSetInfoForm.specificationModel +
                              "ending"
                            : "",
                          "show-overflow-tooltip": ""
                        }
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                cursor: "pointer",
                                "align-items": "center"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sortChange("specificationModel")
                                }
                              }
                            },
                            [
                              _vm._v(" 规格型号 "),
                              _c("span", { staticClass: "caret-wrapper" }, [
                                _c("i", {
                                  staticClass: "sort-caret ascending"
                                }),
                                _c("i", {
                                  staticClass: "sort-caret descending"
                                })
                              ])
                            ]
                          )
                        ])
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        prop: "total",
                        width: "100",
                        label: "套内数量",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.consumLoading,
                    expression: "consumLoading"
                  },
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.canSelect,
                    expression: "!canSelect"
                  }
                ],
                ref: "consumListTable",
                attrs: {
                  data: _vm.consumList,
                  size: "small",
                  border: "",
                  height: "400",
                  "max-height": "400",
                  "default-expand-all": true,
                  "row-class-name": _vm.rowClassName,
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "expand", width: "40" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "small",
                                data: props.row.stockList
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "batchNumber",
                                  "min-width": "150",
                                  label: "批号",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "trackingCode",
                                  "min-width": "150",
                                  label: "序列号",
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "validityDate",
                                  label: "效期",
                                  width: "100",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "normalQuantity",
                                  width: "100",
                                  label: "可用数量",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "deliveryQuantity",
                                  label: "出库数量",
                                  width: "150",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var row = ref.row
                                        return [
                                          _c("el-input-number", {
                                            attrs: {
                                              size: "mini",
                                              min: 0,
                                              max: row.normalQuantity
                                            },
                                            model: {
                                              value: row.deliveryQuantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "deliveryQuantity",
                                                  $$v
                                                )
                                              },
                                              expression: "row.deliveryQuantity"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "100",
                                  label: "操作",
                                  prop: "operations",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteBatch(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除批次")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "#",
                    width: "80",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.consumSetInfoForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.productNumber",
                    "min-width": "100",
                    label: "产品编号",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      prop: "main.productName",
                      "min-width": "150",
                      label: "物资名称",
                      align: "center",
                      "class-name": _vm.consumSetInfoForm.productName
                        ? _vm.consumSetInfoForm.productName + "ending"
                        : "",
                      "show-overflow-tooltip": ""
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            cursor: "pointer",
                            "align-items": "center"
                          },
                          on: {
                            click: function($event) {
                              return _vm.sortChange("productName")
                            }
                          }
                        },
                        [
                          _vm._v(" 物资名称 "),
                          _c("span", { staticClass: "caret-wrapper" }, [
                            _c("i", { staticClass: "sort-caret ascending" }),
                            _c("i", { staticClass: "sort-caret descending" })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      prop: "specs.specificationModel",
                      "min-width": "100",
                      label: "规格型号",
                      align: "center",
                      "class-name": _vm.consumSetInfoForm.specificationModel
                        ? _vm.consumSetInfoForm.specificationModel + "ending"
                        : "",
                      "show-overflow-tooltip": ""
                    }
                  },
                  [
                    _c("template", { slot: "header" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            cursor: "pointer",
                            "align-items": "center"
                          },
                          on: {
                            click: function($event) {
                              return _vm.sortChange("specificationModel")
                            }
                          }
                        },
                        [
                          _vm._v(" 规格型号 "),
                          _c("span", { staticClass: "caret-wrapper" }, [
                            _c("i", { staticClass: "sort-caret ascending" }),
                            _c("i", { staticClass: "sort-caret descending" })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: {
                    prop: "total",
                    width: "100",
                    label: "套内数量",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "deliveryQuantity",
                    width: "160",
                    label: "出库数量",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.isStockEnough
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.deliveryQuantity))
                              ])
                            : _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v("库存可用数不足")
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "operations",
                    width: "100",
                    label: "操作",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.addBatch(scope.row)
                                }
                              }
                            },
                            [_vm._v("添加批次")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, sizes, prev, pager, next, jumper",
                    align: "center",
                    total: _vm.consumSetInfoForm.total,
                    "current-page": _vm.consumSetInfoForm.page,
                    "page-size": _vm.consumSetInfoForm.pageSize,
                    "page-sizes": [5, 10, 20, 50, 100]
                  },
                  on: {
                    "current-change": _vm.consumSetInfoFormPageChange,
                    "size-change": _vm.consumSetInfoFormSizeChange
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.canSelect,
              expression: "!canSelect"
            }
          ],
          attrs: { slot: "footer", align: "center" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.selectConfirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canSelect,
              expression: "canSelect"
            }
          ],
          attrs: { slot: "footer", align: "center" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.canSelect = !_vm.canSelect
                }
              }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      ),
      _c("BatchSelectComponent", {
        ref: "batchSelectComponentRef",
        on: { add: _vm.addBatchConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }