var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "720px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0",
            "text-align": "right",
            position: "absolute",
            right: "20px",
            top: "30px"
          }
        },
        [
          _c(
            "el-popover",
            {
              ref: "optionsPopover",
              attrs: { placement: "bottom", trigger: "click" }
            },
            [
              _c("TableHeadComponent", {
                ref: "TableHeadComponentRef",
                attrs: {
                  "cache-name": _vm.cacheName,
                  "remote-columns-list": _vm.remoteColumnsList
                },
                on: { "save-table-set": _vm.saveTableSet }
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "reference", type: "primary", size: "mini" },
                  slot: "reference"
                },
                [_vm._v("字段自定义")]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.printClick }
            },
            [_vm._v("点击打印")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticStyle: { position: "relative" } }, [
                    _c("div", [
                      _c("h3", [_vm._v(_vm._s(_vm.companyDetail.name))]),
                      _c("h3", [
                        _vm._v(
                          _vm._s(
                            _vm.inWarehouseTypeName(
                              _vm.orderDetail.inWarehouseType
                            )
                          )
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "10px",
                          right: "0"
                        }
                      },
                      [_c("img", { attrs: { id: "barcode" } })]
                    )
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "form-table" }, [
                    _c("table", [
                      _c("tr", [
                        _c("th", [_vm._v("供货单位：")]),
                        _c("td", [_vm._v(_vm._s(_vm.supplierName))]),
                        _c("th", [_vm._v("入库仓库：")]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.orderDetail.warehouseName))
                        ])
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("备注：")]),
                        _c("td", { attrs: { colspan: "3" } }, [
                          _vm._v(_vm._s(_vm.orderDetail.remark))
                        ])
                      ])
                    ])
                  ]),
                  _vm.detailList.length > 0
                    ? _c("div", { staticClass: "detail-table" }, [
                        _c("table", [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    item.checked === true
                                      ? _c("th", { key: "prop-" + index }, [
                                          _vm._v(_vm._s(item.label))
                                        ])
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]),
                          _c(
                            "tbody",
                            [
                              _vm._l(_vm.detailList, function(item, index) {
                                return _c(
                                  "tr",
                                  { key: "data-col-" + index },
                                  [
                                    _vm._l(_vm.remoteColumnsList, function(
                                      item2,
                                      index2
                                    ) {
                                      return [
                                        item2.checked === true
                                          ? _c(
                                              "td",
                                              { key: "data-item-" + index2 },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataFormatter(
                                                      item[item2.prop],
                                                      item2.type
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              _vm._l(_vm.getSummary(), function(item, index) {
                                return _c(
                                  "tr",
                                  { key: index },
                                  [
                                    _vm._l(_vm.remoteColumnsList, function(
                                      item2,
                                      index2
                                    ) {
                                      return [
                                        item2.checked === true
                                          ? _c(
                                              "td",
                                              {
                                                key: "summary-" + index2,
                                                staticClass: "summary"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataFormatter(
                                                      item[item2.prop],
                                                      item2.type
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ])
                    : _c("div", { attrs: { align: "center" } }, [
                        _vm._v(" 暂无数据 ")
                      ]),
                  _c("div", { staticClass: "form-table" }, [
                    _c("table", [
                      _c("tr", [
                        _c("th", [_vm._v("入库人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.orderDetail.inWarehouseName) +
                              " / " +
                              _vm._s(_vm.orderDetail.inWarehouseTime)
                          )
                        ]),
                        _c("th", [_vm._v("审核人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.orderDetail.examineName) +
                              " / " +
                              _vm._s(_vm.orderDetail.examineTime)
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("记账人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.orderDetail.accountName) +
                              " / " +
                              _vm._s(_vm.orderDetail.accountTime)
                          )
                        ]),
                        _c("th", [_vm._v("打印人/时间：")]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.user.nickName) +
                              " / " +
                              _vm._s(_vm.getCurrentTime())
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }