var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("产品信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("注册证管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-upload2",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.importFromExcel()
                    }
                  }
                },
                [_vm._v("批量导入")]
              ),
              _vm.isPromoter
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary"
                      },
                      on: { click: _vm.registrationAsync }
                    },
                    [_vm._v("自动获取产品注册证")]
                  )
                : _vm._e(),
              _vm.isPromoter
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary"
                      },
                      on: { click: _vm.registrationAdd }
                    },
                    [_vm._v("新增产品注册证")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { height: "100%" } },
          [
            _vm.asideShow
              ? _c(
                  "el-aside",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { width: "200px" }
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "输入关键字进行过滤",
                        size: "small",
                        clearable: ""
                      },
                      model: {
                        value: _vm.filterText,
                        callback: function($$v) {
                          _vm.filterText = $$v
                        },
                        expression: "filterText"
                      }
                    }),
                    _c("el-tree", {
                      ref: "classTree",
                      staticClass: "filter-tree",
                      attrs: {
                        data: _vm.manufacturerList,
                        props: _vm.defaultProps,
                        "default-expand-all": "",
                        "highlight-current": true,
                        "filter-node-method": _vm.filterNode,
                        "expand-on-click-node": false
                      },
                      on: { "node-click": _vm.treeClick }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-aside",
              {
                staticStyle: {
                  padding: "0",
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                  background: "#eee"
                },
                attrs: { width: "20px" }
              },
              [
                _c("i", {
                  class: _vm.asideShow
                    ? "fa fa-angle-double-left"
                    : "fa fa-angle-double-right",
                  staticStyle: {
                    "font-size": "20px",
                    width: "20px",
                    height: "100%",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  },
                  on: { click: _vm.asideChange }
                })
              ]
            ),
            _c(
              "el-main",
              [
                _c(
                  "el-card",
                  { staticClass: "form-card", attrs: { shadow: "never" } },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.registrationPageForm,
                          size: "small",
                          "label-suffix": ":"
                        },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择状态" },
                                model: {
                                  value: _vm.registrationPageForm.state,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.registrationPageForm,
                                      "state",
                                      $$v
                                    )
                                  },
                                  expression: "registrationPageForm.state"
                                }
                              },
                              _vm._l(_vm.stateList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "离过期" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { placeholder: "", clearable: "" },
                                model: {
                                  value: _vm.registrationPageForm.expirationDay,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.registrationPageForm,
                                      "expirationDay",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "registrationPageForm.expirationDay"
                                }
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("天")
                                ])
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "注册证号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入注册证号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.registrationPageForm.regNoCn,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.registrationPageForm,
                                    "regNoCn",
                                    $$v
                                  )
                                },
                                expression: "registrationPageForm.regNoCn"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.searchFormExpand
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "产品名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入产品名称",
                                    clearable: ""
                                  },
                                  model: {
                                    value:
                                      _vm.registrationPageForm.regProductName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.registrationPageForm,
                                        "regProductName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "registrationPageForm.regProductName"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.searchSubmit }
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function($event) {
                                    _vm.searchFormExpand = !_vm.searchFormExpand
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                                ),
                                _c("i", {
                                  class:
                                    "el-icon-arrow-" +
                                    (_vm.searchFormExpand ? "up" : "down") +
                                    " el-icon--right"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.registrationLoading,
                        expression: "registrationLoading"
                      }
                    ],
                    ref: "productTable",
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      "highlight-current-row": "",
                      stripeDELETE: "",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      },
                      size: "mini"
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        index: function(index) {
                          return _vm.indexMethod(
                            index,
                            _vm.registrationPageForm
                          )
                        },
                        label: "序号",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "regNoCn",
                        label: "注册证号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "regProductName",
                        label: "产品名称",
                        width: "200",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "regName",
                        label: "注册人姓名",
                        width: "150",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "regDate",
                        label: "批准日期",
                        width: "150",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "expirationDate",
                        label: "有效期至",
                        width: "150",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.longTermValidity
                                      ? "长期"
                                      : row.expirationDate
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "state",
                        label: "状态",
                        width: "100",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  disabled: !scope.row.can_edit,
                                  "active-color": "#409EFF",
                                  "inactive-color": "#ddd",
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.statusChange(scope.row)
                                  }
                                },
                                model: {
                                  value: scope.row.state,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "state", $$v)
                                  },
                                  expression: "scope.row.state"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "", width: "100", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.statusFormatter(row))
                                }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "150",
                        align: "center",
                        fixed: "right"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.state === 3
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(" 不可操作 ")
                                  ])
                                : _c("div", [
                                    _vm.isPromoter
                                      ? _c(
                                          "div",
                                          { staticClass: "control-column" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  size: "mini",
                                                  disabled: !scope.row.can_edit
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.registrationEdit(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  size: "mini",
                                                  disabled: !scope.row.can_edit
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.registrationInvalid(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "prev, pager, next, total",
                        align: "center",
                        total: _vm.registrationPageForm.total,
                        "current-page": _vm.registrationPageForm.page,
                        "page-size": _vm.registrationPageForm.pageSize
                      },
                      on: { "current-change": _vm.registrationPageChange }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("RegistrationEditorFormComponents", {
        ref: "RegistrationEditorFormComponents",
        on: { reload: _vm.loadData }
      }),
      _c("RegistrationAsyncComponents", {
        ref: "RegistrationAsyncComponents",
        on: { reload: _vm.loadData }
      }),
      _c("ImportComponent", {
        ref: "ImportComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }