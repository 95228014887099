//
//
//
//
//
//
//
//
//
export default {
  name: 'd2-container-frame',
  props: {
    src: {
      type: String,
      required: false,
      default: 'https://d2.pub/zh/doc/d2-admin'
    }
  }
};