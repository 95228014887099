import { request } from '@/api/_service.js';
export function listService(params) {
  return request({
    url: '/erpAfterSalesErp/getErpAfterSalesList',
    method: 'get',
    params: params
  });
}
export function listServiceExport(params) {
  return request({
    url: '/erpAfterSalesErp/getErpAfterSalesList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findService(params) {
  return request({
    url: '/erpAfterSalesErp/findErpAfterSales',
    method: 'get',
    params: params
  });
}
export function createService(data) {
  return request({
    url: '/erpAfterSalesErp/createErpAfterSales',
    method: 'post',
    data: data
  });
}
export function updateService(data) {
  return request({
    url: '/erpAfterSalesErp/updateErpAfterSales',
    method: 'put',
    data: data
  });
}
export function deleteService(data) {
  return request({
    url: '/erpAfterSalesErp/deleteErpAfterSales',
    method: 'delete',
    data: data
  });
}
export function deleteServiceByIds(data) {
  return request({
    url: '/erpAfterSalesErp/deleteErpAfterSalesByIds',
    method: 'delete',
    data: data
  });
}
export function listServiceDetail(params) {
  return request({
    url: '/erpAfterSalesInfoErp/getErpAfterSalesInfoList',
    method: 'get',
    params: params
  });
}
export function createErpAfterSalesInfo(data) {
  return request({
    url: '/erpAfterSalesInfoErp/createErpAfterSalesInfo',
    method: 'post',
    data: data
  });
}
export function createErpAfterSalesInfos(data) {
  return request({
    url: '/erpAfterSalesInfoErp/createErpAfterSalesInfos',
    method: 'post',
    data: data
  });
}
export function updateErpServiceInfo(data) {
  return request({
    url: '/erpAfterSalesInfoErp/updateErpAfterSalesInfo',
    method: 'put',
    data: data
  });
}
export function deleteErpAfterSalesInfo(data) {
  return request({
    url: '/erpAfterSalesInfoErp/deleteErpAfterSalesInfo',
    method: 'delete',
    data: data
  });
}
export function deleteErpAfterSalesInfoByIds(data) {
  return request({
    url: '/erpAfterSalesInfoErp/deleteErpAfterSalesInfoByIds',
    method: 'delete',
    data: data
  });
}