//
//
//
//
import FormComponent from './form.vue';
export default {
  name: 'purchase-create',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    FormComponent: FormComponent
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};