//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { createLine, updateLine } from '@/api/line.js';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      itemForm: {},
      itemFormRules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }]
      },
      packageList: [{
        ID: 0,
        name: '商品包列表',
        children: [{
          ID: 1,
          name: '商品包1111'
        }, {
          ID: 2,
          name: '商品包2222'
        }, {
          ID: 3,
          name: '商品包3333'
        }]
      }],
      packageProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;

      if (row) {
        that.dialogTitle = '复制盒子';
        that.itemForm = {
          id: row.ID,
          pid: 0,
          pinyinCode: '',
          warehouseId: null,
          name: ''
        };
      } else {
        that.dialogTitle = '新增耗材分类';
        that.itemForm = {
          pid: 0,
          pinyinCode: '',
          warehouseId: null,
          name: ''
        };
        that.$nextTick(function () {
          that.$refs.itemForm.resetFields();
        });
      }
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    formSubmit: function formSubmit() {
      var that = this;
      that.$refs.itemForm.validate(function (valid) {
        if (valid) {
          if (that.itemForm.id) {
            updateLine(that.itemForm).then(function (res) {
              that.$notify({
                message: '更新成功',
                type: 'success'
              });
              that.$emit('reload');
              that.dialogClose();
            });
          } else {
            createLine(that.itemForm).then(function (res) {
              that.$notify({
                message: '新增成功',
                type: 'success'
              });
              that.$emit('reload');
              that.dialogClose();
            });
          }
        }
      });
    }
  }
};