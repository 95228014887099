var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: { color: "#f00", margin: "10px 0" },
          attrs: { align: "center" }
        },
        [
          _vm._v(
            " 总共" +
              _vm._s(_vm.totalRows) +
              "条记录，当前选中" +
              _vm._s(_vm.rows.length) +
              "条 "
          )
        ]
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          attrs: { model: _vm.form, "label-width": "60px", size: "small" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { maxlength: "500" },
                model: {
                  value: _vm.form.remarks,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remarks", $$v)
                  },
                  expression: "form.remarks"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0", align: "center" } },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }