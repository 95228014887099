var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm._l(_vm.invoiceList, function(row, index) {
        return _c(
          "div",
          { key: "invoice-item-" + index },
          [
            _c(
              "el-form",
              {
                staticClass: "form-table",
                attrs: {
                  size: "small",
                  "label-width": "120px",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "发票号" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.invoiceNumber))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "发票代码" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.invoiceCode))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "发票类型" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.invoiceTypeFormatter(row.invoiceType))
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "开票日期" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.invoicingDate))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "金额（不含税）" } },
                          [
                            _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(row.money.toFixed(2)))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "税率" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.taxRate) + "%")
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "税额" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.taxAmount.toFixed(2)))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "含税金额" } }, [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(row.amountIncludingTax.toFixed(2)))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "校验码(后六位)" } },
                          [
                            _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(row.validationCode))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { staticClass: "auto-height", attrs: { span: 24 } },
                      [
                        _c("el-form-item", { attrs: { label: "附件" } }, [
                          _c(
                            "div",
                            { staticClass: "item-text" },
                            _vm._l(row.attachment, function(item, index2) {
                              return _c(
                                "div",
                                { key: "attach-" + index2 },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.attachPreview(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.url))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }