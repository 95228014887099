import { request } from '@/api/_service.js'; // 更新设置

export function updateSettings(data) {
  return request({
    url: '/erpSystemErp/updateErpSystem',
    method: 'put',
    data: data
  });
} // 读取设置

export function findSettings(params) {
  return request({
    url: '/erpSystemErp/findErpSystem',
    method: 'get',
    params: params
  });
} // 保存设置

export function saveConfig(data) {
  return request({
    url: '/configErp/setConfig',
    method: 'post',
    data: data
  });
} // 读取设置

export function findConfig(params) {
  return request({
    url: '/configErp/findConfig',
    method: 'get',
    params: params
  });
}
export function getAppPayEpDay(params) {
  return request({
    url: '/appPayErp/getAppPayEpDay',
    method: 'get',
    params: params
  });
}
export function setAppPayEpDay(data) {
  return request({
    url: '/appPayErp/setAppPayEpDay',
    method: 'put',
    data: data
  });
}
export function getErpSalesSetList(params) {
  return request({
    url: '/erpSalesSetErp/getErpSalesSetList',
    method: 'get',
    params: params
  });
}
export function updateErpSalesSet(data) {
  return request({
    url: '/erpSalesSetErp/updateErpSalesSet',
    method: 'put',
    data: data
  });
}