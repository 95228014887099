import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getFaErpInWarehouseInfoTemplate } from '@/api/stock.js';
import util from '@/libs/util.js';
import axios from 'axios';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '模板导入',
      dialogVisible: false,
      uploadFileType: ['.xls', '.xlsx'],
      uploadURL: '',
      uploadToken: '',
      loading: false,
      errorMsg: null,
      successMsg: null,
      warehouseId: null,
      supplierId: null,
      errDialogVisible: false,
      errProductList: [],
      errMsg: null
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.errorMsg = null;
      that.successMsg = null;
      that.uploadURL = process.env.VUE_APP_API + '/erpInWarehouse/uploadExcel';
      that.uploadToken = {
        Authorization: util.cookies.get('token'),
        Os: 'web'
      };
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    errDialogClose: function errDialogClose() {
      var that = this;
      that.errDialogVisible = false;
    },
    myUpload: function myUpload(content) {
      var _this = this;

      var that = this;
      that.errorMsg = null;
      that.successMsg = null;
      var form = new FormData();
      form.append('file', content.file);
      var config = {
        headers: content.headers,
        timeout: 1000 * 180
      };

      if (content.file.size / 1024 / 1024 > 30) {
        that.errorMsg = '不允许上传大于30MB的文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      var FileExt = content.file.name.replace(/.+\./, '');

      if (that.uploadFileType.indexOf('.' + FileExt.toLowerCase()) === -1) {
        that.errorMsg = '不允许上传此类型文件';
        that.$message.error(that.errorMsg);
        return false;
      }

      this.loading = true;
      axios.post(content.action, form, config).then(function (res) {
        if (res.data.code !== 0) {
          that.errorMsg = res.data.msg;
          that.$message.error(that.errorMsg);
        } else {
          if (res.data.data.total > 0) {
            that.$notify.success('成功导入' + res.data.data.total + '条');
            that.successMsg = '成功导入' + res.data.data.total + '条';
            that.$emit('add', that.detailListFormat(res.data.data.list));
          } else {
            that.errorMsg = '导入失败，未找到相关数据';
            that.$message.error(that.errorMsg);
          }
        }
      }).catch(function (error) {
        if (error.response) {
          that.errorMsg = '文件上传失败,' + error.response.data;
        } else if (error.request) {
          that.errorMsg = '文件上传失败，服务器端无响应';
        } else {
          that.errorMsg = '文件上传失败，请求封装失败';
        }

        that.$message.error(that.errorMsg);
      }).finally(function () {
        _this.loading = false;
      });
    },
    templateDownload: function templateDownload() {
      getFaErpInWarehouseInfoTemplate({
        fileName: 'goodsStockImport.xlsx'
      }).then(function (res) {
        var blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        }); // type这里表示xlsx类型

        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接

        downloadElement.href = href;
        downloadElement.download = '模板导入.xlsx'; // 下载后文件名

        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载

        document.body.removeChild(downloadElement); // 下载完成移除元素

        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    detailListFormat: function detailListFormat(rows) {
      var resultRows = [];
      rows.forEach(function (row, index) {
        row.excelImport.map(function (row2) {
          resultRows.push({
            id: row.ID,
            rowIndex: index,
            productNumber: row2.productNumber,
            brandName: row2.brandName,
            barCode: row2.barCode,
            batchNumber: row2.batch_number || null,
            trackingCode: row2.trackingCode || null,
            manufacturerDate: row2.manufacturerDate,
            validityDate: row2.validityDate,
            registrationNumber: row2.registrationNumber || row.main.registrationNumber,
            afterTaxPrice: Number(row2.afterTaxPrice.toFixed(2)),
            taxRate: row2.taxRate,
            sterilizationBatchNumber: row2.sterilizationBatchNumber || row.stockMain.sterilization_batch_number,
            sterilizationDate: row2.sterilizationDate || row.stockMain.sterilization_date,
            productName: row.main.productName,
            specificationModel: row.specificationModel,
            stockQuantity: row.stockMain.stock_quantity,
            normalQuantity: row.stockMain.normalQuantity,
            consumedQuantity: row.stockMain.consumedQuantity,
            receiptQuantity: row.stockMain.receiptQuantity,
            issueLocking: row.stockMain.issueLocking,
            salesPendingApproval: row.stockMain.salesPendingApproval,
            productPackageLocking: row.stockMain.productPackageLocking,
            unit: row.unit,
            packNum: row.packNum,
            packUnit: row.packUnit,
            unitPrice: row.wholesalePrice,
            productPlace: row.main.productPlace,
            wholesalePurchasePrice: row.wholesalePurchasePrice,
            consignmentPurchasePrice: row2.afterTaxPrice,
            marketPrice: row.marketPrice,
            consignmentPrice: row.consignmentPrice,
            wholesalePrice: row.wholesalePrice,
            expirationDate: row.main.expirationDate,
            inventoryId: row.stockMain.ID,
            mainId: row.mainId,
            specsId: row.ID,
            regId: row2.certId,
            inWarehouseQuantity: row2.inWarehouseQuantity,
            checked: true
          });
        });
      });
      return resultRows;
    },
    expireDays: function expireDays(sdate) {
      return sdate ? Math.ceil(dayjs(sdate).diff(dayjs(), 'days', true)) : 9999;
    }
  }
};