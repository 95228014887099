var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          attrs: {
            model: _vm.searchForm,
            size: "small",
            "label-suffix": ":",
            "label-width": "0px"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.loadSubsidiary()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": "0px" } },
            [
              _c("el-input", {
                attrs: { "suffix-icon": "el-icon-search", clearable: "" },
                on: {
                  change: function($event) {
                    return _vm.loadSubsidiary()
                  }
                },
                model: {
                  value: _vm.searchForm.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.searchForm, "companyName", $$v)
                  },
                  expression: "searchForm.companyName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-wrap": "wrap",
            overflow: "hidden"
          }
        },
        _vm._l(_vm.selectedCompanyList, function(tag) {
          return _c(
            "el-tag",
            {
              key: tag.companyName,
              staticStyle: { "margin-right": "10px", "margin-bottom": "10px" },
              attrs: { size: "small", closable: "", type: "primary" },
              on: {
                close: function($event) {
                  return _vm.tagClose(tag)
                }
              }
            },
            [_vm._v(" " + _vm._s(tag.companyName) + " ")]
          )
        }),
        1
      ),
      _c(
        "el-table",
        {
          ref: "subsidiaryListRef",
          attrs: {
            data: _vm.subsidiaryList,
            border: "",
            "highlight-current-row": "",
            size: "mini",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "50", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "归属公司", align: "center" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          staticStyle: { "margin-top": "20px" },
          attrs: { align: "center" }
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.formSubmit }
            },
            [_vm._v("保 存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }