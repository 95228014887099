var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          attrs: { inline: true, size: "mini" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: { change: _vm.pidChange },
                  model: {
                    value: _vm.form.pid,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "pid", $$v)
                    },
                    expression: "form.pid"
                  }
                },
                _vm._l(_vm.consumSetList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "组套" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  on: { change: _vm.setIdChange },
                  model: {
                    value: _vm.consumSetInfoForm.setId,
                    callback: function($$v) {
                      _vm.$set(_vm.consumSetInfoForm, "setId", $$v)
                    },
                    expression: "consumSetInfoForm.setId"
                  }
                },
                _vm._l(_vm.consumSubSetList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.ID + "/" + item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            overflow: "auto",
            border: "1px solid #CFD7E5"
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.consumLoading,
                  expression: "consumLoading"
                }
              ],
              ref: "consumListTable",
              attrs: {
                data: _vm.consumList,
                size: "small",
                "highlight-current-row": "",
                border: "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40" }
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: "center",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.consumSetInfoForm)
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryQuantity",
                  width: "160",
                  label: "出库数量",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-input-number", {
                          attrs: { size: "mini", min: 0, max: row.total },
                          on: {
                            change: function($event) {
                              return _vm.deliveryQuantityChange(row)
                            }
                          },
                          model: {
                            value: row.deliveryQuantity,
                            callback: function($$v) {
                              _vm.$set(row, "deliveryQuantity", $$v)
                            },
                            expression: "row.deliveryQuantity"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "total",
                  width: "100",
                  label: "剩余数量",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.productNumber",
                  width: "100",
                  label: "产品编号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "main.productName",
                  width: "200",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.genericName",
                  width: "100",
                  label: "通用名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specs.specificationModel",
                  width: "100",
                  label: "规格型号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.batch_number",
                  width: "100",
                  label: "生产批号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.trackingCode",
                  width: "100",
                  label: "序列号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sleeveLocking",
                  label: "组套锁定",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "available",
                  label: "组套可用",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.normalQuantity",
                  label: "库存可用",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.manufacturer_date",
                  label: "生产日期",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.validity_date",
                  label: "有效期",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.sterilization_batch_number",
                  label: "灭菌批号",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inventory.sterilization_date",
                  label: "灭菌效期",
                  width: "100",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "产地/品牌",
                  width: "200",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.main.productPlace) +
                            "/" +
                            _vm._s(row.main.brandName) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "main.manufacturer",
                  width: "200",
                  label: "生产厂家",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  align: "center",
                  total: _vm.consumSetInfoForm.total,
                  "current-page": _vm.consumSetInfoForm.page,
                  "page-size": _vm.consumSetInfoForm.pageSize,
                  "page-sizes": [5, 10, 20, 50, 100]
                },
                on: {
                  "current-change": _vm.consumSetInfoFormPageChange,
                  "size-change": _vm.consumSetInfoFormSizeChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              attrs: { type: "primary" },
              on: { click: _vm.selectConfirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }