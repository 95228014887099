import _typeof from "D:/wwwroot/ERP\u524D\u7AEF/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.sort.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listCategory } from '@/api/global';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      categoryId1: '',
      categoryName1: '',
      categoryId2: '',
      categoryName2: '',
      categoryList1: [],
      // 旧版分类
      categoryList2: [],
      // 新版分类
      selectForm: {
        newCategory: [],
        oldCategory: []
      },
      formLoading: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      newCategoryActiveName: [],
      oldCategoryActiveName: [],
      multiple: false
    };
  },
  methods: {
    init: function init(row, multiple) {
      var that = this;
      that.multiple = multiple;

      if (row) {
        that.selectForm = JSON.parse(JSON.stringify(row));

        if (Object.prototype.hasOwnProperty.call(that.selectForm, 'oldCategory')) {
          if (that.selectForm.oldCategory.length > 0) {}
        }

        if (Object.prototype.hasOwnProperty.call(that.selectForm, 'newCategory')) {
          if (that.selectForm.newCategory.length > 0) {}
        }
      } else {
        that.selectForm = {
          newCategory: [],
          oldCategory: []
        };
      }

      that.dialogVisible = true;
      that.dialogTitle = '选择分类';
      that.loadCategory();
    },
    loadCategory: function loadCategory() {
      var that = this;
      listCategory().then(function (res) {
        that.categoryList1 = that.jsonSort(res.list1, 'num', false);
        that.categoryList2 = res.list2;
        that.$nextTick(function () {
          if (that.selectForm.newCategory.length > 0) {
            that.categoryList2.forEach(function (row, index) {
              if (row.num === that.selectForm.newCategory[0].num) {
                document.getElementById('new-' + index).scrollIntoView();
              }
            });
          }

          if (that.selectForm.oldCategory.length > 0) {
            that.categoryList1.forEach(function (row, index) {
              if (row.num === that.selectForm.oldCategory[0].num) {
                document.getElementById('old-' + index).scrollIntoView();
              }
            });
          }
        });
      });
    },
    checkboxChange1: function checkboxChange1(item) {
      var that = this; // 查询是否已存在

      var find = that.selectForm.oldCategory.filter(function (row) {
        return row.num === item.num;
      });

      if (find.length > 0) {
        // 已存在，删除
        that.selectForm.oldCategory.forEach(function (row, index) {
          if (row.num === item.num) {
            that.selectForm.oldCategory.splice(index, 1);
          }
        });
      } else {
        // 不存在，添加
        var newItem = {
          id: item.ID,
          name: item.name,
          num: item.num,
          children: []
        };

        if (that.multiple) {
          that.selectForm.oldCategory.push(newItem);
        } else {
          that.selectForm.oldCategory = [];
          that.selectForm.oldCategory.push(newItem);
        }
      }
    },
    // 处理新版分类
    checkboxChange2: function checkboxChange2(item) {
      var that = this; // 查询是否已存在

      var find = that.selectForm.newCategory.filter(function (row) {
        return row.num === item.num;
      });

      if (find.length > 0) {
        // 已存在，删除
        that.selectForm.newCategory.forEach(function (row, index) {
          if (row.num === item.num) {
            that.selectForm.newCategory.splice(index, 1);
          }
        });
      } else {
        // 不存在，添加
        var newItem = {
          id: item.ID,
          name: item.name,
          num: item.num,
          children: []
        };
        item.children.forEach(function (row) {
          newItem.children.push({
            id: row.ID,
            name: row.name,
            num: row.num,
            children: []
          });
        });

        if (that.multiple) {
          that.selectForm.newCategory.push(newItem);
        } else {
          that.selectForm.newCategory = [];
          that.selectForm.newCategory.push(newItem);
        }
      }
    },
    selectedCheckNew: function selectedCheckNew(item) {
      var that = this;
      var find = that.selectForm.newCategory.filter(function (row) {
        return row.num === item.num;
      });

      if (find.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    selectedCheckOld: function selectedCheckOld(item) {
      var that = this;
      var result = false;
      that.selectForm.oldCategory.forEach(function (row) {
        if (row.num === item.num) {
          result = true;
        }
      });
      return result;
    },
    selectFormSubmit: function selectFormSubmit() {
      var that = this;

      if (that.selectForm.oldCategory.length < 1 && that.selectForm.newCategory.length < 1) {
        that.$message.error('请选择新版分类目录、旧版分类目录！');
      } else {
        that.$emit('receive', that.selectForm);
        that.dialogClose();
      }
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.$nextTick(function () {
        that.dialogVisible = false;
      });
    },
    jsonSort: function jsonSort(array, field, reverse) {
      // 数组长度小于2 或 没有指定排序字段 或 不是json格式数据
      if (array.length < 2 || !field || _typeof(array[0]) !== 'object') return array; // 数字类型排序

      if (typeof array[0][field] === 'number') {
        array.sort(function (x, y) {
          return x[field] - y[field];
        });
      } // 字符串类型排序


      if (typeof array[0][field] === 'string') {
        array.sort(function (x, y) {
          return x[field].localeCompare(y[field]);
        });
      } // 判断是否需要倒序


      if (reverse) {
        array.reverse();
      }

      return array;
    }
  }
};