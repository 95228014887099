//
//
//
//
//
//
//
//
//
//
//
//
//
//
import menu from '@/menu/modules/base';
export default {
  data: function data() {
    return {
      menu: menu
    };
  }
};