var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "statistic",
      style:
        "background:linear-gradient(to right, " +
        _vm.color[0] +
        ", " +
        _vm.color[1] +
        ")"
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.value))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }