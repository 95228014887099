var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "400px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "auto",
            size: "small",
            rules: _vm.formRules,
            "label-suffix": ":"
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "仓库绑定", prop: "warehouseId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择仓库" },
                  model: {
                    value: _vm.form.warehouseId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "warehouseId", $$v)
                    },
                    expression: "form.warehouseId"
                  }
                },
                _vm._l(_vm.warehouseList, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货架编号", prop: "shelfNumber" } },
            [
              _c("el-input", {
                attrs: { maxlength: "24" },
                model: {
                  value: _vm.form.shelfNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shelfNumber", $$v)
                  },
                  expression: "form.shelfNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货架名称", prop: "shelfName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.shelfName,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "shelfName", $$v)
                  },
                  expression: "form.shelfName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "巷道灯编码", prop: "laneLightCoding" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.laneLightCoding,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "laneLightCoding", $$v)
                  },
                  expression: "form.laneLightCoding"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "层数", prop: "numberOfRows" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.numberOfRows,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "numberOfRows", $$v)
                  },
                  expression: "form.numberOfRows"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "列数", prop: "numberOfColumns" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.numberOfColumns,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "numberOfColumns", $$v)
                  },
                  expression: "form.numberOfColumns"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单货位容量", prop: "capacity" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.form.capacity,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "capacity", $$v)
                  },
                  expression: "form.capacity"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "总容量", prop: "total" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.form.numberOfRows *
                    _vm.form.numberOfColumns *
                    _vm.form.capacity
                ) +
                " "
            )
          ]),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取消")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }