var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c("span", [_vm._v("文件上传列表：")])
          ]),
          _c(
            "el-table",
            {
              key: "fileListTable" + _vm.timestamp,
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.uploadFileURL,
                border: "",
                size: "small",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { label: "文件名", prop: "name", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { label: "路径", prop: "url" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              target: "_blank",
                              href: _vm.uploadFilePrefixURL + row.url
                            }
                          },
                          [_vm._v(_vm._s(row.url))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "上传状态",
                  prop: "status",
                  width: "150",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status
                          ? _c("span", { staticClass: "text-success" }, [
                              _vm._v(_vm._s(row.message))
                            ])
                          : _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(row.message))
                            ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-top": "10px" } }, [
            _vm._v(
              "1、出厂检测报告文件名字规则为产品编号-批次,比如bx231212-2309123.pdf"
            )
          ]),
          _c("div", [_vm._v("2、如果原来已经存在,新上传的会覆盖原报告文件")]),
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { align: "center" } },
                [
                  _c(
                    "el-upload",
                    {
                      key: "upload" + _vm.timestamp,
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        action: _vm.uploadURL,
                        headers: _vm.uploadToken,
                        "before-upload": function(file) {
                          return _vm.uploadBefore(file)
                        },
                        "on-success": _vm.uploadSuccess,
                        "on-exceed": _vm.uploadExceed,
                        multiple: true,
                        "on-error": _vm.uploadError,
                        accept: _vm.uploadFileType.toString(),
                        limit: 10,
                        "show-file-list": false
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary", icon: "el-icon-upload2" } },
                        [_vm._v("点击上传出厂检验报告")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }