var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "close-on-click-modal": false,
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "auto",
            size: "mini"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品牌名称" },
                        model: {
                          value: _vm.itemForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "name", $$v)
                          },
                          expression: "itemForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产商", prop: "manu_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择、输入生产商名称",
                            filterable: "",
                            "reserve-keyword": ""
                          },
                          model: {
                            value: _vm.itemForm.manu_id,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "manu_id", $$v)
                            },
                            expression: "itemForm.manu_id"
                          }
                        },
                        _vm._l(_vm.manufacturerList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        attrs: { placeholder: "请输入排序数值", min: 0 },
                        model: {
                          value: _vm.itemForm.sort,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "sort", $$v)
                          },
                          expression: "itemForm.sort"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                          staticStyle: { "margin-left": "10px" }
                        },
                        [_vm._v("数值越大，显示越靠前")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌LOGO", prop: "logo" } },
                    [
                      _c(
                        "div",
                        { on: { drop: _vm.reportDrop } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.uploadURL,
                                "show-file-list": false,
                                "on-exceed": _vm.uploadExceed,
                                "on-error": _vm.uploadError,
                                "on-preview": _vm.uploadPreview,
                                accept: _vm.uploadFileType.toString(),
                                "on-success": _vm.uploadSuccess,
                                headers: _vm.uploadToken,
                                "before-upload": _vm.uploadBefore,
                                "on-remove": _vm.uploadRemove,
                                drag: ""
                              }
                            },
                            [
                              _vm.itemForm.logo
                                ? _c("img", {
                                    staticStyle: { "max-width": "100%" },
                                    attrs: {
                                      src:
                                        _vm.uploadFilePrefixURL +
                                        _vm.itemForm.logo
                                    }
                                  })
                                : _c("i", {
                                    staticClass: "el-icon-plus uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }