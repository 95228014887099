var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "350px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "itemForm",
              attrs: {
                model: _vm.itemForm,
                rules: _vm.itemFormRules,
                size: "small",
                "status-icon": true,
                "label-width": "auto"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "配送员：", prop: "deliveryUid" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        placeholder: "请选择",
                        filterable: ""
                      },
                      on: { change: _vm.deliveryUidChange },
                      model: {
                        value: _vm.itemForm.deliveryUid,
                        callback: function($$v) {
                          _vm.$set(_vm.itemForm, "deliveryUid", $$v)
                        },
                        expression: "itemForm.deliveryUid"
                      }
                    },
                    _vm._l(_vm.userList, function(item) {
                      return _c("el-option", {
                        key: item.uid,
                        attrs: { label: item.name, value: item.uid }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "配送员收益：" } }, [
                _vm._v(" " + _vm._s(_vm.expressFee) + " ")
              ]),
              _vm.describe
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { color: "#f00" },
                      attrs: { "label-width": "0", align: "center" }
                    },
                    [_vm._v(" " + _vm._s(_vm.describe) + " ")]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.itemFormSubmit }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }