var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("内部信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("本公司信息")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          {
            staticClass: "text-danger",
            staticStyle: { "font-size": "12px" },
            attrs: { align: "right" }
          },
          [
            _vm._v(
              "注意：本页面信息只有超级管理员、管理员才能维护修改，其他角色没有修改维护权限。"
            )
          ]
        ),
        _c(
          "el-tabs",
          {
            attrs: { "before-leave": _vm.tabBeforeLeave },
            model: {
              value: _vm.tabActiveName,
              callback: function($$v) {
                _vm.tabActiveName = $$v
              },
              expression: "tabActiveName"
            }
          },
          [
            _c("el-tab-pane", { attrs: { label: "基本信息", name: "base" } }),
            _c("el-tab-pane", {
              attrs: { label: "营业执照", name: "license" }
            }),
            _c("el-tab-pane", {
              attrs: { label: "经营许可证", name: "certificate" }
            }),
            _c("el-tab-pane", { attrs: { label: "生产许可证", name: "manu" } })
          ],
          1
        ),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveName === "base" ? true : false,
                expression: "tabActiveName==='base'?true:false"
              }
            ],
            ref: "baseForm",
            attrs: {
              model: _vm.baseForm,
              rules: _vm.baseFormRules,
              "label-width": "150px",
              "label-suffix": ":",
              size: "small"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "base_name",
                        attrs: { label: "公司名称", prop: "name" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入公司名称" },
                          on: { change: _vm.toPinyinCode },
                          model: {
                            value: _vm.baseForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "name", $$v)
                            },
                            expression: "baseForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "base_short_name",
                        attrs: { label: "公司简称", prop: "short_name" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入公司简称" },
                          model: {
                            value: _vm.baseForm.short_name,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "short_name", $$v)
                            },
                            expression: "baseForm.short_name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "拼音码", prop: "pinyin_code" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入拼音码" },
                          model: {
                            value: _vm.baseForm.pinyin_code,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "pinyin_code", $$v)
                            },
                            expression: "baseForm.pinyin_code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属地区" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "baseForm_province_name",
                                    staticStyle: { "margin-bottom": "0px" },
                                    attrs: { label: "", prop: "province_name" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择省份"
                                        },
                                        on: { change: _vm.provinceChange },
                                        model: {
                                          value: _vm.baseForm.province_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.baseForm,
                                              "province_name",
                                              $$v
                                            )
                                          },
                                          expression: "baseForm.province_name"
                                        }
                                      },
                                      _vm._l(_vm.provinceList, function(item) {
                                        return _c("el-option", {
                                          key: item.ID,
                                          attrs: {
                                            label: item.name,
                                            value: item.name
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "baseForm_city_name",
                                    staticStyle: { margin: "0 10px" },
                                    attrs: { label: "", prop: "city_name" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择城市"
                                        },
                                        on: { change: _vm.cityChange },
                                        model: {
                                          value: _vm.baseForm.city_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.baseForm,
                                              "city_name",
                                              $$v
                                            )
                                          },
                                          expression: "baseForm.city_name"
                                        }
                                      },
                                      _vm._l(_vm.cityList, function(item) {
                                        return _c("el-option", {
                                          key: item.ID,
                                          attrs: {
                                            label: item.name,
                                            value: item.name
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "baseForm_district_name",
                                    staticStyle: { margin: "0px 10px 0 0" },
                                    attrs: { label: "", prop: "district_name" }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "请选择区/县"
                                        },
                                        model: {
                                          value: _vm.baseForm.district_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.baseForm,
                                              "district_name",
                                              $$v
                                            )
                                          },
                                          expression: "baseForm.district_name"
                                        }
                                      },
                                      _vm._l(_vm.areaList, function(item) {
                                        return _c("el-option", {
                                          key: item.ID,
                                          attrs: {
                                            label: item.name,
                                            value: item.name
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 9 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { margin: "0" },
                                    attrs: { label: "", prop: "address" }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "请输入详细地址" },
                                      model: {
                                        value: _vm.baseForm.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.baseForm, "address", $$v)
                                        },
                                        expression: "baseForm.address"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "详细地址", prop: "address" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入详细地址" },
                          model: {
                            value: _vm.baseForm.address,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "address", $$v)
                            },
                            expression: "baseForm.address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "法人代表", prop: "legal_person" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入法人代表" },
                          model: {
                            value: _vm.baseForm.legal_person,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "legal_person", $$v)
                            },
                            expression: "baseForm.legal_person"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "开户银行", prop: "bank" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入开户银行" },
                          model: {
                            value: _vm.baseForm.bank,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "bank", $$v)
                            },
                            expression: "baseForm.bank"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "银行账号", prop: "bank_account" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入银行账号" },
                          model: {
                            value: _vm.baseForm.bank_account,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "bank_account", $$v)
                            },
                            expression: "baseForm.bank_account"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "付款方式", prop: "payment_method" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: "请选择付款方式"
                            },
                            model: {
                              value: _vm.baseForm.payment_method,
                              callback: function($$v) {
                                _vm.$set(_vm.baseForm, "payment_method", $$v)
                              },
                              expression: "baseForm.payment_method"
                            }
                          },
                          _vm._l(_vm.paymentList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "税号", prop: "duty_paragraph" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入税号" },
                          model: {
                            value: _vm.baseForm.duty_paragraph,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "duty_paragraph", $$v)
                            },
                            expression: "baseForm.duty_paragraph"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "默认税点", prop: "tax_point" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            "controls-position": "right",
                            precision: 2,
                            min: 0
                          },
                          model: {
                            value: _vm.baseForm.tax_point,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "tax_point", $$v)
                            },
                            expression: "baseForm.tax_point"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司传真", prop: "fax" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入公司传真" },
                          model: {
                            value: _vm.baseForm.fax,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "fax", $$v)
                            },
                            expression: "baseForm.fax"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "公司电话", prop: "contact_number" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入公司电话" },
                          model: {
                            value: _vm.baseForm.contact_number,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "contact_number", $$v)
                            },
                            expression: "baseForm.contact_number"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "移动电话", prop: "mobile_phone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入移动电话" },
                          model: {
                            value: _vm.baseForm.mobile_phone,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "mobile_phone", $$v)
                            },
                            expression: "baseForm.mobile_phone"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮政编码", prop: "postal_code" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入邮政编码" },
                          model: {
                            value: _vm.baseForm.postal_code,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "postal_code", $$v)
                            },
                            expression: "baseForm.postal_code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "QQ", prop: "qq" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入QQ" },
                          model: {
                            value: _vm.baseForm.qq,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "qq", $$v)
                            },
                            expression: "baseForm.qq"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "电子邮箱", prop: "email" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入电子邮箱" },
                          model: {
                            value: _vm.baseForm.email,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "email", $$v)
                            },
                            expression: "baseForm.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "网址", prop: "website" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入网址" },
                          model: {
                            value: _vm.baseForm.website,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "website", $$v)
                            },
                            expression: "baseForm.website"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "baseForm_scope_business_license_text",
                        attrs: {
                          label: "经营许可范围",
                          prop: "scope_business_license_text"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入经营许可范围",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.scope_business_license_text,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.baseForm,
                                "scope_business_license_text",
                                $$v
                              )
                            },
                            expression: "baseForm.scope_business_license_text"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "justify-content": "flex-end"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["base:company:update"],
                                    expression: "['base:company:update']"
                                  }
                                ],
                                attrs: { type: "primary" },
                                on: { click: _vm.selectScope }
                              },
                              [_vm._v("选择经营范围明细")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.baseFormSubmit(true)
                              }
                            }
                          },
                          [_vm._v("下一步")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveName === "license" ? true : false,
                expression: "tabActiveName==='license'?true:false"
              }
            ],
            ref: "licenseForm",
            attrs: {
              model: _vm.licenseForm,
              rules: _vm.licenseFormRules,
              "label-width": "100px",
              "label-suffix": ":",
              size: "small"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.licenseFormSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "执照编号", prop: "license_no" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入执照编号" },
                          model: {
                            value: _vm.licenseForm.license_no,
                            callback: function($$v) {
                              _vm.$set(_vm.licenseForm, "license_no", $$v)
                            },
                            expression: "licenseForm.license_no"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "企业名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入公司名称",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "name", $$v)
                            },
                            expression: "baseForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "base_short_name",
                        attrs: { label: "企业简称", prop: "short_name" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入公司简称",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.short_name,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "short_name", $$v)
                            },
                            expression: "baseForm.short_name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_enterprise_type",
                        attrs: { label: "企业类型", prop: "enterprise_type" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: "请选择企业类型"
                            },
                            model: {
                              value: _vm.licenseForm.enterprise_type,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.licenseForm,
                                  "enterprise_type",
                                  $$v
                                )
                              },
                              expression: "licenseForm.enterprise_type"
                            }
                          },
                          _vm._l(_vm.enterpriseTypeList, function(item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_legal_person",
                        attrs: { label: "法人代表" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入法人代表",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.legal_person,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "legal_person", $$v)
                            },
                            expression: "baseForm.legal_person"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "注册地址", prop: "address" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入注册地址" },
                          model: {
                            value: _vm.baseForm.address,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "address", $$v)
                            },
                            expression: "baseForm.address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_registered_capital",
                        attrs: { label: "注册资本", prop: "registered_capital" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入注册资本" },
                          model: {
                            value: _vm.licenseForm.registered_capital,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "registered_capital",
                                $$v
                              )
                            },
                            expression: "licenseForm.registered_capital"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_establishment_time",
                        attrs: { label: "成立时间", prop: "establishment_time" }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "请选择成立时间"
                          },
                          model: {
                            value: _vm.licenseForm.establishment_time,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "establishment_time",
                                $$v
                              )
                            },
                            expression: "licenseForm.establishment_time"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_registration_time",
                        attrs: { label: "登记时间", prop: "registration_time" }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "请选择登记时间"
                          },
                          model: {
                            value: _vm.licenseForm.registration_time,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "registration_time",
                                $$v
                              )
                            },
                            expression: "licenseForm.registration_time"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_registration_authority",
                        attrs: {
                          label: "登记机关",
                          prop: "registration_authority"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入登记机关" },
                          model: {
                            value: _vm.licenseForm.registration_authority,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "registration_authority",
                                $$v
                              )
                            },
                            expression: "licenseForm.registration_authority"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "营业期限" } },
                      [
                        _c(
                          "el-row",
                          { attrs: { type: "flex", gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 9 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "licenseForm_start_time_business",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "start_time_business"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "开始日期"
                                      },
                                      model: {
                                        value:
                                          _vm.licenseForm.start_time_business,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.licenseForm,
                                            "start_time_business",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenseForm.start_time_business"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 2 } }, [
                              _vm._v("至")
                            ]),
                            _c(
                              "el-col",
                              { attrs: { span: 9 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "licenseForm_end_time_business",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "end_time_business"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "结束日期"
                                      },
                                      model: {
                                        value:
                                          _vm.licenseForm.end_time_business,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.licenseForm,
                                            "end_time_business",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "licenseForm.end_time_business"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "licenseForm_long_term",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "long_term"
                                    }
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: {
                                        label: "长期",
                                        "true-label": 1,
                                        "false-label": 0
                                      },
                                      model: {
                                        value: _vm.licenseForm.long_term,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.licenseForm,
                                            "long_term",
                                            $$v
                                          )
                                        },
                                        expression: "licenseForm.long_term"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 18 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                key: "licenseForm_extension_time",
                                attrs: {
                                  label: "延期时间",
                                  prop: "extension_time"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "请选择延期时间",
                                    disabled:
                                      _vm.licenseForm.whether_postpone !== "1"
                                  },
                                  model: {
                                    value: _vm.licenseForm.extension_time,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.licenseForm,
                                        "extension_time",
                                        $$v
                                      )
                                    },
                                    expression: "licenseForm.extension_time"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                key: "licenseForm_whether_postpone",
                                staticStyle: { "margin-bottom": "0" },
                                attrs: {
                                  "label-width": "0",
                                  prop: "whether_postpone"
                                }
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: {
                                    label: "是否延期",
                                    "true-label": "1",
                                    "false-label": "0"
                                  },
                                  model: {
                                    value: _vm.licenseForm.whether_postpone,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.licenseForm,
                                        "whether_postpone",
                                        $$v
                                      )
                                    },
                                    expression: "licenseForm.whether_postpone"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_extension_certificate_no",
                        attrs: {
                          label: "延期证号",
                          prop: "extension_certificate_no"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入延期证号",
                            disabled: _vm.licenseForm.whether_postpone !== "1"
                          },
                          model: {
                            value: _vm.licenseForm.extension_certificate_no,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "extension_certificate_no",
                                $$v
                              )
                            },
                            expression: "licenseForm.extension_certificate_no"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "baseForm_nature_business",
                        attrs: { label: "经营范围", prop: "nature_business" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            placeholder: "请输入经营范围",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.scope_business_license_text,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.baseForm,
                                "scope_business_license_text",
                                $$v
                              )
                            },
                            expression: "baseForm.scope_business_license_text"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "licenseForm_business_license_photo",
                        attrs: {
                          label: "营业执照照片",
                          prop: "business_license_photo"
                        }
                      },
                      [
                        _c(
                          "div",
                          { on: { drop: _vm.reportDrop } },
                          [
                            _c(
                              "el-upload",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "align-items": "flex-start"
                                },
                                attrs: {
                                  action: _vm.uploadURL,
                                  headers: _vm.uploadToken,
                                  "before-upload": function(file) {
                                    return _vm.uploadBefore(file)
                                  },
                                  "on-remove": function(file, fileList) {
                                    return _vm.uploadRemove(
                                      file,
                                      fileList,
                                      "licenseUploadFileList"
                                    )
                                  },
                                  "on-success": function(res, file, fileList) {
                                    return _vm.uploadSuccess(
                                      res,
                                      file,
                                      fileList,
                                      "licenseUploadFileList"
                                    )
                                  },
                                  "on-error": _vm.uploadError,
                                  "on-preview": _vm.uploadPreview,
                                  accept: _vm.uploadFileType.toString(),
                                  "on-exceed": _vm.uploadExceed,
                                  limit: 10,
                                  "show-file-list": true,
                                  "file-list": _vm.licenseUploadFileList,
                                  drag: ""
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["base:company:update"],
                                        expression: "['base:company:update']"
                                      }
                                    ],
                                    attrs: { size: "small", type: "primary" }
                                  },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [
                                    _vm._v(
                                      "只能上传" +
                                        _vm._s(
                                          _vm.uploadFileType
                                            .toString()
                                            .toUpperCase()
                                            .replace(/\./g, "")
                                            .replace(/,/g, "/")
                                        ) +
                                        "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.formBack }
                          },
                          [_vm._v("上一步")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.licenseFormSubmit }
                          },
                          [_vm._v("下一步")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading"
              },
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveName === "certificate" ? true : false,
                expression: "tabActiveName==='certificate'?true:false"
              }
            ],
            ref: "certificateForm",
            attrs: {
              model: _vm.certificateForm,
              rules: _vm.certificateFormRules,
              "label-width": "100px",
              "label-suffix": ":",
              size: "small"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.certificateFormSubmit(true)
              }
            }
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.business_license_no",
                        attrs: {
                          label: "许可证编号",
                          prop: "business_license_no"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入许可证编号" },
                          model: {
                            value: _vm.certificateForm.business_license_no,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "business_license_no",
                                $$v
                              )
                            },
                            expression: "certificateForm.business_license_no"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.name",
                        attrs: { label: "企业名称" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入企业名称",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "name", $$v)
                            },
                            expression: "baseForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "注册地址", prop: "address" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入注册地址",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.address,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "address", $$v)
                            },
                            expression: "baseForm.address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.legal_person",
                        attrs: { label: "法人代表", prop: "legal_person" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入法人代表",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.legal_person,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "legal_person", $$v)
                            },
                            expression: "baseForm.legal_person"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.date_issue",
                        attrs: { label: "发证日期", prop: "date_issue" }
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            placeholder: "请选择发证日期"
                          },
                          model: {
                            value: _vm.certificateForm.date_issue,
                            callback: function($$v) {
                              _vm.$set(_vm.certificateForm, "date_issue", $$v)
                            },
                            expression: "certificateForm.date_issue"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.issuing_authority",
                        attrs: { label: "发证机关", prop: "issuing_authority" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入发证机关" },
                          model: {
                            value: _vm.certificateForm.issuing_authority,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "issuing_authority",
                                $$v
                              )
                            },
                            expression: "certificateForm.issuing_authority"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "许可证期限", required: true } },
                      [
                        _c(
                          "el-row",
                          { attrs: { type: "flex", gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "certificateForm_license_start_time",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "license_start_time"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "开始日期"
                                      },
                                      model: {
                                        value:
                                          _vm.certificateForm
                                            .license_start_time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.certificateForm,
                                            "license_start_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "certificateForm.license_start_time"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 2, align: "center" } },
                              [_vm._v("至")]
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    key: "certificateForm_license_end_time",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: {
                                      "label-width": "0",
                                      prop: "license_end_time"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "yyyy-MM-dd",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "结束日期"
                                      },
                                      model: {
                                        value:
                                          _vm.certificateForm.license_end_time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.certificateForm,
                                            "license_end_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "certificateForm.license_end_time"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 18 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                key: "certificateForm.license_extension_time",
                                attrs: {
                                  label: "延期时间",
                                  prop: "license_extension_time"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "请选择延期时间",
                                    disabled:
                                      _vm.licenseForm
                                        .whether_license_extended !== "1"
                                  },
                                  model: {
                                    value:
                                      _vm.licenseForm.license_extension_time,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.licenseForm,
                                        "license_extension_time",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "licenseForm.license_extension_time"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                key: "certificateForm.whether_license_extended",
                                staticStyle: { "margin-bottom": "0" },
                                attrs: {
                                  "label-width": "0",
                                  prop: "whether_license_extended"
                                }
                              },
                              [
                                _c("el-checkbox", {
                                  attrs: {
                                    label: "是否延期",
                                    "true-label": "1",
                                    "false-label": "0"
                                  },
                                  model: {
                                    value:
                                      _vm.licenseForm.whether_license_extended,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.licenseForm,
                                        "whether_license_extended",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "licenseForm.whether_license_extended"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.license_extension_no",
                        attrs: {
                          label: "延期证号",
                          prop: "license_extension_no"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入延期证号",
                            disabled:
                              _vm.certificateForm.whether_license_extended !==
                              "1"
                          },
                          model: {
                            value: _vm.certificateForm.license_extension_no,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "license_extension_no",
                                $$v
                              )
                            },
                            expression: "certificateForm.license_extension_no"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.nature_business",
                        attrs: { label: "经营范围", prop: "nature_business" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 2,
                            placeholder: "请输入经营范围",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.scope_business_license_text,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.baseForm,
                                "scope_business_license_text",
                                $$v
                              )
                            },
                            expression: "baseForm.scope_business_license_text"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.person_charge_enterprise",
                        attrs: {
                          label: "企业负责人",
                          prop: "person_charge_enterprise"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入企业负责人" },
                          model: {
                            value: _vm.certificateForm.person_charge_enterprise,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "person_charge_enterprise",
                                $$v
                              )
                            },
                            expression:
                              "certificateForm.person_charge_enterprise"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.quality_manager",
                        attrs: { label: "质量负责人", prop: "quality_manager" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入质量负责人" },
                          model: {
                            value: _vm.certificateForm.quality_manager,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "quality_manager",
                                $$v
                              )
                            },
                            expression: "certificateForm.quality_manager"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.place_business",
                        attrs: { label: "经营场所", prop: "place_business" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入经营场所",
                            disabled: true
                          },
                          model: {
                            value: _vm.baseForm.place_business,
                            callback: function($$v) {
                              _vm.$set(_vm.baseForm, "place_business", $$v)
                            },
                            expression: "baseForm.place_business"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.warehouse_address",
                        attrs: { label: "库房地址", prop: "warehouse_address" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入库房地址" },
                          model: {
                            value: _vm.certificateForm.warehouse_address,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "warehouse_address",
                                $$v
                              )
                            },
                            expression: "certificateForm.warehouse_address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.contact_information",
                        attrs: {
                          label: "联系方式",
                          prop: "contact_information"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入联系方式" },
                          model: {
                            value: _vm.certificateForm.contact_information,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "contact_information",
                                $$v
                              )
                            },
                            expression: "certificateForm.contact_information"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.receiving_address",
                        attrs: { label: "收货地址", prop: "receiving_address" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入收货地址" },
                          model: {
                            value: _vm.certificateForm.receiving_address,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "receiving_address",
                                $$v
                              )
                            },
                            expression: "certificateForm.receiving_address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm.receiving_contact",
                        attrs: { label: "收货人", prop: "receiving_contact" }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入收货人" },
                          model: {
                            value: _vm.certificateForm.receiving_contact,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.certificateForm,
                                "receiving_contact",
                                $$v
                              )
                            },
                            expression: "certificateForm.receiving_contact"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "certificateForm_picture_business_license",
                        attrs: {
                          label: "经营许可证照片",
                          prop: "picture_business_license"
                        }
                      },
                      [
                        _c(
                          "div",
                          { on: { drop: _vm.reportDrop } },
                          [
                            _c(
                              "el-upload",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "align-items": "flex-start"
                                },
                                attrs: {
                                  action: _vm.uploadURL,
                                  headers: _vm.uploadToken,
                                  "before-upload": function(file) {
                                    return _vm.uploadBefore(file)
                                  },
                                  "on-remove": function(file, fileList) {
                                    return _vm.uploadRemove(
                                      file,
                                      fileList,
                                      "businessUploadFileList"
                                    )
                                  },
                                  "on-success": function(res, file, fileList) {
                                    return _vm.uploadSuccess(
                                      res,
                                      file,
                                      fileList,
                                      "businessUploadFileList"
                                    )
                                  },
                                  "on-error": _vm.uploadError,
                                  "on-preview": _vm.uploadPreview,
                                  accept: _vm.uploadFileType.toString(),
                                  "on-exceed": _vm.uploadExceed,
                                  limit: 10,
                                  "show-file-list": true,
                                  "file-list": _vm.businessUploadFileList,
                                  drag: ""
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["base:company:update"],
                                        expression: "['base:company:update']"
                                      }
                                    ],
                                    attrs: { size: "small", type: "primary" }
                                  },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [
                                    _vm._v(
                                      "只能上传" +
                                        _vm._s(
                                          _vm.uploadFileType
                                            .toString()
                                            .toUpperCase()
                                            .replace(/\./g, "")
                                            .replace(/,/g, "/")
                                        ) +
                                        "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.formBack }
                          },
                          [_vm._v("上一步")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              },
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.certificateFormSubmit(true)
                              }
                            }
                          },
                          [_vm._v("下一步")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabActiveName === "manu" ? true : false,
                expression: "tabActiveName==='manu'?true:false"
              }
            ],
            ref: "manuForm",
            attrs: {
              model: _vm.manuForm,
              rules: _vm.manuFormRules,
              "label-width": "150px",
              size: "small",
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.manuFormSubmit(true)
              }
            }
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "许可证编号",
                          prop: "manu_license_code"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "255" },
                          model: {
                            value: _vm.manuForm.manu_license_code,
                            callback: function($$v) {
                              _vm.$set(_vm.manuForm, "manu_license_code", $$v)
                            },
                            expression: "manuForm.manu_license_code"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "统一社会信用代码",
                          prop: "manu_license_unicode"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "191" },
                          model: {
                            value: _vm.manuForm.manu_license_unicode,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.manuForm,
                                "manu_license_unicode",
                                $$v
                              )
                            },
                            expression: "manuForm.manu_license_unicode"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "企业名称",
                          prop: "manu_license_company"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "191" },
                          model: {
                            value: _vm.manuForm.manu_license_company,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.manuForm,
                                "manu_license_company",
                                $$v
                              )
                            },
                            expression: "manuForm.manu_license_company"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "法定代表人",
                          prop: "manu_license_legal"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "191" },
                          model: {
                            value: _vm.manuForm.manu_license_legal,
                            callback: function($$v) {
                              _vm.$set(_vm.manuForm, "manu_license_legal", $$v)
                            },
                            expression: "manuForm.manu_license_legal"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "住所", prop: "manu_license_home" } },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "500" },
                          model: {
                            value: _vm.manuForm.manu_license_home,
                            callback: function($$v) {
                              _vm.$set(_vm.manuForm, "manu_license_home", $$v)
                            },
                            expression: "manuForm.manu_license_home"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "企业负责人",
                          prop: "manu_license_boss"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "191" },
                          model: {
                            value: _vm.manuForm.manu_license_boss,
                            callback: function($$v) {
                              _vm.$set(_vm.manuForm, "manu_license_boss", $$v)
                            },
                            expression: "manuForm.manu_license_boss"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "生产地址",
                          prop: "manu_license_address"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "500" },
                          model: {
                            value: _vm.manuForm.manu_license_address,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.manuForm,
                                "manu_license_address",
                                $$v
                              )
                            },
                            expression: "manuForm.manu_license_address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "发证部门",
                          prop: "manu_license_department"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "191" },
                          model: {
                            value: _vm.manuForm.manu_license_department,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.manuForm,
                                "manu_license_department",
                                $$v
                              )
                            },
                            expression: "manuForm.manu_license_department"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "许可期限" } }, [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                "label-width": "0",
                                prop: "manu_license_start"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "开始日期",
                                  pickerOptions: _vm.manuLicenseStartOptions
                                },
                                model: {
                                  value: _vm.manuForm.manu_license_start,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.manuForm,
                                      "manu_license_start",
                                      $$v
                                    )
                                  },
                                  expression: "manuForm.manu_license_start"
                                }
                              })
                            ],
                            1
                          ),
                          _c("span", { staticStyle: { margin: "0 10px" } }, [
                            _vm._v("至")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0" },
                              attrs: {
                                "label-width": "0",
                                prop: "manu_license_end"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "结束日期",
                                  pickerOptions: _vm.manuLicenseEndOptions
                                },
                                model: {
                                  value: _vm.manuForm.manu_license_end,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.manuForm,
                                      "manu_license_end",
                                      $$v
                                    )
                                  },
                                  expression: "manuForm.manu_license_end"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "生产范围", prop: "manu_license_range" }
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            rows: 3,
                            maxlength: "500"
                          },
                          model: {
                            value: _vm.manuForm.manu_license_range,
                            callback: function($$v) {
                              _vm.$set(_vm.manuForm, "manu_license_range", $$v)
                            },
                            expression: "manuForm.manu_license_range"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        key: "manu_license_photo",
                        attrs: {
                          label: "生产许可证照片",
                          prop: "manu_license_photo"
                        }
                      },
                      [
                        _c(
                          "div",
                          { on: { drop: _vm.reportDrop } },
                          [
                            _c(
                              "el-upload",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  "align-items": "flex-start"
                                },
                                attrs: {
                                  action: _vm.uploadURL,
                                  headers: _vm.uploadToken,
                                  "before-upload": function(file) {
                                    return _vm.uploadBefore(file)
                                  },
                                  "on-remove": function(file, fileList) {
                                    return _vm.uploadRemove(
                                      file,
                                      fileList,
                                      "manuUploadFileList"
                                    )
                                  },
                                  "on-success": function(res, file, fileList) {
                                    return _vm.uploadSuccess(
                                      res,
                                      file,
                                      fileList,
                                      "manuUploadFileList"
                                    )
                                  },
                                  "on-error": _vm.uploadError,
                                  "on-preview": _vm.uploadPreview,
                                  accept: _vm.uploadFileType.toString(),
                                  "on-exceed": _vm.uploadExceed,
                                  limit: 10,
                                  "show-file-list": true,
                                  "file-list": _vm.manuUploadFileList,
                                  drag: ""
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["base:company:update"],
                                        expression: "['base:company:update']"
                                      }
                                    ],
                                    attrs: { size: "small", type: "primary" }
                                  },
                                  [_vm._v("点击上传")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip"
                                  },
                                  [
                                    _vm._v(
                                      "只能上传" +
                                        _vm._s(
                                          _vm.uploadFileType
                                            .toString()
                                            .toUpperCase()
                                            .replace(/\./g, "")
                                            .replace(/,/g, "/")
                                        ) +
                                        "类型文件，且单个文件大小不超过20MB，最多允许上传10个附件"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "0", align: "center" } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.formBack }
                          },
                          [_vm._v("上一步")]
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              },
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["base:company:update"],
                                expression: "['base:company:update']"
                              }
                            ],
                            attrs: { size: "medium", type: "primary" },
                            on: { click: _vm.manuFormSubmit }
                          },
                          [_vm._v("确定")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("CategoryComponents", {
        ref: "CategoryComponents",
        on: { receive: _vm.scopeReceive }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }