import { request } from '@/api/_service.js';
export function listCula(params) {
  return request({
    url: '/culaErp/getCulaList',
    method: 'get',
    params: params
  });
}
export function listCulaExport(params) {
  return request({
    url: '/culaErp/getCulaList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findCula(params) {
  return request({
    url: '/culaErp/findCula',
    method: 'get',
    params: params
  });
}
export function createCula(data) {
  return request({
    url: '/culaErp/createCula',
    method: 'post',
    data: data
  });
}
export function updateCula(data) {
  return request({
    url: '/culaErp/updateCula',
    method: 'put',
    data: data
  });
}
export function deleteCula(data) {
  return request({
    url: '/culaErp/deleteCula',
    method: 'delete',
    data: data
  });
}