var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.printClick }
                },
                [_vm._v("点击打印")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticClass: "custom-title-container" }, [
                    _vm._v(" " + _vm._s(_vm.corporateName) + " ")
                  ]),
                  _c("div", { staticClass: "custom-form-container" }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("医院名称：" + _vm._s(_vm.orderInfo.hospitalName))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("器械名称：" + _vm._s(_vm.orderInfo.surgeryName))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("送货日期：" + _vm._s(_vm.orderInfo.deliveryTime))
                    ]),
                    _c("div", { staticClass: "item" }, [_vm._v("医生签字：")]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("患者姓名：" + _vm._s(_vm.orderInfo.patientName))
                    ]),
                    _c("div", { staticClass: "item" }, [_vm._v("送货人：")]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("手术室护士长签字：")
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "病历号：" + _vm._s(_vm.orderInfo.medicalRecordNumber)
                      )
                    ]),
                    _c("div", { staticClass: "item" }, [_vm._v("验货签字：")]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("手术室护士签字：")
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("骨科主任：" + _vm._s(_vm.orderInfo.surgeon))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("手术日期：" + _vm._s(_vm.orderInfo.operationTime))
                    ])
                  ]),
                  _c("div", { staticClass: "detail-table" }, [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.remoteColumnsList, function(
                              item,
                              index
                            ) {
                              return [
                                _c("th", { key: "field-" + index }, [
                                  _vm._v(_vm._s(item.label))
                                ])
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.detailListGroups(_vm.detailList), function(
                            item,
                            index
                          ) {
                            return _c(
                              "tr",
                              { key: "data-col-" + index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    _c("td", { key: "data-item-" + index2 }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dataFormatter(
                                            item[item2.prop],
                                            item2.type
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._l(_vm.getSummary(), function(item, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    _c(
                                      "td",
                                      {
                                        key: "summary-" + index2,
                                        staticClass: "summary"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataFormatter(
                                              item[item2.prop],
                                              item2.type
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }