var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "250px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { attrs: { align: "center" } }, [
        _c("h4", [_vm._v("手机扫描下方二维码上传附件")]),
        _c("div", { ref: "canvasWrapper" }),
        _c("div", { staticStyle: { color: "#f00" } }, [
          _vm._v(
            " 提示：上传过程中请不要关闭此窗口，上传完毕后请点【完成上传】按钮。 "
          )
        ])
      ]),
      _c(
        "div",
        { attrs: { align: "center" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.dialogClose } },
            [_vm._v("完成上传")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }