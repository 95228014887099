var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "450px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          staticClass: "form-table",
          attrs: { "label-width": "100px", size: "small", "label-suffix": ":" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "付款单位" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.customerName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "收款单位" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.subsidiaryName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款日期" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.returnDate))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "回款总额" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.returnAmount.toFixed(2)))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "付款方式" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.returnTypeName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "经手人" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.handledBy))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-form-item", { attrs: { label: "备注" } }, [
                    _c("span", { staticClass: "item-text" }, [
                      _vm._v(_vm._s(_vm.row.remarks))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _vm.row.returnStatus === 3
                    ? _c("el-form-item", { attrs: { label: "审核意见" } }, [
                        _c("span", { staticClass: "item-text text-danger" }, [
                          _vm._v(_vm._s(_vm.row.returnRemarks))
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "auto-height" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回款凭证" } },
                    [
                      _c(
                        "span",
                        { staticClass: "item-text" },
                        _vm._l(_vm.fileList, function(item, index) {
                          return _c(
                            "div",
                            { key: "img-" + index },
                            [
                              _c(
                                "el-link",
                                {
                                  attrs: { underline: false, type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.uploadPreview(item)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.showViewer
                        ? _c("el-image-viewer", {
                            attrs: {
                              "on-close": _vm.closeViewer,
                              "url-list": _vm.previewSrcList,
                              "z-index": 9999
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关闭")]),
          _vm.command === "CHECK" && _vm.row.returnStatus === 1
            ? _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { type: "primary", loading: _vm.formLoading },
                  on: { click: _vm.toCheck }
                },
                [_vm._v("审核")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("CheckComponent", {
        ref: "CheckComponentRef",
        on: { confirm: _vm.checkConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }