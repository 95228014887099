var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("不动销产品预警")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.pageForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          filterable: ""
                        },
                        on: { change: _vm.warehouseChange },
                        model: {
                          value: _vm.warehouseIds,
                          callback: function($$v) {
                            _vm.warehouseIds = $$v
                          },
                          expression: "warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "不动销范围" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.pageForm.notMoving,
                          callback: function($$v) {
                            _vm.$set(_vm.pageForm, "notMoving", $$v)
                          },
                          expression: "pageForm.notMoving"
                        }
                      },
                      _vm._l(_vm.notMovingList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入产品名称", clearable: "" },
                      model: {
                        value: _vm.pageForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "productName", $$v)
                        },
                        expression: "pageForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品主码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品主码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.barCode,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "barCode", $$v)
                            },
                            expression: "pageForm.barCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "productNumber", $$v)
                            },
                            expression: "pageForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "批号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入批号", clearable: "" },
                          model: {
                            value: _vm.pageForm.batch_number,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "batch_number", $$v)
                            },
                            expression: "pageForm.batch_number"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "序列号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入序列号", clearable: "" },
                          model: {
                            value: _vm.pageForm.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "trackingCode", $$v)
                            },
                            expression: "pageForm.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        (_vm.pageForm.specs_id
        ? true
        : false)
          ? _c(
              "div",
              { staticStyle: { "font-size": "12px", "margin-bottom": "10px" } },
              [
                _vm.tableData.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._v(" 查询结果："),
                        _c(
                          "el-tag",
                          {
                            attrs: { closable: "", type: "primary" },
                            on: { close: _vm.closeTag }
                          },
                          [_vm._v(_vm._s(_vm.tableData[0].productName))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.pageForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.tableData
          },
          scopedSlots: _vm._u([
            {
              key: "epDay",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm._v(" 提前 "),
                  _c("span", { staticStyle: { color: "#f00" } }, [
                    _vm._v(_vm._s(row.epDay))
                  ]),
                  _vm._v(" 天 "),
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.editDay(row)
                        }
                      }
                    },
                    [_vm._v("设置")]
                  )
                ]
              }
            },
            {
              key: "stockQuantity",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-link",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.viewDetailClick(row)
                        }
                      }
                    },
                    [_vm._v(_vm._s(row.stockQuantity))]
                  )
                ]
              }
            }
          ])
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.pageSizeChange
            }
          })
        ],
        1
      ),
      _c("ViewDetailComponent", { ref: "ViewDetailComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }