import { request } from '@/api/_service.js';
export function listReceivable(params) {
  return request({
    url: '/receivableErp/getReceivableList',
    method: 'get',
    params: params
  });
}
export function listReceivableExport(params) {
  return request({
    url: '/receivableErp/getReceivableList',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}
export function findReceivable(params) {
  return request({
    url: '/receivableErp/findReceivable',
    method: 'get',
    params: params
  });
}
export function createReceivable(data) {
  return request({
    url: '/receivableErp/createReceivable',
    method: 'post',
    data: data
  });
}
export function updateReceivable(data) {
  return request({
    url: '/receivableErp/updateReceivable',
    method: 'put',
    data: data
  });
}
export function deleteReceivable(data) {
  return request({
    url: '/receivableErp/deleteReceivable',
    method: 'delete',
    data: data
  });
}
export function deleteReceivableByIds(data) {
  return request({
    url: '/receivableErp/deleteReceivableByIds',
    method: 'delete',
    data: data
  });
}
export function createReceivableLog(data) {
  return request({
    url: '/receivableLogErp/createReceivableLog',
    method: 'post',
    data: data
  });
}
export function deleteReceivableLog(data) {
  return request({
    url: '/receivableLogErp/deleteReceivableLog',
    method: 'delete',
    data: data
  });
}
export function deleteReceivableLogByIds(data) {
  return request({
    url: '/receivableLogErp/deleteReceivableLogByIds',
    method: 'delete',
    data: data
  });
}
export function findReceivableLog(params) {
  return request({
    url: '/receivableLogErp/findReceivableLog',
    method: 'get',
    params: params
  });
}
export function listReceivableDetail(params) {
  return request({
    url: '/receivableLogErp/getReceivableLogList',
    method: 'get',
    params: params
  });
}
export function updateReceivableLog(data) {
  return request({
    url: '/receivableLogErp/updateReceivableLog',
    method: 'put',
    data: data
  });
}
export function updateReceivableRelatedAmount(data) {
  return request({
    url: '/receivableErp/updateReceivableRelatedAmount',
    method: 'put',
    data: data
  });
}