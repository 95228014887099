var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "form",
              attrs: {
                size: "small",
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "100px",
                "label-suffix": ":"
              },
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { sm: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "来源", prop: "warehouseId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                disabled: true
                              },
                              model: {
                                value: _vm.form.warehouseId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "warehouseId", $$v)
                                },
                                expression: "form.warehouseId"
                              }
                            },
                            _vm._l(_vm.warehouseList, function(item, idx) {
                              return _c("el-option", {
                                key: "warehouseId-" + idx,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退回单位", prop: "returnCompany" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.returnCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "returnCompany", $$v)
                              },
                              expression: "form.returnCompany"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退回理由", prop: "returnReason" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.form.returnReason,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "returnReason", $$v)
                                },
                                expression: "form.returnReason"
                              }
                            },
                            _vm._l(_vm.returnReasonList, function(item, idx) {
                              return _c("el-option", {
                                key: "returnReason-" + idx,
                                attrs: { label: item.name, value: item.ID }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "物流信息", prop: "deliveryInfo" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入物流信息",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.deliveryInfo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "deliveryInfo", $$v)
                              },
                              expression: "form.deliveryInfo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 12, lg: 8, xl: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "备注", clearable: "" },
                            model: {
                              value: _vm.form.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  ref: "detailListTable",
                  attrs: {
                    width: "100%",
                    border: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "row-key": _vm.detailRowKey,
                    "row-class-name": _vm.rowClassName,
                    data: _vm.pageDataLoad()
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "60",
                      align: "center",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.detailForm)
                      }
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productNumber",
                      label: "产品编号",
                      "min-width": "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specificationModel",
                      label: "规格型号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "registrationNumber",
                      label: "注册证号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "manufacturer",
                      label: "生产厂家",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit",
                      label: "单位",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remQuantity",
                      label: "待处理数量",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "returnQuantity",
                      label: "退货数量",
                      width: "100",
                      align: "center"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "enter-number",
                                  rawName:
                                    "v-enter-number:[{min:1,max:scope.row.remQuantity,precision:0}]",
                                  arg: {
                                    min: 1,
                                    max: scope.row.remQuantity,
                                    precision: 0
                                  }
                                }
                              ],
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.returnQuantity,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "returnQuantity",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "scope.row.returnQuantity"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { align: "center" }
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next, total",
                      align: "center",
                      total: _vm.detailForm.total,
                      "current-page": _vm.detailForm.page,
                      "page-size": _vm.detailForm.pageSize
                    },
                    on: { "current-change": _vm.detailFormChange }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }