var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button-group",
    [
      _vm.title
        ? _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.$open(_vm.link)
                }
              }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          attrs: { size: "mini" },
          on: {
            click: function($event) {
              return _vm.$open(_vm.link)
            }
          }
        },
        [
          _c("d2-icon", { attrs: { name: _vm.icon } }),
          _vm._v(" " + _vm._s(_vm.link) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }