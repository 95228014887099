import { request } from '@/api/_service.js';
export function listBrand(params) {
  return request({
    url: '/erpProductBrands/getProductBrandsList',
    method: 'get',
    params: params
  });
}
export function brandList(params) {
  return request({
    url: '/erpProductBrands/getProductBrandsList',
    method: 'get',
    params: params
  });
}
export function findBrand(params) {
  return request({
    url: '/erpProductBrands/findProductBrands',
    method: 'get',
    params: params
  });
}
export function createBrand(data) {
  return request({
    url: '/erpProductBrands/createProductBrands',
    method: 'post',
    data: data
  });
}
export function updateBrand(data) {
  return request({
    url: '/erpProductBrands/updateProductBrands',
    method: 'put',
    data: data
  });
}
export function deleteBrand(data) {
  return request({
    url: '/erpProductBrands/deleteProductBrandsByIds',
    method: 'delete',
    data: data
  });
}