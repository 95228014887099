var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                size: "small",
                model: _vm.form,
                rules: _vm.formRules,
                inline: true
              }
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  ref: "detailListTable",
                  attrs: {
                    width: "100%",
                    border: "",
                    "highlight-current-row": "",
                    size: "mini",
                    "max-height": 450,
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    },
                    "row-key": _vm.detailRowKey,
                    "row-class-name": _vm.rowClassName,
                    data: _vm.detailList
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "60",
                      align: "center",
                      index: function(index) {
                        return _vm.indexMethod(index, _vm.detailForm)
                      }
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productNumber",
                      label: "产品编号",
                      "min-width": "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "150",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specificationModel",
                      label: "规格型号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "registrationNumber",
                      label: "注册证号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "manufacturer",
                      label: "生产厂家",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "unit",
                      label: "单位",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNumber",
                      label: "批号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trackingCode",
                      label: "序列号",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "manufacturerDate",
                      label: "生产日期",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "validityDate",
                      label: "有效期",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      label: "复检数量",
                      "min-width": "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "qualifiedQuantity",
                      label: "合格数量",
                      width: "100",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "enter-number",
                                  rawName:
                                    "v-enter-number:[{min:0,max:scope.row.remQuantity,precision:0}]",
                                  arg: {
                                    min: 0,
                                    max: scope.row.remQuantity,
                                    precision: 0
                                  }
                                }
                              ],
                              attrs: { size: "mini" },
                              model: {
                                value: scope.row.qualifiedQuantity,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "qualifiedQuantity",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "scope.row.qualifiedQuantity"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { sm: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c("div", [_vm._v("质量问题")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          rows: 1,
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.qualityRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "qualityRemark", $$v)
                          },
                          expression: "form.qualityRemark"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { sm: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c("div", [_vm._v("复检结果")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          rows: 1,
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.check,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "check", $$v)
                          },
                          expression: "form.check"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { sm: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c("div", [_vm._v("质量复检结论及处理意见")]),
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          rows: 1,
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.resultsRemark,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "resultsRemark", $$v)
                          },
                          expression: "form.resultsRemark"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "20px", display: "flex" },
                      attrs: { sm: 24, lg: 24, xl: 24 }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "经手人", prop: "handleUid" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.form.handleUid,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "handleUid", $$v)
                                },
                                expression: "form.handleUid"
                              }
                            },
                            _vm._l(_vm.userList, function(item, idx) {
                              return _c("el-option", {
                                key: "handleUid-" + idx,
                                attrs: { label: item.name, value: item.uid }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "质量负责人", prop: "qualityUid" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", filterable: "" },
                              model: {
                                value: _vm.form.qualityUid,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "qualityUid", $$v)
                                },
                                expression: "form.qualityUid"
                              }
                            },
                            _vm._l(_vm.userList, function(item, idx) {
                              return _c("el-option", {
                                key: "qualityUid-" + idx,
                                attrs: { label: item.name, value: item.uid }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }