var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "700px",
        "before-close": _vm.dialogClose,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        "append-to-body": true,
        center: "",
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            size: "mini",
            "label-width": "auto",
            "show-message": false,
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "受理意见及措施", prop: "acceptanceRemark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  disabled: _vm.complaintsStatus > 1
                },
                model: {
                  value: _vm.form.acceptanceRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "acceptanceRemark", $$v)
                  },
                  expression: "form.acceptanceRemark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "质量管理部门意见", prop: "qualityRemark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  disabled: _vm.complaintsStatus > 1
                },
                model: {
                  value: _vm.form.qualityRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "qualityRemark", $$v)
                  },
                  expression: "form.qualityRemark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主管领导意见", prop: "leaderRemark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  disabled: _vm.complaintsStatus > 1
                },
                model: {
                  value: _vm.form.leaderRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "leaderRemark", $$v)
                  },
                  expression: "form.leaderRemark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "处理结果", prop: "resultsRemark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  disabled: _vm.complaintsStatus > 1
                },
                model: {
                  value: _vm.form.resultsRemark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "resultsRemark", $$v)
                  },
                  expression: "form.resultsRemark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "执行时间", prop: "executeTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  disabled: _vm.complaintsStatus > 1,
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd"
                },
                model: {
                  value: _vm.form.executeTime,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "executeTime", $$v)
                  },
                  expression: "form.executeTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "执行人反馈", prop: "complaintsFeedback" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  maxlength: "500",
                  "show-word-limit": "",
                  disabled: _vm.complaintsStatus > 1
                },
                model: {
                  value: _vm.form.complaintsFeedback,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "complaintsFeedback", $$v)
                  },
                  expression: "form.complaintsFeedback"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.complaintsStatus <= 1
          ? _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.dialogClose } }, [
                  _vm._v("取 消")
                ]),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "prevent-re-click",
                        rawName: "v-prevent-re-click"
                      }
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.formSubmit }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c("el-button", { on: { click: _vm.dialogClose } }, [
                  _vm._v("关 闭")
                ])
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }