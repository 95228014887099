var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("耗材组套")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-container",
          { staticStyle: { height: "100%", overflow: "auto" } },
          [
            _c(
              "el-aside",
              {
                class: _vm.consumSetVisiable ? "move_right" : "move_left",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  border: "1px solid #f2f3f5",
                  position: "relative"
                },
                attrs: { width: _vm.consumSetVisiable ? "200px" : "20px" }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.consumSetVisiable,
                        expression: "consumSetVisiable"
                      }
                    ],
                    staticClass: "tree-class-container"
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c(
                        "div",
                        { staticClass: "top-buttons" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetAdd }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 新增 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetEdit }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-edit",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 编辑 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSetInvalid }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-trash",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 删除 ")
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "tree" },
                      [
                        _c("el-tree", {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.consumSetLoading,
                              expression: "consumSetLoading"
                            }
                          ],
                          ref: "consumSetTree",
                          staticClass: "filter-tree",
                          attrs: {
                            data: _vm.consumSetList,
                            props: _vm.consumSetProps,
                            "default-expand-all": "",
                            "highlight-current": true,
                            "expand-on-click-node": false,
                            "node-key": "ID"
                          },
                          on: { "node-click": _vm.consumSetRowClick },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c("span", {}, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      data.ID === 0
                                        ? _c("i", {
                                            class: node.expanded
                                              ? "folder el-icon-folder-opened"
                                              : "folder el-icon-folder"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-medkit",
                                            attrs: { "aria-hidden": "true" }
                                          }),
                                      _vm._v(" " + _vm._s(data.name))
                                    ]
                                  )
                                ])
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      right: "0"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "20px",
                          height: "60px",
                          background: "#eee",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          "border-top-left-radius": "5px",
                          "border-bottom-left-radius": "5px"
                        },
                        on: { click: _vm.consumSetVisiableChange }
                      },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "info", underline: false } },
                          [
                            _vm.consumSetVisiable
                              ? _c("i", {
                                  staticClass: "el-icon-caret-left",
                                  attrs: { "aria-hidden": "true" }
                                })
                              : _c("i", {
                                  staticClass: "el-icon-caret-right",
                                  attrs: { "aria-hidden": "true" }
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "el-aside",
              {
                class: _vm.consumSubSetVisiable ? "move_right2" : "move_left2",
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  border: "1px solid #f2f3f5",
                  position: "relative",
                  "margin-left": "10px"
                },
                attrs: { width: _vm.consumSubSetVisiable ? "200px" : "20px" }
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.consumSubSetVisiable,
                        expression: "consumSubSetVisiable"
                      }
                    ],
                    staticClass: "tree-class-container"
                  },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c(
                        "div",
                        { staticClass: "top-buttons" },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetAdd }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 新增 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetEdit }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-edit",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 编辑 ")
                            ]
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false, type: "info" },
                              on: { click: _vm.consumSubSetInvalid }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-trash",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" 删除 ")
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.consumSubSetLoading,
                            expression: "consumSubSetLoading"
                          }
                        ],
                        ref: "consumSubSetListTable",
                        attrs: {
                          data: _vm.consumSubSetList,
                          size: "mini",
                          stripeDELETE: "",
                          "highlight-current-row": "",
                          "row-key": "sub" + _vm.rowKey,
                          "row-class-name": _vm.rowClassName
                        },
                        on: { "row-click": _vm.consumSubSetRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "ID",
                            label: "ID",
                            width: "80",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "名称",
                            align: "center"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "50%",
                      right: "0"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "20px",
                          height: "60px",
                          background: "#f2f3f5",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center",
                          "border-top-left-radius": "5px",
                          "border-bottom-left-radius": "5px"
                        },
                        on: { click: _vm.consumSubSetVisiableChange }
                      },
                      [
                        _c(
                          "el-link",
                          { attrs: { type: "info", underline: false } },
                          [
                            _vm.consumSubSetVisiable
                              ? _c("i", {
                                  staticClass: "el-icon-caret-left",
                                  attrs: { "aria-hidden": "true" }
                                })
                              : _c("i", {
                                  staticClass: "el-icon-caret-right",
                                  attrs: { "aria-hidden": "true" }
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _c(
              "el-container",
              { staticStyle: { "margin-left": "10px" } },
              [
                _c(
                  "el-main",
                  { staticStyle: { "margin-top": "0", padding: "0" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-bottom": "10px",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.consumPageForm.productName
                                      ? true
                                      : false,
                                    expression:
                                      "consumPageForm.productName?true:false"
                                  }
                                ],
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  type: "info",
                                  size: "small",
                                  closable: true
                                },
                                on: { close: _vm.productNameTagClose }
                              },
                              [
                                _vm._v(
                                  "物资名称：" +
                                    _vm._s(
                                      _vm.consumPageForm.productName === "asc"
                                        ? "升序"
                                        : "倒序"
                                    )
                                )
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.consumPageForm.specificationModel
                                      ? true
                                      : false,
                                    expression:
                                      "consumPageForm.specificationModel?true:false"
                                  }
                                ],
                                attrs: {
                                  type: "info",
                                  size: "small",
                                  closable: true
                                },
                                on: { close: _vm.specificationModelTagClose }
                              },
                              [
                                _vm._v(
                                  "规格型号：" +
                                    _vm._s(
                                      _vm.consumPageForm.specificationModel ===
                                        "asc"
                                        ? "升序"
                                        : "倒序"
                                    )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.selectProduct }
                          },
                          [_vm._v("增加产品")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.consumLoading,
                            expression: "consumLoading"
                          }
                        ],
                        ref: "boxTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.consumList,
                          size: "small",
                          "highlight-current-row": "",
                          border: "",
                          "header-cell-style": {
                            background: "#f5f7fa",
                            borderColor: "#ebeef5",
                            color: "#333"
                          }
                        },
                        on: { "row-click": _vm.consumRowClick }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "index",
                            label: "序号",
                            width: "80",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.barCode",
                            "min-width": "150",
                            label: "主码",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.productNumber",
                            width: "100",
                            label: "产品编号",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "main.productName",
                              label: "物资名称",
                              align: "center",
                              "class-name": _vm.consumPageForm.productName
                                ? _vm.consumPageForm.productName + "ending"
                                : "",
                              "show-overflow-tooltip": ""
                            }
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    cursor: "pointer",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sortChange("productName")
                                    }
                                  }
                                },
                                [
                                  _vm._v(" 物资名称 "),
                                  _c("span", { staticClass: "caret-wrapper" }, [
                                    _c("i", {
                                      staticClass: "sort-caret ascending"
                                    }),
                                    _c("i", {
                                      staticClass: "sort-caret descending"
                                    })
                                  ])
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "specs.genericName",
                            width: "100",
                            label: "通用名称",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              prop: "specs.specificationModel",
                              width: "100",
                              label: "规格型号",
                              "class-name": _vm.consumPageForm
                                .specificationModel
                                ? _vm.consumPageForm.specificationModel +
                                  "ending"
                                : "",
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    cursor: "pointer",
                                    "align-items": "center"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.sortChange(
                                        "specificationModel"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(" 规格型号 "),
                                  _c("span", { staticClass: "caret-wrapper" }, [
                                    _c("i", {
                                      staticClass: "sort-caret ascending"
                                    }),
                                    _c("i", {
                                      staticClass: "sort-caret descending"
                                    })
                                  ])
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "产地/品牌",
                            width: "150",
                            align: "center",
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.main.productPlace) +
                                      "/" +
                                      _vm._s(row.main.brandName) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "main.manufacturer",
                            width: "150",
                            label: "生产厂家",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "total",
                            label: "数量",
                            width: "160",
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("el-input-number", {
                                    attrs: { size: "mini", min: 0 },
                                    on: {
                                      change: function($event) {
                                        return _vm.totalChange(row)
                                      }
                                    },
                                    model: {
                                      value: row.total,
                                      callback: function($$v) {
                                        _vm.$set(row, "total", $$v)
                                      },
                                      expression: "row.total"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            fixed: "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.consumRemove(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-footer",
                  {
                    staticStyle: {
                      height: "53px",
                      "border-top": "1px solid #eee",
                      padding: "10px"
                    }
                  },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        total: _vm.consumPageForm.total,
                        "current-page": _vm.consumPageForm.page,
                        "page-size": _vm.consumPageForm.pageSize
                      },
                      on: {
                        "current-change": _vm.consumPageFormPageChange,
                        "size-change": _vm.consumPageFormPageSizeChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("ConsumSetFormComponents", {
        ref: "ConsumSetFormComponents",
        on: {
          reload: function($event) {
            return _vm.loadConsumSet()
          }
        }
      }),
      _c("ConsumSubSetFormComponents", {
        ref: "ConsumSubSetFormComponents",
        on: {
          reload: function($event) {
            return _vm.loadConsumSubSet()
          }
        }
      }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }