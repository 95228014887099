//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'financial-invoice-manage-detail',
  components: {},
  data: function data() {
    return {
      dialogTitle: '查看明细',
      dialogVisible: false,
      loading: false,
      form: {
        invoiceList: []
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(row) {
      var that = this;
      that.dialogVisible = true;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    }
  }
};