var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.row
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-table",
              attrs: {
                model: _vm.row,
                size: "mini",
                "label-width": "120px",
                loading: _vm.formLoading,
                "label-suffix": ":"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "发票号" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.invoiceNumber))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "发票代码" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.invoiceCode))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "发票类型" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.invoiceTypeFormatter(_vm.row.invoiceType)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "开票日期" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.invoicingDate))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "金额（不含税）" } },
                        [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.row.money.toFixed(2)))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "税率" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.taxRate) + "%")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "税额" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.taxAmount.toFixed(2)))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("el-form-item", { attrs: { label: "含税金额" } }, [
                        _c("div", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.row.amountIncludingTax.toFixed(2)))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "校验码(后六位)" } },
                        [
                          _c("div", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.row.validationCode))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "auto-height", attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "附件" } }, [
                        _c(
                          "div",
                          { staticClass: "item-text" },
                          _vm._l(_vm.row.attachment, function(file, index) {
                            return _c(
                              "div",
                              { key: "attachment-" + index },
                              [
                                _c(
                                  "el-link",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.viewFile(file)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(file.name))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" }, attrs: { align: "center" } },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [_vm._v("关 闭")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }