import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    // 容器样式
    certList: {
      type: Array,
      required: false
    },
    targetCertList: {
      type: Array,
      required: false
    }
  },
  computed: {
    inputValue: function inputValue() {
      if (this.modelValue) {
        return this.modelValue.map(function (item) {
          return item.regNoCn;
        }).join(',');
      }

      return "";
    }
  },
  data: function data() {
    return {
      filterMethod: function filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      centerDialogVisible: false,
      modelValue: [],
      loading: false,
      leftFilterText: '',
      rightFilterText: ''
    };
  },
  watch: {
    centerDialogVisible: function centerDialogVisible(val) {
      if (val) {
        this.targetValue();
      }
    },
    targetCertList: function targetCertList(val) {
      this.targetValue();
    }
  },
  mounted: function mounted() {
    this.targetValue();
  },
  methods: {
    targetValue: function targetValue() {
      var _this = this;

      var data = [];
      this.modelValue = [];
      this.targetCertList.forEach(function (cert) {
        _this.modelValue.push(cert);
      });
    },
    selectRegNoCn: function selectRegNoCn(row) {
      var regNoCn = {
        ID: row.ID,
        regNoCn: row.regNoCn
      };
      this.modelValue.push(regNoCn);
      this.$emit('input', this.inputValue);
      this.$emit('update:targetCertList', this.modelValue);
      this.$emit('change', this.modelValue);
    },
    disselectRegNoCn: function disselectRegNoCn(index) {
      this.modelValue.splice(index, 1);
      this.$emit('input', this.inputValue);
      this.$emit('update:targetCertList', this.modelValue);
      this.$emit('change', this.modelValue);
    }
  }
};