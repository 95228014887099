var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购补差")]),
              _c("el-breadcrumb-item", [_vm._v("补差单详情")])
            ],
            1
          ),
          _vm.form.suppOrderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [_vm._v(" 补差单号：" + _vm._s(_vm.form.suppOrderNumber) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "formRef",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "110px",
              "show-message": false,
              loading: _vm.formLoading,
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "补差单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.suppOrderNumber))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "供应商" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.manufacturerName))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "相关采购单号" } }, [
                      _c("div", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.form.purchaseOrderNumbers))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "采购金额" } }, [
                      _c("div", { staticClass: "item-text text-primary" }, [
                        _vm._v(_vm._s(_vm.form.purchaseAmountSum.toFixed(2)))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "付款金额" } }, [
                      _c("div", { staticClass: "item-text text-primary" }, [
                        _vm._v(_vm._s(_vm.form.paAmount.toFixed(2)))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "到货金额" } }, [
                      _c("div", { staticClass: "item-text text-primary" }, [
                        _vm._v(_vm._s(_vm.form.arrAmount.toFixed(2)))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c("el-form-item", { attrs: { label: "补差金额" } }, [
                      _c("div", { staticClass: "item-text text-primary" }, [
                        _vm._v(_vm._s(_vm.form.suppAmountSum.toFixed(2)))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "退款金额", prop: "refundAmount" } },
                      [
                        _vm.form.state === 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { flex: "1" },
                                  attrs: { controls: false, precision: 2 },
                                  on: { change: _vm.refundAmountChange },
                                  model: {
                                    value: _vm.form.refundAmount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "refundAmount", $$v)
                                    },
                                    expression: "form.refundAmount"
                                  }
                                }),
                                _c("i", {
                                  staticClass: "el-icon-edit",
                                  staticStyle: {
                                    padding: "0 5px",
                                    "font-size": "12px",
                                    color: "#C0C4CC"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "120px",
                                      "border-left": "1px solid #eee"
                                    },
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.uploadLoading,
                                            expression: "uploadLoading"
                                          }
                                        ],
                                        attrs: {
                                          "auto-upload": true,
                                          "on-success": _vm.uploadSuccess,
                                          "on-change": _vm.uploadChange,
                                          "on-progress": _vm.uploadOnProgress,
                                          "on-error": _vm.uploadOnError,
                                          action: _vm.uploadURL,
                                          headers: _vm.uploadToken,
                                          multiple: false,
                                          "show-file-list": false,
                                          drag: false,
                                          accept: ".gif,.jpg,.jpeg,.png"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              slot: "trigger",
                                              type: "primary",
                                              underline: false
                                            },
                                            slot: "trigger"
                                          },
                                          [_vm._v("上传凭证")]
                                        ),
                                        _vm.form.refundVoucher.length > 0
                                          ? _c(
                                              "el-link",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                },
                                                attrs: {
                                                  type: "success",
                                                  underline: false
                                                },
                                                on: {
                                                  click: _vm.viewRefundVoucher
                                                }
                                              },
                                              [_vm._v("查看")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c("div", { staticClass: "item-text" }, [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-primary",
                                    staticStyle: { flex: "1" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.refundAmount.toFixed(2))
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "120px",
                                      "border-left": "1px solid #eee"
                                    },
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _vm.form.refundVoucher.length > 0
                                      ? _c(
                                          "el-link",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px"
                                            },
                                            attrs: {
                                              slot: "reference",
                                              type: "success",
                                              underline: false
                                            },
                                            on: {
                                              click: _vm.viewRefundVoucher
                                            },
                                            slot: "reference"
                                          },
                                          [_vm._v("查看")]
                                        )
                                      : _c("span", [_vm._v("未上传")])
                                  ],
                                  1
                                )
                              ])
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "转预付金额", prop: "depositAmount" } },
                      [
                        _vm.form.state === 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  height: "100%"
                                }
                              },
                              [
                                _c("el-input-number", {
                                  staticStyle: { flex: "1" },
                                  attrs: { controls: false, precision: 2 },
                                  on: { change: _vm.depositAmountChange },
                                  model: {
                                    value: _vm.form.depositAmount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "depositAmount", $$v)
                                    },
                                    expression: "form.depositAmount"
                                  }
                                }),
                                _c("i", {
                                  staticClass: "el-icon-edit",
                                  staticStyle: {
                                    padding: "0 5px",
                                    "font-size": "12px",
                                    color: "#C0C4CC"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "item-text text-primary" },
                              [
                                _vm._v(
                                  _vm._s(_vm.form.depositAmount.toFixed(2))
                                )
                              ]
                            )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 24, lg: 24, xl: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _vm.form.state === 1
                          ? _c("el-input", {
                              attrs: {
                                clearable: "",
                                "suffix-icon": "el-icon-edit",
                                maxlength: "100",
                                "show-word-limit": ""
                              },
                              model: {
                                value: _vm.form.remark,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "remark", $$v)
                                },
                                expression: "form.remark"
                              }
                            })
                          : _c("div", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(_vm.form.remark))
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _vm.form.state === 3
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.detailDialog()
                          }
                        }
                      },
                      [_vm._v("处理详情")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "tab-list",
                    staticStyle: { margin: "0 10px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 1 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(1)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "补差明细(" + _vm._s(_vm.detailList.length) + ")"
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 2 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(2)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "到货明细(" + _vm._s(_vm.deliveredList.length) + ")"
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class:
                          _vm.activeTabId === 3 ? "tab-item hover" : "tab-item",
                        on: {
                          click: function($event) {
                            return _vm.tabChange(3)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "采购明细(" + _vm._s(_vm.undeliveredList.length) + ")"
                        )
                      ]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("未收货总数："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.form.suppQuantitySum))]
                  )
                ]),
                _c("div", { staticStyle: { "margin-left": "30px" } }, [
                  _vm._v("未收货总金额："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.form.suppAmountSum.toFixed(2)))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 1,
                expression: "activeTabId===1"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "detailListTable",
                  data: _vm.detailList,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-class-name": _vm.rowClassName,
                  "max-height": "420"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseOrderNumber",
                    label: "采购单号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.productName",
                    label: "产品名称",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.registrationNumber",
                    label: "注册证号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "main.manufacturer",
                    label: "生产商",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specs.unit",
                    width: "60",
                    label: "单位",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "suppQuantity",
                    label: "补差数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseUnitPrice",
                    label: "单价",
                    width: "160",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "suppAmount",
                    label: "金额",
                    width: "100",
                    align: "center"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 2,
                expression: "activeTabId===2"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                ref: "deliveredListRef",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.deliveredList,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "max-height": "420"
                },
                on: { "expand-change": _vm.deliveredExpandChange }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编号",
                    prop: "specs.productNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "main.productName",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "规格型号",
                    prop: "specs.specificationModel",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "注册证号",
                    prop: "main.registrationNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "单位",
                    prop: "specs.unit",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "品牌",
                    prop: "main.brandName",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "入库数量",
                    prop: "inWarehouseQuantity",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "批号",
                    prop: "inventory.batch_number",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "序列号",
                    prop: "trackingCode",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    prop: "remarks",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouse.orderNumber",
                    label: "入库单号",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "inWarehouse.inWarehouseStatus",
                    label: "订单状态",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.inWarehouseStatusFormatter(
                                  row.inWarehouse.inWarehouseStatus
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTabId === 3,
                expression: "activeTabId===3"
              }
            ]
          },
          [
            _c(
              "el-table",
              {
                ref: "undeliveredListRef",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.undeliveredList,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "max-height": "420"
                },
                on: { "expand-change": _vm.undeliveredExpandChange }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品编号",
                    prop: "specs.productNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "产品名称",
                    prop: "main.productName",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "规格型号",
                    prop: "specs.specificationModel",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "注册证号",
                    prop: "main.registrationNumber",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "单位",
                    prop: "specs.unit",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "品牌",
                    prop: "main.brandName",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "采购数量",
                    prop: "purchaseQuantity",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "备注",
                    prop: "remarks",
                    "min-width": "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseOrder.orderNumber",
                    label: "采购单号",
                    "min-width": "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "purchaseOrder.state",
                    label: "订单状态",
                    width: "100",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.purchaseOrderStateFormatter(
                                  row.purchaseOrder.state
                                )
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              staticStyle: { margin: "0px" },
              attrs: { size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { staticStyle: { margin: "0" } },
                [
                  _vm.form.state === 1
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.form.state === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "danger" },
                          on: { click: _vm.formUnSubmit }
                        },
                        [_vm._v("撤销提交")]
                      )
                    : _vm._e(),
                  _vm.form.state === 2
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.formCheck }
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                  _vm.form.state === 3
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.orderPrint }
                        },
                        [_vm._v("打印")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { reload: _vm.init }
      }),
      _c("ViewComponents", { ref: "ViewComponents" }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "查看凭证",
            visible: _vm.refundVoucherVisible,
            width: "500px",
            "before-close": _vm.refundVoucherClose,
            "close-on-click-modal": false,
            "append-to-body": true,
            "modal-append-to-body": false,
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.refundVoucherVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { border: "", size: "small", data: _vm.form.refundVoucher }
            },
            [
              _c("el-table-column", {
                attrs: { label: "附件", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100px" },
                          attrs: {
                            src: _vm.uploadFilePrefixURL + scope.row.url,
                            fit: "contain",
                            "preview-src-list": _vm.refundVoucherList
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.form.state === 1
                          ? _c(
                              "el-link",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeRefundVoucher(scope.row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }