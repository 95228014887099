//
//
//
//
//
//
import util from '@/libs/util';
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  methods: {
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    }
  }
};