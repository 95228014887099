//
//
//
import CommonComponent from './form.vue';
export default {
  name: 'stock-inwarehouse-consignment-return-view',
  props: {
    id: {
      type: String,
      required: true,
      default: function _default() {
        return '0';
      }
    }
  },
  components: {
    CommonComponent: CommonComponent
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {}
  }
};