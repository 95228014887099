import { request } from '@/api/_service.js';
export function createErpPaymentSet(data) {
  return request({
    url: '/erpPaymentSetErp/createErpPaymentSet',
    method: 'post',
    data: data
  });
}
export function updateErpHospitalProduct(data) {
  return request({
    url: '/erpHospitalProductErp/updateErpHospitalProduct',
    method: 'put',
    data: data
  });
}
export function deleteErpPaymentSet(data) {
  return request({
    url: '/erpPaymentSetErp/deleteErpPaymentSet',
    method: 'delete',
    data: data
  });
}
export function deleteErpPaymentSetByIds(data) {
  return request({
    url: '/erpPaymentSetErp/deleteErpPaymentSetByIds',
    method: 'delete',
    data: data
  });
}
export function findErpPaymentSet(params) {
  return request({
    url: '/erpPaymentSetErp/findErpPaymentSet',
    method: 'get',
    params: params
  });
}
export function listErpPaymentSet(params) {
  return request({
    url: '/erpPaymentSetErp/getErpPaymentSetList',
    method: 'get',
    params: params
  });
}
export function updateErpPaymentSet(data) {
  return request({
    url: '/erpPaymentSetErp/updateErpPaymentSet',
    method: 'put',
    data: data
  });
}