import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js';
export function listCompany(params) {
  return request({
    url: '/company/getCompanyList',
    method: 'get',
    params: params
  });
}
export function findCompany(params) {
  return request({
    url: '/company/findCompany',
    method: 'get',
    params: params
  });
}
export function getCompany(params) {
  return request({
    url: '/company/getCompany',
    method: 'get',
    params: params
  });
}
export function createCompany(data) {
  return request({
    url: '/company/createCompany',
    method: 'post',
    data: data
  });
}
export function updateCompany(data) {
  return request({
    url: '/company/updateCompany',
    method: 'put',
    data: data
  });
}