var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            inline: true,
            rules: _vm.itemFormRules,
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入角色名称",
                          disabled: _vm.itemForm.name === "库管员",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "name", $$v)
                          },
                          expression: "itemForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        prop: "status",
                        "label-width": "100px"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.itemForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "status", $$v)
                            },
                            expression: "itemForm.status"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "normal" } }, [
                            _vm._v("启用")
                          ]),
                          _c("el-radio", { attrs: { label: "hidden" } }, [
                            _vm._v("停用")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "数据权限设置", "label-width": "150px" }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            disabled:
                              _vm.itemForm.name === "管理员" ||
                              _vm.itemForm.name === "超级管理员"
                                ? true
                                : false
                          },
                          model: {
                            value: _vm.itemForm.dataAuth,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "dataAuth", $$v)
                            },
                            expression: "itemForm.dataAuth"
                          }
                        },
                        [_vm._v("查看成本价")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "rulesTableRef",
                      attrs: {
                        size: "small",
                        border: "",
                        "default-expand-all": "",
                        "highlight-current-row": "",
                        data: _vm.ruleTreeData,
                        "row-key": "id",
                        "tree-props": { children: "children" },
                        "max-height": "500",
                        "header-cell-style": {
                          background: "#f5f7fa",
                          borderColor: "#ebeef5",
                          color: "#333"
                        }
                      },
                      on: {
                        select: _vm.selectChange,
                        "select-all": _vm.selectAllChange,
                        "selection-change": _vm.selectionChange
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "50",
                          align: "center",
                          "reserve-selection": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "名称",
                          width: "200",
                          prop: "title",
                          "show-overflow-tooltip": ""
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { label: "按钮权限", prop: "btn_permission" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    on: { change: _vm.btn_permissionChange },
                                    model: {
                                      value: _vm.itemForm.btn_permission,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.itemForm,
                                          "btn_permission",
                                          $$v
                                        )
                                      },
                                      expression: "itemForm.btn_permission"
                                    }
                                  },
                                  _vm._l(row.permission, function(item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: "btn_permission" + index,
                                        attrs: { label: item.key }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { span: 24, align: "center" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium" },
                      on: { click: _vm.dialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "prevent-re-click",
                          rawName: "v-prevent-re-click"
                        }
                      ],
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.formSubmit }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }