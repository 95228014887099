var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.dialogVisible
        ? _c(
            "el-form",
            {
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-input",
                    {
                      ref: "barCodeInput",
                      staticClass: "bar-input",
                      attrs: {
                        placeholder: _vm.barInputPlaceholder,
                        clearable: "",
                        readonly: _vm.readonly
                      },
                      on: { blur: _vm.focusInput },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.inputParse.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.form.barCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "barCode", $$v)
                        },
                        expression: "form.barCode"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "prepend", type: "primary" },
                          on: { click: _vm.inputTypeClick },
                          slot: "prepend"
                        },
                        [
                          _vm.inputType === "扫码"
                            ? _c("d2-icon-svg", {
                                staticStyle: { width: "20px", height: "20px" },
                                attrs: { name: "scan" }
                              })
                            : _c("i", {
                                staticClass: "el-icon-edit",
                                staticStyle: {
                                  width: "20px",
                                  height: "20px",
                                  "font-size": "18px"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tips !== ""
        ? _c("div", { staticStyle: { color: "#f00" } }, [
            _vm._v(_vm._s(_vm.tips))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }