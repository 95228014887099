import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
var customers = {
  name: 'demo1',
  // 数据定义
  data: function data() {
    return {
      info: {
        age: 18,
        gender: '',
        list: [{
          name: '张三',
          age: '18',
          gender: '女'
        }, {
          name: '李四',
          age: '28',
          gender: '男'
        }]
      },
      loginForm: {
        username: ''
      }
    };
  },
  created: function created() {},
  methods: {
    /**
     *  改变性别的方法
     *  这里踩坑 ---- 建议在render函数中传参的时候，箭头函数带上event. 这样不传参数的方法得到val就是正常的undefined
     *  如果不带event, 不传参数的方法得到val会被转义， 从而v-if,v-show 在使用的过程中，针对没有数值的场景就会有bug
     *  */
    editGender: function editGender(event, val) {
      console.log(event, val, 'eee');
      this.info.gender = val;
    },
    // v-if 的第三种写法，函数返回
    getGender: function getGender() {
      var h = this.$createElement;
      console.log(this.info.gender, '99');
      return this.info.gender ? this.info.gender === '男' ? h("div", ["\u6211\u662Fboy"]) : h("div", ["\u6211\u662Fgirl"]) : '';
    }
  },
  render: function render() {
    var _this = this;

    var h = arguments[0];
    return h("d2-container", {
      "class": "page"
    }, [h("template", {
      "slot": "header"
    }, [h("el-breadcrumb", {
      "attrs": {
        "separator": "/"
      }
    }, [h("el-breadcrumb-item", ["\u9996\u9875"]), h("el-breadcrumb-item", ["\u5E93\u623F\u7BA1\u7406"]), h("el-breadcrumb-item", ["\u5E93\u5B58\u7BA1\u7406"]), h("el-breadcrumb-item", ["\u5E93\u5B58\u660E\u7EC6"]), h("el-breadcrumb-item", ["\u4EA7\u54C1\u8FFD\u6EAF"])])]), h("div", [h("div", ["\u6211\u662F\u5C0F\u660E\uFF0C\u4ECA\u5E74", this.info.age]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": this.editGender
      }
    }, ["\u8BBE\u7F6E"]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": function click(event) {
          return _this.editGender(event, '男');
        }
      }
    }, ["\u8BBE\u7F6E\u7537"]), h("el-button", {
      "attrs": {
        "type": "primary"
      },
      "on": {
        "click": function click(event) {
          return _this.editGender(event, '女');
        }
      }
    }, ["\u8BBE\u7F6E\u5973"]), h("div", [this.info.gender]), h("div", [this.info.gender ? this.info.gender === '男' ? h("div", ["\u6211\u662Fboy"]) : h("div", ["\u6211\u662Fgirl"]) : '', this.info.gender && (this.info.gender === '男' && h("div", ["\u6211\u662Fboy"]) || this.info.gender === '女' && h("div", ["\u6211\u662Fgirl"])), this.getGender()]), h("div", [h("div", ["v-show\u7684\u7528\u6CD5"]), h("div", {
      "style": {
        display: this.info.gender ? 'block' : 'none'
      }
    }, [this.info.gender])]), h("div", [this.info.list.map(function (item) {
      return h("div", {
        "class": "mt-10"
      }, [h("div", ["\u59D3\u540D\uFF1A", item.name]), h("div", ["\u5E74\u9F84\uFF1A", item.age, "  \u6027\u522B\uFF1A ", item.gender])]);
    })]), h("div", {
      "class": "mt-10 w300"
    }, [h("el-input", {
      "ref": "username",
      "attrs": {
        "placeholder": "Username",
        "name": "username",
        "type": "text",
        "tabindex": "1",
        "auto-complete": "on"
      },
      "model": {
        value: _this.loginForm.username,
        callback: function callback($$v) {
          _this.$set(_this.loginForm, "username", $$v);
        }
      }
    })])])]);
  }
};
export default customers;